import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../../functions/colors';

type PIconArrowDown = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconArrowDown = ({ width, height, color, style }: PIconArrowDown) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" style={style}>
    <mask id="prefix_IconArrowDown" maskUnits="userSpaceOnUse" x={4} y={4} width={16} height={16}>
      <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" fill="#fff" />
    </mask>
    <g mask="url(#prefix_IconArrowDown)">
      <path fill={color} d="M0 0h24v24H0z" />
    </g>
  </svg>
);

IconArrowDown.defaultProps = {
  width: 24,
  height: 24,
  color: colors.error,
  style: {},
};

export default IconArrowDown;
