import React, { CSSProperties } from 'react';
import { colors } from '../../functions/colors';

type PIconDoubleCheck = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconDoubleCheck = ({ width, height, color, style }: PIconDoubleCheck) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <mask
      id="IconDoubleCheck"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x={1}
      y={18}
      width={78}
      height={46}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.1339 18.6333L38.8673 53.9L24.9339 40L20.2339 44.7L38.8673 63.3333L78.8673 23.3333L74.1339 18.6333ZM60.0004 23.3333L55.3004 18.6333L34.1671 39.7666L38.8671 44.4666L60.0004 23.3333ZM20.0005 63.3333L1.36719 44.7L6.10052 40L24.7005 58.6333L20.0005 63.3333Z"
        fill="white"
      />
    </mask>
    <g mask="url(#IconDoubleCheck)">
      <rect width={width} height={height} fill={color} />
    </g>
  </svg>
);

IconDoubleCheck.defaultProps = {
  width: 80,
  height: 80,
  color: colors.purpleLight,
  style: {},
};

export default IconDoubleCheck;
