import React, { ChangeEvent, ReactNode, useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import Button from '../../shared/components/buttons/Button';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import Table from '../../shared/components/table/Table';
import { useUser } from '../../store/reducers/user';
import IconEdit from '../../shared/images/icon/iconEdit';
import InsertUser from './insertEditUser/InsertEditUser';
import { User as IUser } from '../../shared/modals/user/user';
import BlockUser from './blockUser/BlockUser';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import IconBlock from '../../shared/images/icon/iconBlock';
import IconAuthorized from '../../shared/images/icon/iconAuthorized';
import { colors } from '../../shared/functions/colors';
import IconTrash from '../../shared/images/icon/iconTrash';
import DeleteUser from './deleteUser/DeleteUser';
import ImportUserList from './importUserList/ImportUserList';
import { ExtraUrl, MenuUrl } from '../../shared/enums/menuUrl';
import { DisplayFlexAlignCenter } from '../../shared/components/styled/general';
import BoxOnBoarding from '../../shared/components/onBoarding/BoxOnBoarding';
import { Container, Header, TextHeader, BoxHeaderButtons } from './styles';
import Filter, { ClickFilterHandle } from './filter/Filter';
import IconReport from '../../shared/images/icon/iconReport';
import { BoxNameArea, BoxAreas } from '../../shared/components/styled/box';
import BoxHorizontalButtons from '../../shared/components/box/boxHorizontalButtons/BoxHorizontalButtons';
import { useArea } from '../../store/reducers/area';
import { Area } from '../../shared/modals/area/area';
import SearchInput from '../../shared/components/input/searchInput/SearchInput';

type ParamTypes = {
  idUser?: string;
};

const User = () => {
  const { idUser } = useParams<ParamTypes>();
  const history = useHistory();
  const {
    getUsersFiltered,
    users,
    clearErrors,
    totalCount,
    setUserPagination,
    userPagination,
  } = useUser();
  const { getAllAreas } = useArea();
  const [openBlockUser, setOpenBlockUser] = useState(false);
  const [userAction, setUserAction] = useState<IUser>();
  const [searchUser, setSearchUser] = useState('');
  const [editUser, setEditUser] = useState(false);
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const childRef = useRef<ClickFilterHandle>();

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    getAllAreas();
    clearErrors();
  }, []);

  useEffect(() => {
    getUsersFiltered(userPagination);
  }, [userPagination]);

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    if (userPagination.constraints.search || searchUser.length >= 3) {
      debounceTimeout.current = setTimeout(() => {
        setUserPagination({
          ...userPagination,
          page: 1,
          constraints: { ...userPagination.constraints, search: searchUser },
        });
      }, 500);
    }

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchUser]);

  const renderAreas = (areas: Area[]) => {
    const areasComponent: ReactNode[] = [];
    areas.forEach((area: Area) => {
      areasComponent.push(<BoxNameArea>{area.name}</BoxNameArea>);
    });
    return <BoxAreas>{areasComponent}</BoxAreas>;
  };

  const goToEditUser = (user: IUser) => {
    setEditUser(true);
    setUserAction(user);
    history.push(`${MenuUrl.user}/${user.idUser}`);
  };

  const goToInsertUser = () => {
    setEditUser(false);
    setUserAction(undefined);
    history.push(`${MenuUrl.user}/${ExtraUrl.new}`);
  };

  const goToImportUserList = () => {
    setEditUser(false);
    setUserAction(undefined);
    history.push(`${MenuUrl.user}/${ExtraUrl.import}`);
  };

  const handleChangeSearchUser = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUser(event.target.value);
  };

  const handleOnClickDeleteUser = (user: IUser) => {
    setUserAction(user);
    setOpenDeleteUser(true);
  };

  const handleBlockUser = (user: IUser) => {
    setUserAction(user);
    setOpenBlockUser(true);
  };

  const handleOpenReportUser = (user: IUser) => {
    history.push(`${MenuUrl.userReport}/${user.idUser}`);
  };

  const renderRowsTable = (userStatusFiltered: IUser[]) =>
    userStatusFiltered.map((user: IUser) => ({
      columns: [
        user.name,
        renderAreas(user.areas),
        <ButtonIcon onClick={() => handleBlockUser(user)}>
          {user.block ? <IconBlock /> : <IconAuthorized />}
        </ButtonIcon>,
        <>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => goToEditUser(user)}>
            <IconEdit />
          </ButtonIcon>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleOnClickDeleteUser(user)}>
            <IconTrash />
          </ButtonIcon>
          {!!user.areas.length && (
            <ButtonIcon onClick={() => handleOpenReportUser(user)}>
              <IconReport color={colors.grey100} />
            </ButtonIcon>
          )}
        </>,
      ],
    }));

  const renderTable = (userStatusFiltered: IUser[]) => {
    return (
      <Table
        setPagination={setUserPagination}
        pagination={userPagination}
        totalCount={totalCount}
        nameReferenceReduxTable="table_user"
        listRowsPerPage={[10, 25, 50, 100]}
        titles={[
          {
            name: 'Nome',
            openClick: true,
            type: 'string',
            widthTd: '20%',
          },
          {
            name: 'Áreas',
            openClick: false,
            type: 'ReactNode',
            widthTd: '50%',
          },
          {
            name: 'Permissões',
            openClick: false,
            type: 'ReactNode',
          },
          {
            name: 'Ações',
            openClick: false,
            type: 'ReactNode',
          },
        ]}
        rows={renderRowsTable(userStatusFiltered)}
        withFilter
        setOpenFilter={setOpenFilter}
      />
    );
  };

  if (idUser && idUser !== ExtraUrl.import) {
    return <InsertUser isEdit={editUser} idUser={idUser !== ExtraUrl.new ? idUser : undefined} />;
  }

  if (idUser === ExtraUrl.import) {
    return <ImportUserList />;
  }

  return (
    <Container>
      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        pagination={userPagination}
        setPagination={setUserPagination}
        ref={childRef}
      />
      <BlockUser
        openBlockUser={openBlockUser}
        user={userAction}
        setOpenBlockUser={setOpenBlockUser}
      />
      <DeleteUser
        openDeleteUser={openDeleteUser}
        user={userAction}
        setOpenDeleteUser={setOpenDeleteUser}
      />
      <DisplayFlexAlignCenter>
        <BreadCrumbs
          listMenus={[
            {
              name: 'Membros',
            },
          ]}
        />
      </DisplayFlexAlignCenter>

      <Header>
        <TextHeader>Confira abaixo os membros cadastrados e/ou insira um novo membro.</TextHeader>
        <BoxHeaderButtons>
          <SearchInput
            placeholder="Buscar membro..."
            search={searchUser}
            onChange={handleChangeSearchUser}
          />
          <BoxOnBoarding idsShowOnBoarding={[9]}>
            <Button style={{ marginRight: 16, width: 180 }} onClick={goToInsertUser} icon="plus">
              NOVO MEMBRO
            </Button>
          </BoxOnBoarding>
          <Button style={{ width: 180 }} onClick={goToImportUserList} icon="plus">
            IMPORTAR LISTA
          </Button>
        </BoxHeaderButtons>
      </Header>
      <BoxHorizontalButtons
        pagination={userPagination}
        setPagination={setUserPagination}
        items={[
          {
            textButton: 'Membros Ativos',
            children: renderTable(users || []),
          },
          {
            textButton: 'Membros Bloqueados',
            children: renderTable(users || []),
          },
          {
            textButton: 'Membros Congelados',
            children: renderTable(users || []),
          },
        ]}
      />
    </Container>
  );
};

export default User;
