import React, { useState, Dispatch, SetStateAction } from 'react';

import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Category } from '../../../shared/modals/question/category';
import { useQuestion } from '../../../store/reducers/question';

type PDeleteCategory = {
  openDeleteCategory: boolean;
  setOpenDeleteCategory: Dispatch<SetStateAction<boolean>>;
  category: Category | undefined;
};

const DeleteCategory = ({
  openDeleteCategory,
  setOpenDeleteCategory,
  category,
}: PDeleteCategory) => {
  const [loading, setLoading] = useState(false);
  const { deleteCategory } = useQuestion();

  if (!category) return null;

  const handleDeleteCategory = async () => {
    setLoading(true);
    await deleteCategory(category.idCategory);
    setLoading(false);
    setOpenDeleteCategory(false);
  };

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title="Excluir categoria?"
      message="Todas as perguntas relacionadas a essa categoria serão excluídas permanentemente. Tem certeza que deseja excluir essa categoria?"
      onClose={() => setOpenDeleteCategory(false)}
      open={openDeleteCategory}
      onClickPrimary={handleDeleteCategory}
      onClickSecondary={() => setOpenDeleteCategory(false)}
    />
  );
};

export default DeleteCategory;
