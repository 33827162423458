import React, { CSSProperties, FC, ReactNode } from 'react';
import { useComponentVisible } from '../../../hooks/ComponentVisible';
import Button, { OwnPropsButton } from '../Button';
import { Container, Dropdown, ContainerButtons } from './styles';

interface PButtonDropdown extends OwnPropsButton {
  children: ReactNode;
  childrenDropdown: ReactNode;
  style?: CSSProperties;
  styleButton?: CSSProperties;
}

const ButtonDropdown: FC<PButtonDropdown> = ({
  style,
  styleButton,
  children,
  childrenDropdown,
  ...props
}: PButtonDropdown) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <Container style={style}>
      <Button
        style={styleButton}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
        {...props}
      >
        {children}
      </Button>
      {isComponentVisible ? (
        <Dropdown ref={ref}>
          {childrenDropdown}
          <ContainerButtons>
            <Button
              style={{ width: 88 }}
              typeButton="pink-white"
              onClick={() => setIsComponentVisible(false)}
            >
              CONFIRMAR
            </Button>
          </ContainerButtons>
        </Dropdown>
      ) : null}
    </Container>
  );
};

ButtonDropdown.defaultProps = {
  style: {},
  styleButton: {},
};

export default ButtonDropdown;
