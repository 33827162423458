import React from 'react';
import BreadCrumbs from '../../../shared/components/breadCrumbs/BreadCrumbs';

import { Container, TextHeader, Image, Header } from './styles';

const Hello = () => {
  return (
    <Container>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Início',
          },
        ]}
      />
      <Header>
        <TextHeader>Em breve você verá abaixo os principais indicadores de sua empresa.</TextHeader>
      </Header>

      <Image src="/img/hello.png" alt="hello" />
    </Container>
  );
};

export default Hello;
