import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../../functions/colors';

type PIconReward = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconDice = ({ width, height, color, style }: PIconReward) => (
  <svg width={width} height={height} fill="none" style={style}>
    <mask
      id="prefix_IconDice"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={3}
      y={3}
      width={18}
      height={18}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM6 16.5C6 17.33 6.67 18 7.5 18C8.33 18 9 17.33 9 16.5C9 15.67 8.33 15 7.5 15C6.67 15 6 15.67 6 16.5ZM7.5 9C6.67 9 6 8.33 6 7.5C6 6.67 6.67 6 7.5 6C8.33 6 9 6.67 9 7.5C9 8.33 8.33 9 7.5 9ZM10.5 12C10.5 12.83 11.17 13.5 12 13.5C12.83 13.5 13.5 12.83 13.5 12C13.5 11.17 12.83 10.5 12 10.5C11.17 10.5 10.5 11.17 10.5 12ZM16.5 18C15.67 18 15 17.33 15 16.5C15 15.67 15.67 15 16.5 15C17.33 15 18 15.67 18 16.5C18 17.33 17.33 18 16.5 18ZM15 7.5C15 8.33 15.67 9 16.5 9C17.33 9 18 8.33 18 7.5C18 6.67 17.33 6 16.5 6C15.67 6 15 6.67 15 7.5Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#prefix_IconDice)">
      <rect width="24" height="24" fill={color} />
    </g>
  </svg>
);

IconDice.defaultProps = {
  width: 24,
  height: 24,
  color: colors.white,
  style: {},
};

export default IconDice;
