import React from 'react';

import Table from '../../../shared/components/table/Table';
import { UserReport } from '../../../shared/modals/user/user';
import { insertPercentagemInNumber } from '../../../shared/functions/number';

type PContestsUserReport = {
  userReport: UserReport;
  isPrint: boolean;
};

const ContestsUserReport = ({ userReport, isPrint }: PContestsUserReport) => {
  const renderRowsTable = () =>
    userReport.tourneys.map(contest => ({
      columns: [
        contest.name,
        contest.position ? `${contest.position}º` : '',
        contest.percentage !== undefined ? insertPercentagemInNumber(contest.percentage, 2) : '',
        contest.status,
      ],
    }));

  return (
    <Table
      nameReferenceReduxTable="table_contests_user_report"
      listRowsPerPage={isPrint ? [1000] : [10, 25, 50, 100]}
      titles={[
        {
          name: 'Torneio',
          openClick: true,
          type: 'string',
        },
        {
          name: 'Posição',
          openClick: true,
          type: 'position',
        },
        {
          name: '% de acerto',
          openClick: true,
          type: 'percentage',
        },
        {
          name: 'Status',
          openClick: true,
          type: 'string',
        },
      ]}
      rows={renderRowsTable()}
    />
  );
};

export default ContestsUserReport;
