import React, { useState } from 'react';
import { useUser } from '../../../../store/reducers/user';
import ModalConfirm from '../../modal/modalConfirm/ModalConfirm';
import { Container } from './styles';

const ContactUsButton = () => {
  const { userData } = useUser();
  const identifier = userData ? userData.email : '';
  const company = userData ? userData.company.name : '';
  const message = `Olá! Preciso de suporte para ${identifier} (${company}). Minha dúvida: `;
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const redirectToWhatsapp = () => {
    window.open(`https://wa.me/5531993583302?text=${message}`, '_blank');
  };

  const handleOnCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirm = () => {
    setLoading(true);
    redirectToWhatsapp();
    handleOnCloseModal();
    setLoading(false);
  };

  return (
    <>
      <ModalConfirm
        title="Precisando de Ajuda?"
        message="Ao clicar no botão abaixo, você será redirecionado para o WhatsApp."
        open={openModal}
        onClose={handleOnCloseModal}
        onClickSecondary={handleOnCloseModal}
        onClickPrimary={handleConfirm}
        loadingPrimary={loading}
      />
      <Container onClick={() => setOpenModal(true)}>
        <img width="41px" src="/img/wa-logo.png" alt="" />
        Suporte
      </Container>
    </>
  );
};

export default ContactUsButton;
