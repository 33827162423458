import {
  ImportQuestionError,
  Question,
  QuestionPagination,
} from '../../../shared/modals/question/question';
import { Category } from '../../../shared/modals/question/category';

export enum QuestionTypes {
  SET_CATEGORY = '@question/SET_CATEGORY',
  SET_QUESTIONS = '@question/SET_QUESTIONS',
  SET_QUESTION = '@question/SET_QUESTION',
  SET_QUESTION_INFO_ANSWER = '@question/SET_QUESTION_INFO_ANSWER',
  SET_IMPORT_ERRORS = '@question/SET_IMPORT_ERRORS',
  SET_TOTAL_COUNT = '@question/SET_TOTAL_COUNT',
  SET_QUESTION_PAGINATION = '@question/SET_QUESTION_PAGINATION',
}

export type QuestionState = {
  readonly categories: Category[];
  readonly questions: Question[];
  readonly question?: Question;
  readonly questionInfoAnswer?: Question;
  readonly errors: ImportQuestionError[];
  readonly totalCount: number;
  readonly questionPagination: QuestionPagination;
};
