import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../../functions/colors';

type PIconLibrary = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconLibrary = ({ width, height, color, style }: PIconLibrary) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" style={style}>
    <mask id="prefix_IconLibrary" maskUnits="userSpaceOnUse" x={3} y={2} width={18} height={21}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 5c0 1.66-1.34 3-3 3S9 6.66 9 5s1.34-3 3-3 3 1.34 3 3zM3 8c3.48 0 6.64 1.35 9 3.55C14.36 9.35 17.52 8 21 8v11c-3.48 0-6.64 1.36-9 3.55C9.64 20.35 6.48 19 3 19V8z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#prefix_IconLibrary)">
      <path fill={color} d="M0 0h24v24H0z" />
    </g>
  </svg>
);

IconLibrary.defaultProps = {
  width: 24,
  height: 24,
  color: colors.white,
  style: {},
};

export default IconLibrary;
