import { Dispatch } from 'redux';

import * as libraryActions from './libraryActions';
import * as serviceLibrary from '../../../shared/services/serviceLibrary';
import * as serviceQuestion from '../../../shared/services/serviceQuestion';
import {
  ERROR_MAINTENANCE,
  LIBRARY_DELETE,
  LIBRARY_EDIT,
  LIBRARY_IMPORT,
  LIBRARY_INSERT,
} from '../../../shared/constants/messages';
import { doLogout } from '../../../shared/functions/connection/auth';
import { BodyImportLibrary, BodyInsertLibrary } from '../../../shared/modals/library/bodies';
import { Category } from '../../../shared/modals/question/category';
import { setSnackbar } from '../general/generalActions';
import { SUCCESS_SNACKBAR } from '../../../shared/components/snackbar/Snackbar';

export const getLibraries = () => async (dispatch: Dispatch) => {
  try {
    const libraries = await serviceLibrary.getLibraries();
    dispatch(libraryActions.setLibraries(libraries));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getLibrary = (idLibrary: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(libraryActions.setLibrary());
    const library = await serviceLibrary.getLibraryById(idLibrary);
    dispatch(libraryActions.setLibrary(library));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const insertLibrary = (body: BodyInsertLibrary) => async (dispatch: Dispatch) => {
  try {
    await serviceLibrary.insertLibrary(body);
    await getLibraries()(dispatch);
    dispatch(setSnackbar(LIBRARY_INSERT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const editLibrary = (idLibrary: string, body: BodyInsertLibrary) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceLibrary.editLibrary(idLibrary, body);
    await getLibraries()(dispatch);
    dispatch(setSnackbar(LIBRARY_EDIT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const deleteLibrary = (idLibrary: string) => async (dispatch: Dispatch) => {
  try {
    await serviceLibrary.deleteLibrary(idLibrary);
    await getLibraries()(dispatch);
    dispatch(setSnackbar(LIBRARY_DELETE, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const importLibrary = (body: BodyImportLibrary) => async (dispatch: Dispatch) => {
  try {
    await serviceLibrary.importLibrary(body);
    dispatch(setSnackbar(LIBRARY_IMPORT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const importLibraryInsertCategory = (
  body: BodyImportLibrary,
  nameCategory: string,
) => async (dispatch: Dispatch) => {
  try {
    const category: Category = await serviceQuestion.insertCategory({ name: nameCategory });
    await importLibrary({ ...body, idCategory: category.idCategory })(dispatch);
    dispatch(setSnackbar(LIBRARY_IMPORT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};
