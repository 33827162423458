import React, { useState, useEffect, ChangeEvent } from 'react';
import _, { cloneDeep } from 'lodash';
import Button from '../../shared/components/buttons/Button';
import { useUser } from '../../store/reducers/user';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import {
  Container,
  BoxInsertUser,
  InputUser,
  ContainerInsertUser,
  DisplayFlex,
  BoxInfo,
  Header,
  TextHeader,
  ImageIcon,
  ImageProfile,
  ContainerFlex,
  TextPlaceHolder,
  SubTitle,
  TitleWrapper,
  Title,
  Wrapper,
  ContainerRadios,
  ContainerColors,
  ContainerHome,
  ModalContainer,
  InputPhone,
  TitleWpp,
} from './styles';
import { TitleContest } from '../../shared/components/styled/text';
import { useCompany } from '../../store/reducers/company';
import { BodyEditCompany } from '../../shared/modals/company/bodies';
import Input from '../../shared/components/input/Input';
import { uploadImage } from '../../shared/services/serviceFile';
import EditPassword from './editPassword/EditPassword';
import ColorSelect from '../../shared/components/colorSelector/colorSelector';
import { PreferenceStyle } from '../../shared/modals/preferences/preferences';
import RadioPurple from '../../shared/components/input/inputRadio/radioPurple/radioPurple';
import { getPlayURL, handleChangeFieldValue } from '../../shared/functions/handlers';
import InputFileImage from '../../shared/components/input/inputFileImage/InputFile';
import IconEye from '../../shared/images/icon/iconEye';
import Modal from '../../shared/components/modal/Modal';
import { CompanyStyle } from '../../shared/modals/user/user';
import ModalConfirm from '../../shared/components/modal/modalConfirm/ModalConfirm';
import Security from './Security';
import Checkbox from '../../shared/components/input/checkbox/Checkbox';

const INITIAL_BODY = {
  name: '',
  passwordConnection: '',
  image: '',
  subdomain: '',
  user: [],
  whatsapp: '',
  showWhatsapp: true,
};

// DO NOT reference this variable directly! Use the getCleanPreference()
// function instead!
const INITIAL_PREFERENCE = {
  colorsStyles: {
    background: {
      backgroundPrimary: {
        backgroundColor: '',
      },
      backgroundSecondary: {
        backgroundColor: '',
      },
    },
    primaryColor: {
      color: '',
    },
    secondaryColor: {
      color: '',
    },
    textColor: {
      color: '',
    },
  },
  home: {
    coins: {
      display: 'block',
    },
    logo: {
      display: 'block',
    },
  },
  journeys: {
    background: { urlImage: '' },
  },
};

const Preferences = () => {
  const { userData } = useUser();
  const {
    getCompany,
    editCompanyPreferences,
    company,
    getCompanyPreferenceStyles,
    editCompanyPreferencesStyles,
  } = useCompany();

  const [loading, setLoading] = useState(false);
  const [openEditPassword, setOpenEditPassword] = useState(false);
  const [bodyEditCompany, setBodyEditCompany] = useState<BodyEditCompany>(INITIAL_BODY);
  const getCleanPreference = () => cloneDeep(INITIAL_PREFERENCE);
  const [preferences, setPreferences] = useState<PreferenceStyle | Record<string, never>>(
    getCleanPreference(),
  );
  const [companyStyles, setCompanyStyles] = useState<CompanyStyle>({});
  const [openModal, setOpenModal] = useState(false);
  const [openDefaultModal, setOpenDefaultModal] = useState(false);

  useEffect(() => {
    if (userData) {
      getCompany();
      getCompanyPreferenceStyles().then(setCompanyStyles);
    }
  }, [userData]);

  useEffect(() => {
    if (company) {
      setBodyEditCompany({
        ...bodyEditCompany,
        name: company.name,
        passwordConnection: '',
        image: company.image,
        user: company.user,
        whatsapp: company.whatsapp,
        showWhatsapp: company.showWhatsapp,
      });
    } else {
      setBodyEditCompany(INITIAL_BODY);
    }
  }, [company]);

  useEffect(() => {
    if (companyStyles.live?.colorsStyles) {
      setPreferences(companyStyles.live);
    } else {
      setPreferences(getCleanPreference());
    }
  }, [companyStyles]);

  const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files?.length) {
      const formData = new FormData();
      formData.append('image', files[0]);
      formData.append('origin', '');
      setLoading(true);
      const returnUploadImage = await uploadImage(formData, false);
      setLoading(false);
      setBodyEditCompany({
        ...bodyEditCompany,
        image: returnUploadImage.locationFile,
      });
    }
  };

  const handleEditCompany = async () => {
    setLoading(true);
    if (userData) {
      await editCompanyPreferences(bodyEditCompany);
    }
    setLoading(false);
  };

  const handleEditCompanyPreferences = async (status: 'preview' | 'live') => {
    setLoading(true);
    await editCompanyPreferencesStyles({ [status]: preferences });
    if (status === 'preview') {
      setOpenModal(true);
    }
    setLoading(false);
  };

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setBodyEditCompany({
      ...bodyEditCompany,
      name: event.target.value,
    });
  };

  const handleChangeWhatsapp = (event: ChangeEvent<HTMLInputElement>) => {
    setBodyEditCompany({
      ...bodyEditCompany,
      whatsapp: event.target.value.replace(/\D/g, ''), // remove special characters
    });
  };

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleChangeFieldValue(event, setPreferences);
  };

  const handleChangeImage = (path: string, image: string) => {
    const preferencesAux = { ...preferences };
    _.get(preferencesAux, path).urlImage = image;

    setPreferences(preferencesAux);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseDefaultModal = () => {
    setOpenDefaultModal(false);
  };

  const handleDefault = () => {
    editCompanyPreferencesStyles({ live: {}, preview: {} }).then(() => {
      setPreferences(getCleanPreference());
      setOpenDefaultModal(false);
    });
  };

  return (
    <Container>
      <ModalConfirm
        loadingPrimary={loading}
        title="Restaurar Padrão"
        message="Tem certeza que deseja restaurar o padrão?"
        onClose={handleCloseDefaultModal}
        open={openDefaultModal}
        onClickPrimary={handleDefault}
        onClickSecondary={handleCloseDefaultModal}
      />
      ;
      <BreadCrumbs
        listMenus={[
          {
            name: 'Preferências',
          },
        ]}
      />
      <EditPassword openEditPassword={openEditPassword} setOpenEditPassword={setOpenEditPassword} />
      <Header>
        <TextHeader>
          Abaixo você pode conferir ou editar os dados da empresa, planos e esclarecer dúvidas.
        </TextHeader>
      </Header>
      <BoxInfo>
        <TitleContest>DADOS DA EMPRESA</TitleContest>
        <ContainerInsertUser>
          <BoxInsertUser>
            <InputUser
              title="Nome da empresa*"
              placeholder="Digite"
              value={bodyEditCompany.name}
              onChange={handleChangeName}
              style={{ width: 672 }}
            />
            <InputUser
              title="Acesso de colaboradores*"
              placeholder="Digite"
              value={company?.subdomain}
              style={{ width: 672 }}
              disabled
            />
            <DisplayFlex>
              <div>
                <TitleWpp>WhatsApp para contato</TitleWpp>
                <InputPhone
                  mask="(99) 99999-9999"
                  maskChar={null}
                  placeholder={
                    bodyEditCompany.showWhatsapp ? 'Digite' : 'Ative o suporte via WhatsApp'
                  }
                  style={{ width: 400 }}
                  value={bodyEditCompany.showWhatsapp ? bodyEditCompany?.whatsapp : ''}
                  onChange={handleChangeWhatsapp}
                  disabled={!bodyEditCompany.showWhatsapp}
                />
                <Checkbox
                  style={{ marginBottom: '10px' }}
                  text="Marque para ativar suporte via WhatsApp"
                  checked={bodyEditCompany.showWhatsapp}
                  onChange={() =>
                    setBodyEditCompany({
                      ...bodyEditCompany,
                      showWhatsapp: !bodyEditCompany.showWhatsapp,
                    })
                  }
                />
              </div>

              <div>
                <TextPlaceHolder>Senha API</TextPlaceHolder>
                <Button
                  typeButton="primary-white"
                  style={{ width: 240, marginLeft: 32 }}
                  onClick={() => setOpenEditPassword(true)}
                >
                  EDITAR
                </Button>
              </div>
            </DisplayFlex>

            <Button
              style={{ width: 180, marginLeft: '2px', marginTop: '10px' }}
              loading={loading}
              onClick={() => handleEditCompany()}
            >
              SALVAR ALTERAÇÕES
            </Button>
          </BoxInsertUser>
          <ContainerFlex>
            <ImageIcon htmlFor="photo_select">
              <ImageProfile
                src={bodyEditCompany.image ? bodyEditCompany.image : '/img/profile_add.png'}
              />
            </ImageIcon>
            <Input type="file" onChange={handleChangeFile} size={104} hidden id="photo_select" />
          </ContainerFlex>
        </ContainerInsertUser>
      </BoxInfo>
      <TitleContest>CONFIGURAÇÃO & PERSONALIZAÇÃO</TitleContest>
      <BoxInsertUser>
        <ContainerColors>
          <TitleWrapper>
            <SubTitle>Geral - cores e estilos</SubTitle>
            <TextHeader style={{ marginTop: '16px' }}>
              Nesta seção você poderá personalizar qual cor do background do seu app, cores
              primárias e segundárias, entre outras configurações
            </TextHeader>
          </TitleWrapper>
          <ColorSelect
            name="colorsStyles.background.backgroundPrimary.backgroundColor"
            inputValue={preferences.colorsStyles.background.backgroundPrimary.backgroundColor}
            onChange={handleChangeValue}
            id="background"
            title="Background primário"
            subTitle="Será a principal cor do fundo do seu aplicativo."
          />
          <ColorSelect
            name="colorsStyles.background.backgroundSecondary.backgroundColor"
            inputValue={preferences.colorsStyles.background.backgroundSecondary.backgroundColor}
            onChange={handleChangeValue}
            id="background"
            title="Background secundário"
            subTitle="Será a cor do fundo complementar ao background primário."
          />
          <ColorSelect
            name="colorsStyles.primaryColor.color"
            inputValue={preferences.colorsStyles.primaryColor.color}
            onChange={handleChangeValue}
            title="Cor primária"
            id="primaryColor"
            subTitle="Será a cor principal em destaque do seu aplicativo."
          />
          <ColorSelect
            name="colorsStyles.secondaryColor.color"
            inputValue={preferences.colorsStyles.secondaryColor.color}
            onChange={handleChangeValue}
            title="Cor secundária"
            id="secondaryColor"
            subTitle="Será a cor complementar à primária do seu aplicativo."
          />
          <ColorSelect
            style={{ width: '100%' }}
            name="colorsStyles.textColor.color"
            inputValue={preferences.colorsStyles.textColor.color}
            onChange={handleChangeValue}
            title="Cor dos textos"
            id="textColor"
            subTitle="Será a cor dos textos e de toda tipografia do aplicativo."
          />
        </ContainerColors>
      </BoxInsertUser>
      <BoxInsertUser>
        <ContainerHome>
          <TitleWrapper>
            <SubTitle>Destaques - Home</SubTitle>
          </TitleWrapper>
          <Wrapper>
            <Title>Exibir logo da empresa?</Title>
            <ContainerRadios>
              <div>
                <RadioPurple
                  checked={preferences.home.logo.display === 'block'}
                  value="block"
                  labelTitle="sim"
                  id="logo-yes"
                  name="home.logo.display"
                  onChange={handleChangeValue}
                />
                <RadioPurple
                  checked={preferences.home.logo.display === 'none'}
                  value="none"
                  labelTitle="não"
                  id="logo-no"
                  name="home.logo.display"
                  onChange={handleChangeValue}
                />
              </div>
            </ContainerRadios>
          </Wrapper>
          <Wrapper>
            <Title>Exibir saldo de moedas?</Title>
            <ContainerRadios>
              <div>
                <RadioPurple
                  checked={preferences.home.coins.display === 'block'}
                  value="block"
                  labelTitle="sim"
                  id="coins-yes"
                  name="home.coins.display"
                  onChange={handleChangeValue}
                />
                <RadioPurple
                  checked={preferences.home.coins.display === 'none'}
                  value="none"
                  labelTitle="não"
                  id="coins-no"
                  name="home.coins.display"
                  onChange={handleChangeValue}
                />
              </div>
            </ContainerRadios>
          </Wrapper>
        </ContainerHome>
      </BoxInsertUser>
      <BoxInsertUser style={{ paddingTop: '0' }}>
        <TitleWrapper>
          <SubTitle>Destaques - Jornadas</SubTitle>
          <TextHeader style={{ marginTop: '16px' }}>
            Escolha qual imagem irá aparecer no fundo das Jornadas. Você pode carregar uma imagem da
            sua escolha ou utilizar a padrão.
          </TextHeader>
          <div style={{ display: 'flex', paddingBottom: '75px' }}>
            <Wrapper style={{ width: 250, paddingBottom: '145px' }}>
              <InputFileImage
                title="Inserir imagem de plano de fundo da Jornada"
                subTitle="Você pode inserir imagem em .png .jpeg .gif."
                urlImage={preferences.journeys.background.urlImage || '/img/journeyBackground.png'}
                setUrlImage={image => handleChangeImage('journeys.background', image)}
                style={{ height: 80, position: 'inherit', marginTop: '16px' }}
                duplicated={false}
              />
            </Wrapper>
          </div>
        </TitleWrapper>
      </BoxInsertUser>
      <Modal onClose={handleCloseModal} open={openModal}>
        <div>
          <SubTitle style={{ color: '#32C585' }}>Prévia Geral - cores e estilos</SubTitle>
          <ModalContainer>
            <iframe
              title="preview-Play"
              height={window.innerHeight}
              src={`${getPlayURL()}?preview=true`}
              frameBorder="0"
              style={{ border: 0, width: '100%', padding: '5%' }}
              allowFullScreen
            />
            <Button style={{ width: 152 }} onClick={handleCloseModal} typeButton="primary-white">
              FECHAR
            </Button>
          </ModalContainer>
        </div>
      </Modal>
      <ContainerFlex>
        <Button
          style={{ width: 180, marginLeft: '15px', fontSize: '13px', marginTop: '-70px' }}
          onClick={() => handleEditCompanyPreferences('preview')}
          typeButton="primary-white"
        >
          VISUALIZAR PRÉVIA
          <IconEye color="#512C85" style={{ marginLeft: '4px' }} />
        </Button>
        <Button
          style={{ width: 180, marginLeft: '15px', fontSize: '13px', marginTop: '-70px' }}
          onClick={() => setOpenDefaultModal(true)}
          disabled={_.isEqual(preferences, getCleanPreference())}
        >
          RESTAURAR PADRÃO
        </Button>
        <Button
          style={{ width: 180, marginLeft: '15px', fontSize: '13px', marginTop: '-70px' }}
          onClick={() => handleEditCompanyPreferences('live')}
          disabled={preferences === INITIAL_PREFERENCE || preferences === companyStyles.live}
        >
          SALVAR PERSONALIZAÇÃO
        </Button>
      </ContainerFlex>
      <Security />
    </Container>
  );
};

Preferences.defaultProps = {
  idUser: undefined,
};

export default Preferences;
