import styled from 'styled-components';
import { colors } from '../../../functions/colors';

export const Container = styled.div`
  width: 100%;
`;

export const InputFileStyle = styled.input`
  position: absolute;
  width: 100%;
  z-index: 2;
  opacity: 0;
`;

export const BoxImage = styled.div`
  width: 100%;
  background-color: ${colors.white};
  border: 1px solid ${colors.grey80};
  border-radius: 4px;
  padding: 8px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 152px;
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 136px;
`;
