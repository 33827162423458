import { Reducer } from 'redux';

import { CompanyTypes, CompanyState } from './companyTypes';

const INITIAL_STATE: CompanyState = {
  companies: [],
  company: undefined,
};

const reducer: Reducer<CompanyState> = (state = INITIAL_STATE, action) => {
  if (action.type === CompanyTypes.SET_COMPANIES) {
    return {
      ...state,
      companies: action.payload,
    };
  }
  if (action.type === CompanyTypes.SET_COMPANY) {
    return {
      ...state,
      company: action.payload,
    };
  }
  return state;
};

export default reducer;
