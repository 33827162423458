import React, { useEffect, useState } from 'react';

import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import { Column, RowTable, Table, TBody, THead } from '../../shared/components/table/styleTable';
import IconEdit from '../../shared/images/icon/iconEdit';
import IconLock from '../../shared/images/icon/iconLock';
import IconUnlock from '../../shared/images/icon/iconUnlock';
import Plus from '../../shared/images/icon/plus';
import { Company as CompanyObj } from '../../shared/modals/user/user';
import { useCompany } from '../../store/reducers/company';
import BlockCompany from './blockCompany/BlockCompany';
import InsertCompany from './insertEditCompany/InsertEditCompany';
import InsertAdminCompany from './insertAdminCompany/InsertAdminCompany';
import { Container, Title, BoxHead } from './styles';
import { useUser } from '../../store/reducers/user';
import { typeUser } from '../../shared/enums/typeUser';
import { useGeneral } from '../../store/reducers/general';

const Company = () => {
  const { companies, getCompanies } = useCompany();
  const [openInsertCompany, setOpenInsertCompany] = useState(false);
  const [openInsertAdminCompany, setOpenInsertAdminCompany] = useState(false);
  const [editCompany, setEditCompany] = useState(false);
  const [openBlockCompany, setOpenBlockCompany] = useState(false);
  const [companyAction, setCompanyAction] = useState<CompanyObj>();
  const { setSnackbar } = useGeneral();

  const { userData } = useUser();

  const isCustomerSuccess = userData?.typeUser?.id === typeUser.SUCESSO_DO_CLIENTE;

  useEffect(() => {
    getCompanies();
  }, []);

  const handleBlockCompany = (companie: CompanyObj) => {
    setCompanyAction(companie);
    setOpenBlockCompany(true);
  };

  const goToInsertCompany = () => {
    setOpenInsertCompany(true);
    setEditCompany(false);
  };

  const goToInsertAdminCompany = (companie: CompanyObj) => {
    if (isCustomerSuccess && companie.adminsLength > 0) {
      setSnackbar(
        'error',
        'Essa empresa já tem pelo menos um admin cadastrado. Se for necessário criar mais um solicite a um super admin',
        true,
      );

      return;
    }

    setCompanyAction(companie);
    setOpenInsertAdminCompany(true);
  };

  const goToEditCompany = (companie: CompanyObj) => {
    setOpenInsertCompany(true);
    setEditCompany(true);
    setCompanyAction(companie);
  };

  return (
    <Container>
      <InsertCompany
        openInsertCompany={openInsertCompany}
        setOpenInsertCompany={setOpenInsertCompany}
        isEdit={editCompany}
        company={companyAction}
      />
      {!!companyAction && (
        <InsertAdminCompany
          openInsertAdminCompany={openInsertAdminCompany}
          setOpenInsertAdminCompany={setOpenInsertAdminCompany}
          company={companyAction}
        />
      )}
      <BlockCompany
        openBlockCompany={openBlockCompany}
        company={companyAction}
        setOpenBlockCompany={setOpenBlockCompany}
      />
      <BoxHead>
        <Title>Empresas</Title>
        <ButtonIcon onClick={goToInsertCompany}>
          <Plus />
        </ButtonIcon>
      </BoxHead>

      <Table>
        <THead>
          <RowTable>
            <Column>Id</Column>
            <Column>Nome</Column>
            <Column>Licenças</Column>
            <Column>Status</Column>
            <Column>Ações</Column>
          </RowTable>
        </THead>
        <TBody>
          {companies.map((companie: CompanyObj) => (
            <RowTable>
              <Column>{companie.idCompany}</Column>
              <Column>{companie.name}</Column>
              <Column>{companie.usersLimit}</Column>
              <Column>{companie.block ? 'Bloqueado' : 'Ativo'}</Column>
              <Column>
                <ButtonIcon onClick={() => goToEditCompany(companie)}>
                  <IconEdit />
                </ButtonIcon>
                <ButtonIcon style={{ marginLeft: 16 }} onClick={() => handleBlockCompany(companie)}>
                  {companie.block ? <IconLock /> : <IconUnlock />}
                </ButtonIcon>
                <ButtonIcon
                  style={{ marginLeft: 16 }}
                  onClick={() => goToInsertAdminCompany(companie)}
                >
                  <IconEdit
                    color={isCustomerSuccess && companie.adminsLength > 0 ? 'red' : undefined}
                  />
                </ButtonIcon>
              </Column>
            </RowTable>
          ))}
        </TBody>
      </Table>
    </Container>
  );
};

export default Company;
