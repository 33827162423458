import { Reducer } from 'redux';

import { QuestionState, QuestionTypes } from './questionTypes';

const INITIAL_STATE: QuestionState = {
  categories: [],
  questions: [],
  question: undefined,
  questionInfoAnswer: undefined,
  errors: [],
  totalCount: 0,
  questionPagination: { limit: 10, page: 1, constraints: {} },
};

const reducer: Reducer<QuestionState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QuestionTypes.SET_CATEGORY:
      return {
        ...state,
        categories: action.payload,
      };
    case QuestionTypes.SET_QUESTIONS:
      return {
        ...state,
        questions: action.payload,
      };
    case QuestionTypes.SET_QUESTION:
      return {
        ...state,
        question: action.payload,
      };
    case QuestionTypes.SET_QUESTION_INFO_ANSWER:
      return {
        ...state,
        questionInfoAnswer: action.payload,
      };
    case QuestionTypes.SET_IMPORT_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case QuestionTypes.SET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case QuestionTypes.SET_QUESTION_PAGINATION:
      return {
        ...state,
        questionPagination: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
