import { Dispatch } from 'redux';

import * as reportActions from './reportActions';
import * as serviceReport from '../../../shared/services/serviceReport';
import { ReportFiltersType } from '../../../shared/enums/reportFiltersTypes';
import { ERROR_MAINTENANCE } from '../../../shared/constants/messages';
import { doLogout } from '../../../shared/functions/connection/auth';
import { setSnackbar } from '../general/generalActions';

export const getReport = (
  ids: string[],
  startDate: string,
  endDate: string,
  reportFiltersType: number,
) => async (dispatch: Dispatch) => {
  try {
    let report;
    switch (reportFiltersType) {
      case ReportFiltersType.category:
        report = await serviceReport.getReportCategory(ids, startDate, endDate);
        break;
      case ReportFiltersType.stage:
        report = await serviceReport.getReportStage(ids, startDate, endDate);
        break;
      case ReportFiltersType.user:
        report = await serviceReport.getReportUser(ids, startDate, endDate);
        break;
      case ReportFiltersType.question:
        report = await serviceReport.getReportQuestion(ids, startDate, endDate);
        break;
      case ReportFiltersType.trail:
        report = await serviceReport.getReportTrail(ids, startDate, endDate);
        break;
      case ReportFiltersType.contest:
        report = await serviceReport.getReportContest(ids, startDate, endDate);
        break;
      case ReportFiltersType.area:
      default:
        report = await serviceReport.getReportAreas(ids, startDate, endDate);
        break;
    }
    dispatch(reportActions.setReport(report));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getReportPresence = (idContests: string[]) => async (dispatch: Dispatch) => {
  try {
    dispatch(reportActions.setReportPresence());
    const report = await serviceReport.getReportPresence(idContests);
    dispatch(reportActions.setReportPresence(report.presenceGraphic));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};
