import * as React from 'react';

const UnderlineIcon = ({ active, disabled }: { active: boolean; disabled: boolean }) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <mask id="underlineIconMask" maskUnits="userSpaceOnUse" x={0} y={0} width="100%" height="100%">
      <rect x="0" y="0" width="100%" height="100%" fill={disabled ? '#777' : '#fff'} />
    </mask>
    <g>
      <path
        fill={active ? 'blue' : '#5f6368'}
        d="M6,12 C8.76,12 11,9.76 11,7 L11,0 L9,0 L9,7 C9,8.75029916 7.49912807,10 6,10 C4.50087193,10 3,8.75837486 3,7 L3,0 L1,0 L1,7 C1,9.76 3.24,12 6,12 Z M0,13 L0,15 L12,15 L12,13 L0,13 Z"
        transform="translate(3 3)"
        mask="url(#underlineIconMask)"
      />
    </g>
  </svg>
);

export default UnderlineIcon;
