import * as React from 'react';

const Plus = () => (
  <svg width={20} height={21} viewBox="0 0 28 30" fill="none">
    <path
      d="M16.89 12.026h10.904v4.7H16.89v12.353h-4.995V16.726H.992v-4.7h10.903V.612h4.995v11.414z"
      fill="#fff"
    />
  </svg>
);

export default Plus;
