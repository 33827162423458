import { Reducer } from 'redux';

import { AreaState, AreaTypes } from './areaTypes';

const INITIAL_STATE: AreaState = {
  areas: [],
  errors: [],
};

const reducer: Reducer<AreaState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AreaTypes.SET_ALL_AREAS:
      return {
        ...state,
        areas: action.payload,
      };
    case AreaTypes.SET_IMPORT_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
