import React, { ChangeEvent } from 'react';
import InputDate from '../../input/inputDateTime/InputDateTime';
import InputRadio from '../../input/inputRadio/InputRadio';
import { dateTimeStampToString, dateTimeStringToDateMaterialUi } from '../../../functions/date';

import { Container, SubTitle, Title, DisplayFlex } from './styles';

type PBoxStartShowFeedback = {
  attribute?: string;
  attributeName: string;
  setStartShow: (x?: string) => void;
};

const BoxStartShowFeedback = ({
  attribute,
  attributeName,
  setStartShow,
}: PBoxStartShowFeedback) => {
  const handleChangeDate = (event: ChangeEvent<HTMLInputElement>) => {
    setStartShow(event.target.value);
  };

  const handleCleanDate = () => {
    setStartShow(undefined);
  };

  const handleInsertCurrentDate = () => {
    const startShowTime = dateTimeStampToString(new Date());
    setStartShow(dateTimeStringToDateMaterialUi(startShowTime));
  };

  const title = () => {
    switch (attributeName) {
      case 'startShowFeedback':
        return 'Exibição do gabarito';
      case 'startShowRanking':
        return 'Exibição do Ranking (opcional)';
      default:
        return '';
    }
  };

  const subtitle = () => {
    switch (attributeName) {
      case 'startShowFeedback':
        return 'Selecione se deseja exibir o gabarito o tempo todo ou a partir de uma data.';
      case 'startShowRanking':
        return 'Selecione se deseja exibir o ranking o tempo todo ou a partir de uma data.';
      default:
        return '';
    }
  };

  const radioName = () => {
    switch (attributeName) {
      case 'startShowFeedback':
        return 'feedback_radio_0';
      case 'startShowRanking':
        return 'radio_1';
      default:
        return '';
    }
  };

  const firstOption = () => {
    switch (attributeName) {
      case 'startShowFeedback':
        return 'Não exibir';
      case 'startShowRanking':
        return 'Exibir sempre';
      default:
        return '';
    }
  };

  return (
    <Container>
      <Title>{title()}</Title>
      <SubTitle>{subtitle()}</SubTitle>
      <DisplayFlex>
        <InputRadio
          text={firstOption()}
          name={radioName()}
          checked={!attribute}
          onChange={handleCleanDate}
          style={{ marginRight: 32 }}
        />
        <InputRadio
          text="Exibir a partir de "
          name={radioName()}
          checked={!!attribute}
          onChange={handleInsertCurrentDate}
        />
        <InputDate
          value={attribute || ''}
          onChange={handleChangeDate}
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
          style={{ marginLeft: 32, width: 260 }}
        />
      </DisplayFlex>
    </Container>
  );
};

BoxStartShowFeedback.defaultProps = {
  attribute: undefined,
};

export default BoxStartShowFeedback;
