import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../../functions/colors';

type PIconHome = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconHome = ({ width, height, color, style }: PIconHome) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" style={style}>
    <mask id="prefix_IconHome" maskUnits="userSpaceOnUse" x={2} y={3} width={20} height={17}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8h5z" fill="#fff" />
    </mask>
    <g mask="url(#prefix_IconHome)">
      <path fill={color} d="M0 0h24v24H0z" />
    </g>
  </svg>
);

IconHome.defaultProps = {
  width: 24,
  height: 24,
  color: colors.white,
  style: {},
};

export default IconHome;
