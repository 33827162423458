import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import ReactLoading from 'react-loading';

import { noop } from 'lodash';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import {
  BoxInfo,
  ContainerMain,
  HeaderMain,
  TextHeaderMain,
} from '../../shared/components/styled/general';
import RankingContestReport from './rankingContestReport/RankingContestReport';
import { colors } from '../../shared/functions/colors';
import BoxMoreErros from './boxMoreErros/BoxMoreErros';
import GraphicsContestReport from './graphicsContestReport/GraphicsContestReport';
import InputDate from '../../shared/components/input/inputDate/InputDate';
import { TitleSemiBold } from '../../shared/components/styled/text';
import Button from '../../shared/components/buttons/Button';
import {
  ContainerMoreError,
  BoxFlex,
  Title,
  ButtonPrint,
  ButtonContainer,
  HeaderRanking,
} from './styles';
import { BoxRadiusBorder } from '../../shared/components/styled/box';
import ReportPresence from './reportPresence/ReportPresence';
import { useContest } from '../../store/reducers/contest';

type ParamTypes = {
  idContest: string;
};

const ContestReport = () => {
  const { idContest } = useParams<ParamTypes>();
  const { contestReport, getContestReport, downloadOnePageCsv } = useContest();
  const [isPrint, setIsPrint] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPresence, setShowPresence] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (idContest) {
      getContestReport(idContest);
    }
  }, []);

  const handlePrint = async () => {
    setIsPrint(true);
    setTimeout(() => {
      window.print();
      setIsPrint(false);
    }, 100);
  };

  const handleDownloadCsv = async () => {
    setLoading(true);
    await downloadOnePageCsv(idContest);
    setLoading(false);
  };

  return (
    <ContainerMain>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Torneios',
            action: () => history.go(-1),
          },
          {
            name: 'Relatório',
          },
        ]}
      />
      <HeaderMain>
        <TextHeaderMain>
          Você pode visualizar e personalizar seus relatórios e acompanhar diariamente.
        </TextHeaderMain>
      </HeaderMain>
      <BoxInfo>
        {contestReport ? (
          <>
            <BoxFlex>
              <InputDate
                title="Data de início"
                disabled
                onChangeDate={noop}
                value={contestReport.start_date.toString()}
                style={{ marginRight: 10, marginBottom: 30 }}
              />
              <InputDate
                title="Data de fim"
                disabled
                onChangeDate={noop}
                value={contestReport.end_date.toString()}
                style={{ marginBottom: 30 }}
              />
            </BoxFlex>
            <Title>{contestReport.name}</Title>
            <GraphicsContestReport contestReport={contestReport} />
            {showPresence && (
              <ReportPresence setShowPresence={setShowPresence} idContest={idContest} />
            )}
            <BoxRadiusBorder>
              <HeaderRanking>
                <TitleSemiBold color={colors.pinkDark}>Ranking total</TitleSemiBold>
                {!showPresence && (
                  <Button
                    style={{ width: 270 }}
                    typeButton="primary-white"
                    onClick={() => setShowPresence(true)}
                  >
                    MEMBROS QUE NÃO JOGARAM
                  </Button>
                )}
              </HeaderRanking>
              {contestReport && (
                <RankingContestReport
                  ranking={contestReport.ranking}
                  questionAmount={contestReport.question_amount}
                  isPrint={isPrint}
                />
              )}
            </BoxRadiusBorder>
            <ContainerMoreError>
              {contestReport.list_question.map((question, index) => {
                return (
                  <BoxMoreErros idContest={idContest} position={index + 1} question={question} />
                );
              })}
            </ContainerMoreError>
            <ButtonContainer>
              <ButtonPrint typeButton="primary-white" icon="iconPrint" onClick={handlePrint}>
                IMPRIMIR RELATÓRIO
              </ButtonPrint>
              <ButtonPrint icon="iconList" loading={loading} onClick={handleDownloadCsv}>
                EXPORTAR EM CSV
              </ButtonPrint>
            </ButtonContainer>
          </>
        ) : (
          <ReactLoading type="bubbles" color={colors.primary} width={44} height={40} />
        )}
      </BoxInfo>
    </ContainerMain>
  );
};

export default ContestReport;
