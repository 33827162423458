import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { colors } from '../../functions/colors';
import IconChevronBottom from '../../images/icon/iconChevronBottom';
import IconChevronRight from '../../images/icon/iconChevronRight';
import ButtonIcon from '../buttons/buttonIcon/ButtonIcon';
import { verifySortTable } from './verifySortTable';
import SelectNotBox from '../select/selectNotBox/SelectNotBox';
import {
  Table as StyleTable,
  THead,
  RowTable,
  Column,
  TBody,
  BottomPagination,
  HeaderSort,
  TextAmountRows,
} from './styleTable';
import { useGeneral } from '../../../store/reducers/general';
import IconFilter from '../../images/icon/iconFilter';
import { UserPagination } from '../../modals/user/user';
import { QuestionPagination } from '../../modals/question/question';

export enum Order {
  ASC = 0,
  DESC = 1,
}

export type ColumnReactNode = {
  columns: ReactNode[];
};

type Titles = {
  name: string | ReactNode;
  type: string;
  openClick: boolean;
  widthTd?: string;
  textAlignTd?: boolean;
};

type PTable = {
  titles: Titles[];
  rows: ColumnReactNode[];
  rowsPerPage?: number;
  listRowsPerPage?: number[];
  nameReferenceReduxTable?: string;
  withFilter?: boolean;
  setOpenFilter?: (x: boolean) => void;
  pagination?: UserPagination | QuestionPagination;
  setPagination?: (x: UserPagination) => void;
  totalCount?: number;
};

const initialTableInfo = {
  name: 'nameReferenceTable',
  currentPage: 1,
  amountPerPage: 10,
};

const Table = ({
  titles,
  rows,
  rowsPerPage,
  listRowsPerPage,
  nameReferenceReduxTable,
  withFilter,
  setOpenFilter,
  setPagination,
  pagination,
  totalCount,
}: PTable) => {
  const { tableInfo, setTableInfo } = useGeneral();
  const currentTableInfo =
    tableInfo.find(info => info.name === nameReferenceReduxTable) || initialTableInfo;
  const [typeOrder, setTypeOrder] = useState(Order.ASC);
  const [rowsEdit, setRowsEdit] = useState(rows);
  const [columnSort, setColumnSort] = useState(-1);
  const amountStart: number = pagination
    ? (pagination.page - 1) * pagination.limit + 1
    : (currentTableInfo.currentPage - 1) * currentTableInfo.amountPerPage + 1;
  const calculateAmountEnd = () => {
    if (pagination && totalCount && pagination.page * pagination.limit < totalCount) {
      return pagination.page * pagination.limit;
    }

    if (currentTableInfo.currentPage * currentTableInfo.amountPerPage < rows.length) {
      return currentTableInfo.currentPage * currentTableInfo.amountPerPage;
    }
    return totalCount || rows.length;
  };

  const amountEnd: number = calculateAmountEnd();

  useEffect(() => {
    if (pagination && setPagination) {
      setPagination({ ...pagination, page: 1, limit: currentTableInfo.amountPerPage });
    }
  }, [currentTableInfo.amountPerPage]);

  useEffect(() => {
    const currentTableInfoChange = tableInfo.find(info => info.name === nameReferenceReduxTable);
    if (!currentTableInfoChange && nameReferenceReduxTable) {
      setTableInfo([...tableInfo, { ...initialTableInfo, name: nameReferenceReduxTable }]);
    }
  }, []);

  const setCurrentPage = (currentPage: number) => {
    const tableInfoRemoveCurrent = tableInfo.filter(info => info.name !== nameReferenceReduxTable);
    const currentTableInfoChange = tableInfo.find(info => info.name === nameReferenceReduxTable);
    if (currentTableInfoChange) {
      setTableInfo([
        ...tableInfoRemoveCurrent,
        {
          ...currentTableInfoChange,
          currentPage,
        },
      ]);
    }
  };

  const setAmountPerPage = (amountPerPage: number) => {
    const tableInfoRemoveCurrent = tableInfo.filter(info => info.name !== nameReferenceReduxTable);
    const currentTableInfoChange = tableInfo.find(info => info.name === nameReferenceReduxTable);
    if (currentTableInfoChange) {
      setTableInfo([
        ...tableInfoRemoveCurrent,
        {
          ...currentTableInfoChange,
          amountPerPage,
        },
      ]);
    }
  };

  useEffect(() => {
    setColumnSort(-1);
    setRowsEdit(rows);
    if (
      (currentTableInfo.currentPage - 1) * currentTableInfo.amountPerPage > rows.length &&
      rows.length > 0
    ) {
      setCurrentPage(1);
    }
  }, [rows]);

  useEffect(() => {
    if (listRowsPerPage?.length) {
      const amoutPerPageFind = listRowsPerPage.find(
        item => item === currentTableInfo.amountPerPage,
      );
      if (!amoutPerPageFind) {
        setAmountPerPage(listRowsPerPage[0]);
      }
    } else {
      setAmountPerPage(rowsPerPage || 0);
    }
  }, [listRowsPerPage]);

  const isFirstPage = () => (pagination ? pagination.page <= 1 : currentTableInfo.currentPage <= 1);

  const isLastPage = () =>
    pagination
      ? amountEnd === totalCount
      : currentTableInfo.currentPage * currentTableInfo.amountPerPage >= rows.length;

  const sortPerColumn = (indexColumn: number, openClick: boolean) => {
    if (!openClick) {
      return;
    }
    let order = Order.ASC;
    if (indexColumn === columnSort && typeOrder === Order.ASC) {
      order = Order.DESC;
    }
    setTypeOrder(order);
    setColumnSort(indexColumn);
    const rowsSorted = [...rows].sort((a, b) =>
      verifySortTable(
        a.columns[indexColumn],
        b.columns[indexColumn],
        titles[indexColumn].type,
        order,
      ),
    );
    setRowsEdit([...rowsSorted]);
  };

  const handleChangeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    setAmountPerPage(parseInt(event.target.value, 10));
  };

  return (
    <>
      <StyleTable>
        <THead>
          <RowTable>
            {titles.map((title, index) => (
              <Column
                style={{ width: title.widthTd }}
                onClick={() => sortPerColumn(index, title.openClick)}
              >
                <HeaderSort openClick={title.openClick}>
                  {title.name}
                  {columnSort === index && (
                    <IconChevronBottom
                      style={{
                        transform: typeOrder === Order.ASC ? 'rotate(0)' : 'rotate(180deg)',
                        transition: '0.5s',
                      }}
                    />
                  )}
                </HeaderSort>
              </Column>
            ))}
            {withFilter && setOpenFilter && (
              <Column
                style={{ width: '10%', cursor: 'pointer' }}
                onClick={() => setOpenFilter(true)}
              >
                <IconFilter />
              </Column>
            )}
          </RowTable>
        </THead>
        <TBody>
          {rowsEdit.map((row, index) => {
            if (
              currentTableInfo.currentPage * currentTableInfo.amountPerPage < index + 1 ||
              currentTableInfo.currentPage * currentTableInfo.amountPerPage -
                currentTableInfo.amountPerPage >=
                index + 1
            ) {
              return null;
            }
            return (
              <RowTable>
                {row.columns.map((column, indexC) => (
                  <Column
                    style={{
                      textAlign: titles[indexC]?.textAlignTd ? 'center' : 'left',
                    }}
                  >
                    {column}
                  </Column>
                ))}
                {withFilter && <Column />}
              </RowTable>
            );
          })}
        </TBody>
      </StyleTable>
      <BottomPagination>
        {listRowsPerPage && (
          <SelectNotBox
            style={{ marginRight: 16, width: 122 }}
            title="Linhas por página:"
            nameSelect="select_rows_per_page"
            onChange={handleChangeSelect}
            listOptions={listRowsPerPage.map(value => {
              return {
                value: `${value}`,
                viewValue: `${value}`,
                selected: value === currentTableInfo.amountPerPage,
              };
            })}
          />
        )}
        <ButtonIcon
          disabled={isFirstPage()}
          onClick={() =>
            pagination && setPagination
              ? setPagination({ ...pagination, page: pagination.page - 1 })
              : setCurrentPage(currentTableInfo.currentPage - 1)
          }
        >
          <IconChevronRight
            style={{ transform: 'rotate(180deg)' }}
            color={isFirstPage() ? colors.grey100 : colors.primary}
          />
        </ButtonIcon>
        <TextAmountRows>
          {`${amountStart} - ${amountEnd} de ${totalCount || rows.length}`}
        </TextAmountRows>
        <ButtonIcon
          disabled={isLastPage()}
          onClick={() =>
            pagination && setPagination
              ? setPagination({ ...pagination, page: pagination.page + 1 })
              : setCurrentPage(currentTableInfo.currentPage + 1)
          }
        >
          <IconChevronRight color={isLastPage() ? colors.grey100 : colors.primary} />
        </ButtonIcon>
      </BottomPagination>
    </>
  );
};

Table.defaultProps = {
  rowsPerPage: 10,
  listRowsPerPage: undefined,
  nameReferenceReduxTable: 'nameReferenceDefault',
  withFilter: false,
  setOpenFilter: undefined,
  pagination: undefined,
  setPagination: undefined,
  totalCount: 0,
};

export default Table;
