import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
`;

export const BoxMenuMain = styled.div`
  display: flex;
`;

export const Main = styled.main`
  overflow-y: scroll;
  width: 100%;
  height: 100vh;

  @media print {
    height: auto;
  }
`;
