import * as React from 'react';
import { CSSProperties } from 'styled-components';

type PIconClose = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconAlert = ({ width, height, color, style }: PIconClose) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="icon_Alert"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x={2}
      y={2}
      width={20}
      height={20}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM13 13V7H11V13H13ZM13 17V15H11V17H13ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12Z"
        fill="white"
      />
    </mask>
    <g mask="url(#icon_Alert)">
      <rect width="24" height="24" fill={color} />
    </g>
  </svg>
);

IconAlert.defaultProps = {
  width: 24,
  height: 24,
  color: '#F95D4A',
  style: {},
};

export default IconAlert;
