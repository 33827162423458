import React, { ChangeEventHandler, MouseEventHandler } from 'react';
import { CSSProperties } from 'styled-components';

import IconTrash from '../../../images/icon/iconTrash';
import ButtonIcon from '../../buttons/buttonIcon/ButtonIcon';

import { Container, InputFileStyle } from './styles';
import { Title, SubTitle, ContainerInput, BoxInput, InfoLocalFile } from '../styles';
import IconAttach from '../../../images/icon/iconAttach';

const INITIAL_MESSAGE_NOT_FILE = 'Nenhum arquivo selecionado.';

type PInputFile = {
  title: string;
  subTitle?: string;
  style?: CSSProperties;
  onChange: ChangeEventHandler;
  handleDelete: MouseEventHandler<HTMLButtonElement>;
  valueInput: string;
};

const InputFile = ({ title, subTitle, onChange, style, handleDelete, valueInput }: PInputFile) => (
  <Container style={style}>
    <Title>{title}</Title>
    {subTitle && <SubTitle>{subTitle}</SubTitle>}
    <ContainerInput>
      <BoxInput>
        <InfoLocalFile>{valueInput || INITIAL_MESSAGE_NOT_FILE}</InfoLocalFile>
        <IconAttach />
        <InputFileStyle type="file" onChange={onChange} value={valueInput} />
      </BoxInput>
      <ButtonIcon onClick={handleDelete}>
        <IconTrash />
      </ButtonIcon>
    </ContainerInput>
  </Container>
);

InputFile.defaultProps = {
  subTitle: '',
  style: {},
};

export default InputFile;
