import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import { useUser } from '../../../store/reducers/user';
import Button from '../../../shared/components/buttons/Button';
import Modal from '../../../shared/components/modal/Modal';
import { BodyCreateUser } from '../../../shared/modals/user/bodies';
import { BoxButtons, Title, InputUser } from './styles';
import { Company } from '../../../shared/modals/user/user';
import { typeUser } from '../../../shared/enums/typeUser';

type PInsertAdminCompany = {
  openInsertAdminCompany: boolean;
  setOpenInsertAdminCompany: Dispatch<SetStateAction<boolean>>;
  company: Company;
};

const initialBody = {
  name: '',
  email: '',
  password: '',
  areas: [],
  admin: true,
  idTypeUser: typeUser.ADMIN,
};

const InsertAdminCompany = ({
  openInsertAdminCompany,
  setOpenInsertAdminCompany,
  company,
}: PInsertAdminCompany) => {
  const { insertAdminGlobal, userData } = useUser();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [bodyInsertAdminCompany, setBodyInsertAdminCompany] = useState<BodyCreateUser>(initialBody);

  const isCsOrSeller =
    userData?.typeUser?.id === typeUser.SUCESSO_DO_CLIENTE ||
    userData?.typeUser?.id === typeUser.VENDEDOR;

  useEffect(() => {
    setBodyInsertAdminCompany(initialBody);
  }, [company, openInsertAdminCompany]);

  const handleInsertAdminCompany = async () => {
    setLoading(true);
    try {
      const skipGetUsersFiltered = isCsOrSeller;

      await insertAdminGlobal(bodyInsertAdminCompany, company, skipGetUsersFiltered);
      setOpenInsertAdminCompany(false);
      setBodyInsertAdminCompany(initialBody);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
    setLoading(false);
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBodyInsertAdminCompany({
      ...bodyInsertAdminCompany,
      name: event.target.value,
    });
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBodyInsertAdminCompany({
      ...bodyInsertAdminCompany,
      email: event.target.value,
    });
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBodyInsertAdminCompany({
      ...bodyInsertAdminCompany,
      password: event.target.value,
    });
  };

  return (
    <Modal onClose={() => setOpenInsertAdminCompany(false)} open={openInsertAdminCompany}>
      <Title>Inserir Gerenciador</Title>
      <InputUser
        title="Digite o nome*"
        placeholder="Digite o nome"
        value={bodyInsertAdminCompany.name}
        onChange={handleChangeName}
      />
      <InputUser
        title="Digite o email*"
        placeholder="Digite o email"
        value={bodyInsertAdminCompany.email}
        onChange={handleChangeEmail}
      />
      <InputUser
        title="Digite a senha (opcional)"
        placeholder="Digite a senha"
        value={bodyInsertAdminCompany.password}
        onChange={handleChangePassword}
      />
      {errorMessage}
      <BoxButtons>
        <Button
          typeButton="primary-blue"
          style={{ width: 180 }}
          onClick={() => setOpenInsertAdminCompany(false)}
        >
          Cancelar
        </Button>
        <Button
          disabled={bodyInsertAdminCompany.name === ''}
          loading={loading}
          style={{ width: 180 }}
          onClick={handleInsertAdminCompany}
        >
          Inserir
        </Button>
      </BoxButtons>
    </Modal>
  );
};

export default InsertAdminCompany;
