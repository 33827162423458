import React, { useState, Dispatch, SetStateAction } from 'react';

import { useContest } from '../../../store/reducers/contest';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Contest } from '../../../shared/modals/contest/contest';

type PDuplicateContest = {
  openDuplicateContest: boolean;
  setOpenDuplicateContest: Dispatch<SetStateAction<boolean>>;
  contest: Contest | undefined;
};

const DuplicateContest = ({
  openDuplicateContest,
  setOpenDuplicateContest,
  contest,
}: PDuplicateContest) => {
  const [loading, setLoading] = useState(false);
  const { duplicateContest } = useContest();

  if (!contest) return null;

  const handleDuplicateContest = async () => {
    setLoading(true);
    await duplicateContest(contest.idTourney);
    setLoading(false);
    setOpenDuplicateContest(false);
  };

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title="Duplicar torneio"
      message="Tem certeza que deseja duplicar o torneio?"
      onClose={() => setOpenDuplicateContest(false)}
      open={openDuplicateContest}
      onClickPrimary={handleDuplicateContest}
      onClickSecondary={() => setOpenDuplicateContest(false)}
    />
  );
};

export default DuplicateContest;
