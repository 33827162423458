import React, { useEffect, ChangeEvent } from 'react';
import { useHistory } from 'react-router';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import Button from '../../shared/components/buttons/Button';
import Checkbox from '../../shared/components/input/checkbox/Checkbox';
import { ContainerMain, HeaderMain, TextHeaderMain } from '../../shared/components/styled/general';
import Table from '../../shared/components/table/Table';
import { MenuUrl } from '../../shared/enums/menuUrl';
import { Permission as IPermission, TypeUser } from '../../shared/modals/typeUser/typeUser';
import { useGeneral } from '../../store/reducers/general';
import { useTypeUser } from '../../store/reducers/typeUser';

const Permission = () => {
  const history = useHistory();
  const { getPermissions, getTypeUser, typesUser, permissions, editTypeUser } = useTypeUser();
  const { setLoadingBlockScreen } = useGeneral();

  useEffect(() => {
    getPermissions();
    getTypeUser();
  }, []);

  const handleChangePermission = async (
    event: ChangeEvent<HTMLInputElement>,
    typeUser: TypeUser,
    idPermission: string,
  ) => {
    setLoadingBlockScreen(true);
    let permissionsBody: string[] = typeUser.permissions
      ? typeUser.permissions.map(permission => permission.idPermission)
      : [];
    if (event.target.checked) {
      permissionsBody.push(idPermission);
    } else {
      permissionsBody = permissionsBody.filter(
        idPermissionBody => idPermissionBody !== idPermission,
      );
    }
    await editTypeUser(typeUser.idTypeUser, {
      name: typeUser.name,
      permissions: permissionsBody,
    });
    setLoadingBlockScreen(false);
  };

  const handleGoToTypeUser = () => {
    history.push(MenuUrl.typeUser);
  };

  const verifyCheckedPermission = (typeUser: TypeUser, permission: IPermission) => {
    let hasPermission = false;
    if (typeUser?.permissions?.length) {
      typeUser.permissions.forEach(permissionTypeUser => {
        if (permissionTypeUser.idPermission === permission.idPermission) {
          hasPermission = true;
        }
      });
    }
    return hasPermission;
  };

  const renderRowsTable = () =>
    permissions.map(permission => ({
      columns: [<>{permission.name}</>].concat(
        typesUser.map(typeUser => (
          <Checkbox
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleChangePermission(event, typeUser, permission.idPermission)
            }
            checked={verifyCheckedPermission(typeUser, permission)}
          />
        )),
      ),
    }));

  return (
    <ContainerMain>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Permissões',
          },
        ]}
      />
      <HeaderMain>
        <TextHeaderMain>Confira e edite abaixo a lista de permissões dos usuários</TextHeaderMain>
        <Button style={{ width: 210 }} onClick={handleGoToTypeUser} icon="plus">
          TIPOS DE USUÁRIOS
        </Button>
      </HeaderMain>
      {!!(typesUser.length && permissions.length) && (
        <Table
          nameReferenceReduxTable="table_permission"
          listRowsPerPage={[10000]}
          titles={[
            {
              name: '',
              openClick: false,
              type: 'string',
            },
          ].concat(
            typesUser.map(typeUser => ({
              name: typeUser.name,
              openClick: false,
              type: 'string',
            })),
          )}
          rows={renderRowsTable()}
        />
      )}
    </ContainerMain>
  );
};

export default Permission;
