import { PListAccordion } from '../../../shared/components/input/checkboxAccordion/CheckboxAccordion';
import { TableInfo } from '../../../shared/modals/general/table';
import { GeneralTypes } from './generalTypes';

export const setCurrentOnBoarding = (currentOnBoarding?: number[]) => ({
  type: GeneralTypes.SET_CURRENT_ON_BOARDING,
  payload: currentOnBoarding,
});

export const setCurrentIndexOnBoarding = (index: number) => ({
  type: GeneralTypes.SET_CURRENT_INDEX_ON_BOARDING,
  payload: index,
});

export const setTableInfo = (tableInfo: TableInfo[]) => ({
  type: GeneralTypes.SET_TABLE_INFO,
  payload: tableInfo,
});

export const setLoadingBlockScreen = (loadingBlockScreen: boolean) => ({
  type: GeneralTypes.SET_LOADING_BLOCK_SCREEN,
  payload: loadingBlockScreen,
});

export const setCurrentSelectedTab = (selectedTab: number) => ({
  type: GeneralTypes.SET_CURRENT_SELECTED_TAB,
  payload: selectedTab,
});

export const setFilterInput = (text: string) => ({
  type: GeneralTypes.SET_FILTER_INPUT,
  payload: text,
});

export const setFilterSelect = (selected: PListAccordion[]) => ({
  type: GeneralTypes.SET_FILTER_SELECT,
  payload: selected,
});

export type SnackBarActionInterface = {
  type: GeneralTypes;
  payload: { message: string; type: string; show: boolean };
};

export const setSnackbar = (
  message: string,
  type = 'error',
  show = true,
): SnackBarActionInterface => ({
  type: GeneralTypes.SET_SNACKBAR,
  payload: {
    message,
    type,
    show,
  },
});
