import React, { useState, Dispatch, SetStateAction } from 'react';

import { useCompany } from '../../../store/reducers/company';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Company } from '../../../shared/modals/user/user';

type PBlockCompany = {
  openBlockCompany: boolean;
  setOpenBlockCompany: Dispatch<SetStateAction<boolean>>;
  company: Company | undefined;
};

const BlockCompany = ({ openBlockCompany, setOpenBlockCompany, company }: PBlockCompany) => {
  const [loadingBlock, setLoadingBlock] = useState(false);
  const { blockCompany, unlockCompany } = useCompany();

  if (!company) return null;

  const handleBlockCompany = async () => {
    setLoadingBlock(true);
    if (company.block) {
      await unlockCompany(company.idCompany);
    } else {
      await blockCompany(company.idCompany);
    }
    setLoadingBlock(false);
    setOpenBlockCompany(false);
  };

  return (
    <ModalConfirm
      loadingPrimary={loadingBlock}
      message={
        company.block
          ? 'Tem certeza que deseja desbloquear a empresa?'
          : 'Tem certeza que deseja bloquear a empresa?'
      }
      title={company.block ? 'Desbloquear empresa' : 'Bloquear empresa'}
      onClose={() => setOpenBlockCompany(false)}
      open={openBlockCompany}
      onClickPrimary={handleBlockCompany}
      onClickSecondary={() => setOpenBlockCompany(false)}
    />
  );
};

export default BlockCompany;
