import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import Button from '../../shared/components/buttons/Button';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import IconEdit from '../../shared/images/icon/iconEdit';
import IconTrash from '../../shared/images/icon/iconTrash';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import Table from '../../shared/components/table/Table';
import Input from '../../shared/components/input/Input';
import { colors } from '../../shared/functions/colors';
import { filterArraySearch } from '../../shared/functions/utils';
import { dateTimeStampToString } from '../../shared/functions/date';
import IconCheck from '../../shared/images/icon/iconCheck';
import IconBlock from '../../shared/images/icon/iconBlock';
import IconDuplicate from '../../shared/images/icon/iconDuplicate';
import {
  ContainerMain,
  DisplayFlexAlignCenter,
  HeaderMain,
  TextHeaderMain,
} from '../../shared/components/styled/general';
import DeleteSelfSignUpCampaign from './delete/DeleteSelfSignUpCampaign';
import DuplicateSelfSignUpCampaign from './duplicate/DuplicateSelfSignUpCampaign';
import * as selfSignUpCampaignsService from '../../shared/services/selfSignUpCampaigns';
import ToggleSelfSignUpCampaignEnabled from './toggleEnabled/ToggleSelfSignUpCampaignEnabled';
import IconLink from '../../shared/images/icon/iconLink';
import Tooltip from '../../shared/components/tooltip/Tooltip';

export type SelfSignUpCampaignInterface = {
  id: string;
  name: string;
  usersCount: number;
  startDate: Date;
  endDate: Date;
  url: string;
  enabled: boolean;
};

const SelfSignUpCampaigns = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selfSignUpCampaigns, setSelfSignUpCampaigns] = useState<SelfSignUpCampaignInterface[]>([]);
  const [selfSignUpCampaign, setSelfSignUpCampaign] = useState<SelfSignUpCampaignInterface>();
  const [filter, setFilter] = useState('');
  const [filtered, setFiltered] = useState<SelfSignUpCampaignInterface[]>([]);
  const [isDeleteModalEnabled, setIsDeleteModalEnabled] = useState(false);
  const [isDuplicateModalEnabled, setIsDuplicateModalEnabled] = useState(false);
  const [isToggleModalEnabled, setIsToggleModalEnabled] = useState(false);

  const refreshList = () => {
    selfSignUpCampaignsService.getAll(dispatch).then(campaigns => {
      setSelfSignUpCampaigns(campaigns);
      setFiltered(campaigns);
    });
  };

  useEffect(refreshList, []);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    const filteredCampaigns = filterArraySearch(selfSignUpCampaigns, 'name', query);
    setFilter(query);
    setFiltered(filteredCampaigns);
  };

  const handleDelete = (campaign: SelfSignUpCampaignInterface) => {
    setIsDeleteModalEnabled(true);
    setSelfSignUpCampaign(campaign);
  };

  const handleDuplicate = (campaign: SelfSignUpCampaignInterface) => {
    setIsDuplicateModalEnabled(true);
    setSelfSignUpCampaign(campaign);
  };

  const handleToggle = (campaign: SelfSignUpCampaignInterface) => {
    setIsToggleModalEnabled(true);
    setSelfSignUpCampaign(campaign);
  };

  const handleCopyLink = (campaign: SelfSignUpCampaignInterface) => {
    navigator.clipboard.writeText(campaign.url);
    setSelfSignUpCampaign(campaign);
  };

  const handleCloseModal = () => {
    setIsDeleteModalEnabled(false);
    setIsDuplicateModalEnabled(false);
    setIsToggleModalEnabled(false);

    setSelfSignUpCampaign(undefined);
  };

  const goToNew = () => {
    history.push('/selfSignUpCampaigns/new');
  };

  const goToEdit = (campaign: SelfSignUpCampaignInterface) => {
    history.push(`/selfSignUpCampaigns/${campaign.id}/edit`);
  };

  const renderRowsTable = () => {
    return filtered.map(campaign => ({
      columns: [
        campaign.name,
        `${dateTimeStampToString(campaign.startDate)} - ${dateTimeStampToString(campaign.endDate)}`,
        campaign.usersCount,
        <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleToggle(campaign)}>
          {campaign.enabled ? <IconCheck color={colors.green} /> : <IconBlock color={colors.red} />}
        </ButtonIcon>,
        <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleCopyLink(campaign)}>
          {(selfSignUpCampaign?.id === campaign.id && (
            <Tooltip title="Link copiado.">
              <IconLink />
            </Tooltip>
          )) || <IconLink />}
        </ButtonIcon>,
        <>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => goToEdit(campaign)}>
            <IconEdit />
          </ButtonIcon>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleDuplicate(campaign)}>
            <IconDuplicate />
          </ButtonIcon>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleDelete(campaign)}>
            <IconTrash />
          </ButtonIcon>
        </>,
      ],
    }));
  };

  return (
    <ContainerMain>
      {selfSignUpCampaign && (
        <>
          <DeleteSelfSignUpCampaign
            id={selfSignUpCampaign.id}
            open={isDeleteModalEnabled}
            handleCloseModal={handleCloseModal}
            refreshList={refreshList}
          />
          <DuplicateSelfSignUpCampaign
            id={selfSignUpCampaign.id}
            open={isDuplicateModalEnabled}
            handleCloseModal={handleCloseModal}
            refreshList={refreshList}
          />
          <ToggleSelfSignUpCampaignEnabled
            selfSignUpCampaign={selfSignUpCampaign}
            open={isToggleModalEnabled}
            handleCloseModal={handleCloseModal}
            refreshList={refreshList}
          />
        </>
      )}
      <BreadCrumbs
        listMenus={[
          {
            name: 'Auto cadastro',
          },
        ]}
      />
      <HeaderMain>
        <TextHeaderMain>Você pode criar, excluir e editar os cadastros criados.</TextHeaderMain>
        <DisplayFlexAlignCenter>
          <Input
            style={{ marginRight: 16, width: 260 }}
            backgroundColor={colors.grey40}
            showBorder={false}
            icon="search"
            placeholder="Buscar cadastro..."
            value={filter}
            onChange={handleSearch}
          />
          <Button style={{ width: 160 }} onClick={goToNew} icon="plus">
            NOVO CADASTRO
          </Button>
        </DisplayFlexAlignCenter>
      </HeaderMain>
      <Table
        nameReferenceReduxTable="table_self_signup_campaign"
        listRowsPerPage={[10, 25, 50, 100]}
        titles={[
          {
            name: 'Campanha',
            openClick: true,
            type: 'string',
          },
          {
            name: 'Data início - fim',
            openClick: false,
            type: 'string',
          },
          {
            name: 'Nº de cadastros',
            openClick: false,
            type: 'number',
          },
          {
            name: 'Status ativar/desativar',
            openClick: false,
            type: 'ReactNode',
          },
          {
            name: 'Link',
            openClick: false,
            type: 'ReactNode',
          },
          {
            name: 'Ações',
            openClick: false,
            type: 'ReactNode',
            widthTd: '20%',
          },
        ]}
        rows={renderRowsTable()}
      />
    </ContainerMain>
  );
};

export default SelfSignUpCampaigns;
