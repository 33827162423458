import {
  ConnectApiPost,
  ConnectApiGet,
  urls,
  ConnectApiPUT,
  ConnectApiDelete,
} from '../functions/connection';
import { BodyTypeUser } from '../modals/typeUser/bodies';

export const getTypeUser = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_TYPE_USER);
  return respostaServico.data;
};

export const getPermissions = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_TYPE_USER_PERMISSION);
  return respostaServico.data;
};

export const getShowPermissions = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_TYPE_USER_SHOW_PERMISSION);
  return respostaServico.data;
};

export const insertTypeUser = async (body: BodyTypeUser) => {
  const respostaServico = await ConnectApiPost(urls.URL_TYPE_USER, body);
  return respostaServico.data;
};

export const deleteTypeUser = async (idTypeUser: number) => {
  const url = urls.URL_TYPE_USER_ID.replace('{idTypeUser}', `${idTypeUser}`);
  const respostaServico = await ConnectApiDelete(url);
  return respostaServico.data;
};

export const editTypeUser = async (idTypeUser: number, body: BodyTypeUser) => {
  const url = urls.URL_TYPE_USER_ID.replace('{idTypeUser}', `${idTypeUser}`);
  const respostaServico = await ConnectApiPUT(url, body);
  return respostaServico.data;
};
