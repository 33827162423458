import React, { ChangeEvent, Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Button from '../../../shared/components/buttons/Button';
import Input from '../../../shared/components/input/Input';
import Select, { POptions } from '../../../shared/components/select/Select';
import { Category } from '../../../shared/modals/question/category';
import { BodyCreateStage } from '../../../shared/modals/stage/bodies';
import { useStage } from '../../../store/reducers/stage';
import { useUser } from '../../../store/reducers/user';
import { useQuestion } from '../../../store/reducers/question';
import { Container, BoxInfo, BoxPadding, BoxFlex, BoxBottom } from './styles';
import BreadCrumbs from '../../../shared/components/breadCrumbs/BreadCrumbs';
import { TitleContest } from '../../../shared/components/styled/text';
import { MenuUrl } from '../../../shared/enums/menuUrl';
import { Level } from '../../../shared/modals/level/level';
import TextArea from '../../../shared/components/input/textArea/TextArea';
import InputFileImage from '../../../shared/components/input/inputFileImage/InputFile';
import Presentation, {
  BODY_START_PRESENTATION,
} from '../../../shared/components/presentation/Presentation';
import { Presentation as PresentationObj } from '../../../shared/modals/trail/trail';
import { getNextPositionStage } from '../../../shared/services/serviceStage';
import BoxStartShowFeedback from '../../../shared/components/box/boxStartShowFeedback/BoxStartShowFeedback';
import {
  dateTimeStampToString,
  dateTimeStringToDateMaterialUi,
} from '../../../shared/functions/date';
import MultipleSelect from '../../../shared/components/select/multipleSelect/MultipleSelect';
import RadioPurple from '../../../shared/components/input/inputRadio/radioPurple/radioPurple';
import { ContainerRadios } from '../../preferences/styles';
import { TextPlaceHolder } from '../../../shared/components/input/styles';
import { handleChangeFieldValue } from '../../../shared/functions/handlers';

const BODY_START = {
  name: '',
  description: '',
  categories: [],
  questionAmount: undefined,
  image: '',
  amountToConclude: undefined,
  position: undefined,
  idLevel: '',
  entryLimit: undefined,
  duplicated: false,
  startShowFeedback: undefined,
  showRightAnswer: true,
};

type PInsertStage = {
  setOpenInsertStage: Dispatch<SetStateAction<boolean>>;
  idStageEdit?: string;
  idTrail: string;
};

const InsertStage = ({ setOpenInsertStage, idStageEdit, idTrail }: PInsertStage) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [itensCategorySelected, setItensCategorySelected] = useState<POptions[]>([]);
  const [bodyStage, setBodyStage] = useState<BodyCreateStage>({
    ...BODY_START,
    presentationsAttributes: [{ ...BODY_START_PRESENTATION }],
  });
  const { categories, getCategories } = useQuestion();
  const { stage, insertStage, editStage, getStage, setStage } = useStage();
  const { levels, getAllLevels } = useUser();

  useEffect(() => {
    const verifyEditStage = async () => {
      if (idStageEdit) {
        await getStage(idStageEdit);
      } else {
        try {
          const { nextPosition } = await getNextPositionStage(idTrail);
          setBodyStage({
            ...bodyStage,
            position: nextPosition,
          });
        } catch (_) {
          setBodyStage({
            ...bodyStage,
            position: 1,
          });
        }
      }
    };
    verifyEditStage();
    if (levels.length <= 0) {
      getAllLevels();
    }
    if (!categories || categories.length === 0) {
      getCategories();
    }
  }, []);

  useEffect(() => {
    setBodyStage({
      ...bodyStage,
      categories: itensCategorySelected.map(option => option.value),
    });
  }, [itensCategorySelected]);

  useEffect(() => {
    if (stage && idStageEdit) {
      const startShowFeedback = stage.startShowFeedback
        ? dateTimeStampToString(new Date(stage.startShowFeedback))
        : undefined;
      setBodyStage({
        name: stage.name,
        description: stage.description,
        categories: stage.categories.map(category => category.idCategory),
        questionAmount: stage.questionAmount,
        amountToConclude: stage.amountToConclude,
        image: stage.image,
        position: stage.position,
        entryLimit: stage.entryLimit,
        idLevel: stage.idLevel,
        duplicated: stage.duplicated,
        duration: stage.duration,
        prize: stage.prize,
        showRightAnswer: stage.showRightAnswer,
        startShowFeedback: startShowFeedback
          ? dateTimeStringToDateMaterialUi(startShowFeedback)
          : undefined,
        presentationsAttributes:
          stage.presentations.length !== 0 ? stage.presentations : [{ ...BODY_START_PRESENTATION }],
      });

      const newListCategories = stage.categories.map(category => {
        const categoryFilter = categories.filter(
          newCategory => newCategory.idCategory === category.idCategory,
        );
        let nameCategory = '';
        if (categoryFilter.length > 0) {
          nameCategory = categoryFilter[0].name;
        }
        return {
          value: `${category.idCategory}`,
          viewValue: nameCategory,
        };
      });
      setItensCategorySelected(newListCategories);
    } else {
      setStage();
    }
  }, [stage]);

  const convertCategoriesToPOptions = (): POptions[] => {
    const arrayReturn: POptions[] = [];

    categories.forEach((category: Category) => {
      arrayReturn.push({
        value: `${category.idCategory}`,
        viewValue: category.name,
      });
    });

    return arrayReturn;
  };

  const convertLevelsToPOptions = (): POptions[] => {
    const arrayReturn: POptions[] = [
      {
        value: '',
        viewValue: 'Selecione um nível',
      },
    ];
    levels.forEach((level: Level) => {
      arrayReturn.push({
        value: `${level.idLevel}`,
        viewValue: level.name,
      });
    });

    return arrayReturn;
  };

  const verifyEnableSubmit = () => {
    if (bodyStage.name === '') {
      return false;
    }
    if (bodyStage.categories.length <= 0) {
      return false;
    }
    if (!bodyStage.idLevel) {
      return false;
    }
    if (
      (!bodyStage.amountToConclude && bodyStage.amountToConclude !== 0) ||
      bodyStage.amountToConclude < 0
    ) {
      return false;
    }
    if (!bodyStage.questionAmount || bodyStage.questionAmount <= 0) {
      return false;
    }

    return true;
  };

  const handleCloseInsertStage = () => {
    setOpenInsertStage(false);
  };

  const handleInsertStage = async () => {
    setLoading(true);
    try {
      if (idStageEdit && !!stage) {
        await editStage(bodyStage, idStageEdit, idTrail);
      } else {
        await insertStage(bodyStage, idTrail);
      }
      handleCloseInsertStage();
      setLoading(false);
    } catch (_) {
      setLoading(false);
    }
  };

  const handleOnChangeSelectLevel = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setBodyStage({
      ...bodyStage,
      idLevel: event.target.value,
    });
  };

  const handleChangeBody = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string,
  ) => {
    setBodyStage({
      ...bodyStage,
      [name]: event.target.value,
    });
  };

  return (
    <Container>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Trilhas',
            action: () => history.push(MenuUrl.trail),
          },
          {
            name: 'Fases',
            action: handleCloseInsertStage,
          },
          {
            name: 'Nova fase',
          },
        ]}
      />
      <BoxInfo>
        <TitleContest>CONFIGURAÇÕES</TitleContest>
        <BoxPadding>
          <BoxFlex>
            <div>
              <Input
                title="Nome da fase*"
                placeholder="Digite"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'name')}
                value={bodyStage.name}
                style={{ width: 400 }}
              />
              <Select
                nameSelect="select_level"
                title="Nível*"
                listOptions={convertLevelsToPOptions()}
                valueSelected={stage ? stage.idLevel.toString() : ''}
                onChange={handleOnChangeSelectLevel}
                style={{ width: '100%' }}
              />
            </div>
            <TextArea
              title="Descrição (opcional)"
              placeholder="Digite"
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChangeBody(e, 'description')}
              value={bodyStage.description}
              styleTextarea={{ height: 112 }}
              maxLength={500}
              showLenghtMax
              style={{ width: '100%', marginLeft: 32 }}
            />
          </BoxFlex>
        </BoxPadding>
        <TitleContest>DETALHAMENTOS</TitleContest>
        <BoxPadding>
          <BoxFlex>
            <div>
              <BoxFlex>
                <MultipleSelect
                  listOptions={convertCategoriesToPOptions()}
                  itensSelected={itensCategorySelected}
                  setItensSelected={setItensCategorySelected}
                  title="Categoria(s)*"
                />
              </BoxFlex>
              <BoxFlex>
                <Input
                  title="Valor da premiação (opcional)"
                  placeholder="0"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'prize')}
                  value={bodyStage.prize}
                  style={{ marginTop: 20, width: 220 }}
                />
                <Input
                  title="N° de tentativas (opcional)"
                  placeholder="0"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'entryLimit')}
                  value={bodyStage.entryLimit}
                  style={{ marginTop: 20, marginLeft: 32, width: 220 }}
                />
                <Input
                  title="Carga Horária (opcional)"
                  placeholder="0"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'duration')}
                  value={bodyStage.duration}
                  style={{ marginTop: 20, marginLeft: 32, width: 220 }}
                />
              </BoxFlex>
              <BoxFlex>
                <Input
                  title="Qtd de perguntas*"
                  placeholder="0"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeBody(e, 'questionAmount')
                  }
                  value={bodyStage.questionAmount}
                  style={{ width: 220 }}
                />
                <Input
                  title="Nº de acertos mínimos*"
                  placeholder="0"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeBody(e, 'amountToConclude')
                  }
                  value={bodyStage.amountToConclude}
                  style={{ marginLeft: 32, width: 220 }}
                />
                <div style={{ marginLeft: 32, width: 220 }}>
                  <TextPlaceHolder>Revelar resposta certa quando usuário errar?</TextPlaceHolder>
                  <ContainerRadios>
                    <RadioPurple
                      checked={bodyStage.showRightAnswer}
                      value="true"
                      labelTitle="sim"
                      id="show-yes"
                      name="showRightAnswer"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleChangeFieldValue(e, setBodyStage)
                      }
                    />
                    <RadioPurple
                      checked={!bodyStage.showRightAnswer}
                      value="false"
                      labelTitle="não"
                      id="show-no"
                      name="showRightAnswer"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleChangeFieldValue(e, setBodyStage)
                      }
                      style={{ marginLeft: 16 }}
                    />
                  </ContainerRadios>
                </div>
              </BoxFlex>
              <BoxStartShowFeedback
                attribute={bodyStage.startShowFeedback}
                attributeName="startShowFeedback"
                setStartShow={startShowFeedback =>
                  setBodyStage({ ...bodyStage, startShowFeedback })
                }
              />
            </div>
            <InputFileImage
              title="Inserir imagem (opcional)"
              subTitle="Você pode inserir imagem em .png .jpeg .gif."
              urlImage={bodyStage.image}
              setUrlImage={image => setBodyStage({ ...bodyStage, image })}
              style={{ width: '100%', marginLeft: 32 }}
              duplicated={bodyStage.duplicated}
              dimensions={{ width: 356, height: 144 }}
              isCrop
            />
          </BoxFlex>
        </BoxPadding>
        <Presentation
          presentations={bodyStage.presentationsAttributes}
          setPresentations={(presentationsAttributes: PresentationObj[]) =>
            setBodyStage({
              ...bodyStage,
              presentationsAttributes,
            })
          }
        />
        <BoxBottom>
          <Button
            onClick={handleCloseInsertStage}
            style={{ width: 120 }}
            typeButton="primary-white"
          >
            CANCELAR
          </Button>
          <Button
            loading={loading}
            onClick={handleInsertStage}
            style={{ width: 120, marginLeft: 16 }}
            disabled={!verifyEnableSubmit()}
          >
            CONCLUIR
          </Button>
        </BoxBottom>
      </BoxInfo>
    </Container>
  );
};

InsertStage.defaultProps = {
  idStageEdit: undefined,
};

export default InsertStage;
