import React from 'react';
import Table from '../../../shared/components/table/Table';
import { Ranking } from '../../../shared/modals/ranking/ranking';

type PRankingContestReport = {
  ranking: Ranking[];
  questionAmount: number;
  isPrint?: boolean;
};

const RankingContestReport = ({ ranking, questionAmount, isPrint }: PRankingContestReport) => {
  const renderRowsTable = () =>
    ranking.map(itemRanking => ({
      columns: [
        itemRanking.prize > 0 ? <img src="/img/trophy_24px.svg" alt="trofeu" /> : '',
        `${itemRanking.position}º`,
        itemRanking.name,
        itemRanking.time,
        `${itemRanking.score}/${questionAmount}`,
      ],
    }));

  return (
    <Table
      nameReferenceReduxTable="table_ranking_contest_report"
      listRowsPerPage={isPrint ? [1000] : [10, 25, 50, 100]}
      titles={[
        {
          name: '',
          openClick: false,
          type: 'ReactNode',
          widthTd: '5%',
        },
        {
          name: 'Posição',
          openClick: true,
          type: 'number',
          widthTd: '10%',
        },
        {
          name: 'Nome',
          openClick: true,
          type: 'string',
        },
        {
          name: 'Tempo',
          openClick: false,
          type: 'string',
          widthTd: '10%',
        },
        {
          name: 'Acertos',
          openClick: true,
          type: 'string',
          widthTd: '10%',
          textAlignTd: true,
        },
      ]}
      rows={renderRowsTable()}
    />
  );
};

RankingContestReport.defaultProps = {
  isPrint: false,
};

export default RankingContestReport;
