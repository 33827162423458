import styled from 'styled-components';
import { fonts } from '../../functions/fonts';
/* eslint-disable no-nested-ternary */
import { colors } from '../../functions/colors';

type PContainer = {
  error?: boolean;
  backgroundColor?: string;
  showBorder?: boolean;
  icon?: boolean;
  disabled?: boolean;
};

export const Container = styled.div<PContainer>`
  ${props => (props.icon ? 'display: flex;' : '')};
  position: relative;
`;

export const Title = styled.h2`
  font-family: ${fonts.semiBold};
  color: ${colors.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;

  margin-left: 8px;
`;

export const SubTitle = styled.h2`
  font-family: ${fonts.regular};
  color: ${colors.grey100};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;

  margin: 8px;
`;

export const ContainerInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InfoLocalFile = styled.p`
  color: ${colors.black};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  width: calc(100% - 40px);

  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BoxInput = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  background-color: transparent;
  border: 1px solid ${colors.grey80};
  border-radius: 4px;
  padding: 8px;
  color: ${colors.black};
  height: 40px;
  width: calc(100% - 40px);

  z-index: 1;
`;

export const TextPlaceHolder = styled.p`
  font-family: ${fonts.semiBold};
  color: ${colors.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  margin-bottom: 8px;
  margin-left: 4px;
`;

export const TextErrorMessage = styled.p`
  font-family: ${fonts.semiBold};
  color: ${colors.error};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  margin-bottom: 8px;
  margin-left: 4px;
`;

export const StyledInput = styled.input<PContainer>`
  ${props =>
    props.showBorder
      ? props.error
        ? `border: 1px solid ${colors.error}`
        : `border: 1px solid ${colors.grey80}`
      : 'border: none;'};
  background-color: ${props =>
    props.disabled ? colors.grey40 : props.backgroundColor ? props.backgroundColor : 'transparent'};
  border-radius: 4px;
  padding: ${props => (props.icon ? '0px 8px 0px 56px' : '0px 8px')};
  width: 100%;
  outline: none;
  color: ${props => (props.error ? colors.error : colors.black)};
  height: 40px;
  margin: ${props => (props.icon ? '0px 0px 0px -22px' : '0px')};

  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

export const TextLenght = styled.p`
  position: absolute;
  right: 12px;
  bottom: 20px;
  font-family: ${fonts.regular};
  color: ${colors.grey100};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
`;
