import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import ReactLoading from 'react-loading';

import { noop } from 'lodash';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import {
  BoxInfo,
  ContainerMain,
  HeaderMain,
  TextHeaderMain,
} from '../../shared/components/styled/general';
import { colors } from '../../shared/functions/colors';
import InputDate from '../../shared/components/input/inputDate/InputDate';
import { BoxFlex, Title, ButtonPrint, ButtonContainer } from './styles';
import { useTrail } from '../../store/reducers/trail';
import GraphicsTrailReport from './graphicsTrailReport/GraphicsTrailReport';
import { BoxRadiusBorder } from '../../shared/components/styled/box';
import TableDefault from '../../shared/components/table/tableDefault/TableDefault';
import ListQuestion from './listQuestions/ListQuestions';

type ParamTypes = {
  idTrail: string;
};

const TrailReport = () => {
  const { idTrail } = useParams<ParamTypes>();
  const { trailReport, getTrailReport } = useTrail();
  const [isPrint, setIsPrint] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (idTrail) {
      getTrailReport(idTrail);
    }
  }, []);

  const handlePrint = async () => {
    setIsPrint(true);
    setTimeout(() => {
      window.print();
      setIsPrint(false);
    }, 100);
  };

  return (
    <ContainerMain>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Trilha',
            action: () => history.go(-1),
          },
          {
            name: 'Relatório',
          },
        ]}
      />
      <HeaderMain>
        <TextHeaderMain>
          Você pode visualizar e personalizar seus relatórios e acompanhar diariamente.
        </TextHeaderMain>
      </HeaderMain>
      <BoxInfo>
        {trailReport ? (
          <>
            {trailReport.trail.start_date && trailReport.trail.end_date && (
              <BoxFlex>
                <InputDate
                  title="Data de início"
                  disabled
                  onChangeDate={noop}
                  value={trailReport.trail.start_date.toString()}
                  style={{ marginRight: 10, marginBottom: 30 }}
                />
                <InputDate
                  title="Data de fim"
                  disabled
                  onChangeDate={noop}
                  value={trailReport.trail.end_date.toString()}
                  style={{ marginBottom: 30 }}
                />
              </BoxFlex>
            )}
            <Title>{trailReport.trail.name}</Title>
            <GraphicsTrailReport trailReport={trailReport} />
            <BoxRadiusBorder>
              <TableDefault infoTable={trailReport.table_result_of_stage} isPrint={isPrint} />
            </BoxRadiusBorder>
            <ListQuestion listQuestion={trailReport.list_question} isPrint={isPrint} />
            <ButtonContainer>
              <ButtonPrint typeButton="primary-white" icon="iconPrint" onClick={handlePrint}>
                IMPRIMIR RELATÓRIO
              </ButtonPrint>
            </ButtonContainer>
          </>
        ) : (
          <ReactLoading type="bubbles" color={colors.primary} width={44} height={40} />
        )}
      </BoxInfo>
    </ContainerMain>
  );
};

export default TrailReport;
