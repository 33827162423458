import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../../functions/colors';

type PIconPreferences = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconPreferences = ({ width, height, color, style }: PIconPreferences) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" style={style}>
    <mask
      id="IconPreferences"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x={2}
      y={2}
      width={20}
      height={20}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4989 12C19.4989 12.34 19.4689 12.66 19.4289 12.98L21.5389 14.63C21.7289 14.78 21.7789 15.05 21.6589 15.27L19.6589 18.73C19.5389 18.95 19.2789 19.04 19.0489 18.95L16.5589 17.95C16.0389 18.34 15.4789 18.68 14.8689 18.93L14.4889 21.58C14.4589 21.82 14.2489 22 13.9989 22H9.99886C9.74886 22 9.53886 21.82 9.50886 21.58L9.12886 18.93C8.51886 18.68 7.95886 18.35 7.43886 17.95L4.94886 18.95C4.72886 19.03 4.45886 18.95 4.33886 18.73L2.33886 15.27C2.21886 15.05 2.26886 14.78 2.45886 14.63L4.56886 12.98C4.52886 12.66 4.49886 12.33 4.49886 12C4.49886 11.67 4.52886 11.34 4.56886 11.02L2.45886 9.37C2.26886 9.22 2.20886 8.95 2.33886 8.73L4.33886 5.27C4.45886 5.05 4.71886 4.96 4.94886 5.05L7.43886 6.05C7.95886 5.66 8.51886 5.32 9.12886 5.07L9.50886 2.42C9.53886 2.18 9.74886 2 9.99886 2H13.9989C14.2489 2 14.4589 2.18 14.4889 2.42L14.8689 5.07C15.4789 5.32 16.0389 5.65 16.5589 6.05L19.0489 5.05C19.2689 4.97 19.5389 5.05 19.6589 5.27L21.6589 8.73C21.7789 8.95 21.7289 9.22 21.5389 9.37L19.4289 11.02C19.4689 11.34 19.4989 11.66 19.4989 12ZM8.49886 12C8.49886 13.93 10.0689 15.5 11.9989 15.5C13.9289 15.5 15.4989 13.93 15.4989 12C15.4989 10.07 13.9289 8.5 11.9989 8.5C10.0689 8.5 8.49886 10.07 8.49886 12Z"
        fill="white"
      />
    </mask>
    <g mask="url(#IconPreferences)">
      <rect width="24" height="24" fill={color} />
    </g>
  </svg>
);

IconPreferences.defaultProps = {
  width: 24,
  height: 24,
  color: colors.white,
  style: {},
};

export default IconPreferences;
