import { BodyInsertArea, GetAreasParams } from '../modals/area/bodies';
import {
  ConnectApiPost,
  ConnectApiGet,
  urls,
  ConnectApiPUT,
  ConnectApiDelete,
} from '../functions/connection';

export const getAllAreas = async ({ withDisabled }: GetAreasParams = {}) => {
  const url = withDisabled ? `${urls.URL_AREA}?with_disabled=${withDisabled}` : urls.URL_AREA;
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const insertArea = async (body: BodyInsertArea) => {
  const respostaServico = await ConnectApiPost(urls.URL_AREA, body);
  return respostaServico.data;
};

export const deleteArea = async (idArea: string) => {
  const url = urls.URL_AREA_ID.replace('{idArea}', `${idArea}`);
  const respostaServico = await ConnectApiDelete(url);
  return respostaServico.data;
};

export const editArea = async (idArea: string, body: BodyInsertArea) => {
  const url = urls.URL_AREA_ID.replace('{idArea}', `${idArea}`);
  const respostaServico = await ConnectApiPUT(url, body);
  return respostaServico.data;
};

export const importAreas = async (body: FormData) => {
  const respostaServico = await ConnectApiPost(urls.URL_IMPORT_AREAS, body);
  return respostaServico.data;
};
