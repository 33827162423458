export const ERROR_AUTHORIZATION_VALUE_IS_INVALID = 'Valor usado para autorização é inválido.';
export const ERROR_CONNECTION =
  'Não foi possível conectar ao servidor, verifique sua conexão com a internet.';
export const ERROR_GENERIC = 'Ops, ocorreu um erro! Tente novamente mais tarde.';
export const ERROR_INTERNAL = 'Houve um erro interno no servidor.';
export const ERROR_ACCESS_DANIED = 'Sem permissão para acesso.';
export const ERROR_NOT_FOUND = 'Nenhum retorno encontrado.';
export const ERROR_INVALID_PASSWORD = 'Usuário e/ou senha inválidos';
export const SUCESS_GENERIC = 'Concluído!';
export const ERROR_USER_BLOCKED = 'Usuário bloqueado';
export const ERROR_MAINTENANCE =
  'O servidor encontra-se em manutenção. Tente novamente mais tarde.';

export const USER_BLOCK = 'Usuário bloqueado com sucesso!';
export const USER_UNLOCK = 'Usuário desbloqueado com sucesso!';
export const USER_INSERT = 'Usuário inserido com sucesso!';
export const USER_IMPORT = 'Usuários importados com sucesso!';
export const ADMIN_INSERT = 'Administrador inserido com sucesso!';
export const USER_EDIT = 'Usuário modificado com sucesso!';
export const SEND_PASSWORD = 'Senha enviada por e-mail com sucesso!';
export const CHANGE_PASSWORD = 'Senha modificada com sucesso!';
export const USER_DELETE = 'Usuário deletado com sucesso!';
export const AREA_INSERT = 'Área inserida com sucesso!';
export const AREA_DELETE = 'Área deletada com sucesso!';
export const AREA_EDIT = 'Área modificada com sucesso!';
export const AREA_IMPORT = 'Área importada com sucesso!';

export const COMPANY_CREATE = 'Empresa criada com sucesso!';
export const COMPANY_EDIT = 'Empresa modificada com sucesso!';
export const PREFERENCES_EDIT = 'Preferências modificadas com sucesso!';
export const COMPANY_BLOCK = 'Empresa bloqueada com sucesso!';
export const COMPANY_UNLOCK = 'Empresa desbloqueada com sucesso!';

export const LIBRARY_INSERT = 'Biblioteca inserida com sucesso!';
export const LIBRARY_EDIT = 'Biblioteca modificada com sucesso!';
export const LIBRARY_DELETE = 'Biblioteca deletada com sucesso!';
export const LIBRARY_IMPORT = 'Biblioteca importada com sucesso!';

export const STAGE_INSERT = 'Fase inserida com sucesso!';
export const STAGE_EDIT = 'Fase modificada com sucesso!';
export const STAGE_DELETE = 'Fase deletada com sucesso!';
export const STAGE_DUPLICATE = 'Fase duplicada com sucesso!';

export const CATEGORY_INSERT = 'Categoria inserida com sucesso!';
export const CATEGORY_EDIT = 'Categoria modificada com sucesso!';
export const CATEGORY_DELETE = 'Categoria deletada com sucesso!';

export const QUESTION_INSERT = 'Questão inserida com sucesso!';
export const QUESTION_IMPORT = 'Questões importadas com sucesso!';
export const QUESTION_EDIT = 'Questão modificada com sucesso!';
export const QUESTION_DELETE = 'Questão deletada com sucesso!';
export const QUESTION_DUPLICATE = 'Questão duplicada com sucesso!';

export const CONTEST_INSERT = 'Torneio inserido com sucesso!';
export const CONTEST_EDIT = 'Torneio modificado com sucesso!';
export const CONTEST_DELETE = 'Torneio deletado com sucesso!';
export const CONTEST_DUPLICATE = 'Torneio duplicado com sucesso!';
export const CONTEST_PERMISSION = 'Permissão modificada com sucesso!';

export const TRAIL_INSERT = 'Trilha inserida com sucesso!';
export const TRAIL_EDIT = 'Trilha modificada com sucesso!';
export const TRAIL_PERMISSION = 'Permissão modificada com sucesso!';
export const TRAIL_DELETE = 'Trilha deletada com sucesso!';
export const TRAIL_DUPLICATE = 'Trilha duplicada com sucesso!';

export const TYPE_USER_INSERT = 'Tipo de usuário inserido com sucesso!';
export const TYPE_USER_EDIT = 'Tipo de usuário editado com sucesso!';
export const TYPE_USER_DELETE = 'Tipo de usuário deletado com sucesso!';

export const REWARD_INSERT = 'Prêmio inserido com sucesso!';
export const REWARD_EDIT = 'Prêmio editado com sucesso!';
export const REWARD_DELETE = 'Prêmio deletado com sucesso!';
export const REWARD_PERMISSION = 'Permissão modificada com sucesso!';
export const REWARD_DUPLICATE = 'Prêmio duplicado com sucesso!';
