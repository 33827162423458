import React, { FC } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { Container, Title, BoxInput } from './styles';

import { fonts } from '../../../functions/fonts';

type InputDateTimeProps = TextFieldProps & { instructions?: string };

const InputDate: FC<InputDateTimeProps> = ({
  title,
  instructions,
  style,
  onChange,
  ...props
}: InputDateTimeProps) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { type } = props;
    if (onChange) {
      if (type !== 'time') {
        const { value } = event.target;
        const valueSplit = value.split('-');
        if (valueSplit[0]?.length > 4) {
          return;
        }
      }
      onChange(event);
    }
  };

  return (
    <Container style={style}>
      <Title>{title}</Title>
      {instructions && (
        <p style={{ color: '#929898', fontSize: 11, marginBottom: 16 }}>{instructions}</p>
      )}
      <BoxInput>
        <TextField
          InputProps={{ disableUnderline: true, style: { fontFamily: fonts.regular } }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleOnChange}
          {...props}
        />
      </BoxInput>
    </Container>
  );
};

InputDate.defaultProps = {
  instructions: undefined,
};

export default InputDate;
