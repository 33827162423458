/* eslint-disable no-underscore-dangle */
import React, { useRef } from 'react';
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryContainer,
  VictoryLine,
  VictoryLegend,
} from 'victory';
import { colors } from '../../../functions/colors';
import { insertPercentagemInNumber } from '../../../functions/number';
import { Container, Title } from './styles';

export type DataGraphicBars = {
  id: number;
  value: number;
  tickText: string;
};

type PGraphicBars = {
  data: DataGraphicBars[];
  graphicColor?: string;
  title?: string;
  average?: number;
};

const GraphicBars = ({ data, graphicColor, title, average }: PGraphicBars) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const widthChart = containerRef.current ? containerRef.current.clientWidth : 400;

  const currentData = data.filter((x, index) => index < 10);

  return (
    <Container ref={containerRef}>
      {title && <Title color={graphicColor || colors.pinkDark}>{title}</Title>}
      <VictoryChart
        domainPadding={50}
        domain={{ y: [0, 0.85] }}
        animate={{ duration: 500 }}
        height={240}
        containerComponent={<VictoryContainer />}
      >
        <VictoryAxis
          style={{
            tickLabels: { fill: colors.grey80, angle: -15, fontSize: 8 },
            axis: { stroke: colors.grey80, fontSize: 10 },
          }}
          tickValues={currentData.map((_, index) => index + 1)}
          tickFormat={currentData.map(item => item.tickText)}
        />
        <VictoryAxis
          style={{
            tickLabels: { fill: colors.grey80, fontSize: 8 },
            axis: { stroke: colors.black },
          }}
          dependentAxis
          tickFormat={(x: number) => `${insertPercentagemInNumber(x)}`}
        />
        <VictoryBar
          cornerRadius={{ topLeft: 4, topRight: 4 }}
          barRatio={0.7}
          style={{ data: { fill: graphicColor }, labels: { fill: graphicColor } }}
          labels={({ datum }) => `${insertPercentagemInNumber(datum._y)}`}
          data={currentData}
          x="id"
          y="value"
        />
        {average && (
          <VictoryLine
            style={{
              data: { stroke: `${colors.orangeRegular}` },
            }}
            data={[
              { x: 0, y: average },
              { x: currentData.length, y: average },
            ]}
          />
        )}
        {average && (
          <VictoryLegend
            style={{
              labels: { fill: colors.black, fontSize: 10 },
            }}
            x={widthChart / 2 - 40}
            y={280}
            orientation="horizontal"
            colorScale={[`${colors.orangeRegular}`]}
            data={[{ name: 'Taxa média' }]}
          />
        )}
      </VictoryChart>
    </Container>
  );
};

GraphicBars.defaultProps = {
  graphicColor: colors.purpleLight,
  title: undefined,
  average: undefined,
};

export default GraphicBars;
