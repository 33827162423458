import styled from 'styled-components';
import { colors } from '../../../shared/functions/colors';

export const Container = styled.div``;

export const Title = styled.h1`
  color: ${colors.primary};
  margin-bottom: 32px;
`;

export const BoxButtons = styled.div`
  display: flex;
  width: 400px;
  margin: 48px auto 8px auto;
  justify-content: space-between;
`;
