import styled from 'styled-components';
import { colors } from '../../../shared/functions/colors';
import Input from '../../../shared/components/input/Input';
import { fonts } from '../../../shared/functions/fonts';

export const Container = styled.div``;

export const Title = styled.h1`
  color: ${colors.pinkDark};
  font-family: ${fonts.bold};
  font-size: 24px;
  margin-bottom: 15px;
`;

export const Subtitle = styled.p`
  font-family: ${fonts.regular};
  font-size: 16px;
  margin-bottom: 20px;
`;

export const BoxButtons = styled.div`
  display: flex;
  width: 100%;
  margin: 48px 0px 8px 0px;
  justify-content: flex-end;
`;

export const BoxSuccess = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleSuccess = styled.h1`
  color: ${colors.purpleLight};
  font-family: ${fonts.bold};
  font-size: 24px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
`;

export const SubtitleSuccess = styled.p`
  font-family: ${fonts.regular};
  font-size: 16px;
  text-align: center;
`;

export const BoxButtonsSuccess = styled.div`
  display: flex;
  width: 100%;
  margin: 48px 0px 8px 0px;
  justify-content: center;
`;

export const InputUser = styled(Input)`
  display: block;
  margin-top: 16px;
  width: 90%;
  text-align: left;
`;
