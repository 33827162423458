import { Dispatch } from 'redux';

import * as stageActions from './stageActions';
import * as serviceStage from '../../../shared/services/serviceStage';
import { BodyCreateStage } from '../../../shared/modals/stage/bodies';
import {
  ERROR_MAINTENANCE,
  STAGE_DELETE,
  STAGE_DUPLICATE,
  STAGE_EDIT,
  STAGE_INSERT,
} from '../../../shared/constants/messages';
import { doLogout } from '../../../shared/functions/connection/auth';
import { setSnackbar } from '../general/generalActions';
import { SUCCESS_SNACKBAR } from '../../../shared/components/snackbar/Snackbar';

export const getStages = () => async (dispatch: Dispatch) => {
  try {
    const stages = await serviceStage.getStages();
    dispatch(stageActions.setStages(stages));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getStagesIdTrail = (idTrail: string) => async (dispatch: Dispatch) => {
  try {
    const stages = await serviceStage.getStagesIdTrail(idTrail);
    dispatch(stageActions.setStages(stages));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const insertStage = (bodyCreateStage: BodyCreateStage, idTrail: string) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceStage.insertStage(bodyCreateStage, idTrail);
    await getStagesIdTrail(idTrail)(dispatch);
    dispatch(setSnackbar(STAGE_INSERT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const changePositionStage = (
  idTrail: string,
  idStage: string,
  upPosition: boolean,
) => async (dispatch: Dispatch) => {
  try {
    await serviceStage.changePositionStage(idTrail, idStage, upPosition);
    await getStagesIdTrail(idTrail)(dispatch);
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const editStage = (
  bodyCreateStage: BodyCreateStage,
  idStage: string,
  idTrail: string,
) => async (dispatch: Dispatch) => {
  try {
    await serviceStage.editStage(bodyCreateStage, idStage, idTrail);
    await getStagesIdTrail(idTrail)(dispatch);
    dispatch(setSnackbar(STAGE_EDIT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const getStage = (idStage: string) => async (dispatch: Dispatch) => {
  try {
    const stage = await serviceStage.getStage(idStage);
    dispatch(stageActions.setStage(stage));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const deleteStage = (idStage: string, idTrail?: string) => async (dispatch: Dispatch) => {
  try {
    await serviceStage.deleteStage(idStage);
    if (idTrail) {
      await getStagesIdTrail(idTrail)(dispatch);
    } else {
      getStages()(dispatch);
    }
    dispatch(setSnackbar(STAGE_DELETE, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const duplicateStage = (idStage: string, idTrail?: string) => async (dispatch: Dispatch) => {
  try {
    await serviceStage.duplicateStage(idStage);
    if (idTrail) {
      await getStagesIdTrail(idTrail)(dispatch);
    } else {
      getStages()(dispatch);
    }
    dispatch(setSnackbar(STAGE_DUPLICATE, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};
