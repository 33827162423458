import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../../functions/colors';

type PIconHelp = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconHelp = ({ width, height, color, style }: PIconHelp) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" style={style}>
    <mask id="prefix_IconHelp" maskUnits="userSpaceOnUse" x={2} y={2} width={20} height={20}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17v-2h2v2h-2zm3.17-6.83l.9-.92c.57-.57.93-1.37.93-2.25 0-2.21-1.79-4-4-4S8 6.79 8 9h2c0-1.1.9-2 2-2s2 .9 2 2c0 .55-.22 1.05-.59 1.41l-1.24 1.26C11.45 12.4 11 13.4 11 14.5v.5h2c0-1.5.45-2.1 1.17-2.83z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#prefix_IconHelp)">
      <path fill={color} d="M0 0h24v24H0z" />
    </g>
  </svg>
);

IconHelp.defaultProps = {
  width: 24,
  height: 24,
  color: colors.grey80,
  style: {},
};

export default IconHelp;
