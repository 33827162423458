import { useDispatch, useSelector } from 'react-redux';
import { RootStateGlobal } from '../../reducer';
import * as reportOperations from './reportOperations';

export const useReport = () => {
  const dispatch = useDispatch();

  const { report, presenceGraphic } = useSelector((state: RootStateGlobal) => state.reportReducer);

  const getReport = async (
    ids: string[],
    startDate: string,
    endDate: string,
    reportFiltersType: number,
  ) => reportOperations.getReport(ids, startDate, endDate, reportFiltersType)(dispatch);
  const getReportPresence = async (idContests: string[]) =>
    reportOperations.getReportPresence(idContests)(dispatch);

  return {
    report,
    presenceGraphic,
    getReport,
    getReportPresence,
  };
};
