import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import Button from '../../../shared/components/buttons/Button';
import Modal from '../../../shared/components/modal/Modal';
import { BoxButtons, Title, InputUser } from './styles';
import { BodyEditPassword } from '../../../shared/modals/company/bodies';
import { useCompany } from '../../../store/reducers/company';

type PEditPassword = {
  openEditPassword: boolean;
  setOpenEditPassword: Dispatch<SetStateAction<boolean>>;
};

const initialBody = {
  passwordConnection: '',
};

const EditPassword = ({ openEditPassword, setOpenEditPassword }: PEditPassword) => {
  const { editPassword } = useCompany();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [bodyEditPassword, setBodyEditPassword] = useState<BodyEditPassword>(initialBody);

  useEffect(() => {
    setBodyEditPassword(initialBody);
  }, [openEditPassword]);

  const handleInsertAdminCompany = async () => {
    setLoading(true);
    try {
      await editPassword(bodyEditPassword);
      setOpenEditPassword(false);
      setBodyEditPassword(initialBody);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
    setLoading(false);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBodyEditPassword({
      ...bodyEditPassword,
      passwordConnection: event.target.value,
    });
  };

  return (
    <Modal onClose={() => setOpenEditPassword(false)} open={openEditPassword}>
      <Title>Editar a senha da API</Title>
      <InputUser
        title="Digite a senha"
        placeholder="Digite a senha"
        value={bodyEditPassword.passwordConnection}
        onChange={handleChangePassword}
      />
      {errorMessage}
      <BoxButtons>
        <Button style={{ width: 180 }} onClick={() => setOpenEditPassword(false)}>
          Cancelar
        </Button>
        <Button
          disabled={bodyEditPassword.passwordConnection === ''}
          loading={loading}
          style={{ width: 180 }}
          onClick={handleInsertAdminCompany}
        >
          Salvar
        </Button>
      </BoxButtons>
    </Modal>
  );
};

export default EditPassword;
