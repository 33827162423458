import styled from 'styled-components';
import { colors } from '../../../shared/functions/colors';
import { fonts } from '../../../shared/functions/fonts';

export const Container = styled.button`
  padding: 24px;
  border-radius: 4px;
  width: 292px;
  border: 1px solid ${colors.grey80};
  background-color: ${colors.white};
  margin: 0px 24px 24px 0px;
  cursor: pointer;

  @media print {
    width: 150px;
    margin: 0px 8px 8px 0px;
    padding: 8px;
  }
`;

export const Title = styled.h2`
  width: 100%;
  text-align: center;
  color: ${colors.pinkDark};
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  @media print {
    font-size: 11px;
  }
`;

export const ContainerPercentage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0px;

  @media print {
    margin: 8px 0px;
  }
`;

export const TextPercentage = styled.h3`
  color: ${colors.orangeDark};
  font-family: ${fonts.bold};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;

  @media print {
    font-size: 13px;
  }
`;

export const ExtraText = styled.p`
  color: ${colors.grey100};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;

  @media print {
    font-size: 9px;
  }
`;

export const Text = styled.p`
  color: ${colors.black};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  strong {
    font-family: ${fonts.bold};
  }

  @media print {
    font-size: 11px;
  }
`;
