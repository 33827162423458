import React, { useState, ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react';

import Button from '../../../shared/components/buttons/Button';
import { useQuestion } from '../../../store/reducers/question';
import { Container, BoxInsertUser, BoxBottom, ContainerInsertUser } from './styles';
import BreadCrumbs from '../../../shared/components/breadCrumbs/BreadCrumbs';
import InputFile from '../../../shared/components/input/inputFile/InputFile';
import FixedSnackBar, {
  PFixedSnackbar,
} from '../../../shared/components/snackbar/fixedSnackbar/FixedSnackbar';

type PImportResearchList = {
  setShowImportResearchList: Dispatch<SetStateAction<boolean>>;
  idLibrary?: string;
};

const ImportResearchList = ({ setShowImportResearchList, idLibrary }: PImportResearchList) => {
  const { importResearchList, clearErrors, errors, questionPagination } = useQuestion();
  const [loading, setLoading] = useState(false);
  const [fixedSnackBar, setFixedSnackbar] = useState<PFixedSnackbar>({
    type: 'error',
    errors: [],
    title: 'Ops, ocorreu um problema!',
    subtitle:
      'Ao carregar sua planilha houve um erro e a(s) linha(s) descrita(s) abaixo não foram possíveis de importar. Por favor, corrija as linhas e tente importar novamente seu arquivo.',
    show: false,
  });
  const [formData] = useState<FormData>(new FormData());
  const [disabled, setDisabled] = useState(true);
  const [valueInput, setValueInput] = useState('');

  const handleImportResearchList = async () => {
    setLoading(true);
    try {
      await importResearchList(formData, questionPagination, idLibrary);
      setLoading(false);
    } catch (error) {
      setFixedSnackbar({
        type: 'error',
        title: 'Ops, ocorreu um problema!',
        subtitle:
          'Ao carregar sua planilha houve um erro e a(s) linha(s) descrita(s) abaixo não foram possíveis de importar. Por favor, corrija as linhas e tente importar novamente seu arquivo.',
        errors,
        show: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    if (errors && errors.length === 0 && valueInput !== '') {
      setShowImportResearchList(false);
    } else if (errors.length > 0) {
      setFixedSnackbar({
        type: 'error',
        title: 'Ops, ocorreu um problema!',
        subtitle:
          'Ao carregar sua planilha houve um erro e a(s) linha(s) descrita(s) abaixo não foram possíveis de importar. Por favor, corrija as linhas e tente importar novamente seu arquivo.',
        errors,
        show: true,
      });
    }
  }, [errors]);

  const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    setValueInput(event.target.value);
    const { files } = event.target;
    if (files?.length) {
      setLoading(true);
      formData.set('questionList', files[0]);
      setDisabled(false);
      setLoading(false);
    }
  };

  const handleDelete = () => {
    setValueInput('');
  };

  const closeScreen = () => {
    clearErrors();
    setShowImportResearchList(false);
  };

  return (
    <Container>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Perguntas',
            action: () => setShowImportResearchList(false),
          },
          {
            name: 'Importar Pesquisas',
          },
        ]}
      />
      <ContainerInsertUser>
        <BoxInsertUser>
          <InputFile
            title="Arquivo CSV*"
            subTitle="As categorias listadas no arquivo precisam ser criadas previamente"
            onChange={handleChangeFile}
            handleDelete={handleDelete}
            valueInput={valueInput}
            style={{ marginLeft: 32, width: '40%' }}
          />
          <BoxBottom>
            <Button onClick={closeScreen} style={{ width: 120 }} typeButton="primary-white">
              Cancelar
            </Button>
            <Button
              loading={loading}
              onClick={handleImportResearchList}
              disabled={disabled}
              style={{ width: 120, marginLeft: 16 }}
            >
              SALVAR
            </Button>
            <Button style={{ width: 130, marginLeft: 16 }}>
              <a
                href={idLibrary ? '/modelo_pesquisas_lib.csv' : '/modelo_pesquisas.csv'}
                download="modelo_pesquisas.csv"
                style={{ color: 'white', textDecoration: 'none' }}
              >
                BAIXAR MODELO
              </a>
            </Button>
          </BoxBottom>
        </BoxInsertUser>
      </ContainerInsertUser>
      <FixedSnackBar
        type={fixedSnackBar.type}
        title={fixedSnackBar.title}
        subtitle={fixedSnackBar.subtitle}
        errors={fixedSnackBar.errors}
        show={fixedSnackBar.show}
        setSnackBar={setFixedSnackbar}
      />
    </Container>
  );
};

ImportResearchList.defaultProps = {
  idLibrary: undefined,
};

export default ImportResearchList;
