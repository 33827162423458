import { Reward, RewardManager } from '../../../shared/modals/reward/reward';

export enum RewardTypes {
  SET_REWARDS = '@reward/SET_REWARDS',
  SET_REWARD = '@reward/SET_REWARD',
  SET_REWARDS_REQUESTS = '@reward/SET_REWARDS_REQUESTS',
}

export type RewardState = {
  readonly rewards: Reward[];
  readonly reward?: Reward;
  readonly rewardsRequests: RewardManager[];
};
