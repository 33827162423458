import { noop } from 'lodash';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useUser } from '../../store/reducers/user';

type ParamTypes = {
  token?: string;
  email?: string;
};

const LoginWithMfa = () => {
  const { token } = useParams<ParamTypes>();
  const history = useHistory();
  const { reqLogin } = useUser();

  useEffect(() => {
    if (!token) return;

    (async () => {
      try {
        await reqLogin({ token });
        history.push('/home');
      } catch (e) {
        noop();
      }
    })();
  }, [token]);

  return <div>Entrando...</div>;
};

export default LoginWithMfa;
