import React, { ChangeEvent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  ContainerMain,
  DisplayFlexAlignCenter,
  HeaderMain,
  TextHeaderMain,
} from '../../shared/components/styled/general';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import { colors } from '../../shared/functions/colors';
import Button from '../../shared/components/buttons/Button';
import { filterArraySearch } from '../../shared/functions/utils';
import Table from '../../shared/components/table/Table';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import IconEdit from '../../shared/images/icon/iconEdit';
import IconTrash from '../../shared/images/icon/iconTrash';
import IconDuplicate from '../../shared/images/icon/iconDuplicate';
import { dateTimeStampToString } from '../../shared/functions/date';
import * as missionsService from '../../shared/services/missions';
import DeleteMission from './delete/DeleteMission';
import DuplicateMission from './duplicate/DuplicateMission';
import IconCheck from '../../shared/images/icon/iconCheck';
import IconBlock from '../../shared/images/icon/iconBlock';
import ToggleMissionEnabled from './toggleEnabled/ToggleMissionEnabled';
import BoxHorizontalButtons from '../../shared/components/box/boxHorizontalButtons/BoxHorizontalButtons';
import SearchInput from '../../shared/components/input/searchInput/SearchInput';
import { useGeneral } from '../../store/reducers/general';

export type IMission = {
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  description: string;
  image: string;
  enabled: boolean;
  challengesCount: number;
};

const Mission = () => {
  const { filterInput } = useGeneral();
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchMission, setSearchMission] = useState('');
  const [isDeleteModalEnabled, setIsDeleteModalEnabled] = useState(false);
  const [isDuplicateModalEnabled, setIsDuplicateModalEnabled] = useState(false);
  const [isToggleModalEnabled, setIsToggleModalEnabled] = useState(false);
  const [mission, setMission] = useState<IMission | undefined>(undefined);
  const [missions, setMissions] = useState<IMission[]>([]);
  const [missionsFilter, setMissionsFilter] = useState<IMission[]>([]);
  const [loading, setLoading] = useState(false);

  const refreshList = () => {
    missionsService.getAll(dispatch).then(missions => {
      setMissions(missions);
      setMissionsFilter(missions);
    });
    if (filterInput !== '') {
      setSearchMission(filterInput);
    }
  };

  useEffect(() => {
    refreshList();
  }, []);

  useEffect(() => {
    setMissionsFilter(filterArraySearch(missions, 'name', searchMission));
  }, [missions]);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchMission(event.target.value);
    setMissionsFilter(filterArraySearch(missions, 'name', event.target.value));
  };

  // TODO: show success and error messages
  const handleDelete = (mission: IMission) => {
    setIsDeleteModalEnabled(true);
    setMission(mission);
  };

  const handleDuplicate = (mission: IMission) => {
    setIsDuplicateModalEnabled(true);
    setMission(mission);
  };

  const handleToggle = (mission: IMission) => {
    setIsToggleModalEnabled(true);
    setMission(mission);
  };

  const handleCloseModal = () => {
    setIsDeleteModalEnabled(false);
    setIsDuplicateModalEnabled(false);
    setIsToggleModalEnabled(false);
    setMission(undefined);
  };

  const goToNew = () => {
    history.push('/missions/new');
  };

  const downloadReport = async () => {
    if (loading) return;

    setLoading(true);
    await missionsService.downloadReport().finally(() => {
      setLoading(false);
    });
  };

  const goToEdit = (mission: IMission) => {
    history.push(`/missions/${mission.id}/edit`);
  };

  const goToMissionChallenges = (mission: IMission) => {
    history.push(`/missions/${mission.id}/challenges`);
  };

  const renderRowsTable = (missionlist: IMission[]) => {
    return missionlist.map(mission => ({
      columns: [
        dateTimeStampToString(mission.startDate),
        dateTimeStampToString(mission.endDate),
        mission.name,
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {mission.challengesCount}
          <ButtonIcon style={{ marginLeft: 16 }} onClick={() => goToMissionChallenges(mission)}>
            <IconEdit />
          </ButtonIcon>
        </div>,
        <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleToggle(mission)}>
          {mission.enabled ? <IconCheck color={colors.green} /> : <IconBlock color={colors.red} />}
        </ButtonIcon>,
        <>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => goToEdit(mission)}>
            <IconEdit />
          </ButtonIcon>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleDuplicate(mission)}>
            <IconDuplicate />
          </ButtonIcon>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleDelete(mission)}>
            <IconTrash />
          </ButtonIcon>
        </>,
      ],
    }));
  };

  const renderTable = (missionStatus: IMission[]) => {
    return (
      <Table
        nameReferenceReduxTable="table_mission"
        listRowsPerPage={[10, 25, 50, 100]}
        titles={[
          {
            name: 'Início',
            openClick: true,
            type: 'date',
          },
          {
            name: 'Fim',
            openClick: true,
            type: 'date',
          },
          {
            name: 'Nome da missão',
            openClick: true,
            type: 'string',
          },
          {
            name: 'Desafios', // TODO: add popover
            openClick: true,
            type: 'number',
          },
          {
            name: 'Permissões',
            openClick: false,
            type: 'ReactNode',
            widthTd: '10%',
          },
          {
            name: 'Ações',
            openClick: false,
            type: 'ReactNode',
            widthTd: '20%',
          },
        ]}
        rows={renderRowsTable(missionStatus)}
      />
    );
  };

  return (
    <ContainerMain>
      {mission && (
        <>
          <DeleteMission
            id={mission.id}
            open={isDeleteModalEnabled}
            handleCloseModal={handleCloseModal}
            refreshList={refreshList}
          />
          <DuplicateMission
            id={mission.id}
            open={isDuplicateModalEnabled}
            handleCloseModal={handleCloseModal}
            refreshList={refreshList}
          />
          <ToggleMissionEnabled
            mission={mission}
            open={isToggleModalEnabled}
            handleCloseModal={handleCloseModal}
            refreshList={refreshList}
          />
        </>
      )}
      <BreadCrumbs
        listMenus={[
          {
            name: 'Missões',
          },
        ]}
      />
      <HeaderMain>
        <TextHeaderMain>Você pode criar, excluir ou editar as missões cadastradas.</TextHeaderMain>
        <DisplayFlexAlignCenter>
          <SearchInput
            placeholder="Buscar missão..."
            search={searchMission}
            onChange={handleSearch}
          />
          <Button
            style={{ width: 160, marginRight: '1em' }}
            onClick={downloadReport}
            loading={loading}
          >
            BAIXAR RELATÓRIO
          </Button>
          <Button style={{ width: 160 }} onClick={goToNew} icon="plus">
            NOVA MISSÃO
          </Button>
        </DisplayFlexAlignCenter>
      </HeaderMain>

      <BoxHorizontalButtons
        items={[
          {
            textButton: 'Missões Ativas',
            children: renderTable(missionsFilter.filter(mission => mission.enabled)),
          },
          {
            textButton: 'Missões Inativas',
            children: renderTable(missionsFilter.filter(mission => !mission.enabled)),
          },
        ]}
      />
    </ContainerMain>
  );
};

export default Mission;
