/* eslint-disable import/no-cycle */
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { BodyInsertQuestion } from '../../../../shared/modals/question/bodies';
import { Container, Title, SubTitle, ContainerFlex } from './styles';
import Checkbox from '../../../../shared/components/input/checkbox/Checkbox';
import Input from '../../../../shared/components/input/Input';

const MAX_ALTERNATIVES = 4;

const NEW_ALTERNATIVE = {
  index: 0,
  text: '',
  trueQuestion: false,
  idQuestion: '',
};

type PInsertAlternatives = {
  bodyInsertQuestion: BodyInsertQuestion;
  setBodyInsertQuestion: Dispatch<SetStateAction<BodyInsertQuestion>>;
  isStandardQuestion: boolean;
};

const InsertAlternatives = ({
  bodyInsertQuestion,
  setBodyInsertQuestion,
  isStandardQuestion,
}: PInsertAlternatives) => {
  const verifyAmountEmpty = () => {
    let amount = 0;
    bodyInsertQuestion.alternativesAttributes.forEach(body => {
      if (!body.text) {
        amount += 1;
      }
    });
    return amount;
  };

  useEffect(() => {
    const amountEmpty = verifyAmountEmpty();
    if (
      amountEmpty === 0 &&
      (!isStandardQuestion || bodyInsertQuestion.alternativesAttributes.length < MAX_ALTERNATIVES)
    ) {
      setBodyInsertQuestion(question => {
        return {
          ...question,
          alternativesAttributes: question.alternativesAttributes.concat([
            {
              ...NEW_ALTERNATIVE,
              index: question.alternativesAttributes.length,
            },
          ]),
        };
      });
    }
  }, [bodyInsertQuestion]);

  const handleOnChangeInput = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newBodyChangeInput = bodyInsertQuestion.alternativesAttributes.map(newBody => {
      if (index === newBody.index) {
        return {
          ...newBody,
          text: event.target.value,
        };
      }
      return { ...newBody };
    });
    setBodyInsertQuestion(question => {
      return {
        ...question,
        alternativesAttributes: newBodyChangeInput,
      };
    });
  };

  const handleOnChangeCheckbox = (index: number) => {
    const newBodyChangeInput = bodyInsertQuestion.alternativesAttributes.map(newBody => ({
      ...newBody,
      trueQuestion: index === newBody.index,
    }));

    setBodyInsertQuestion({
      ...bodyInsertQuestion,
      alternativesAttributes: newBodyChangeInput,
    });
  };

  return (
    <Container>
      <Title>Opções de resposta*</Title>
      <SubTitle>Selecione ao lado qual a resposta correta.</SubTitle>
      {bodyInsertQuestion.alternativesAttributes.map((body, index) => (
        <ContainerFlex>
          <Checkbox
            checked={isStandardQuestion && body.trueQuestion}
            disabled={!isStandardQuestion}
            onChange={() => {
              if (body.text) handleOnChangeCheckbox(index);
            }}
          />
          <Input
            value={body.text}
            placeholder={`Opção ${index + 1}`}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleOnChangeInput(index, event);
            }}
            style={{ width: '100%', marginTop: 8 }}
            showLenghtMax
          />
        </ContainerFlex>
      ))}
    </Container>
  );
};

export default InsertAlternatives;
