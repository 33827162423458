import React, { ChangeEvent, useEffect } from 'react';
import { useGeneral } from '../../../../store/reducers/general';
import { colors } from '../../../functions/colors';
import Input from '../Input';

type PropsTypes = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  search: string;
};

const SearchInput = ({ onChange, placeholder, search }: PropsTypes) => {
  const { setFilterInput, filterInput } = useGeneral();

  useEffect(() => {
    setFilterInput(search);
  }, [search]);

  return (
    <>
      <Input
        style={{ marginRight: 16, width: 260 }}
        backgroundColor={colors.grey40}
        showBorder={false}
        icon="search"
        placeholder={placeholder}
        value={filterInput}
        onChange={onChange}
      />
    </>
  );
};

export default SearchInput;
