import React, { Component, ChangeEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, DispatchProp } from 'react-redux';
import { set } from 'lodash';
import { Container, BoxInfo, BoxPadding, BoxFlex, BoxBottom } from './styles';
import MultipleSelect from '../../../shared/components/select/multipleSelect/MultipleSelect';
import BreadCrumbs from '../../../shared/components/breadCrumbs/BreadCrumbs';
import Input from '../../../shared/components/input/Input';
import Button from '../../../shared/components/buttons/Button';
import { TitleContest } from '../../../shared/components/styled/text';
import {
  get as getRollout,
  create as createRollout,
  update as updateRollout,
} from '../../../shared/services/rollouts';
import { getCompanys } from '../../../shared/services/serviceCompany';
import { POptions } from '../../../shared/components/select/Select';
import { Company } from '../../../shared/modals/company/company';
import { RolloutInterface } from '../Rollout';
import { Title } from '../../../shared/components/select/styles';
import RadioPurple from '../../../shared/components/input/inputRadio/radioPurple/radioPurple';

export type RolloutState = {
  id: string;
  name: string;
  percentage: number;
  description: string;
  inclusionMode: boolean;
  companyIds: POptions[];
  selectedCompanyIds: POptions[];
  loading: boolean;
};

type PropsType = RouteComponentProps & DispatchProp;

class CreateEditRollout extends Component<PropsType, RolloutState> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      id: '',
      name: '',
      percentage: 0,
      description: '',
      inclusionMode: false,
      companyIds: [],
      selectedCompanyIds: [],
      loading: false,
    };
  }

  async componentDidMount() {
    const formattedCompanies = await this.fetchformattedCompanies();
    const companysState = { companyIds: formattedCompanies };

    const rolloutState = await this.fetchRollout(formattedCompanies);

    this.setState({ ...companysState, ...rolloutState });
  }

  fetchRollout = async (formattedCompanies: POptions[]) => {
    const { match, dispatch } = this.props;
    const { id } = match.params as { id: string };
    let rolloutState = {};

    if (id) {
      const rollout = await getRollout(id, dispatch);
      if (rollout) {
        const { name, description, inclusionMode, percentage, companyIds } = rollout;

        rolloutState = {
          id,
          name,
          percentage,
          description,
          inclusionMode,
          selectedCompanyIds: formattedCompanies.filter(company =>
            companyIds.map(companyId => companyId.toString()).includes(company.value),
          ),
        };
      }
    }

    return rolloutState;
  };

  // Submit form
  handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ loading: true });

    const { dispatch } = this.props;
    const { id, name, percentage, description, inclusionMode, selectedCompanyIds } = this.state;
    const companyIds = selectedCompanyIds.map(area => area.value);
    const body: RolloutInterface = {
      id,
      name,
      percentage,
      description,
      inclusionMode,
      companyIds,
    };

    try {
      if (id) {
        await updateRollout(body, id, dispatch);
      } else {
        await createRollout(body, dispatch);
      }
      this.setState({ loading: false });
      this.goToRolloutsList();
    } catch (_) {
      this.setState({ loading: false });
    }
  };

  fetchformattedCompanies = async (): Promise<POptions[]> => {
    const companies: Company[] = await getCompanys();
    const formattedCompany = companies
      .filter(company => !company.block) // Get only enabled companies
      .map((company: { idCompany: string; name: string }) => ({
        value: company.idCompany,
        viewValue: company.name,
      }));

    return formattedCompany;
  };

  // Set state of input form
  handleChangeValue = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { type, name, value, checked } = (event.target as unknown) as {
      type: 'radio' | 'checkbox';
      name: keyof RolloutState;
      value: string;
      checked: boolean;
    };
    let overrides: Partial<RolloutState>;
    // const state = { [name]: value } as unknown;

    // this.setState(state as Pick<RolloutState, keyof RolloutState>);
    switch (type) {
      case 'radio': {
        const isBoolean = ['true', 'false'].includes(value);
        overrides = set({}, name, isBoolean ? value === 'true' : value);
        break;
      }
      case 'checkbox':
        overrides = set({}, name, checked);
        break;
      default:
        overrides = set({}, name, value);
        break;
    }

    this.setState(overrides as Pick<RolloutState, keyof RolloutState>);
  };

  // Set state to companies selected
  handleCompanySelected = (selectedCompanyIds: POptions[]) => {
    this.setState({ selectedCompanyIds });
  };

  // Back to the rollouts list
  goToRolloutsList = () => {
    const { history } = this.props;

    history.push('/rollouts');
  };

  // Check form can be submitedd
  isSubmissionDisabled() {
    const { name, percentage } = this.state;

    if (!name || (!percentage && percentage !== 0)) return true;

    return false;
  }

  render() {
    const {
      id,
      name,
      percentage,
      companyIds,
      description,
      inclusionMode,
      selectedCompanyIds,
      loading,
    } = this.state;

    return (
      <Container onSubmit={this.handleSubmit}>
        <BreadCrumbs
          listMenus={[
            {
              name: 'Rollouts',
              action: this.goToRolloutsList,
            },
            {
              name: 'Novo',
            },
          ]}
        />
        <BoxInfo>
          <TitleContest>DEFINIÇÕES</TitleContest>
          <BoxPadding>
            <BoxFlex style={{ width: '100%' }}>
              <div>
                <Title>Modo*</Title>
                <RadioPurple
                  checked={inclusionMode}
                  value="true"
                  labelTitle="Inclusivo"
                  id="inclusionMode"
                  name="inclusionMode"
                  onChange={this.handleChangeValue}
                  details="as empresas selecionadas são INCLUÍDAS no experimento"
                />
                <RadioPurple
                  checked={!inclusionMode}
                  value="false"
                  labelTitle="Exclusivo"
                  id="exclusionMode"
                  name="inclusionMode"
                  onChange={this.handleChangeValue}
                  details="as empresas selecionadas são EXCLUÍDAS do experimento"
                />
              </div>
              <Input
                title="Nome*"
                name="name"
                disabled={!!id}
                placeholder="Digite"
                onChange={this.handleChangeValue}
                value={name}
                style={{ width: '15%' }}
              />
              <Input
                title="Percentual*"
                name="percentage"
                placeholder="Digite"
                onChange={this.handleChangeValue}
                value={percentage}
                style={{ width: '15%' }}
              />
              <Input
                title="Descrição"
                name="description"
                placeholder="Digite"
                onChange={this.handleChangeValue}
                value={description}
                style={{ width: '58%' }}
              />
            </BoxFlex>
            <BoxFlex style={{ marginBottom: 16 }}>
              <MultipleSelect
                listOptions={companyIds}
                itensSelected={selectedCompanyIds}
                setItensSelected={this.handleCompanySelected}
                title={`${inclusionMode ? 'Habilitar' : 'Desabilitar'} experimento para`}
              />
            </BoxFlex>
          </BoxPadding>

          <BoxBottom>
            <Button
              onClick={this.goToRolloutsList}
              style={{ width: 120 }}
              typeButton="primary-white"
            >
              CANCELAR
            </Button>
            <Button
              loading={loading}
              disabled={this.isSubmissionDisabled()}
              type="submit"
              style={{ width: 120, marginLeft: 16 }}
            >
              CONCLUIR
            </Button>
          </BoxBottom>
        </BoxInfo>
      </Container>
    );
  }
}

export default withRouter(connect()(CreateEditRollout));
