/* eslint-disable no-underscore-dangle */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import Chip from '@mui/material/Chip';
import { Container, BoxInfo, BoxPadding, BoxBottom } from '../../mission/create/styles';
import BreadCrumbs from '../../../shared/components/breadCrumbs/BreadCrumbs';
import Input from '../../../shared/components/input/Input';
import Button from '../../../shared/components/buttons/Button';
import { TitleContest } from '../../../shared/components/styled/text';
import * as journeysService from '../../../shared/services/journeys';
import * as stepsService from '../../../shared/services/steps';
import { handleChangeFieldValue } from '../../../shared/functions/handlers';
import { Title, SubTitle, Line } from '../../../shared/components/presentation/styles';
import Select, { POptions } from '../../../shared/components/select/Select';
import { colors } from '../../../shared/functions/colors';
import ButtonIcon from '../../../shared/components/buttons/buttonIcon/ButtonIcon';
import IconTrash from '../../../shared/images/icon/iconTrash';
import InputFileImage from '../../../shared/components/input/inputFileImage/InputFile';
import IconArrowDown from '../../../shared/images/icon/iconArrowDown';
import IconArrowUp from '../../../shared/images/icon/iconArrowUp';
import Modal from '../../../shared/components/modal/Modal';
import { TextPlaceHolder } from '../../../shared/components/input/styles';
import { useUser } from '../../../store/reducers/user';

export type JourneyInterface = {
  id: string | undefined;
  name: string;
  enabled: boolean | undefined;
  image: string;
  stepsAttributes: Pick<GameableInterface, 'gameableId' | 'gameableType'>[];
  journeysDependenciesAttributes: { dependencyId: string; name: string }[];
};

export type GameableInterface = {
  gameableId: string;
  gameableType: string;
  name: string;
  image: string;
};

const CreateEditJourney = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const { userData } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [journey, setJourney] = useState<JourneyInterface>({
    id: undefined,
    enabled: undefined,
    name: '',
    image: '',
    stepsAttributes: [],
    journeysDependenciesAttributes: [],
  });
  const [journeyOptions, setJourneyOptions] = useState<POptions[]>([]);
  const [stepOptions, setStepOptions] = useState<POptions[]>([]);
  const [showDependenciesModal, setShowDependenciesModal] = useState(false);

  const fetchJourney = async () => {
    const { id } = params as { id: string };

    if (id) {
      const jrny = await journeysService.get(id, dispatch);
      if (jrny) setJourney(jrny);
    }
  };

  const fetchGameables = async () => {
    const stps = await stepsService.getAll();

    if (stps) {
      const formattedStepsOptions = stps.map(gameable => ({
        value: `${gameable.gameableType}|${gameable.gameableId}`,
        viewValue: gameable.name,
      }));
      setStepOptions(formattedStepsOptions);
    }
  };

  const fetchJourneys = () => {
    journeysService.getAll(dispatch).then(jrns => {
      const formattedJourneyOptions: POptions[] = jrns.map(j => ({
        value: `${j.id}`,
        viewValue: j.name,
      }));
      formattedJourneyOptions.unshift({ value: '', viewValue: '' });
      setJourneyOptions(formattedJourneyOptions);
    });
  };

  useEffect(() => {
    fetchGameables();
    fetchJourneys();
    fetchJourney();
  }, []);

  const appendNewStep = () => {
    const unselectedStepOption = stepOptions.find(
      stepOption =>
        !journey.stepsAttributes.find(
          stepAttribute => stepAttribute.gameableId === stepOption.value.split('|')[1],
        ),
    );

    if (unselectedStepOption) {
      const [gameableType, gameableId] = unselectedStepOption.value.split('|');

      setJourney(previousState => ({
        ...previousState,
        stepsAttributes: [
          ...previousState.stepsAttributes,
          {
            gameableId,
            gameableType,
          },
        ],
      }));
    }
  };

  useEffect(() => {
    const { id } = params as { id: string };
    // If stepOptions is already loaded and user is creating a new
    // journey (instead of editing), then adds the first journey's step
    if (stepOptions.length && !id) appendNewStep();
  }, [stepOptions]);

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleChangeFieldValue<JourneyInterface>(event, setJourney);
  };

  const goToIndex = () => {
    history.push('/journeys');
  };

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      if (journey.id) {
        await journeysService.update(journey, journey.id, dispatch);
      } else {
        await journeysService.create(journey, dispatch);
      }
      goToIndex();
      // eslint-disable-next-line no-empty
    } catch (_) {}

    setIsLoading(false);
  };

  const handleChangeStep = (index: number) => {
    return (event: ChangeEvent<HTMLSelectElement>) => {
      const {
        target: { value },
      } = event;

      setJourney(previousState => {
        const [type, id] = value.split('|');
        const overrides = cloneDeep(previousState);
        overrides.stepsAttributes[index].gameableId = id;
        overrides.stepsAttributes[index].gameableType = type;

        return {
          ...previousState,
          ...overrides,
        };
      });
    };
  };

  const handleAddDependencyJourney = (event: ChangeEvent<HTMLSelectElement>) => {
    const { target } = event;
    const { value, selectedIndex } = target;
    // @ts-expect-error: `text` is defined!
    const { text } = target[selectedIndex];

    if (journey.journeysDependenciesAttributes.find(dep => dep.dependencyId === value)) {
      return;
    }

    setJourney(previousState => {
      const overrides = cloneDeep(previousState);
      overrides.journeysDependenciesAttributes.push({
        dependencyId: value,
        name: text,
      });
      return overrides;
    });
  };

  const handleDeleteStep = (index: number) => {
    setJourney(previousState => {
      const overrides = cloneDeep(previousState);
      overrides.stepsAttributes.splice(index, 1);
      return overrides;
    });
  };

  const handleDeleteDependency = (index: number) => {
    setJourney(previousState => {
      const overrides = cloneDeep(previousState);
      overrides.journeysDependenciesAttributes.splice(index, 1);
      return overrides;
    });
  };

  const handleMoveStepUp = (index: number) => {
    setJourney(prevState => {
      const steps = prevState.stepsAttributes;

      [steps[index], steps[index - 1]] = [steps[index - 1], steps[index]];

      return {
        ...prevState,
        stepsAttributes: steps,
      };
    });
  };

  const handleMoveStepDown = (index: number) => {
    setJourney(prevState => {
      const steps = prevState.stepsAttributes;

      [steps[index], steps[index + 1]] = [steps[index + 1], steps[index]];

      return {
        ...prevState,
        stepsAttributes: steps,
      };
    });
  };

  const isSubmissionEnabled = () => {
    const { name, stepsAttributes } = journey;

    if (name && stepsAttributes.length) return true;

    return false;
  };

  const renderSteps = () => {
    let indexLabel = 0;

    const stepsCount = journey.stepsAttributes.length;

    return journey.stepsAttributes.map((step, i) => {
      indexLabel += 1;

      const shouldRenderButtonUp = i > 0;
      const shouldRenderButtonDown = i + 1 < stepsCount;

      return (
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Select
            style={{ marginTop: 16, width: '70%' }}
            title={`${indexLabel}. Etapa`}
            nameSelect="gameableId"
            listOptions={stepOptions}
            onChange={handleChangeStep(i)}
            valueSelected={`${step.gameableType}|${step.gameableId}`}
          />
          <ButtonIcon
            style={{ marginLeft: 16, marginBottom: 8 }}
            onClick={() => handleDeleteStep(i)}
          >
            <IconTrash />
          </ButtonIcon>
          {shouldRenderButtonUp && (
            <ButtonIcon
              style={{ marginLeft: 16, marginBottom: 8 }}
              onClick={() => handleMoveStepUp(i)}
            >
              <IconArrowUp />
            </ButtonIcon>
          )}
          {shouldRenderButtonDown && (
            <ButtonIcon
              style={{ marginLeft: 16, marginBottom: 8 }}
              onClick={() => handleMoveStepDown(i)}
            >
              <IconArrowDown />
            </ButtonIcon>
          )}
        </div>
      );
    });
  };

  const renderDependencies = () =>
    journey.journeysDependenciesAttributes.map((dependency, i) => (
      <Chip
        key={dependency.dependencyId}
        label={dependency.name}
        onDelete={() => handleDeleteDependency(i)}
        sx={{
          background: colors.primary,
          color: colors.white,
          maxWidth: 200,
          margin: '4px',
        }}
      />
    ));

  if (!userData) return null;

  return (
    <>
      <Modal open={showDependenciesModal} onClose={() => setShowDependenciesModal(false)}>
        <div style={{ color: colors.primary, marginBottom: 40 }}>
          Escolher jornada como pré-requisito
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <Select
              title=""
              nameSelect="dependencyId"
              listOptions={journeyOptions}
              onChange={handleAddDependencyJourney}
              placeholder="Selecione as jornadas"
            />
          </div>
          <div style={{ flex: 1 }}>{renderDependencies()}</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
          <Button
            type="submit"
            style={{ width: 120, marginLeft: 24 }}
            onClick={() => setShowDependenciesModal(false)}
          >
            CONCLUIR
          </Button>
        </div>
      </Modal>
      <Container onSubmit={handleSubmit}>
        <BreadCrumbs
          listMenus={[
            {
              name: 'Jornada',
              action: goToIndex,
            },
            {
              name: 'Nova jornada',
            },
          ]}
        />
        <BoxInfo>
          <TitleContest>CONFIGURAÇÕES</TitleContest>
          <BoxPadding>
            <Input
              title="Título da jornada*"
              name="name"
              placeholder="Digite"
              onChange={handleChangeValue}
              value={journey.name}
            />
            <InputFileImage
              title="Inserir imagem (opcional)"
              subTitle="Você pode inserir imagem em .png .jpeg .gif."
              urlImage={journey.image}
              setUrlImage={image => setJourney({ ...journey, image })}
              style={{ width: '40%', marginTop: '30px' }}
              dimensions={{ width: 372, height: 151 }}
              isCrop
            />
          </BoxPadding>
          <BoxPadding>
            <TextPlaceHolder>
              Adicionar jornadas como pré-requisito para serem concluídas
            </TextPlaceHolder>
            <Button
              onClick={() => setShowDependenciesModal(true)}
              style={{ width: 200, marginTop: 24, marginBottom: 16 }}
              typeButton="primary-white"
              icon="plusInnerBorder"
              colorIcon={colors.purpleRegular}
            >
              INSERIR JORNADA
            </Button>
            {renderDependencies()}
          </BoxPadding>
          <BoxPadding>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Title>Etapas</Title>
              <Line />
            </div>
            <SubTitle>Defina abaixo cada etapa da jornada:</SubTitle>
            {renderSteps()}
            <Button
              onClick={appendNewStep}
              style={{ width: 200, marginTop: 24 }}
              typeButton="primary-white"
              icon="plusInnerBorder"
              colorIcon={colors.purpleRegular}
            >
              INSERIR NOVA ETAPA
            </Button>
          </BoxPadding>

          <BoxBottom>
            <Button onClick={goToIndex} style={{ width: 120 }} typeButton="primary-white">
              CANCELAR
            </Button>
            {!userData.company.migrated && (
              <Button
                loading={isLoading}
                disabled={!isSubmissionEnabled()}
                type="submit"
                style={{ width: 120, marginLeft: 16 }}
              >
                CONCLUIR
              </Button>
            )}
          </BoxBottom>
        </BoxInfo>
      </Container>
    </>
  );
};

export default CreateEditJourney;
