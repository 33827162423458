import { BodyInsertQuestion, BodyInsertCategory } from '../modals/question/bodies';
import {
  ConnectApiDelete,
  ConnectApiGet,
  ConnectApiPost,
  ConnectApiPUT,
  urls,
} from '../functions/connection';
import { Question, QuestionPagination } from '../modals/question/question';
import {
  generatePaginateQueryString,
  generateQueryString,
} from '../functions/connection/queryString';

export const getCategories = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_CATEGORY);
  return respostaServico.data;
};

export const deleteCategory = async (idCategory: string) => {
  const url = urls.URL_CATEGORY_ID.replace('{idCategory}', `${idCategory}`);
  await ConnectApiDelete(url);
};

export const insertCategory = async (bodyInsertCategory: BodyInsertCategory) => {
  const respostaServico = await ConnectApiPost(urls.URL_CATEGORY, bodyInsertCategory);
  return respostaServico.data;
};

export const editCategory = async (bodyInsertCategory: BodyInsertCategory, idCategory: string) => {
  const url = urls.URL_CATEGORY_ID.replace('{idCategory}', `${idCategory}`);
  const respostaServico = await ConnectApiPUT(url, bodyInsertCategory);
  return respostaServico.data;
};

export const getQuestions = async (pagination?: QuestionPagination) => {
  let url;

  if (pagination) {
    url = generatePaginateQueryString(urls.URL_QUESTION, pagination);
  } else {
    url = urls.URL_QUESTION;
  }
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const getAllDataQuestion = async (idQuestion: string) => {
  const url = urls.URL_QUESTION_ID.replace('{idQuestion}', `${idQuestion}`);
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const insertQuestion = async (bodyInsertQuestion: BodyInsertQuestion) => {
  const respostaServico = await ConnectApiPost(urls.URL_QUESTION, bodyInsertQuestion);
  return respostaServico.data;
};

export const importQuestionList = async (body: FormData, isLibrary: boolean) => {
  const url = generateQueryString(urls.URL_QUESTION_CSV, isLibrary ? { isLibrary } : {});
  const respostaServico = await ConnectApiPost(url, body);
  return respostaServico.data;
};

export const importResearchList = async (body: FormData, isLibrary: boolean) => {
  const url = generateQueryString(urls.URL_RESEARCH_CSV, isLibrary ? { isLibrary } : {});
  const respostaServico = await ConnectApiPost(url, body);
  return respostaServico.data;
};

export const editQuestion = async (bodyInsertQuestion: BodyInsertQuestion, idQuestion: string) => {
  const url = urls.URL_QUESTION_ID.replace('{idQuestion}', `${idQuestion}`);
  const respostaServico = await ConnectApiPUT(url, bodyInsertQuestion);
  return respostaServico.data;
};

export const deleteQuestion = async (idQuestion: string) => {
  const url = urls.URL_QUESTION_ID.replace('{idQuestion}', `${idQuestion}`);
  await ConnectApiDelete(url);
};

export const duplicateQuestion = async (idQuestion: string) => {
  const url = urls.URL_QUESTION_ID_DUPLICATE.replace('{idQuestion}', `${idQuestion}`);
  await ConnectApiPost(url, {});
};

export const getInfoAnswerQuestion = async (
  idQuestion: string,
  idContest?: string,
  idStage?: string,
): Promise<Question> => {
  let body;
  if (idContest) {
    body = { idContest };
  } else {
    body = { idStage };
  }
  const url = generateQueryString(
    urls.URL_QUESTION_ID_INFO_ANSWER.replace('{idQuestion}', `${idQuestion}`),
    body,
  );
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};
