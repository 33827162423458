import React, { ReactNode } from 'react';
import { useGeneral } from '../../../../store/reducers/general';
import ButtonLineBottom from '../../buttons/buttonLineBottom/ButtonLineBottom';
import { BoxInfo, DisplayFlex } from '../../styled/general';
import { UserPagination } from '../../../modals/user/user';

export type PItemBoxHorizontalButtons = {
  textButton: string;
  children: ReactNode;
};

type PBoxHorizontalButtons = {
  items: PItemBoxHorizontalButtons[];
  pagination?: UserPagination;
  setPagination?: (x: UserPagination) => void;
};

const BoxHorizontalButtons = ({ items, pagination, setPagination }: PBoxHorizontalButtons) => {
  const { setCurrentSelectedTab, currentSelectedTab } = useGeneral();

  const children = items[currentSelectedTab];

  return (
    <BoxInfo style={{ padding: 0 }}>
      <DisplayFlex style={{ marginBottom: 16 }}>
        {items.map((item, index) => (
          <ButtonLineBottom
            isSelected={currentSelectedTab === index}
            onClick={() => {
              if (pagination && setPagination) {
                switch (index) {
                  case 2:
                    setPagination({
                      ...pagination,
                      page: 1,
                      constraints: { ...pagination.constraints, status: 'frozen' },
                    });
                    break;
                  case 1:
                    setPagination({
                      ...pagination,
                      page: 1,
                      constraints: { ...pagination.constraints, status: 'blocked' },
                    });
                    break;
                  default:
                    setPagination({
                      ...pagination,
                      page: 1,
                      constraints: { ...pagination.constraints, status: 'active' },
                    });
                }
              }
              setCurrentSelectedTab(index);
            }}
            textButton={item.textButton}
          />
        ))}
      </DisplayFlex>
      {children?.children}
    </BoxInfo>
  );
};

BoxHorizontalButtons.defaultProps = {
  pagination: undefined,
  setPagination: undefined,
};

export default BoxHorizontalButtons;
