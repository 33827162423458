import styled from 'styled-components';
import { colors } from '../../functions/colors';
import { fonts } from '../../functions/fonts';

type BoxShowPresentation = {
  showPresentation: boolean;
  amountSlides: number;
};

export const Container = styled.div`
  padding: 24px;
  width: 100%;
  display: inline-block;
`;

export const DisplayFlex = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h1`
  color: ${colors.pinkRegular};
  font-family: ${fonts.bold};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

export const SubTitle = styled.p`
  color: ${colors.grey100};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  margin: 24px 8px;
`;

export const NameApresentation = styled.h2`
  color: ${colors.purpleRegular};
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;

  margin-bottom: 16px;
`;

export const BoxShowPresentation = styled.div<BoxShowPresentation>`
  max-height: ${props => (props.showPresentation ? `${props.amountSlides * 340}px` : '0px')};
  max-width: ${props => (props.showPresentation ? '100%' : '50%')};
  opacity: ${props => (props.showPresentation ? 1 : 0)};
  transition: 1s;
`;

export const Line = styled.div`
  flex-grow: 1;
  height: 2px;
  background-color: ${colors.pinkRegular};
  margin: 0px 16px;
`;

export const AttachTitle = styled.h2`
  font-family: ${fonts.semiBold};
  color: ${colors.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;

  margin-left: 8px;
`;
