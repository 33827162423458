import ReactGA from 'react-ga';
import { userNameJwt } from './token';

export function startAnalitycs() {
  ReactGA.initialize(`${process.env.REACT_APP_ID_ANALYTICS}`);
}

export function actionAnalitycs(category?: string, action?: string, link?: string) {
  ReactGA.event({
    category: category || '',
    action: action || '',
  });
  ReactGA.set({ userId: userNameJwt() });
  if (link) ReactGA.pageview(link);
}
