import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import Button from '../../../shared/components/buttons/Button';
import Modal from '../../../shared/components/modal/Modal';
import {
  BoxButtons,
  Title,
  InputUser,
  Subtitle,
  TitleSuccess,
  BoxButtonsSuccess,
  SubtitleSuccess,
  BoxSuccess,
} from './styles';
import { BodyForgotPassword } from '../../../shared/modals/user/bodies';
import { useUser } from '../../../store/reducers/user';
import IconDoubleCheck from '../../../shared/images/icon/iconDoubleCheck';

type PForgotPassword = {
  openForgotPassword: boolean;
  setOpenForgotPassword: Dispatch<SetStateAction<boolean>>;
};

const initialBody = {
  email: '',
};

const ForgotPassword = ({ openForgotPassword, setOpenForgotPassword }: PForgotPassword) => {
  const { requestPasswordEmail } = useUser();
  const [loading, setLoading] = useState(false);
  const [popupIndex, setPopupIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [bodyForgotPassword, setBodyForgotPassword] = useState<BodyForgotPassword>(initialBody);

  useEffect(() => {
    setBodyForgotPassword(initialBody);
  }, [openForgotPassword]);

  const handleSendPasswordEmail = async () => {
    setLoading(true);
    try {
      await requestPasswordEmail(bodyForgotPassword);
      setPopupIndex(1);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
    setLoading(false);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBodyForgotPassword({
      ...bodyForgotPassword,
      email: event.target.value,
    });
  };

  const closePopup = () => {
    setOpenForgotPassword(false);
    setPopupIndex(0);
  };

  const renderMessage = (index: number) => {
    switch (index) {
      case 0:
        return (
          <>
            <Title>Esqueci minha senha</Title>
            <Subtitle>
              Para sua segurança, insira abaixo o seu email para redefinição de senha:
            </Subtitle>
            <InputUser
              title="Email"
              placeholder="Digite o email"
              value={bodyForgotPassword.email}
              onChange={handleChangeEmail}
            />
            <BoxButtons>
              <Button
                style={{ width: 180, marginRight: 15 }}
                onClick={closePopup}
                typeButton="primary-white"
              >
                CANCELAR
              </Button>
              <Button
                disabled={bodyForgotPassword.email === ''}
                loading={loading}
                style={{ width: 180 }}
                onClick={handleSendPasswordEmail}
              >
                ENVIAR
              </Button>
            </BoxButtons>
            {errorMessage}
          </>
        );
      case 1:
        return (
          <>
            <BoxSuccess>
              <IconDoubleCheck style={{ marginLeft: 'auto', marginRight: 'auto' }} />
              <TitleSuccess>Email enviado com sucesso</TitleSuccess>
              <SubtitleSuccess>
                Acesse o link enviado para o seu email e siga corretamente todas as orientações para
                redefinir sua senha.
              </SubtitleSuccess>
              <BoxButtonsSuccess>
                <Button style={{ width: 180 }} onClick={closePopup}>
                  OK, ENTENDI
                </Button>
              </BoxButtonsSuccess>
            </BoxSuccess>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Modal onClose={() => setOpenForgotPassword(false)} open={openForgotPassword}>
      {renderMessage(popupIndex)}
    </Modal>
  );
};

export default ForgotPassword;
