import { User } from '../../shared/modals/user/user';

export default class UserData {
  userData: User;

  constructor(userData: User) {
    this.userData = userData;
  }

  getMenus() {
    return this.userData?.typeUser?.menus || [];
  }
}
