import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { MenuUrl } from './shared/enums/menuUrl';
import Logged from './features/logged';
import Company from './features/company';
import Question from './features/question';
import Login from './features/login';
import User from './features/user';
import FirstLogin from './features/firstLogin';
import Contest from './features/contest';
import Trail from './features/trail';
import Stage from './features/stage';
import Category from './features/category';
import Report from './features/report';
import Home from './features/home';
import Setup from './features/setup';
import ContestReport from './features/contestReport';
import Area from './features/area';
import TrailReport from './features/trailReport';
import LibraryAdmin from './features/libraryAdmin';
import Library from './features/library';
import Preferences from './features/preferences';
import Permission from './features/permission';
import TypeUser from './features/typeUser';
import UserReport from './features/userReport';
import Reward from './features/reward';
import RewardManage from './features/rewardManage';
import Mission from './features/mission/Mission';
import CreateEditMission from './features/mission/create/CreateEditMission';
import Challenge from './features/challenge/Challenge';
import CreateEditChallenge from './features/challenge/createEdit/CreateEditChallenge';
import ChallengeApprovals from './features/approvals/ChallengeApprovals';
import SelfSignUpCampaigns from './features/selfSignUpCampaigns/SelfSignUpCampaigns';
import CreateEditSelfSignUpCampaign from './features/selfSignUpCampaigns/createEdit/CreateEditSelfSignUpCampaign';
import ImportAreas from './features/area/importAreas/importAreas';
import Journeys from './features/journeys/Journeys';
import CreateEditJourney from './features/journeys/createEdit/CreateEditJourney';
import Rollout from './features/rollout/Rollout';
import CreateEditRollout from './features/rollout/forms/CreateEditRollout';
import LoginWithMFA from './features/login/LoginWithMFA';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/verify/:token/:email" exact component={Login} />
      <Route path="/mfa/:token" exact component={LoginWithMFA} />
      <Route path={`${MenuUrl.setup}/:token?`} exact component={Setup} />
      <Route path={MenuUrl.home} exact>
        <Logged>
          <Home />
        </Logged>
      </Route>
      <Route path={MenuUrl.preferences} exact>
        <Logged>
          <Preferences />
        </Logged>
      </Route>
      <Route path={MenuUrl.company} exact>
        <Logged>
          <Company />
        </Logged>
      </Route>
      <Route path={`${MenuUrl.question}/:idLibrary?`} exact>
        <Logged>
          <Question />
        </Logged>
      </Route>
      <Route path={MenuUrl.category} exact>
        <Logged>
          <Category />
        </Logged>
      </Route>
      <Route path={`${MenuUrl.user}/:idUser?`} exact>
        <Logged>
          <User />
        </Logged>
      </Route>
      <Route path={MenuUrl.firstLogin} exact>
        <Logged>
          <FirstLogin />
        </Logged>
      </Route>
      <Route path={`${MenuUrl.contest}/:idContest?`} exact>
        <Logged>
          <Contest />
        </Logged>
      </Route>
      <Route path={`${MenuUrl.trail}/:idTrail?`} exact>
        <Logged>
          <Trail />
        </Logged>
      </Route>
      <Route path={`${MenuUrl.contestReport}/:idContest`} exact>
        <Logged>
          <ContestReport />
        </Logged>
      </Route>
      <Route path={`${MenuUrl.userReport}/:idUser`} exact>
        <Logged>
          <UserReport />
        </Logged>
      </Route>
      <Route path={`${MenuUrl.trailReport}/:idTrail`} exact>
        <Logged>
          <TrailReport />
        </Logged>
      </Route>
      <Route path={`${MenuUrl.libraryAdmin}/:idLibrary?`} exact>
        <Logged>
          <LibraryAdmin />
        </Logged>
      </Route>
      <Route path={`${MenuUrl.library}/:idLibrary?`} exact>
        <Logged>
          <Library />
        </Logged>
      </Route>
      <Route path={`${MenuUrl.permission}`} exact>
        <Logged>
          <Permission />
        </Logged>
      </Route>
      <Route path={`${MenuUrl.typeUser}`} exact>
        <Logged>
          <TypeUser />
        </Logged>
      </Route>
      <Route path={`${MenuUrl.area}`} exact>
        <Logged>
          <Area />
        </Logged>
      </Route>
      <Route path={MenuUrl.importAreas} exact>
        <Logged>
          <ImportAreas />
        </Logged>
      </Route>
      <Route path={MenuUrl.stage} exact>
        <Logged>
          <Stage />
        </Logged>
      </Route>
      <Route path={MenuUrl.report} exact>
        <Logged>
          <Report />
        </Logged>
      </Route>
      <Route path={`${MenuUrl.reward}/:idReward?`} exact>
        <Logged>
          <Reward />
        </Logged>
      </Route>
      <Route path={MenuUrl.rewardManage} exact>
        <Logged>
          <RewardManage />
        </Logged>
      </Route>
      <Route path={MenuUrl.missions} exact>
        <Logged>
          <Mission />
        </Logged>
      </Route>
      <Route path={`${MenuUrl.missions}/new`} exact>
        <Logged>
          <CreateEditMission />
        </Logged>
      </Route>
      <Route path={`${MenuUrl.missions}/:id/edit`} exact>
        <Logged>
          <CreateEditMission />
        </Logged>
      </Route>
      <Route path={[MenuUrl.challenges, `${MenuUrl.missions}/:missionId/challenges`]} exact>
        <Logged>
          <Challenge />
        </Logged>
      </Route>
      <Route
        path={[
          `${MenuUrl.missions}/:missionId/challenges/new`,
          `${MenuUrl.missions}/:missionId/challenges/:challengeId/edit`,
        ]}
        exact
      >
        <Logged>
          <CreateEditChallenge />
        </Logged>
      </Route>
      <Route path={MenuUrl.approvals} exact>
        <Logged>
          <ChallengeApprovals />
        </Logged>
      </Route>
      <Route path={MenuUrl.selfSignUpCampaigns} exact>
        <Logged>
          <SelfSignUpCampaigns />
        </Logged>
      </Route>
      <Route
        path={[`${MenuUrl.selfSignUpCampaigns}/new`, `${MenuUrl.selfSignUpCampaigns}/:id/edit`]}
        exact
      >
        <Logged>
          <CreateEditSelfSignUpCampaign />
        </Logged>
      </Route>
      <Route path={MenuUrl.journeys} exact>
        <Logged>
          <Journeys />
        </Logged>
      </Route>
      <Route path={[`${MenuUrl.journeys}/new`, `${MenuUrl.journeys}/:id/edit`]} exact>
        <Logged>
          <CreateEditJourney />
        </Logged>
      </Route>

      <Route path={`${MenuUrl.rollouts}`} exact>
        <Logged>
          <Rollout />
        </Logged>
      </Route>

      <Route path={[`${MenuUrl.rollouts}/new`, `${MenuUrl.rollouts}/:id/edit`]} exact>
        <Logged>
          <CreateEditRollout />
        </Logged>
      </Route>
    </Switch>
  </BrowserRouter>
);

export default Router;
