import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useQuestion } from '../../../store/reducers/question';
import Select from '../../../shared/components/select/Select';
import { BodyInsertQuestion } from '../../../shared/modals/question/bodies';
import { Category } from '../../../shared/modals/question/category';
import { Container, BoxNewQuestion, ContainerFlex, BoxBottom } from './styles';
import InsertAlternatives from './insertAlternatives/InsertAlternatives';
import BreadCrumbs from '../../../shared/components/breadCrumbs/BreadCrumbs';
import InputFileImage from '../../../shared/components/input/inputFileImage/InputFile';

import Button from '../../../shared/components/buttons/Button';
import TextArea from '../../../shared/components/input/textArea/TextArea';
import { useGeneral } from '../../../store/reducers/general';
import RichTextEditor from '../../../shared/components/richEditor/RichTextEditor';
import RadioPurple from '../../../shared/components/input/inputRadio/radioPurple/radioPurple';
import { Title } from '../../../shared/components/select/styles';
import { QuestionType } from '../../../shared/modals/question/question';
import { handleChangeFieldValue } from '../../../shared/functions/handlers';

const NEW_QUESTION = {
  idCategory: undefined,
  idLibrary: undefined,
  text: '',
  image: '',
  justification: '',
  type: 'Question' as QuestionType,
  alternativesAttributes: [
    {
      index: 0,
      text: '',
      trueQuestion: false,
      idQuestion: '',
    },
  ],
};

const MIN_ALTERNATIVES_COUNT = 2;

type PInsertQuestion = {
  setOpenInsertQuestion: Dispatch<SetStateAction<boolean>>;
  idQuestionEdit: string;
  setIdQuestionEdit: Dispatch<SetStateAction<string>>;
  idLibrary?: string;
};

const InsertQuestion = ({
  setOpenInsertQuestion,
  idQuestionEdit,
  setIdQuestionEdit,
  idLibrary,
}: PInsertQuestion) => {
  const {
    insertQuestion,
    editQuestion,
    getAllDataQuestion,
    categories,
    question,
    questionPagination,
  } = useQuestion();
  const { setLoadingBlockScreen } = useGeneral();

  const [loading, setLoading] = useState(false);
  const [categorySelected, setCategorySelected] = useState('');
  const [bodyInsertQuestion, setBodyInsertQuestion] = useState<BodyInsertQuestion>(
    idLibrary ? { ...NEW_QUESTION, idLibrary } : NEW_QUESTION,
  );
  const isStandardQuestion = bodyInsertQuestion.type === 'Question';

  useEffect(() => {
    const getAllQuestionBlock = async () => {
      setLoadingBlockScreen(true);
      await getAllDataQuestion(idQuestionEdit);
      setLoadingBlockScreen(false);
    };

    if (idQuestionEdit) {
      getAllQuestionBlock();
    }
  }, [idQuestionEdit]);

  useEffect(() => {
    if (idQuestionEdit && question) {
      setCategorySelected(`${question.idCategory}`);
      setBodyInsertQuestion({
        idCategory: question.idCategory,
        idLibrary: question.idLibrary,
        text: question.text,
        image: question.image,
        justification: question.justification ? question.justification : '',
        type: question.type.length ? question.type : 'Question',
        alternativesAttributes: question.alternatives.map((alternative, index) => ({
          ...alternative,
          index,
        })),
      });
    }
  }, [question]);

  const convertCategoriesToPOptions = () => {
    const arrayReturn = [
      {
        value: '',
        viewValue: 'Selecione',
      },
    ];

    categories.forEach((category: Category) => {
      arrayReturn.push({
        value: `${category.idCategory}`,
        viewValue: category.name,
      });
    });

    return arrayReturn;
  };

  const amountAlternativesValid = () => {
    let amount = 0;
    bodyInsertQuestion.alternativesAttributes.forEach(body => {
      if (body.text) {
        amount += 1;
      }
    });
    return amount;
  };

  const amountAlternativesTrueQuestion = () => {
    let amount = 0;
    bodyInsertQuestion.alternativesAttributes.forEach(body => {
      if (body.trueQuestion) {
        amount += 1;
      }
    });
    return amount;
  };

  const verifyAllowInsertQuestion = () => {
    if (!bodyInsertQuestion.text) {
      return false;
    }
    if (!idLibrary && !bodyInsertQuestion.idCategory) {
      return false;
    }
    const amountAlternatives = amountAlternativesValid();
    if (amountAlternatives < MIN_ALTERNATIVES_COUNT) {
      return false;
    }
    const amountTrueQuestions = amountAlternativesTrueQuestion();
    if (isStandardQuestion && amountTrueQuestions <= 0) {
      return false;
    }
    return true;
  };

  const handleBackQuestion = () => {
    setOpenInsertQuestion(false);
    setIdQuestionEdit('');
  };

  const handleInsertQuestion = async () => {
    setLoading(true);
    if (idQuestionEdit) {
      await editQuestion(
        {
          ...bodyInsertQuestion,
          image: bodyInsertQuestion.image,
        },
        idQuestionEdit,
        questionPagination,
      );
    } else {
      await insertQuestion(
        {
          ...bodyInsertQuestion,
          image: bodyInsertQuestion.image,
        },
        questionPagination,
      );
    }
    handleBackQuestion();
    setLoading(false);
  };

  const handleOnChangeQuestion = (content: string) => {
    setBodyInsertQuestion({
      ...bodyInsertQuestion,
      text: content,
    });
  };

  const handleOnChangeSelectCategory = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setBodyInsertQuestion({
      ...bodyInsertQuestion,
      idCategory: event.target.value,
    });
  };

  const handleOnChangeJustification = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBodyInsertQuestion({
      ...bodyInsertQuestion,
      justification: event.target.value,
    });
  };

  return (
    <Container>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Perguntas',
            action: handleBackQuestion,
          },
          {
            name: 'Nova pergunta',
          },
        ]}
      />
      <BoxNewQuestion>
        <ContainerFlex>
          {!idLibrary && (
            <>
              <Select
                title="Categoria*"
                nameSelect="select_category"
                listOptions={convertCategoriesToPOptions()}
                onChange={handleOnChangeSelectCategory}
                valueSelected={categorySelected}
                style={{ marginRight: 32, width: 400 }}
              />
              <div>
                <Title>Tipo*</Title>
                <RadioPurple
                  checked={isStandardQuestion}
                  value="Question"
                  labelTitle="Padrão"
                  id="question"
                  name="type"
                  onChange={event => handleChangeFieldValue(event, setBodyInsertQuestion)}
                  details="Limite de 4 alternativas sendo que uma delas deve ser escolhida como a resposta certa."
                />
                <RadioPurple
                  checked={!isStandardQuestion}
                  value="Research"
                  labelTitle="Pesquisa"
                  id="research"
                  name="type"
                  onChange={event => handleChangeFieldValue(event, setBodyInsertQuestion)}
                  details="Alternativas ilimitadas; Sem resposta certa."
                />
              </div>
            </>
          )}
        </ContainerFlex>
        <ContainerFlex>
          <ContainerFlex style={{ flexFlow: 'column', width: '100%' }}>
            <RichTextEditor
              label="Pergunta*"
              value={bodyInsertQuestion.text}
              onChange={handleOnChangeQuestion}
              style={{ width: '100%' }}
            />
          </ContainerFlex>
        </ContainerFlex>
        <ContainerFlex>
          <InsertAlternatives
            bodyInsertQuestion={bodyInsertQuestion}
            setBodyInsertQuestion={setBodyInsertQuestion}
            isStandardQuestion={isStandardQuestion}
          />
          <InputFileImage
            title="Inserir imagem (opcional)"
            subTitle="Você pode inserir imagem em .png .jpeg .gif."
            urlImage={bodyInsertQuestion.image || ''}
            setUrlImage={image => setBodyInsertQuestion({ ...bodyInsertQuestion, image })}
            style={{ marginLeft: 32, width: '40%' }}
            dimensions={{ width: 276, height: 148 }}
            isCrop
          />
        </ContainerFlex>
        <TextArea
          title="Justificativa (opcional)"
          placeholder="Digite"
          onChange={handleOnChangeJustification}
          value={bodyInsertQuestion.justification}
          styleTextarea={{ height: 112 }}
          maxLength={200}
          showLenghtMax
          style={{ width: '100%' }}
        />
        <BoxBottom>
          <Button onClick={handleBackQuestion} style={{ width: 120 }} typeButton="primary-white">
            Cancelar
          </Button>
          <Button
            loading={loading}
            onClick={handleInsertQuestion}
            disabled={!verifyAllowInsertQuestion()}
            style={{ width: 120, marginLeft: 16 }}
          >
            SALVAR
          </Button>
        </BoxBottom>
      </BoxNewQuestion>
    </Container>
  );
};

InsertQuestion.defaultProps = {
  idLibrary: undefined,
};

export default InsertQuestion;
