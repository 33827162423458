import { useDispatch, useSelector } from 'react-redux';
import { SecurityPreferences } from '../../../features/preferences/Security';

import {
  BodyEditCompany,
  BodyEditPassword,
  BodyInsertCompany,
} from '../../../shared/modals/company/bodies';
import { CompanyStyle } from '../../../shared/modals/user/user';
import { RootStateGlobal } from '../../reducer';
import * as companyOperations from './companyOperations';

export const useCompany = () => {
  const dispatch = useDispatch();
  const { companies, company } = useSelector((state: RootStateGlobal) => state.companyReducer);

  const getCompanies = async () => companyOperations.getCompanies()(dispatch);
  const getCompany = async () => companyOperations.getCompany()(dispatch);
  const getCompanyPreferenceStyles = async () =>
    companyOperations.getCompanyPreferenceStyles()(dispatch);
  const getCompanySecurityPreferences = async () =>
    companyOperations.getCompanySecurityPreferences()(dispatch);
  const insertCompany = async (bodyInsertCompany: BodyInsertCompany) =>
    companyOperations.insertCompany(bodyInsertCompany)(dispatch);
  const verifyAndCreateCompany = async (bodyInsertCompany: BodyInsertCompany) =>
    companyOperations.verifyAndCreateCompany(bodyInsertCompany)(dispatch);
  const verifyCompany = async (token: string) => companyOperations.verifyCompany(token)(dispatch);
  const editCompany = async (idCompany: string, bodyInsertCompany: BodyInsertCompany) =>
    companyOperations.editCompany(idCompany, bodyInsertCompany)(dispatch);
  const editCompanyPreferences = async (bodyEditCompany: BodyEditCompany) =>
    companyOperations.editCompanyPreferences(bodyEditCompany)(dispatch);
  const editCompanyPreferencesStyles = async (companyStyle: CompanyStyle) =>
    companyOperations.editCompanyPreferencesStyles(companyStyle)(dispatch);
  const editCompanySecurityPreferences = async (companySecurityPreference: SecurityPreferences) =>
    companyOperations.editCompanySecurityPreferences(companySecurityPreference)(dispatch);
  const editPassword = async (bodyEditPassword: BodyEditPassword) =>
    companyOperations.editPassword(bodyEditPassword)(dispatch);
  const blockCompany = async (idCompany: string) =>
    companyOperations.blockCompany(idCompany)(dispatch);
  const unlockCompany = async (idCompany: string) =>
    companyOperations.unlockCompany(idCompany)(dispatch);

  return {
    companies,
    company,
    getCompanies,
    getCompanyPreferenceStyles,
    getCompanySecurityPreferences,
    getCompany,
    insertCompany,
    verifyAndCreateCompany,
    verifyCompany,
    editCompany,
    editCompanyPreferences,
    editCompanyPreferencesStyles,
    editCompanySecurityPreferences,
    blockCompany,
    unlockCompany,
    editPassword,
  };
};
