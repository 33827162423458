import { Trail, TrailReport } from '../../../shared/modals/trail/trail';

export enum TrailTypes {
  SET_TRAILS = '@trail/SET_TRAILS',
  SET_TRAIL = '@trail/SET_TRAIL',
  SET_TRAIL_REPORT = '@contest/SET_TRAIL_REPORT',
}

export type TrailState = {
  readonly trails: Trail[];
  readonly trail?: Trail;
  readonly trailReport?: TrailReport;
};
