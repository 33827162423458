import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as selfSignUpCampaignsService from '../../../shared/services/selfSignUpCampaigns';

import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { SelfSignUpCampaignInterface } from '../SelfSignUpCampaigns';

type PToggleSelfSignUpCampaignEnabled = {
  selfSignUpCampaign: SelfSignUpCampaignInterface;
  open: boolean;
  handleCloseModal: () => void;
  refreshList: () => void;
};

const ToggleSelfSignUpCampaignEnabled = ({
  selfSignUpCampaign,
  open,
  handleCloseModal,
  refreshList,
}: PToggleSelfSignUpCampaignEnabled) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  if (!selfSignUpCampaign) return null;

  const handleToggleEnabled = async () => {
    setLoading(true);
    await selfSignUpCampaignsService.update(
      { enabled: !selfSignUpCampaign.enabled },
      selfSignUpCampaign.id,
      dispatch,
    );
    refreshList();
    setLoading(false);
    handleCloseModal();
  };

  const title = selfSignUpCampaign.enabled
    ? 'Desativar campanha de cadastro'
    : 'Ativar campanha de cadastro';
  const message = selfSignUpCampaign.enabled
    ? 'Os usuários não poderão cadastrar-se através dessa campanha de cadastro. Tem certeza que deseja desativar essa campanha?'
    : 'Os usuários poderão cadastrar-se através dessa campanha de cadastro. Tem certeza que deseja ativar esse cadastro?';

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title={title}
      message={message}
      onClose={handleCloseModal}
      open={open}
      onClickPrimary={handleToggleEnabled}
      onClickSecondary={handleCloseModal}
    />
  );
};

export default ToggleSelfSignUpCampaignEnabled;
