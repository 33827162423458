import styled from 'styled-components';
import { colors } from '../../../functions/colors';

export const Container = styled.div`
  position: relative;
`;

export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: absolute;
  top: 44px;
  left: 0;
  z-index: 1;
  border: '1px solid';

  padding: 16px;
  background-color: ${colors.white};
  border: 1px solid #eceef0;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(38, 40, 36, 0.24);
  border-radius: 4px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;
