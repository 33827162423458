import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import Button from '../../shared/components/buttons/Button';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import { dateTimeStampToString } from '../../shared/functions/date';
import IconEdit from '../../shared/images/icon/iconEdit';
import IconTrash from '../../shared/images/icon/iconTrash';
import { Contest as IContest } from '../../shared/modals/contest/contest';
import { useContest } from '../../store/reducers/contest';
import InsertContest from './insertContest/InsertContest';
import DeleteContest from './deleteContest/DeleteContest';
import { Container, Header, TextHeader, BoxHeaderButtons } from './styles';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import Table from '../../shared/components/table/Table';
import { colors } from '../../shared/functions/colors';
import { filterArraySearch } from '../../shared/functions/utils';
import IconDuplicate from '../../shared/images/icon/iconDuplicate';
import DuplicateContest from './duplicateContest/DuplicateContest';
import IconCheck from '../../shared/images/icon/iconCheck';
import IconBlock from '../../shared/images/icon/iconBlock';
import EnableContest from './enableContest/EnableContest';
import IconReport from '../../shared/images/icon/iconReport';
import { ExtraUrl, MenuUrl } from '../../shared/enums/menuUrl';
import FilterContest, { ClickFilterHandle } from './filterContest/FilterContest';
import ImportContestList from './importContestList/ImportContestList';
import BoxHorizontalButtons from '../../shared/components/box/boxHorizontalButtons/BoxHorizontalButtons';
import SearchInput from '../../shared/components/input/searchInput/SearchInput';

type ParamTypes = {
  idContest?: string;
};

const Contest = () => {
  const { idContest } = useParams<ParamTypes>();
  const history = useHistory();
  const { contests, getContests } = useContest();
  const [openInsertContest, setOpenInsertContest] = useState(false);
  const [openImportContest, setOpenImportContest] = useState(false);
  const [openDeleteContest, setOpenDeleteContest] = useState(false);
  const [openDuplicateContest, setOpenDuplicateContest] = useState(false);
  const [openEnableContest, setOpenEnableContest] = useState(false);
  const [contestAction, setContestAction] = useState<IContest>();
  const [idContestEdit, setIdContestEdit] = useState<string | undefined>(undefined);
  const [searchContest, setSearchContest] = useState('');
  const [contestsFilter, setContestsFilter] = useState<IContest[]>([]);
  const [openFilter, setOpenFilter] = useState(false);
  const childRef = useRef<ClickFilterHandle>();

  useEffect(() => {
    getContests();
    if (idContest === ExtraUrl.import) {
      setOpenImportContest(true);
    } else if (idContest) {
      setIdContestEdit(idContest);
      setOpenInsertContest(true);
    } else {
      setOpenImportContest(false);
    }
  }, [idContest]);

  useEffect(() => {
    setContestsFilter(filterArraySearch(contests, 'name', searchContest));
  }, [contests]);

  const handleChangeSearchContest = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchContest(event.target.value);
    setContestsFilter(filterArraySearch(contests, 'name', event.target.value));
  };

  const handleOpenInsertContest = (idContest: string | undefined) => {
    setIdContestEdit(idContest);
    setOpenInsertContest(true);
  };

  const handleOnClickDeleteContest = (contest: IContest) => {
    setContestAction(contest);
    setOpenDeleteContest(true);
  };

  const handleOnClickEnableContest = (contest: IContest) => {
    setContestAction(contest);
    setOpenEnableContest(true);
  };

  const handleOnClickDuplicateContest = (contest: IContest) => {
    setContestAction(contest);
    setOpenDuplicateContest(true);
  };

  const handleOpenReportContest = (contest: IContest) => {
    history.push(`${MenuUrl.contestReport}/${contest.idTourney}`);
  };

  const goToImportContestList = () => {
    history.push(`${MenuUrl.contest}/${ExtraUrl.import}`);
  };

  if (openInsertContest) {
    return (
      <InsertContest setOpenInsertContest={setOpenInsertContest} idContestEdit={idContestEdit} />
    );
  }

  if (openImportContest) {
    return <ImportContestList />;
  }

  const renderRowsTable = (contestStatusFiltered: IContest[]) =>
    contestStatusFiltered.map(contest => ({
      columns: [
        dateTimeStampToString(contest.startDate),
        dateTimeStampToString(contest.endDate),
        contest.name,
        contest.amountParticipants,
        <>
          <ButtonIcon
            style={{ marginRight: 16 }}
            onClick={() => handleOnClickEnableContest(contest)}
          >
            {contest.active ? <IconCheck color={colors.green} /> : <IconBlock color={colors.red} />}
          </ButtonIcon>
        </>,
        <>
          <ButtonIcon
            style={{ marginRight: 16 }}
            onClick={() => handleOpenInsertContest(contest.idTourney)}
          >
            <IconEdit />
          </ButtonIcon>
          <ButtonIcon
            style={{ marginRight: 16 }}
            onClick={() => handleOnClickDuplicateContest(contest)}
          >
            <IconDuplicate />
          </ButtonIcon>
          <ButtonIcon
            style={{ marginRight: 16 }}
            onClick={() => handleOnClickDeleteContest(contest)}
          >
            <IconTrash />
          </ButtonIcon>
          {!!contest.amountParticipants && (
            <ButtonIcon onClick={() => handleOpenReportContest(contest)}>
              <IconReport color={colors.grey100} />
            </ButtonIcon>
          )}
        </>,
      ],
    }));

  const renderTable = (contestStatusFiltered: IContest[]) => {
    return (
      <Table
        nameReferenceReduxTable="table_contest"
        listRowsPerPage={[10, 25, 50, 100]}
        titles={[
          {
            name: 'Início',
            openClick: true,
            type: 'date',
            widthTd: '15%',
          },
          {
            name: 'Fim',
            openClick: true,
            type: 'date',
            widthTd: '15%',
          },
          {
            name: 'Nome do torneio',
            openClick: true,
            type: 'string',
            widthTd: '30%',
          },
          {
            name: 'Participantes',
            openClick: true,
            type: 'number',
          },
          {
            name: 'Permissões',
            openClick: false,
            type: 'ReactNode',
            widthTd: '10%',
          },
          {
            name: 'Ações',
            openClick: false,
            type: 'ReactNode',
            widthTd: '20%',
          },
        ]}
        rows={renderRowsTable(contestStatusFiltered)}
        withFilter
        setOpenFilter={setOpenFilter}
      />
    );
  };

  return (
    <Container>
      <FilterContest openFilter={openFilter} setOpenFilter={setOpenFilter} ref={childRef} />
      <EnableContest
        contest={contestAction}
        openEnableContest={openEnableContest}
        setOpenEnableContest={setOpenEnableContest}
      />
      <DeleteContest
        contest={contestAction}
        openDeleteContest={openDeleteContest}
        setOpenDeleteContest={setOpenDeleteContest}
      />
      <DuplicateContest
        contest={contestAction}
        openDuplicateContest={openDuplicateContest}
        setOpenDuplicateContest={setOpenDuplicateContest}
      />
      <BreadCrumbs
        listMenus={[
          {
            name: 'Torneios',
          },
        ]}
      />
      <Header>
        <TextHeader>Você pode criar, excluir ou editar os torneios cadastrados.</TextHeader>
        <BoxHeaderButtons>
          <SearchInput
            placeholder="Buscar torneio..."
            search={searchContest}
            onChange={handleChangeSearchContest}
          />
          <Button
            style={{ marginRight: 16, width: 180 }}
            onClick={() => handleOpenInsertContest(undefined)}
            icon="plus"
          >
            NOVO TORNEIO
          </Button>
          <Button style={{ width: 180 }} onClick={goToImportContestList} icon="plus">
            IMPORTAR LISTA
          </Button>
        </BoxHeaderButtons>
      </Header>
      <BoxHorizontalButtons
        items={[
          {
            textButton: 'Torneios Ativos',
            children: renderTable(contestsFilter.filter(contest => contest.active)),
          },
          {
            textButton: 'Torneios Inativos',
            children: renderTable(contestsFilter.filter(contest => !contest.active)),
          },
        ]}
      />
    </Container>
  );
};

export default Contest;
