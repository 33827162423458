import { Report, Ranking } from '../../../shared/modals/report/report';

export enum ReportTypes {
  SET_REPORT = '@report/SET_REPORT',
  SET_REPORT_PRESENCE = '@report/SET_REPORT_PRESENCE',
}

export type ReportState = {
  readonly report?: Report;
  readonly presenceGraphic?: Ranking;
};
