import styled from 'styled-components';
import { colors } from '../../../functions/colors';
import { fonts } from '../../../functions/fonts';
import { DefaultText } from '../../styled/text';

type IsTrueQuestion = {
  isTrueQuestion?: boolean;
};

export const BoxAlternative = styled(DefaultText)<IsTrueQuestion>`
  padding: 16px 40px;
  border-bottom: 1px solid ${colors.grey80};
  font-family: ${props => (props.isTrueQuestion ? fonts.semiBold : fonts.regular)};
  color: ${props => (props.isTrueQuestion ? colors.pinkRegular : colors.black)};
  width: 100%;
`;

export const BoxTitleAlternative = styled(BoxAlternative)`
  font-family: ${fonts.semiBold};
  color: ${colors.purpleRegular};
`;

export const Image = styled.img`
  width: 100%;
`;
