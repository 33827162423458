import { ChallengeInterface as ListChallengeInterface } from '../../features/challenge/Challenge';
import { ChallengeInterface } from '../../features/challenge/createEdit/CreateEditChallenge';
import {
  ConnectApiGet,
  ConnectApiPost,
  ConnectApiPUT,
  ConnectApiDelete,
  urls,
} from '../functions/connection';
import { doLogout } from '../functions/connection/auth';
import { ERROR_MAINTENANCE } from '../constants/messages';
import { setSnackbar, SnackBarActionInterface } from '../../store/reducers/general/generalActions';

export const getAll = async (dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    const response = await ConnectApiGet(urls.CHALLENGES_URL);
    const challenges: ListChallengeInterface[] = response.data;
    return challenges;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }

  return [];
};

export const getAllByMission = async (
  missionId: string,
  dispatch: (arg: SnackBarActionInterface) => void,
) => {
  try {
    const response = await ConnectApiGet(`${urls.MISSIONS_URL}/${missionId}/challenges`);
    const challenges: ListChallengeInterface[] = response.data;
    return challenges;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }

  return [];
};

export const get = async (id: string, dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    const response = await ConnectApiGet(`${urls.CHALLENGES_URL}/${id}`);
    const challenge: ListChallengeInterface = response.data;
    return challenge;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }

  return null;
};

export const create = async (
  attrs: ChallengeInterface,
  dispatch: (arg: SnackBarActionInterface) => void,
) => {
  try {
    await ConnectApiPost(urls.CHALLENGES_URL, { challenge: attrs });
    dispatch(setSnackbar('Desafio inserido com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const update = async (
  attrs: ChallengeInterface | { upPosition: boolean },
  id: string,
  dispatch: (arg: SnackBarActionInterface) => void,
) => {
  try {
    await ConnectApiPUT(`${urls.CHALLENGES_URL}/${id}`, { challenge: attrs });
    dispatch(setSnackbar('Desafio modificado com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const destroy = async (id: string, dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    await ConnectApiDelete(`${urls.CHALLENGES_URL}/${id}`);
    dispatch(setSnackbar('Desafio excluído com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const duplicate = async (id: string, dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    await ConnectApiPost(`${urls.CHALLENGES_URL}/${id}/duplicate`, {});
    dispatch(setSnackbar('Desafio duplicado com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};
