import { useDispatch, useSelector } from 'react-redux';
import { TableInfo } from '../../../shared/modals/general/table';

import { RootStateGlobal } from '../../reducer';
import * as generalActions from './generalActions';
import { PListAccordion } from '../../../shared/components/input/checkboxAccordion/CheckboxAccordion';

export const useGeneral = () => {
  const dispatch = useDispatch();
  const {
    currentOnBoarding,
    currentIndexOnBoarding,
    tableInfo,
    loadingBlockScreen,
    currentSelectedTab,
    filterInput,
    filterSelect,
    snackbar,
  } = useSelector((state: RootStateGlobal) => state.generalReducer);

  const setCurrentOnBoarding = (x?: number[]) => dispatch(generalActions.setCurrentOnBoarding(x));
  const setCurrentIndexOnBoarding = (x: number) =>
    dispatch(generalActions.setCurrentIndexOnBoarding(x));
  const setTableInfo = (x: TableInfo[]) => dispatch(generalActions.setTableInfo(x));
  const setLoadingBlockScreen = (x: boolean) => dispatch(generalActions.setLoadingBlockScreen(x));
  const setCurrentSelectedTab = (x: number) => dispatch(generalActions.setCurrentSelectedTab(x));
  const setFilterInput = (x: string) => dispatch(generalActions.setFilterInput(x));
  const setFilterSelect = (x: PListAccordion[]) => dispatch(generalActions.setFilterSelect(x));
  const setSnackbar = (message: string, type = 'error', show = true) =>
    dispatch(generalActions.setSnackbar(type, message, show));

  return {
    currentOnBoarding,
    currentIndexOnBoarding,
    tableInfo,
    loadingBlockScreen,
    currentSelectedTab,
    filterInput,
    filterSelect,
    snackbar,
    setCurrentOnBoarding,
    setCurrentIndexOnBoarding,
    setTableInfo,
    setLoadingBlockScreen,
    setCurrentSelectedTab,
    setFilterInput,
    setFilterSelect,
    setSnackbar,
  };
};
