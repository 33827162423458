import { Library } from '../../../shared/modals/library/library';

export enum LibraryTypes {
  SET_LIBRARIES = '@library/SET_LIBRARIES',
  SET_LIBRARY = '@library/SET_LIBRARY',
}

export type LibraryState = {
  readonly libraries: Library[];
  readonly library?: Library;
};
