import React, { ReactNode } from 'react';
import { colors } from '../../../functions/colors';
import IconClose from '../../../images/icon/iconClose';
import Button from '../../buttons/Button';
import ButtonIcon from '../../buttons/buttonIcon/ButtonIcon';
import { Container, Shadow, Menu, BoxButtonsBottom, Header, Title } from './styles';

type PFilterModal = {
  children: ReactNode;
  onClickClear: () => void;
  onClickFilter: () => void;
  loading: boolean;
  title: string;
  openFilter: boolean;
  setOpenFilter: (x: boolean) => void;
};

const FilterModal = ({
  children,
  onClickClear,
  onClickFilter,
  loading,
  title,
  openFilter,
  setOpenFilter,
}: PFilterModal) => {
  if (!openFilter) {
    return null;
  }

  return (
    <Container>
      <Shadow />
      <Menu>
        <Header>
          <Title>{title}</Title>
          <ButtonIcon onClick={() => setOpenFilter(false)}>
            <IconClose width={24} height={24} color={colors.grey100} />
          </ButtonIcon>
        </Header>
        {children}
        <BoxButtonsBottom>
          <Button onClick={onClickClear} typeButton="primary-white" style={{ width: 164 }}>
            LIMPAR
          </Button>
          <Button loading={loading} style={{ width: 164 }} onClick={onClickFilter}>
            FILTRAR
          </Button>
        </BoxButtonsBottom>
      </Menu>
    </Container>
  );
};

export default FilterModal;
