import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../../functions/colors';

type PIconEye = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconEye = ({ width, height, color, style }: PIconEye) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" style={style}>
    <mask id="prefix_IconEye" maskUnits="userSpaceOnUse" x={1} y={4} width={22} height={16}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm-3-5c0-1.66 1.34-3 3-3s3 1.34 3 3-1.34 3-3 3-3-1.34-3-3z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#prefix_IconEye)">
      <path fill={color} d="M0 0h24v24H0z" />
    </g>
  </svg>
);

IconEye.defaultProps = {
  width: 24,
  height: 24,
  color: colors.grey100,
  style: {},
};

export default IconEye;
