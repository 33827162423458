import styled from 'styled-components';
import { colors } from '../../../functions/colors';
import { fonts } from '../../../functions/fonts';

export const Container = styled.div`
  padding: 16px;
  margin-left: 8px;
`;

export const Title = styled.h3`
  margin-left: 8px;
  color: ${colors.purpleRegular};
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
`;

export const SubTitle = styled.p`
  margin: 8px 0px;
  color: ${colors.grey100};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
`;

export const DisplayFlex = styled.div`
  display: flex;
  align-items: center;
`;
