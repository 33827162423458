import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import IconChevronBottom from '../../../images/icon/iconChevronBottom';
import ButtonIcon from '../../buttons/buttonIcon/ButtonIcon';
import Checkbox from '../checkbox/Checkbox';

import { Container, ContainerAccodionExpanded, DisplayFlex } from './styles';

export type PListAccordion = {
  id: string;
  label: string;
  selected: boolean;
  show?: boolean;
};

export type PCheckboxAccorion = {
  idCheckbox: string;
  title: string;
  listAccordion: PListAccordion[];
  setListAccordion?: Dispatch<SetStateAction<PListAccordion[]>>;
  changeSearch?: boolean;
};

const CheckboxAccordion = ({
  idCheckbox,
  title,
  listAccordion,
  setListAccordion,
  changeSearch,
}: PCheckboxAccorion) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (changeSearch !== undefined) {
      setExpanded(true);
    }
  }, [changeSearch]);

  const verifyAllChecked = () => {
    let allChecked = true;
    if (listAccordion.length === 0) {
      allChecked = false;
    }
    listAccordion.forEach(accordion => {
      if (!accordion.selected) {
        allChecked = false;
      }
    });
    return allChecked;
  };

  const handleChangeCheckboxGeral = (event: ChangeEvent<HTMLInputElement>) => {
    if (setListAccordion) {
      setListAccordion(
        listAccordion.map(accordion => ({
          ...accordion,
          selected: event.target.checked,
        })),
      );
    }
  };

  const handleChangeCheckbox = (
    event: ChangeEvent<HTMLInputElement>,
    accordionChange: PListAccordion,
  ) => {
    if (setListAccordion) {
      setListAccordion(
        listAccordion.map(accordion => {
          if (accordion.id === accordionChange.id) {
            return {
              ...accordion,
              selected: event.target.checked,
            };
          }
          return {
            ...accordion,
          };
        }),
      );
    }
  };

  return (
    <Container>
      <DisplayFlex>
        <Checkbox
          text={title}
          idCheckbox={idCheckbox}
          onChange={handleChangeCheckboxGeral}
          checked={verifyAllChecked()}
        />
        {!!listAccordion.length && (
          <ButtonIcon onClick={() => setExpanded(!expanded)}>
            <IconChevronBottom
              style={{
                transform: `${expanded ? 'rotate(180deg)' : 'rotate(0deg)'}`,
                transition: '0.2s',
              }}
            />
          </ButtonIcon>
        )}
      </DisplayFlex>
      {listAccordion.length && expanded && (
        <ContainerAccodionExpanded>
          {listAccordion
            .filter(accordion => accordion.show === undefined || accordion.show)
            .map(accordion => (
              <Checkbox
                idCheckbox={`${idCheckbox}_${accordion.id}`}
                text={accordion.label}
                style={{ marginTop: 16 }}
                checked={accordion.selected}
                onChange={(x: ChangeEvent<HTMLInputElement>) => handleChangeCheckbox(x, accordion)}
              />
            ))}
        </ContainerAccodionExpanded>
      )}
    </Container>
  );
};

CheckboxAccordion.defaultProps = {
  setListAccordion: undefined,
  changeSearch: undefined,
};

export default CheckboxAccordion;
