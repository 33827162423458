import { Report, Ranking } from '../../../shared/modals/report/report';
import { ReportTypes } from './reportTypes';

export const setReport = (report: Report) => ({
  type: ReportTypes.SET_REPORT,
  payload: report,
});

export const setReportPresence = (ranking?: Ranking) => ({
  type: ReportTypes.SET_REPORT_PRESENCE,
  payload: ranking,
});
