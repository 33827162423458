import { isEmpty } from 'lodash';
import { MediaType } from '../modals/trail/bodies';

export const noMedia = (mediaType: MediaType) => isEmpty(mediaType);
export const isImage = (mediaType: MediaType) => mediaType === 'image';
export const isVideo = (mediaType: MediaType) => mediaType === 'video';
export const isSelfHostedVideo = (mediaType: MediaType) => mediaType === 'self-hosted-video';
export const isWebSite = (mediaType: MediaType) => mediaType === 'website';
export const isPdf = (mediaType: MediaType) => mediaType === 'pdf';
export const isDownloadable = (mediaType: MediaType) => mediaType === 'downloadable';
export const isFile = (mediaType: MediaType) =>
  isImage(mediaType) || isPdf(mediaType) || isDownloadable(mediaType);

// A downloadable item is a file other than image and pdf.
export const getMediaTypeFromFile = (file: File) => {
  const mimeType = file.type;

  if (mimeType.split('/')[0] === 'image') return 'image';
  if (mimeType.split('/')[0] === 'video') return 'self-hosted-video';
  if (mimeType === 'application/pdf') return 'pdf';
  return 'downloadable';
};
