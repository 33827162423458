import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { merge, set } from 'lodash';

export const handleChangeFieldValue = <type>(
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setState: Dispatch<SetStateAction<type>>,
) => {
  const { type, name, value, checked } = event.target as HTMLInputElement;
  let overrides: Record<string, string | boolean>;

  switch (type) {
    case 'radio': {
      const isBoolean = ['true', 'false'].includes(value);
      overrides = set({}, name, isBoolean ? value === 'true' : value);
      break;
    }
    case 'checkbox':
      overrides = set({}, name, checked);
      break;
    default:
      overrides = set({}, name, value);
      break;
  }

  // If we use the splat operator here ({ ...previousState, ...overrides }),
  // our `fields` property would always be overridden instead of adding new
  // properties to the object.
  //
  // Example: if we had { ..., fields: { whatsapp: true } } and then selected
  // the email field, it would become { ..., fields: { email: true } } instead
  // of { ..., fields: { whatsapp: true, email: true } }.
  //
  // Lodash.merge fixes this issue by merging objects deeply. However it uses
  // the first object as the base. I.e., it goes adding new properties to the
  // object. It's problematic for React because we should not change the state
  // variable directly. That's why we then use the splat operator.
  setState(previousState => ({ ...merge(previousState, overrides) }));
};

export const getPlayURL = () => {
  const { NODE_ENV, REACT_APP_API_URL } = process.env;
  switch (NODE_ENV) {
    case 'development':
      return 'http://localhost:3001';
    case 'production':
      return REACT_APP_API_URL === 'https://api.staging.tutodigital.com.br'
        ? 'https://staging.play.tutodigital.com.br'
        : 'https://play.tutodigital.com.br';
    default:
      return 'https://play.tutodigital.com.br';
  }
};
