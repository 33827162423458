import React from 'react';

import ArrowRight from '../../shared/images/icon/arrows/arrowRight';
import color from '../../shared/functions/colors';

import {
  FirstLoginContainer,
  TitleContainer,
  Title,
  BoxContent,
  Titleh4,
  ButtonContainer,
} from './styles';

const FirstLogin = () => (
  <FirstLoginContainer>
    <BoxContent>
      <TitleContainer>
        <Title>
          {'Estamos felizes em tê-lo '}
          <br />
          {' conosco!'}
        </Title>
        <Titleh4>Para iniciar, clique na seta ao lado.</Titleh4>
      </TitleContainer>
      <ButtonContainer>
        <ArrowRight height="auto" width="7.5vw" color={color.blue} />
      </ButtonContainer>
    </BoxContent>
  </FirstLoginContainer>
);

export default FirstLogin;
