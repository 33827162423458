import { PListAccordion } from '../../../shared/components/input/checkboxAccordion/CheckboxAccordion';
import { SnackbarInfo } from '../../../shared/modals/general/snackbar';
import { TableInfo } from '../../../shared/modals/general/table';

export enum GeneralTypes {
  SET_CURRENT_INDEX_ON_BOARDING = '@general/SET_CURRENT_INDEX_ON_BOARDING',
  SET_CURRENT_ON_BOARDING = '@general/SET_CURRENT_ON_BOARDING',
  SET_LOADING_BLOCK_SCREEN = '@general/SET_LOADING_BLOCK_SCREEN',
  SET_SNACKBAR = '@general/SET_SNACKBAR',
  SET_TABLE_INFO = '@general/SET_TABLE_INFO',
  SET_CURRENT_SELECTED_TAB = '@general/SET_CURRENT_SELECTED_TAB',
  SET_FILTER_INPUT = '@general/SET_FILTER_INPUT',
  SET_FILTER_SELECT = '@general/SET_FILTER_SELECT',
}

export type GeneralState = {
  readonly currentIndexOnBoarding: number;
  readonly currentOnBoarding?: number[];
  readonly loadingBlockScreen: boolean;
  readonly snackbar: SnackbarInfo;
  readonly tableInfo: TableInfo[];
  readonly currentSelectedTab: number;
  readonly filterInput: string;
  readonly filterSelect: PListAccordion[];
};
