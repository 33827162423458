import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Paragraph } from '../../../shared/components/modal/modalConfirm/styles';
import * as rolloutsService from '../../../shared/services/rollouts';

type PDeleteRollout = {
  open: boolean;
  handleCloseModal: () => void;
  refreshList: () => void;
  id: string | undefined;
};

const DeleteRollout = ({ id, open, handleCloseModal, refreshList }: PDeleteRollout) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  if (!id) return null;

  const handleDelete = async () => {
    setLoading(true);
    await rolloutsService.destroy(id, dispatch);
    refreshList();
    setLoading(false);
    handleCloseModal();
  };

  const warningMsg = () => (
    <>
      <Paragraph style={{ marginBottom: '20px' }}>
        Tem certeza que deseja excluir o rollout?
      </Paragraph>
    </>
  );

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title="Excluir rollout"
      message={warningMsg()}
      onClose={handleCloseModal}
      open={open}
      onClickPrimary={handleDelete}
      onClickSecondary={handleCloseModal}
    />
  );
};

export default DeleteRollout;
