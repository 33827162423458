import React, { CSSProperties } from 'react';
import { colors } from '../../functions/colors';

type PIconBlockCircle = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconBlockCircle = ({ width, height, color, style }: PIconBlockCircle) => (
  <svg width={width} height={height} fill="none" style={style}>
    <mask
      id="prefix_IconBlockCircle"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={2}
      width={width ? width - 4 : 0}
      height={height ? height - 4 : 0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.47 6.47 2 12 2s10 4.47 10 10-4.47 10-10 10S2 17.53 2 12zm13.59 5L17 15.59 13.41 12 17 8.41 15.59 7 12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#prefix_IconBlockCircle)">
      <path fill={color} d="M0 0h24v24H0z" />
    </g>
  </svg>
);

IconBlockCircle.defaultProps = {
  width: 24,
  height: 24,
  color: colors.error,
  style: {},
};

export default IconBlockCircle;
