import React, { useEffect, useState } from 'react';
import { TitleContest } from '../../shared/components/styled/text';
import RadioPurple from '../../shared/components/input/inputRadio/radioPurple/radioPurple';
import { handleChangeFieldValue } from '../../shared/functions/handlers';
import Button from '../../shared/components/buttons/Button';
import {
  BoxInsertUser,
  TextHeader,
  SubTitle,
  TitleWrapper,
  Title,
  Wrapper,
  ContainerRadios,
  ContainerHome,
  ContainerFlex,
} from './styles';
import { useCompany } from '../../store/reducers/company';

export interface SecurityPreferences {
  lockAdminUserOnExcessiveLoginAttempts: boolean;
  lockOrdinaryUserOnExcessiveLoginAttempts: boolean;
  mfaOnAdmin: boolean;
  mfaOnUser: boolean;
}

const Security = () => {
  const [loading, setLoading] = useState(false);
  const [security, setSecurity] = useState<SecurityPreferences>();
  const { getCompanySecurityPreferences, editCompanySecurityPreferences } = useCompany();

  useEffect(() => {
    getCompanySecurityPreferences().then(setSecurity);
  }, []);

  if (!security) return null;

  const handleEditCompanySecurityPreference = async () => {
    setLoading(true);
    await editCompanySecurityPreferences(security);
    setLoading(false);
  };

  return (
    <>
      <TitleContest>SEGURANÇA</TitleContest>
      <BoxInsertUser style={{ paddingBottom: 75 }}>
        <ContainerHome>
          <TitleWrapper>
            <SubTitle>Bloqueio temporário</SubTitle>
            <TextHeader style={{ marginTop: '16px' }}>
              Nesta seção você poderá habilitar/desabilitar o bloqueio temporário de conta quando o
              usuário informar a senha incorretamente 5 vezes (usuários comuns) ou 3 vezes
              (adminstradores). O bloqueio dura 1 minuto.
            </TextHeader>
            <TextHeader style={{ marginTop: '16px' }}>
              Note que o engajamento dos usuários poderá ser impactado negativamente com a
              habilitação dessa funcionalidade.
            </TextHeader>
          </TitleWrapper>
          <Wrapper>
            <Title>Habilitar bloqueio de conta para administradores?</Title>
            <ContainerRadios>
              <div>
                <RadioPurple
                  checked={security.lockAdminUserOnExcessiveLoginAttempts}
                  value="true"
                  labelTitle="sim"
                  id="lock-admin-yes"
                  name="lockAdminUserOnExcessiveLoginAttempts"
                  onChange={event => handleChangeFieldValue(event, setSecurity)}
                />
                <RadioPurple
                  checked={!security.lockAdminUserOnExcessiveLoginAttempts}
                  value="false"
                  labelTitle="não"
                  id="lock-admin-no"
                  name="lockAdminUserOnExcessiveLoginAttempts"
                  onChange={event => handleChangeFieldValue(event, setSecurity)}
                />
              </div>
            </ContainerRadios>
          </Wrapper>
          <Wrapper>
            <Title>Habilitar bloqueio de conta para usuários comuns?</Title>
            <ContainerRadios>
              <div>
                <RadioPurple
                  checked={security.lockOrdinaryUserOnExcessiveLoginAttempts}
                  value="true"
                  labelTitle="sim"
                  id="lock-ordinary-yes"
                  name="lockOrdinaryUserOnExcessiveLoginAttempts"
                  onChange={event => handleChangeFieldValue(event, setSecurity)}
                />
                <RadioPurple
                  checked={!security.lockOrdinaryUserOnExcessiveLoginAttempts}
                  value="false"
                  labelTitle="não"
                  id="lock-ordinary-no"
                  name="lockOrdinaryUserOnExcessiveLoginAttempts"
                  onChange={event => handleChangeFieldValue(event, setSecurity)}
                />
              </div>
            </ContainerRadios>
          </Wrapper>
        </ContainerHome>
        <ContainerHome>
          <TitleWrapper>
            <SubTitle>Autenticação Multifator (MFA)</SubTitle>
            <TextHeader style={{ marginTop: '16px' }}>
              Nesta seção você poderá habilitar/desabilitar a obrigatoriedade da autenticação
              multifator.
            </TextHeader>
            <TextHeader style={{ marginTop: '16px' }}>
              Note que o engajamento dos usuários poderá ser impactado negativamente com a
              habilitação dessa funcionalidade.
            </TextHeader>
          </TitleWrapper>
          <Wrapper>
            <Title>Habilitar MFA para administradores?</Title>
            <ContainerRadios>
              <div>
                <RadioPurple
                  checked={security.mfaOnAdmin}
                  value="true"
                  labelTitle="sim"
                  id="mfa-admin-yes"
                  name="mfaOnAdmin"
                  onChange={event => handleChangeFieldValue(event, setSecurity)}
                />
                <RadioPurple
                  checked={!security.mfaOnAdmin}
                  value="false"
                  labelTitle="não"
                  id="mfa-admin-no"
                  name="mfaOnAdmin"
                  onChange={event => handleChangeFieldValue(event, setSecurity)}
                />
              </div>
            </ContainerRadios>
          </Wrapper>
          <Wrapper>
            <Title>Habilitar MFA para usuários comuns?</Title>
            <ContainerRadios>
              <div>
                <RadioPurple
                  checked={security.mfaOnUser}
                  value="true"
                  labelTitle="sim"
                  id="mfa-ordinary-yes"
                  name="mfaOnUser"
                  onChange={event => handleChangeFieldValue(event, setSecurity)}
                />
                <RadioPurple
                  checked={!security.mfaOnUser}
                  value="false"
                  labelTitle="não"
                  id="mfa-ordinary-no"
                  name="mfaOnUser"
                  onChange={event => handleChangeFieldValue(event, setSecurity)}
                />
              </div>
            </ContainerRadios>
          </Wrapper>
        </ContainerHome>
      </BoxInsertUser>
      <ContainerFlex>
        <Button
          style={{ width: 180, marginLeft: '15px', fontSize: '13px', marginTop: '-70px' }}
          onClick={handleEditCompanySecurityPreference}
          loading={loading}
        >
          SALVAR
        </Button>
      </ContainerFlex>
    </>
  );
};

export default Security;
