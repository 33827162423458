import styled from 'styled-components';
import { TitleSemiBold } from '../../styled/text';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled(TitleSemiBold)`
  width: 100%;
  text-align: center;
`;
