import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import Button from '../../shared/components/buttons/Button';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import {
  ContainerMain,
  HeaderMain,
  TextHeaderMain,
  DisplayFlexAlignCenter,
} from '../../shared/components/styled/general';
import Table from '../../shared/components/table/Table';
import { ExtraUrl, MenuUrl } from '../../shared/enums/menuUrl';
import IconEdit from '../../shared/images/icon/iconEdit';
import IconTrash from '../../shared/images/icon/iconTrash';
import { Library } from '../../shared/modals/library/library';
import { useLibrary } from '../../store/reducers/library';
import DeleteLibrary from './deleteLibrary/DeleteLibrary';
import InsertEditLibrary from './insertEditLibrary/InsertEditLibrary';

type ParamTypes = {
  idLibrary?: string;
};

const LibraryAdmin = () => {
  const { idLibrary } = useParams<ParamTypes>();
  const history = useHistory();
  const { getLibraries, libraries } = useLibrary();
  const [openDeleteLibrary, setOpenDeleteLibrary] = useState(false);
  const [libraryAction, setLibraryAction] = useState<Library | undefined>();

  useEffect(() => {
    getLibraries();
  }, []);

  const handleGoToQuestion = (idLibraryCurrent: string) => {
    history.push(`${MenuUrl.question}/${idLibraryCurrent}`);
  };

  const handleGoToIdLibrary = (idLibraryCurrent: string) => {
    history.push(`${MenuUrl.libraryAdmin}/${idLibraryCurrent}`);
  };

  const handleOpenDeleteLibrary = (library: Library) => {
    setLibraryAction(library);
    setOpenDeleteLibrary(true);
  };

  const renderRowsTable = () =>
    libraries.map(library => ({
      columns: [
        library.name,
        <>
          <DisplayFlexAlignCenter>
            {library.amountQuestions}
            <ButtonIcon
              onClick={() => handleGoToQuestion(library.idLibrary)}
              style={{ marginRight: 16 }}
            >
              <IconEdit />
            </ButtonIcon>
          </DisplayFlexAlignCenter>
        </>,
        <>
          <ButtonIcon
            onClick={() => handleGoToIdLibrary(library.idLibrary)}
            style={{ marginRight: 16 }}
          >
            <IconEdit />
          </ButtonIcon>
          <ButtonIcon onClick={() => handleOpenDeleteLibrary(library)} style={{ marginRight: 16 }}>
            <IconTrash />
          </ButtonIcon>
        </>,
      ],
    }));

  if (idLibrary) {
    return <InsertEditLibrary idLibrary={idLibrary !== ExtraUrl.new ? idLibrary : undefined} />;
  }

  return (
    <ContainerMain>
      <DeleteLibrary
        openDeleteLibrary={openDeleteLibrary}
        library={libraryAction}
        setOpenDeleteLibrary={setOpenDeleteLibrary}
      />
      <BreadCrumbs
        listMenus={[
          {
            name: 'Biblioteca',
          },
        ]}
      />
      <HeaderMain>
        <TextHeaderMain>
          Confira a lista de bibliotecas cadastradas e/ou inserir uma nova.
        </TextHeaderMain>
        <DisplayFlexAlignCenter>
          <Button
            style={{ width: 210 }}
            onClick={() => history.push(`${MenuUrl.libraryAdmin}/${ExtraUrl.new}`)}
            icon="plus"
          >
            NOVA BIBLIOTECA
          </Button>
        </DisplayFlexAlignCenter>
      </HeaderMain>
      <Table
        nameReferenceReduxTable="table_library_admin"
        listRowsPerPage={[10, 25, 50, 100]}
        titles={[
          {
            name: 'Nome',
            openClick: true,
            type: 'string',
          },
          {
            name: 'Questões',
            openClick: false,
            type: 'ReactNode',
            widthTd: '20%',
          },
          {
            name: 'Ações',
            openClick: false,
            type: 'ReactNode',
            widthTd: '20%',
          },
        ]}
        rows={renderRowsTable()}
      />
    </ContainerMain>
  );
};

export default LibraryAdmin;
