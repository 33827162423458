import React, { ChangeEvent, Dispatch, SetStateAction, useState, useEffect } from 'react';
import Button from '../../../shared/components/buttons/Button';
import Input from '../../../shared/components/input/Input';
import { POptions } from '../../../shared/components/select/Select';
import { BodyCreateTrail, BodyPresentation } from '../../../shared/modals/trail/bodies';
import { useTrail } from '../../../store/reducers/trail';
import { Container, BoxInfo, BoxPadding, BoxFlex, BoxBottom, BoxFlexVertical } from './styles';
import { Area } from '../../../shared/modals/area/area';
import BreadCrumbs from '../../../shared/components/breadCrumbs/BreadCrumbs';
import { TitleContest } from '../../../shared/components/styled/text';
import MultipleSelect from '../../../shared/components/select/multipleSelect/MultipleSelect';
import InputFileImage from '../../../shared/components/input/inputFileImage/InputFile';
import Presentation, {
  BODY_START_PRESENTATION,
} from '../../../shared/components/presentation/Presentation';
import InputDate from '../../../shared/components/input/inputDateTime/InputDateTime';
import { dateTimeStampToDateMaterialUi } from '../../../shared/functions/date';
import TextArea from '../../../shared/components/input/textArea/TextArea';
import { useGeneral } from '../../../store/reducers/general';
import { useArea } from '../../../store/reducers/area';
import { useUser } from '../../../store/reducers/user';

const BODY_START = {
  name: '',
  description: '',
  image: '',
  areas: [],
  startDate: '',
  endDate: '',
  prize: 0,
  duplicated: false,
};

type PInsertTrail = {
  setOpenInsertTrail: Dispatch<SetStateAction<boolean>>;
  idTrailEdit?: string;
};

const InsertTrail = ({ setOpenInsertTrail, idTrailEdit }: PInsertTrail) => {
  const [loading, setLoading] = useState(false);
  const { areas, getAllAreas } = useArea();
  const { trail, insertTrail, editTrail, getTrail } = useTrail();
  const { setLoadingBlockScreen } = useGeneral();
  const { userData } = useUser();
  const [bodyInsertTrail, setBodyInsertTrail] = useState<BodyCreateTrail>({
    ...BODY_START,
    presentationsAttributes: [{ ...BODY_START_PRESENTATION }],
  });
  const [itensAreaSelected, setItensAreaSelected] = useState<POptions[]>([]);

  useEffect(() => {
    const verifyEditTrail = async () => {
      if (idTrailEdit) {
        setLoadingBlockScreen(true);
        await getTrail(idTrailEdit);
        setLoadingBlockScreen(false);
      }
    };
    verifyEditTrail();
    getAllAreas();
  }, []);

  useEffect(() => {
    if (trail && idTrailEdit) {
      setBodyInsertTrail({
        name: trail.name,
        description: trail.description,
        image: trail.image || '',
        areas: trail.areas ? trail.areas.map(area => area.idArea) : [],
        startDate: dateTimeStampToDateMaterialUi(trail.startDate),
        endDate: dateTimeStampToDateMaterialUi(trail.endDate),
        prize: trail.prize,
        duplicated: trail.duplicated,
        presentationsAttributes: trail.presentations?.length
          ? trail.presentations
          : [{ ...BODY_START_PRESENTATION }],
      });

      const newListAreas = trail.areas
        ? trail.areas.map(area => {
            const areaFilter = areas.filter(newArea => newArea.idArea === area.idArea);
            let nameArea = '';
            if (areaFilter.length > 0) {
              nameArea = areaFilter[0].name;
            }
            return {
              value: `${area.idArea}`,
              viewValue: nameArea,
            };
          })
        : [];
      setItensAreaSelected(newListAreas);
    }
  }, [trail]);

  useEffect(() => {
    setBodyInsertTrail({
      ...bodyInsertTrail,
      areas: itensAreaSelected.map(option => option.value),
    });
  }, [itensAreaSelected]);

  const convertAreasToPOptions = () => {
    const arrayReturn: POptions[] = [];

    areas.forEach((area: Area) => {
      arrayReturn.push({
        value: `${area.idArea}`,
        viewValue: area.name,
      });
    });

    return arrayReturn;
  };

  const handleCloseInsertTrail = () => {
    setBodyInsertTrail({
      ...BODY_START,
      presentationsAttributes: [{ ...BODY_START_PRESENTATION }],
    });
    setOpenInsertTrail(false);
  };

  const handleInsertTrail = async () => {
    setLoading(true);
    if (idTrailEdit) {
      await editTrail({ ...bodyInsertTrail, image: bodyInsertTrail.image }, idTrailEdit);
    } else {
      await insertTrail({ ...bodyInsertTrail, image: bodyInsertTrail.image });
    }
    handleCloseInsertTrail();
    setLoading(false);
  };

  const handleChangePresentations = (presentationsAttributes: BodyPresentation[]) => {
    setBodyInsertTrail({
      ...bodyInsertTrail,
      presentationsAttributes,
    });
  };

  const handleChangeBody = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string,
  ) => {
    setBodyInsertTrail({
      ...bodyInsertTrail,
      [name]: event.target.value,
    });
  };

  const verifyEnableSubmit = () => {
    const { name, startDate, endDate, areas } = bodyInsertTrail;

    return name && areas.length && startDate && endDate;
  };

  if (!userData) return null;

  return (
    <Container>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Trilhas',
            action: handleCloseInsertTrail,
          },
          {
            name: 'Nova trilha',
          },
        ]}
      />
      <BoxInfo>
        <TitleContest>CONFIGURAÇÕES</TitleContest>
        <BoxPadding>
          <BoxFlex style={{ marginBottom: 16 }}>
            <BoxFlexVertical style={{ width: 600, marginRight: 32 }}>
              <Input
                title="Nome da trilha*"
                placeholder="Digite"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'name')}
                value={bodyInsertTrail.name}
                style={{ width: '100%' }}
              />
              <BoxFlex>
                <InputDate
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'startDate')}
                  type="datetime-local"
                  title="Data e hora de início*"
                  value={bodyInsertTrail.startDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: '100%', marginRight: 32 }}
                />
                <InputDate
                  title="Data e hora de fim*"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'endDate')}
                  type="datetime-local"
                  value={bodyInsertTrail.endDate}
                  style={{ width: '100%' }}
                />
              </BoxFlex>
            </BoxFlexVertical>
            <TextArea
              title="Descrição (opcional)"
              placeholder="Digite"
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChangeBody(e, 'description')}
              value={bodyInsertTrail.description}
              style={{ width: '100%' }}
              styleTextarea={{ height: 112 }}
              maxLength={500}
              showLenghtMax
            />
          </BoxFlex>
          <MultipleSelect
            listOptions={convertAreasToPOptions()}
            itensSelected={itensAreaSelected}
            setItensSelected={setItensAreaSelected}
            title="Área(s)"
          />
        </BoxPadding>
        <TitleContest>CONFIGURAÇÕES</TitleContest>
        <BoxPadding>
          <BoxFlex>
            <InputFileImage
              title="Inserir imagem (opcional)"
              subTitle="Você pode inserir imagem em .png .jpeg .gif."
              urlImage={bodyInsertTrail.image}
              setUrlImage={image => setBodyInsertTrail({ ...bodyInsertTrail, image })}
              style={{ width: '40%' }}
              duplicated={bodyInsertTrail.duplicated}
              dimensions={{ width: 178, height: 132 }}
              isCrop
            />
            <Input
              title="Premiação ao concluir a trilha (opcional)"
              placeholder="0"
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'prize')}
              value={bodyInsertTrail.prize}
              style={{ width: 260, marginLeft: 32 }}
            />
          </BoxFlex>
        </BoxPadding>
        <Presentation
          presentations={bodyInsertTrail.presentationsAttributes}
          setPresentations={handleChangePresentations}
        />
        <BoxBottom>
          <Button
            onClick={handleCloseInsertTrail}
            style={{ width: 120 }}
            typeButton="primary-white"
          >
            CANCELAR
          </Button>
          {!userData.company.migrated && (
            <Button
              loading={loading}
              onClick={handleInsertTrail}
              style={{ width: 120, marginLeft: 16 }}
              disabled={!verifyEnableSubmit()}
            >
              CONCLUIR
            </Button>
          )}
        </BoxBottom>
      </BoxInfo>
    </Container>
  );
};

InsertTrail.defaultProps = {
  idTrailEdit: undefined,
};

export default InsertTrail;
