import { Library } from '../../../shared/modals/library/library';
import { LibraryTypes } from './libraryTypes';

export const setLibraries = (libraries: Library[]) => ({
  type: LibraryTypes.SET_LIBRARIES,
  payload: libraries,
});

export const setLibrary = (library?: Library) => ({
  type: LibraryTypes.SET_LIBRARY,
  payload: library,
});
