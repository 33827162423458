import React, { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import CheckboxAccordion, {
  PListAccordion,
} from '../../../shared/components/input/checkboxAccordion/CheckboxAccordion';
import { colors } from '../../../shared/functions/colors';
import Input from '../../../shared/components/input/Input';
import { filterArraySearchInsertShow } from '../../../shared/functions/utils';
import FilterModal from '../../../shared/components/modal/filterModal/FilterModal';
import { BoxAccordions } from '../../../shared/components/styled/box';
import { useArea } from '../../../store/reducers/area';
import { useGeneral } from '../../../store/reducers/general';
import { UserPagination } from '../../../shared/modals/user/user';

type PFilter = {
  openFilter: boolean;
  setOpenFilter: (x: boolean) => void;
  pagination: UserPagination;
  setPagination: (x: UserPagination) => void;
};

export type ClickFilterHandle = {
  onClickFilter: () => void;
};

const Filter = forwardRef(
  (
    { openFilter, setOpenFilter, pagination, setPagination }: PFilter,
    ref: React.Ref<ClickFilterHandle | undefined>,
  ) => {
    const { areas } = useArea();
    const { setFilterSelect } = useGeneral();
    const [textSearch, setTextSearch] = useState('');
    const [changeSearch, setChangeSearch] = useState<undefined | boolean>();
    const [loading, setLoading] = useState(false);
    const [clear, setClear] = useState(false);
    const [listAccordionArea, setListAccordionArea] = useState<PListAccordion[]>([]);

    const convertAreasToAccordion = (): PListAccordion[] => {
      if (!areas || areas.length <= 0) {
        return [];
      }
      return filterArraySearchInsertShow(
        areas.map(area => ({
          id: area.idArea,
          label: area.name,
          selected: false,
        })),
        'label',
        textSearch,
      );
    };

    useEffect(() => {
      setListAccordionArea(convertAreasToAccordion());
    }, [areas, clear]);

    const handleOnClickFilter = () => {
      setLoading(true);
      setPagination({
        ...pagination,
        page: 1,
        constraints: {
          ...pagination.constraints,
          idAreas: listAccordionArea
            .filter(accordion => accordion.selected)
            .map(accordion => accordion.id),
        },
      });

      setFilterSelect(listAccordionArea.filter(accordion => accordion.selected));

      setLoading(false);
      setOpenFilter(false);
    };

    const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
      const valueSearch = event.target.value;
      setTextSearch(valueSearch);
      setListAccordionArea([
        ...filterArraySearchInsertShow(listAccordionArea, 'label', valueSearch),
      ]);
      setChangeSearch(!changeSearch);
    };

    useImperativeHandle(ref, () => ({
      async onClickFilter() {
        await handleOnClickFilter();
      },
    }));

    return (
      <FilterModal
        title="Filtrar por"
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        loading={loading}
        onClickClear={() => setClear(!clear)}
        onClickFilter={handleOnClickFilter}
      >
        <Input
          style={{ padding: 24 }}
          backgroundColor={colors.grey40}
          showBorder={false}
          icon="search"
          placeholder="Buscar"
          value={textSearch}
          onChange={handleChangeSearch}
        />
        <BoxAccordions>
          <CheckboxAccordion
            title="Por área"
            idCheckbox="por_area"
            listAccordion={listAccordionArea}
            setListAccordion={setListAccordionArea}
            changeSearch={changeSearch}
          />
        </BoxAccordions>
      </FilterModal>
    );
  },
);

export default Filter;
