import React, { useState, Dispatch, SetStateAction } from 'react';

import { useTrail } from '../../../store/reducers/trail';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Trail } from '../../../shared/modals/trail/trail';
import { Paragraph } from '../../../shared/components/modal/modalConfirm/styles';

type PDeleteTrail = {
  openDeleteTrail: boolean;
  setOpenDeleteTrail: Dispatch<SetStateAction<boolean>>;
  trail: Trail | undefined;
};

const DeleteTrail = ({ openDeleteTrail, setOpenDeleteTrail, trail }: PDeleteTrail) => {
  const [loading, setLoading] = useState(false);
  const { deleteTrail } = useTrail();

  if (!trail) return null;

  const handleDeleteTrail = async () => {
    setLoading(true);
    await deleteTrail(trail.idTrail);
    setLoading(false);
    setOpenDeleteTrail(false);
  };

  const warningMsg = () => (
    <>
      <Paragraph style={{ marginBottom: '20px' }}>
        CUIDADO! Esta ação impacta as estatísticas dos relatórios, pois remove PERMANENTEMENTE:
      </Paragraph>
      <Paragraph>- A trilha;</Paragraph>
      <Paragraph>- As fases (associadas à trilha);</Paragraph>
      <Paragraph>- As partidas (associadas à trilha);</Paragraph>
      <Paragraph>- As respostas (associadas às partidas da trilha);</Paragraph>
    </>
  );

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title="Excluir trilha"
      message={warningMsg()}
      onClose={() => setOpenDeleteTrail(false)}
      open={openDeleteTrail}
      onClickPrimary={handleDeleteTrail}
      onClickSecondary={() => setOpenDeleteTrail(false)}
    />
  );
};

export default DeleteTrail;
