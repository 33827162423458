import { Reducer } from 'redux';

import { TrailTypes, TrailState } from './trailTypes';

const INITIAL_STATE: TrailState = {
  trails: [],
  trail: undefined,
  trailReport: undefined,
};

const reducer: Reducer<TrailState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TrailTypes.SET_TRAILS:
      return {
        ...state,
        trails: action.payload,
      };
    case TrailTypes.SET_TRAIL:
      return {
        ...state,
        trail: action.payload,
      };
    case TrailTypes.SET_TRAIL_REPORT:
      return {
        ...state,
        trailReport: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
