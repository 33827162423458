import React, { Fragment } from 'react';
import IconChevronRight from '../../images/icon/iconChevronRight';
import { Container, Title } from './styles';

type ListMenus = {
  name: string;
  action?: () => void;
};

type PBreadCrumbs = {
  listMenus: ListMenus[];
};

const BreadCrumbs = ({ listMenus }: PBreadCrumbs) => {
  const renderFirstMenu = () => (
    <Title isAction={listMenus[0].action !== undefined} onClick={listMenus[0].action}>
      {listMenus[0].name}
    </Title>
  );

  return (
    <Container>
      {renderFirstMenu()}
      {listMenus.map((menu, index) => {
        if (index === 0) return null;
        return (
          <Fragment key={menu.name}>
            <IconChevronRight style={{ margin: '0px 16px' }} />
            <Title isAction={menu.action !== undefined} onClick={menu.action}>
              {menu.name}
            </Title>
          </Fragment>
        );
      })}
    </Container>
  );
};

export default BreadCrumbs;
