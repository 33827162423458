import * as React from 'react';

const BlockQuoteIcon = ({ active, disabled }: { active: boolean; disabled: boolean }) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <mask id="blockQuoteIconMask" maskUnits="userSpaceOnUse" x={0} y={0} width="100%" height="100%">
      <rect x="0" y="0" width="100%" height="100%" fill={disabled ? '#777' : '#fff'} />
    </mask>
    <g>
      <path
        fill={active ? 'blue' : '#5f6368'}
        d="M10 5v5h2.75L11 13h2.25L15 10V5h-5zm-7 5h2.75L4 13h2.25L8 10V5H3v5z"
        mask="url(#blockQuoteIconMask)"
      />
      <path fill="none" d="M0 0h18v18H0z" mask="url(#blockQuoteIconMask)" />
    </g>
  </svg>
);

export default BlockQuoteIcon;
