import React, { ChangeEvent, useEffect, useState } from 'react';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';

import Button from '../../shared/components/buttons/Button';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import SearchInput from '../../shared/components/input/searchInput/SearchInput';
import Table from '../../shared/components/table/Table';
import { filterArraySearch } from '../../shared/functions/utils';
import IconEdit from '../../shared/images/icon/iconEdit';
import IconTrash from '../../shared/images/icon/iconTrash';
import { Category as ICategory } from '../../shared/modals/question/category';
import { useQuestion } from '../../store/reducers/question';
import DeleteCategory from './deleteCategory/DeleteCategory';
import InsertCategory from './insertCategory/InsertCategory';
import { Container, Header, TextHeader, BoxHeaderButtons } from './styles';

const Category = () => {
  const { categories, getCategories } = useQuestion();
  const [searchCategory, setSearchCategory] = useState('');
  const [editCategory, setEditCategory] = useState<ICategory | undefined>(undefined);
  const [categoriesFilter, setCategoriesFilter] = useState<ICategory[]>([]);

  const [categoryAction, setCategoryAction] = useState<ICategory>();
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const [openInsertCategory, setOpenInsertCategory] = useState(false);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    setCategoriesFilter(filterArraySearch(categories, 'name', searchCategory));
  }, [categories]);

  const handleOpenEditCategory = (category: ICategory) => {
    setEditCategory(category);
    setOpenInsertCategory(true);
  };

  const handleOpenInsertCategory = () => {
    setEditCategory(undefined);
    setOpenInsertCategory(true);
  };

  const handleOnClickDeleteCategory = (category: ICategory) => {
    setCategoryAction(category);
    setOpenDeleteCategory(true);
  };

  const handleChangeSearchCategory = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchCategory(event.target.value);
    setCategoriesFilter(filterArraySearch(categories, 'name', event.target.value));
  };

  const renderRowsTable = () =>
    categoriesFilter.map(category => ({
      columns: [
        category.name,
        category.amount,
        <>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleOpenEditCategory(category)}>
            <IconEdit />
          </ButtonIcon>
          <ButtonIcon onClick={() => handleOnClickDeleteCategory(category)}>
            <IconTrash />
          </ButtonIcon>
        </>,
      ],
    }));

  return (
    <Container>
      <InsertCategory
        openInsertCategory={openInsertCategory}
        setOpenInsertCategory={setOpenInsertCategory}
        editCategory={editCategory}
      />
      <DeleteCategory
        category={categoryAction}
        openDeleteCategory={openDeleteCategory}
        setOpenDeleteCategory={setOpenDeleteCategory}
      />
      <BreadCrumbs
        listMenus={[
          {
            name: 'Categorias',
          },
        ]}
      />
      <Header>
        <TextHeader>Confira a lista de categorias cadastradas e/ou inserir uma nova.</TextHeader>
        <BoxHeaderButtons>
          <SearchInput
            placeholder="Buscar categoria..."
            search={searchCategory}
            onChange={handleChangeSearchCategory}
          />
          <Button style={{ width: 180 }} onClick={handleOpenInsertCategory} icon="plus">
            NOVA CATEGORIA
          </Button>
        </BoxHeaderButtons>
      </Header>
      <Table
        nameReferenceReduxTable="table_category"
        listRowsPerPage={[10, 25, 50, 100]}
        titles={[
          {
            name: 'Categorias',
            openClick: true,
            type: 'string',
            widthTd: '35%',
          },
          {
            name: 'Nº perguntas relacionadas',
            openClick: true,
            type: 'number',
            widthTd: '15%',
          },
          {
            name: 'Ações',
            openClick: false,
            type: 'ReactNode',
            widthTd: '10%',
          },
        ]}
        rows={renderRowsTable()}
      />
    </Container>
  );
};

export default Category;
