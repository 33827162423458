import { Permission, TypeUser } from '../../../shared/modals/typeUser/typeUser';

export enum TypeUserTypes {
  SET_TYPES_USER = '@typeUser/SET_TYPES_USER',
  SET_PERMISSIONS = '@typeUser/SET_PERMISSIONS',
  SET_SHOW_PERMISSIONS = '@typeUser/SET_SHOW_PERMISSIONS',
}

export type TypeUserState = {
  readonly typesUser: TypeUser[];
  readonly permissions: Permission[];
  readonly showPermissions: Permission[];
};
