import styled from 'styled-components';
import { colors } from '../../../functions/colors';

export const Container = styled.div`
  width: 100%;
`;

export const InputFileField = styled.input`
  position: absolute;
  width: 100%;
  z-index: 2;
  opacity: 0;
`;

export const BoxImage = styled.div`
  width: 100%;
  background-image: url(/img/transparent-bg_3.jpg);
  border: 1px solid ${colors.grey80};
  border-radius: 4px;
  padding: 8px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 152px;
  position: relative;
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 136px;
`;

export const TextZoom = styled.p`
  font-size: 14px;
  font-weight: 800;
  color: ${colors.primary};
`;

export const DownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DownloadText = styled.p`
  font-weight: 800;
  margin-top: 10px;
`;
