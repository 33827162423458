import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../../functions/colors';

type PIconReward = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconMaps = ({ width, height, color, style }: PIconReward) => (
  <svg width={width} height={height} fill="none" style={style}>
    <mask
      id="mask0_2931_84851"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x="5"
      y="2"
      width="14"
      height="20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 9C5 5.13 8.13 2 12 2C15.87 2 19 5.13 19 9C19 14.25 12 22 12 22C12 22 5 14.25 5 9ZM9.5 9C9.5 10.38 10.62 11.5 12 11.5C13.38 11.5 14.5 10.38 14.5 9C14.5 7.62 13.38 6.5 12 6.5C10.62 6.5 9.5 7.62 9.5 9Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2931_84851)">
      <rect width="24" height="24" fill={color} />
    </g>
  </svg>
);

IconMaps.defaultProps = {
  width: 24,
  height: 24,
  color: colors.white,
  style: {},
};

export default IconMaps;
