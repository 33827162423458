import styled from 'styled-components';
import { colors } from '../../../shared/functions/colors';

export const Container = styled.div`
  width: 100%;
  padding: 16px 40px;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 24px 0px;
  justify-content: space-between;
`;

export const ContainerFlex = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const BoxNewQuestion = styled.div`
  width: 100%;
  margin-top: 32px;
  padding: 24px;
  background-color: ${colors.white};
`;

export const BoxBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;
