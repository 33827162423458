import styled from 'styled-components';
import { colors } from '../../../functions/colors';
import { fonts } from '../../../functions/fonts';

type PContainer = {
  position: number;
};

type PBoxMessage = {
  type: string;
};

export const Container = styled.div<PContainer>`
  transition: 0.5s;
  margin-left: ${props => props.position}px;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const BoxMessage = styled.div<PBoxMessage>`
  height: 64px;
  background-color: ${props => (props.type === 'success' ? colors.greenLight : colors.redLight)};
  border: 2px solid ${props => (props.type === 'success' ? colors.green : colors.red)};
  color: ${props => (props.type === 'success' ? colors.green : colors.red)};
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  width: 70%;
  padding: 0px 16px;
`;

export const TitleBox = styled.div`
  display: flex;
  width: 100%;
  margin-top: -15px;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
`;

export const Title = styled.p`
  font-family: ${fonts.semiBold};
  font-size: 14px;
  color: #f95d4a;
`;

export const Subtitle = styled.p`
  font-family: ${fonts.regular};
  font-size: 14px;
  color: #f95d4a;
  width: 95%;
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const CloseBox = styled.div`
  display: flex;
  width: 100%;
  margin-top: 14px;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #f95d4a;
  font-size: 14px;
  font-family: ${fonts.regular};
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 5px 5px;
  font-family: ${fonts.regular};
  width: 95%;
  color: #f95d4a;
  font-size: 14px;
  margin-bottom: 10px;
`;

export const TableSlot = styled.td`
  padding: 5px 15px;
  background-color: rgba(249, 93, 74, 0.15);
`;
