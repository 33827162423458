import { ConnectApiGet, urls } from '../functions/connection';
import { generateQueryString } from '../functions/connection/queryString';

export const getReportAreas = async (idAreas: string[], startDate: string, endDate: string) => {
  const url = generateQueryString(urls.URL_REPORT_AREA, { idAreas, startDate, endDate });
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const getReportCategory = async (
  idCategories: string[],
  startDate: string,
  endDate: string,
) => {
  const url = generateQueryString(urls.URL_REPORT_CATEGORY, { idCategories, startDate, endDate });
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const getReportStage = async (idStages: string[], startDate: string, endDate: string) => {
  const url = generateQueryString(urls.URL_REPORT_STAGE, { idStages, startDate, endDate });
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const getReportUser = async (idUsers: string[], startDate: string, endDate: string) => {
  const url = generateQueryString(urls.URL_REPORT_USER, { idUsers, startDate, endDate });
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const getReportQuestion = async (
  idQuestions: string[],
  startDate: string,
  endDate: string,
) => {
  const url = generateQueryString(urls.URL_REPORT_QUESTION, { idQuestions, startDate, endDate });
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const getReportTrail = async (idTrails: string[], startDate: string, endDate: string) => {
  const url = generateQueryString(urls.URL_REPORT_TRAIL, { idTrails, startDate, endDate });
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const getReportContest = async (
  idContests: string[],
  startDate: string,
  endDate: string,
) => {
  const url = generateQueryString(urls.URL_REPORT_CONTEST, { idContests, startDate, endDate });
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const getReportPresence = async (idContests: string[]) => {
  const url = generateQueryString(urls.URL_REPORT_CONTEST_PRESENCE, { idContests });
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};
