import styled from 'styled-components';
import { colors } from '../../functions/colors';
import { fonts } from '../../functions/fonts';

type PTextDays = {
  backgroundColor?: string;
  borderColor?: string;
  isFunction?: boolean;
};

export const Container = styled.div`
  padding: 16px;
  background-color: ${colors.white};
  border: 1px solid ${colors.grey40};
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(38, 40, 36, 0.24);
  border-radius: 4px;
  width: 310px;
`;

export const ContainerFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`;

export const Title = styled.h2`
  color: ${colors.black};
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const HeaderDays = styled.p`
  color: ${colors.grey80};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  width: 14%;
  text-align: center;
`;

export const TextDays = styled(HeaderDays)<PTextDays>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.black};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'transparent')};
  ${props => (props.borderColor ? `border: 2px solid ${props.borderColor};` : '')}
  border-radius: 50%;
  width: 26px;
  height: 26px;
  ${props => (props.isFunction ? 'cursor: pointer;' : '')}
`;
