import React, { useEffect, useState, ChangeEvent } from 'react';
import { CSVLink } from 'react-csv';
import BoxHorizontalButtons from '../../shared/components/box/boxHorizontalButtons/BoxHorizontalButtons';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import { ContainerMain, HeaderMain, TextHeaderMain } from '../../shared/components/styled/general';
import { useRewards } from '../../store/reducers/reward';
import Table from '../../shared/components/table/Table';
import { RewardManager } from '../../shared/modals/reward/reward';
import { dateTimeStampToString, dateToStringWithMinusSeparator } from '../../shared/functions/date';
import { RewardManagerTypes } from '../../shared/enums/rewardRequestTypes';
import IconStatus from '../../shared/images/icon/iconStatus';
import { colors } from '../../shared/functions/colors';
import { ContainerStatus, FlexContainer } from './styles';
import { filterArraySearchObjectWithUser } from '../../shared/functions/utils';
import ChangeStatusRewardManager from './changeStatusRewardManager/ChangeStatusRewardManager';
import Tooltip from '../../shared/components/tooltip/Tooltip';
import Button from '../../shared/components/buttons/Button';
import SearchInput from '../../shared/components/input/searchInput/SearchInput';

interface IDataCSV {
  createdAt: Date | string;
  user: string;
  award: string;
  status: string;
  area: string;
}

const RewardManage = () => {
  const [searchRewardManager, setSearchRewardManager] = useState('');
  const [rewardsRequestFilter, setRewardsRequestFilter] = useState<RewardManager[]>([]);
  const { rewardsRequests, getRewardsManagers } = useRewards();
  const [dataCSV, setDataCSV] = useState<IDataCSV[]>([]);

  const headersCSV = [
    { label: 'Data', key: 'createdAt' },
    { label: 'Produto', key: 'award' },
    { label: 'Status', key: 'status' },
    { label: 'Área', key: 'area' },
    { label: 'Membro', key: 'user' },
  ];

  const statusValue = (value: number) => {
    switch (value) {
      case 1:
        return 'Em análise';
      case 2:
        return 'Rejeitado';
      default:
        return 'Aprovado';
    }
  };

  const handleCSVData = (data: RewardManager[]) => {
    setDataCSV(
      data.map(e => ({
        createdAt: dateToStringWithMinusSeparator(e.createdAt),
        user: `${e.user.name} (${e.user.email})`,
        status: statusValue(e.status),
        award: e.award.name,
        area: e.user.areas.map(area => area.name).join(', '),
      })),
    );
  };

  useEffect(() => {
    getRewardsManagers();
  }, []);

  useEffect(() => {
    if (rewardsRequests?.length) {
      setRewardsRequestFilter(
        filterArraySearchObjectWithUser(rewardsRequests, 'award', 'name', searchRewardManager),
      );
    }
  }, [rewardsRequests]);

  useEffect(() => {
    handleCSVData(rewardsRequestFilter);
  }, [rewardsRequestFilter]);

  const handleChangeSearchReward = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchRewardManager(event.target.value);
    if (rewardsRequests?.length) {
      setRewardsRequestFilter(
        filterArraySearchObjectWithUser(rewardsRequests, 'award', 'name', event.target.value),
      );
    }
  };

  const renderIconStatus = (rewardRequest: RewardManager) => {
    switch (rewardRequest.status) {
      case RewardManagerTypes.approved:
        return (
          <ContainerStatus>
            <Tooltip title="Aprovada">
              <IconStatus color={colors.success} />
            </Tooltip>
          </ContainerStatus>
        );
      case RewardManagerTypes.cancelled:
        return (
          <ContainerStatus>
            <Tooltip title="Cancelada">
              <IconStatus color={colors.error} />
            </Tooltip>
          </ContainerStatus>
        );
      default:
        return (
          <ContainerStatus>
            <Tooltip title="Pendente">
              <IconStatus />
            </Tooltip>
          </ContainerStatus>
        );
    }
  };

  const renderRowsTable = (typeRewardManager?: number[]) =>
    rewardsRequestFilter
      .filter(
        rewardRequest => !typeRewardManager || typeRewardManager.indexOf(rewardRequest.status) >= 0,
      )
      .map((rewardRequest: RewardManager) => ({
        columns: [
          renderIconStatus(rewardRequest),
          rewardRequest.user.name,
          dateTimeStampToString(rewardRequest.createdAt),
          rewardRequest.award.name,
          <ChangeStatusRewardManager rewardRequest={rewardRequest} />,
        ],
      }));

  const renderTable = (typeRewardManager?: number[]) => {
    return (
      <Table
        nameReferenceReduxTable="table_reward_request"
        listRowsPerPage={[10, 25, 50, 100]}
        titles={[
          {
            name: 'Status',
            openClick: false,
            type: 'ReactNode',
            widthTd: '40px',
          },
          {
            name: 'Membro',
            openClick: true,
            type: 'string',
          },
          {
            name: 'Data',
            openClick: true,
            type: 'date',
          },
          {
            name: 'Produto',
            openClick: true,
            type: 'string',
          },
          {
            name: 'Aprovar ou cancelar',
            openClick: false,
            type: 'ReactNode',
            widthTd: '200px',
          },
        ]}
        rows={renderRowsTable(typeRewardManager)}
      />
    );
  };

  return (
    <ContainerMain>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Gerenciador',
          },
        ]}
      />
      <HeaderMain>
        <TextHeaderMain>
          Abaixo você consegue gerenciar os membros e suas compras (produtos adquiridos).
        </TextHeaderMain>
        <FlexContainer>
          <SearchInput
            placeholder="Buscar produto..."
            search={searchRewardManager}
            onChange={handleChangeSearchReward}
          />
          <CSVLink
            filename="Relatório Solicitações de Prêmios.csv"
            data={dataCSV}
            headers={headersCSV}
          >
            <Button style={{ width: 160, marginLeft: '1em' }}>BAIXAR RELATÓRIO</Button>
          </CSVLink>
        </FlexContainer>
      </HeaderMain>
      <BoxHorizontalButtons
        items={[
          {
            textButton: 'Todas as solicitações',
            children: renderTable(),
          },
          {
            textButton: 'Pendentes',
            children: renderTable([RewardManagerTypes.pending]),
          },
          {
            textButton: 'Finalizadas',
            children: renderTable([RewardManagerTypes.approved, RewardManagerTypes.cancelled]),
          },
        ]}
      />
    </ContainerMain>
  );
};

export default RewardManage;
