import React, { useState, Dispatch, SetStateAction } from 'react';

import { useUser } from '../../../store/reducers/user';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { User } from '../../../shared/modals/user/user';

type PBlockUser = {
  openBlockUser: boolean;
  setOpenBlockUser: Dispatch<SetStateAction<boolean>>;
  user: User | undefined;
};

const BlockUser = ({ openBlockUser, setOpenBlockUser, user }: PBlockUser) => {
  const [loadingBlock, setLoadingBlock] = useState(false);
  const { blockUser, unlockUser, userPagination } = useUser();

  if (!user) return null;

  const handleBlockUser = async () => {
    setLoadingBlock(true);
    if (user.block) {
      await unlockUser(user.idUser, userPagination);
    } else {
      await blockUser(user.idUser, userPagination);
    }
    setLoadingBlock(false);
    setOpenBlockUser(false);
  };

  return (
    <ModalConfirm
      loadingPrimary={loadingBlock}
      message={
        user.block
          ? 'Tem certeza que deseja desbloquear o membro?'
          : 'Tem certeza que deseja bloquear o membro?'
      }
      title={user.block ? 'Desbloquear membro' : 'Bloquear membro'}
      onClose={() => setOpenBlockUser(false)}
      open={openBlockUser}
      onClickPrimary={handleBlockUser}
      onClickSecondary={() => setOpenBlockUser(false)}
    />
  );
};

export default BlockUser;
