import React, { ReactNode, useRef, useState } from 'react';
import { TextSmall } from '../styled/text';
import { Container, BoxAbsolute, BoxWhite } from './styles';

type PTooltip = {
  children: ReactNode;
  title: string;
};

const Tooltip = ({ children, title }: PTooltip) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handerHover = () => {
    setShowTooltip(true);
  };

  const handleMouseOut = () => {
    setShowTooltip(false);
  };

  return (
    <Container onMouseOver={handerHover} onMouseOut={handleMouseOut} ref={containerRef}>
      <BoxAbsolute
        isShow={showTooltip}
        left={containerRef.current ? containerRef.current.offsetWidth / 2 : 0}
      >
        <BoxWhite>
          <TextSmall>{title}</TextSmall>
        </BoxWhite>
      </BoxAbsolute>
      {children}
    </Container>
  );
};

export default Tooltip;
