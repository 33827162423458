import { Area } from '../../../shared/modals/area/area';
import { ImportUserError } from '../../../shared/modals/user/user';

export enum AreaTypes {
  SET_ALL_AREAS = '@area/SET_ALL_AREAS',
  SET_IMPORT_ERRORS = '@area/SET_IMPORT_ERRORS',
}

export type AreaState = {
  readonly areas: Area[];
  readonly errors: ImportUserError[];
};
