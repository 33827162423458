/* eslint-disable no-underscore-dangle */
import React, { useRef } from 'react';
import { VictoryChart, VictoryAxis, VictoryContainer, VictoryLine } from 'victory';
import { colors } from '../../../functions/colors';
import { insertPercentagemInNumber } from '../../../functions/number';
import { Container, Title } from './styles';

export type DataGraphicBars = {
  id: number;
  value: number;
  tickText: string;
};

type PGraphicBars = {
  data: DataGraphicBars[];
  graphicColor?: string;
  title?: string;
};

const GraphicLine = ({ data, graphicColor, title }: PGraphicBars) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const currentData = data.filter((x, index) => index < 10);

  return (
    <Container ref={containerRef}>
      {title && <Title color={graphicColor || colors.pinkDark}>{title}</Title>}
      <VictoryChart
        domainPadding={20}
        domain={{ y: [0, 1] }}
        animate={{ duration: 500 }}
        height={240}
        containerComponent={<VictoryContainer />}
      >
        <VictoryAxis
          style={{
            tickLabels: { fill: colors.grey80, angle: -15, fontSize: 8 },
            axis: { stroke: colors.grey80, fontSize: 10 },
          }}
          tickValues={currentData.map((_, index) => index + 1)}
          tickFormat={currentData.map(item => item.tickText)}
        />
        <VictoryAxis
          style={{
            tickLabels: { fill: colors.grey80, fontSize: 8 },
            axis: { stroke: colors.black },
          }}
          dependentAxis
          tickFormat={(x: number) => `${insertPercentagemInNumber(x)}`}
        />
        <VictoryLine
          style={{
            data: {
              stroke: graphicColor,
            },
            labels: {
              fontSize: 10,
              fill: graphicColor,
            },
          }}
          labels={({ datum }) => `${insertPercentagemInNumber(datum._y)}`}
          data={currentData}
          x="id"
          y="value"
        />
      </VictoryChart>
    </Container>
  );
};

GraphicLine.defaultProps = {
  graphicColor: colors.purpleLight,
  title: undefined,
};

export default GraphicLine;
