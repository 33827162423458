import React, { useRef, useState } from 'react';
import moment from 'moment';

import Button from '../../shared/components/buttons/Button';
import InputDate from '../../shared/components/input/inputDate/InputDate';
import RankingReport from '../../shared/components/table/tableDefault/TableDefault';
import { Graphic } from '../../shared/modals/report/report';
import { GraphicTypes } from '../../shared/enums/graphicTypes';
import Filter, { ClickFilterHandle } from './filter/Filter';
import Types from './types/Types';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import { colors } from '../../shared/functions/colors';
import {
  Container,
  Header,
  TextHeader,
  BoxInfos,
  ButtonsFilter,
  BoxGraphic,
  BoxRanking,
  BoxAllGraphics,
} from './styles';
import ButtonDropdown from '../../shared/components/buttons/buttonDropdown/ButtonDropdown';
import GraphicBars from '../../shared/components/graphics/GraphicBars/GraphicBars';
import GraphicBarsHorizontal from '../../shared/components/graphics/GraphicBarsHorizontal/GraphicBarsHorizontal';
import { useReport } from '../../store/reducers/report';

const Report = () => {
  const [labelDataSet, setLabelDataSet] = useState('');
  const [openFilter, setOpenFilter] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [disabledButtonFilter, setDisabledButtonFilter] = useState(true);
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(30, 'days')
      .format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(
    moment()
      .subtract(-1, 'days')
      .format('YYYY-MM-DD'),
  );
  const [checkboxesSelected, setCheckboxesSelected] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const { report } = useReport();
  const childRef = useRef<ClickFilterHandle>();

  const handleOnChangeStart = (e: string) => {
    setStartDate(e);
    setDisabledButtonFilter(false);
  };

  const handleOnChangeEnd = (e: string) => {
    setEndDate(e);
    setDisabledButtonFilter(false);
  };

  const handleCheckboxesSelected = (index: number, active: boolean) => {
    if (index === 8) {
      setCheckboxesSelected([active, active, active, active, active, active, active, active]);
    } else {
      const newCheckboxSelected = [];
      for (let i = 0; i < 8; i += 1) {
        if (i === index) {
          if (!active) newCheckboxSelected[0] = false;

          newCheckboxSelected[i] = active;
        } else {
          newCheckboxSelected[i] = checkboxesSelected[i];
        }
      }
      setCheckboxesSelected(newCheckboxSelected);
    }
  };

  const renderGraphic = (graphic: Graphic) => {
    switch (graphic.typeGraphic) {
      case GraphicTypes.horizontalBar:
        return (
          <BoxGraphic>
            <GraphicBarsHorizontal
              data={graphic.data.map((itemGraphic, index) => ({
                id: index + 1,
                value: itemGraphic.value,
                tickText: itemGraphic.text,
              }))}
              graphicColor={graphic.colorGraphic}
              title={graphic.title}
            />
          </BoxGraphic>
        );
      case GraphicTypes.verticalBar:
      default:
        return (
          <BoxGraphic>
            <GraphicBars
              data={graphic.data.map((itemGraphic, index) => ({
                id: index + 1,
                value: itemGraphic.value,
                tickText: itemGraphic.text,
              }))}
              graphicColor={graphic.colorGraphic}
              title={graphic.title}
              average={graphic.average}
            />
          </BoxGraphic>
        );
    }
  };

  return (
    <Container>
      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        startDate={startDate}
        endDate={endDate}
        ref={childRef}
        setLoadingFilter={setLoadingFilter}
        setLabelDataSet={setLabelDataSet}
      />

      <BreadCrumbs
        listMenus={[
          {
            name: 'Relatórios',
          },
        ]}
      />
      <Header>
        <TextHeader>Aqui você pode visualizar os relatórios dos torneios e trilhas.</TextHeader>
      </Header>
      <BoxInfos>
        <ButtonsFilter>
          <ButtonsFilter>
            <InputDate
              title="Data de início"
              onChangeDate={handleOnChangeStart}
              value={startDate}
              style={{ marginBottom: -8 }}
            />
            <InputDate
              style={{ marginLeft: 24, marginBottom: -8 }}
              title="Data de fim"
              onChangeDate={handleOnChangeEnd}
              value={endDate}
            />
            <Button
              style={{ width: 160, marginLeft: 24 }}
              onClick={() => {
                if (childRef?.current) {
                  childRef.current.onClickFilter();
                }

                return null;
              }}
              loading={loadingFilter}
              disabled={disabledButtonFilter}
            >
              FILTRAR DATA
            </Button>
          </ButtonsFilter>
          <ButtonsFilter>
            <ButtonDropdown
              iconLeft=""
              colorIcon={colors.purpleRegular}
              styleButton={{ width: 180 }}
              childrenDropdown={
                <Types
                  checkboxesSelected={checkboxesSelected}
                  handleCheckboxesSelected={handleCheckboxesSelected}
                  labelDataSet={labelDataSet}
                />
              }
            >
              TIPOS DE RELATÓRIO
            </ButtonDropdown>
            <Button
              iconLeft="filter"
              colorIcon={colors.purpleRegular}
              onClick={() => setOpenFilter(true)}
              style={{ width: 160, marginLeft: 24 }}
              typeButton="primary-white"
            >
              FILTROS
            </Button>
          </ButtonsFilter>
        </ButtonsFilter>
        <BoxAllGraphics>
          {report?.graphics?.map((graphic, index) => {
            if (checkboxesSelected[index + 1]) {
              return renderGraphic(graphic);
            }
            return null;
          })}
        </BoxAllGraphics>
        {report?.ranking && checkboxesSelected[5] && (
          <BoxRanking>
            <RankingReport infoTable={report.ranking} />
          </BoxRanking>
        )}
        {report?.playedGraphic && checkboxesSelected[6] && (
          <BoxRanking>
            <RankingReport infoTable={report.playedGraphic} />
          </BoxRanking>
        )}
        {report?.presenceGraphic && checkboxesSelected[7] && (
          <BoxRanking>
            <RankingReport infoTable={report.presenceGraphic} />
          </BoxRanking>
        )}
      </BoxInfos>
    </Container>
  );
};

export default Report;
