import React, { useState, Dispatch, SetStateAction } from 'react';

import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { TypeUser } from '../../../shared/modals/typeUser/typeUser';
import { useTypeUser } from '../../../store/reducers/typeUser';

type PDeleteTypeUser = {
  openDeleteTypeUser: boolean;
  setOpenDeleteTypeUser: Dispatch<SetStateAction<boolean>>;
  typeUser?: TypeUser;
};

const DeleteTypeUser = ({
  openDeleteTypeUser,
  setOpenDeleteTypeUser,
  typeUser,
}: PDeleteTypeUser) => {
  const [loading, setLoading] = useState(false);
  const { deleteTypeUser } = useTypeUser();

  if (!typeUser) return null;

  const handleDeleteTypeUser = async () => {
    setLoading(true);
    await deleteTypeUser(typeUser.idTypeUser);
    setLoading(false);
    setOpenDeleteTypeUser(false);
  };

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title="Excluir tipo de usuário?"
      message="Todas as permissões relacionadas a esse tipo de usuário serão excluídas permanentemente. Tem certeza que deseja excluir esse tipo de usuário?"
      onClose={() => setOpenDeleteTypeUser(false)}
      open={openDeleteTypeUser}
      onClickPrimary={handleDeleteTypeUser}
      onClickSecondary={() => setOpenDeleteTypeUser(false)}
    />
  );
};

DeleteTypeUser.defaultProps = {
  typeUser: undefined,
};

export default DeleteTypeUser;
