import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Button from '../../shared/components/buttons/Button';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import IconEdit from '../../shared/images/icon/iconEdit';
import IconTrash from '../../shared/images/icon/iconTrash';
import { Stage as IStage } from '../../shared/modals/stage/stage';
import { useStage } from '../../store/reducers/stage';
import InsertStage from './insertStage/InsertStage';
import { colors } from '../../shared/functions/colors';
import DeleteStage from './deleteStage/DeleteStage';
import { Container, Header, TextHeader } from './styles';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import Table from '../../shared/components/table/Table';
import { MenuUrl } from '../../shared/enums/menuUrl';
import IconChevronBottom from '../../shared/images/icon/iconChevronBottom';
import IconDuplicate from '../../shared/images/icon/iconDuplicate';
import DuplicateStage from './duplicateStage/DuplicateStage';
import { useGeneral } from '../../store/reducers/general';

type InsertStageLocationState = {
  idTrail?: string;
};

const Stage = () => {
  const { stages, getStagesIdTrail, getStages, changePositionStage } = useStage();
  const { setLoadingBlockScreen } = useGeneral();
  const history = useHistory();
  const location = useLocation<InsertStageLocationState>();
  const { idTrail } = location?.state || { idTrail: undefined };
  const [openInsertStage, setOpenInsertStage] = useState(false);
  const [openDeleteStage, setOpenDeleteStage] = useState(false);
  const [openDuplicateStage, setOpenDuplicateStage] = useState(false);
  const [stageAction, setStageAction] = useState<IStage>();
  const [idStageEdit, setIdStageEdit] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getStagesStart = async () => {
      setLoadingBlockScreen(true);
      if (idTrail) {
        await getStagesIdTrail(idTrail);
      } else {
        await getStages();
      }
      setLoadingBlockScreen(false);
    };
    getStagesStart();
  }, []);

  const handleOpenInsertStage = (idStage: string | undefined) => {
    setIdStageEdit(idStage);
    setOpenInsertStage(true);
  };

  const handleOnClickDeleteStage = (stage: IStage) => {
    setStageAction(stage);
    setOpenDeleteStage(true);
  };

  const handleOnClickDuplicateStage = (stage: IStage) => {
    setStageAction(stage);
    setOpenDuplicateStage(true);
  };

  const handleOnClickChangePosition = async (stage: IStage, upPosition: boolean) => {
    setLoadingBlockScreen(true);
    await changePositionStage(stage.idTrail, stage.idPhase, upPosition);
    setLoadingBlockScreen(false);
  };

  if (openInsertStage && idTrail) {
    return (
      <InsertStage
        setOpenInsertStage={setOpenInsertStage}
        idStageEdit={idStageEdit}
        idTrail={idTrail}
      />
    );
  }

  const renderRowsTable = () =>
    stages.map((stage: IStage, index: number) => ({
      columns: [
        stage.trail.name,
        stage.name,
        stage.level.name,
        stage.questionAmount,
        stage.position,
        <>
          {idTrail && (
            <>
              <ButtonIcon
                style={{ marginRight: 16 }}
                onClick={() => handleOpenInsertStage(stage.idPhase)}
              >
                <IconEdit />
              </ButtonIcon>
              <ButtonIcon
                style={{ marginRight: 16 }}
                onClick={() => handleOnClickDuplicateStage(stage)}
              >
                <IconDuplicate />
              </ButtonIcon>
              <ButtonIcon onClick={() => handleOnClickDeleteStage(stage)}>
                <IconTrash />
              </ButtonIcon>

              <ButtonIcon onClick={() => handleOnClickChangePosition(stage, false)}>
                <IconChevronBottom
                  style={{ transform: 'rotate(180deg)' }}
                  color={index === 0 ? colors.white : colors.grey100}
                />
              </ButtonIcon>
              <ButtonIcon onClick={() => handleOnClickChangePosition(stage, true)}>
                <IconChevronBottom
                  color={index === stages.length - 1 ? colors.white : colors.grey100}
                />
              </ButtonIcon>
            </>
          )}
        </>,
      ],
    }));

  return (
    <Container>
      <DeleteStage
        stage={stageAction}
        openDeleteStage={openDeleteStage}
        setOpenDeleteStage={setOpenDeleteStage}
        idTrail={idTrail}
      />
      <DuplicateStage
        stage={stageAction}
        openDuplicateStage={openDuplicateStage}
        setOpenDuplicateStage={setOpenDuplicateStage}
        idTrail={idTrail}
      />
      <BreadCrumbs
        listMenus={[
          {
            name: 'Trilhas',
            action: () => history.push(MenuUrl.trail),
          },
          {
            name: 'Fases',
          },
        ]}
      />
      <Header>
        <TextHeader>Você pode criar, excluir ou editar as fases cadastradas.</TextHeader>
        {idTrail && (
          <Button
            style={{ width: 180 }}
            onClick={() => handleOpenInsertStage(undefined)}
            icon="plus"
          >
            NOVA FASE
          </Button>
        )}
      </Header>
      <Table
        nameReferenceReduxTable="table_stage"
        listRowsPerPage={[10, 25, 50, 100]}
        titles={[
          {
            name: 'Trilha',
            openClick: !idTrail,
            type: 'string',
            widthTd: '25%',
          },
          {
            name: 'Nome da fase',
            openClick: !idTrail,
            type: 'string',
            widthTd: '30%',
          },
          {
            name: 'Nível',
            openClick: !idTrail,
            type: 'string',
            widthTd: '10%',
          },
          {
            name: 'Perguntas',
            openClick: !idTrail,
            type: 'string',
            widthTd: '10%',
          },
          {
            name: 'Posição',
            openClick: !idTrail,
            type: 'number',
            widthTd: '10%',
          },
          {
            name: idTrail ? 'Ações' : '',
            openClick: false,
            type: 'ReactNode',
            widthTd: idTrail ? '20%' : '0%',
          },
        ]}
        rows={renderRowsTable()}
      />
    </Container>
  );
};

export default Stage;
