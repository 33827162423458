import { useDispatch, useSelector } from 'react-redux';
import { questionActions } from '.';
import { BodyInsertQuestion, BodyInsertCategory } from '../../../shared/modals/question/bodies';
import { RootStateGlobal } from '../../reducer';
import * as questionOperations from './questionOperations';
import { QuestionPagination } from '../../../shared/modals/question/question';

export const useQuestion = () => {
  const dispatch = useDispatch();
  const {
    categories,
    questions,
    question,
    questionInfoAnswer,
    errors,
    totalCount,
    questionPagination,
  } = useSelector((state: RootStateGlobal) => state.questionReducer);

  const getCategories = async () => questionOperations.getCategories()(dispatch);
  const insertCategory = async (body: BodyInsertCategory) =>
    questionOperations.insertCategory(body)(dispatch);
  const editCategory = async (body: BodyInsertCategory, idCategory: string) =>
    questionOperations.editCategory(body, idCategory)(dispatch);
  const deleteCategory = async (idCategory: string) =>
    questionOperations.deleteCategory(idCategory)(dispatch);
  const getAllDataQuestion = async (idQuestion: string) =>
    questionOperations.getAllDataQuestion(idQuestion)(dispatch);
  const getQuestionsFiltered = async (questionPagination?: QuestionPagination) =>
    questionOperations.getQuestionsFiltered(questionPagination)(dispatch);
  const insertQuestion = async (body: BodyInsertQuestion, questionPagination: QuestionPagination) =>
    questionOperations.insertQuestion(body, questionPagination)(dispatch);
  const importQuestionList = async (
    body: FormData,
    questionPagination: QuestionPagination,
    idLibrary?: string,
  ) => questionOperations.importQuestionList(body, questionPagination, idLibrary)(dispatch);
  const importResearchList = async (
    body: FormData,
    questionPagination: QuestionPagination,
    idLibrary?: string,
  ) => questionOperations.importResearchList(body, questionPagination, idLibrary)(dispatch);
  const editQuestion = async (
    body: BodyInsertQuestion,
    idQuestion: string,
    questionPagination: QuestionPagination,
  ) => questionOperations.editQuestion(body, idQuestion, questionPagination)(dispatch);
  const deleteQuestion = async (idQuestion: string, questionPagination: QuestionPagination) =>
    questionOperations.deleteQuestion(idQuestion, questionPagination)(dispatch);
  const duplicateQuestion = async (idQuestion: string, questionPagination: QuestionPagination) =>
    questionOperations.duplicateQuestion(idQuestion, questionPagination)(dispatch);
  const getInfoAnswerQuestion = async (idQuestion: string, idContest?: string, idStage?: string) =>
    questionOperations.getInfoAnswerQuestion(idQuestion, idContest, idStage)(dispatch);
  const clearErrors = () => {
    dispatch(questionActions.setImportErrors([]));
  };
  const setQuestionPagination = (x: QuestionPagination) =>
    dispatch(questionActions.setQuestionPagination(x));

  return {
    categories,
    questions,
    question,
    questionInfoAnswer,
    errors,
    totalCount,
    questionPagination,
    getCategories,
    insertCategory,
    editCategory,
    deleteCategory,
    getAllDataQuestion,
    getQuestionsFiltered,
    insertQuestion,
    importQuestionList,
    importResearchList,
    editQuestion,
    deleteQuestion,
    duplicateQuestion,
    getInfoAnswerQuestion,
    clearErrors,
    setQuestionPagination,
  };
};
