export enum ReportFiltersType {
  area = 1,
  category = 2,
  stage = 3,
  user = 4,
  question = 5,
  trail = 6,
  contest = 7,
}

export default ReportFiltersType;
