import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { ContainerMain, HeaderMain, TextHeaderMain } from '../../shared/components/styled/general';
import Button from '../../shared/components/buttons/Button';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import Table from '../../shared/components/table/Table';
import * as rolloutService from '../../shared/services/rollouts';
import IconEdit from '../../shared/images/icon/iconEdit';
import DeleteRollout from './delete/DeleteRollout';
import IconTrash from '../../shared/images/icon/iconTrash';
import { useUser } from '../../store/reducers/user';
import { typeUser } from '../../shared/enums/typeUser';

export type RolloutInterface = {
  id: string;
  name: string;
  percentage: number;
  description: string;
  inclusionMode: boolean;
  companyIds: string[];
};

const Rollout = () => {
  const [rollout, setRollout] = useState<RolloutInterface | undefined>(undefined);
  const [rollouts, setRollouts] = useState<RolloutInterface[]>([]);
  const [isDeleteModalEnabled, setIsDeleteModalEnabled] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { userData } = useUser();

  const isSuperAdmin = userData?.typeUser?.id === typeUser.ADMIN;

  const refreshList = useCallback(() => {
    rolloutService.getAll(dispatch).then(rollouts => {
      setRollouts(rollouts);
    });
  }, [dispatch]);

  useEffect(() => {
    refreshList();
  }, []);

  const goToNew = useCallback(() => {
    history.push('/rollouts/new');
  }, [history]);

  const goToEdit = useCallback(
    (rollout: RolloutInterface) => {
      history.push(`/rollouts/${rollout.id}/edit`);
    },
    [history],
  );

  const handleDelete = useCallback((rollout: RolloutInterface) => {
    setRollout(rollout);
    setIsDeleteModalEnabled(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsDeleteModalEnabled(false);
  }, []);

  const rowsTable = useMemo(() => {
    return rollouts.map(rollout => ({
      columns: [
        rollout.name,
        rollout.description,
        rollout.percentage,
        rollout.companyIds.length,
        <>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => goToEdit(rollout)}>
            <IconEdit />
          </ButtonIcon>
          {isSuperAdmin && (
            <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleDelete(rollout)}>
              <IconTrash />
            </ButtonIcon>
          )}
        </>,
      ],
    }));
  }, [rollouts, goToEdit, handleDelete, isSuperAdmin]);

  return (
    <ContainerMain>
      {rollout && (
        <>
          <DeleteRollout
            id={rollout.id}
            open={isDeleteModalEnabled}
            handleCloseModal={handleCloseModal}
            refreshList={refreshList}
          />
        </>
      )}
      <BreadCrumbs
        listMenus={[
          {
            name: 'Rollouts',
          },
        ]}
      />
      <HeaderMain>
        <TextHeaderMain>
          Confira abaixo os rollouts cadastrados e/ou insira um novo rollout.
        </TextHeaderMain>
        {isSuperAdmin && (
          <Button style={{ width: 180 }} onClick={goToNew} icon="plus">
            NOVO ROLLOUT
          </Button>
        )}
      </HeaderMain>
      <Table
        nameReferenceReduxTable="table_rollouts"
        listRowsPerPage={[10, 25, 50, 100]}
        titles={[
          {
            name: 'Nome',
            openClick: true,
            type: 'string',
          },
          {
            name: 'Descrição',
            openClick: true,
            type: 'string',
          },
          {
            name: 'Percentual',
            openClick: true,
            type: 'number',
          },
          {
            name: 'Empresas Ignoradas',
            openClick: true,
            type: 'number',
          },
          {
            name: 'Ações',
            openClick: false,
            type: 'ReactNode',
            widthTd: '20%',
          },
        ]}
        rows={rowsTable}
      />
    </ContainerMain>
  );
};

export default withRouter(connect()(Rollout));
