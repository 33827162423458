import React, { ChangeEvent } from 'react';
import { CSSProperties } from 'styled-components';
import Input from '../input/Input';
import { Container, InputColor, SubTitle, Title, Wrapper } from './style';

type PColorSelect = {
  style?: CSSProperties;
  title:
    | 'Background primário'
    | 'Background secundário'
    | 'Cor primária'
    | 'Cor secundária'
    | 'Cor dos textos';
  subTitle: string;
  inputValue: string;
  id: string;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const ColorSelect = ({ style, id, inputValue, onChange, title, subTitle, name }: PColorSelect) => (
  <Wrapper style={style}>
    <Title>{title}</Title>
    <SubTitle>{subTitle}</SubTitle>
    <Container>
      <Input
        placeholder="Selecione"
        disabled
        value={inputValue}
        style={{ width: '288px', height: '40px' }}
      />
      <InputColor
        id={id}
        type="color"
        value={inputValue || '#FFFFFF'}
        name={name}
        onChange={onChange}
      />
    </Container>
  </Wrapper>
);

ColorSelect.defaultProps = {
  style: {},
};

export default ColorSelect;
