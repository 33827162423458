import styled from 'styled-components';
import { colors } from '../../../../shared/functions/colors';
import { fonts } from '../../../../shared/functions/fonts';

export const Container = styled.div`
  width: 60%;
`;

export const Title = styled.h2`
  font-family: ${fonts.semiBold};
  color: ${colors.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;

  margin-left: 8px;
  margin-bottom: 8px;
`;

export const SubTitle = styled.h2`
  font-family: ${fonts.regular};
  color: ${colors.grey100};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;

  margin-left: 8px;
`;

export const ContainerFlex = styled.div`
  display: flex;
  align-items: center;
`;
