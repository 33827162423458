import * as LocalStorageProxy from './connection/localStorageProxy';
import { IMAGE_COMPANY_KEY } from '../constants/keysLocalStorage';

export const setImageCompany = (value: string) => {
  LocalStorageProxy.setItem(IMAGE_COMPANY_KEY, value);
};

export const getImageCompany = () => {
  const value = LocalStorageProxy.getItem(IMAGE_COMPANY_KEY);
  if (value === null) {
    return '/images/logoMagiCash.png';
  }
  return value;
};
