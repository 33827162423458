import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import Button from '../../shared/components/buttons/Button';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import { ContainerMain, HeaderMain, TextHeaderMain } from '../../shared/components/styled/general';
import Table from '../../shared/components/table/Table';
import { MenuUrl } from '../../shared/enums/menuUrl';
import IconEdit from '../../shared/images/icon/iconEdit';
import IconTrash from '../../shared/images/icon/iconTrash';
import { TypeUser as ITypeUser } from '../../shared/modals/typeUser/typeUser';
import { useTypeUser } from '../../store/reducers/typeUser';
import DeleteTypeUser from './deleteTypeUser/DeleteTypeUser';
import InsertTypeUser from './insertTypeUser/InsertTypeUser';

const TypeUser = () => {
  const history = useHistory();
  const [typeUserAction, setTypeUserAction] = useState<ITypeUser>();
  const [openDeleteTypeUser, setOpenDeleteTypeUser] = useState(false);
  const [openInsertTypeUser, setOpenInsertTypeUser] = useState(false);
  const { getTypeUser, typesUser } = useTypeUser();

  useEffect(() => {
    getTypeUser();
  }, []);

  const handleGoToPermission = () => {
    history.push(MenuUrl.permission);
  };

  const handleEditTypeUser = (typeUser: ITypeUser) => {
    setTypeUserAction(typeUser);
    setOpenInsertTypeUser(true);
  };

  const handleDeleteTypeUser = (typeUser: ITypeUser) => {
    setTypeUserAction(typeUser);
    setOpenDeleteTypeUser(true);
  };

  const handleOpenInsertTypeUser = () => {
    setOpenInsertTypeUser(true);
  };

  const renderRowsTable = () =>
    typesUser.map(typeUser => ({
      columns: [
        typeUser.name,
        typeUser.permissions ? typeUser.permissions.length : 0,
        <>
          <ButtonIcon onClick={() => handleEditTypeUser(typeUser)} style={{ marginRight: 16 }}>
            <IconEdit />
          </ButtonIcon>
          <ButtonIcon onClick={() => handleDeleteTypeUser(typeUser)} style={{ marginRight: 16 }}>
            <IconTrash />
          </ButtonIcon>
        </>,
      ],
    }));

  return (
    <ContainerMain>
      <InsertTypeUser
        openInsertTypeUser={openInsertTypeUser}
        setOpenInsertTypeUser={setOpenInsertTypeUser}
        typeUser={typeUserAction}
      />
      <DeleteTypeUser
        typeUser={typeUserAction}
        openDeleteTypeUser={openDeleteTypeUser}
        setOpenDeleteTypeUser={setOpenDeleteTypeUser}
      />
      <BreadCrumbs
        listMenus={[
          {
            name: 'Permissões',
            action: handleGoToPermission,
          },
          {
            name: 'Tipos de usuários',
          },
        ]}
      />
      <HeaderMain>
        <TextHeaderMain>Confira e edite abaixo a lista de tipos de usuários</TextHeaderMain>
        <Button style={{ width: 300 }} onClick={handleOpenInsertTypeUser} icon="plus">
          ADICIONAR TIPO DE USUÁRIO
        </Button>
      </HeaderMain>
      <Table
        nameReferenceReduxTable="table_type_user"
        listRowsPerPage={[10, 25, 50, 100]}
        titles={[
          {
            name: 'Nome',
            openClick: true,
            type: 'string',
          },
          {
            name: 'Qtd permissões',
            openClick: false,
            type: 'number',
            widthTd: '20%',
          },
          {
            name: 'Ações',
            openClick: false,
            type: 'ReactNode',
            widthTd: '20%',
          },
        ]}
        rows={renderRowsTable()}
      />
    </ContainerMain>
  );
};

export default TypeUser;
