import React, { Dispatch, useEffect } from 'react';

import Modal from '../../../../shared/components/modal/Modal';
import { TitleSemiBold } from '../../../../shared/components/styled/text';
import Table from '../../../../shared/components/table/Table';
import { colors } from '../../../../shared/functions/colors';
import IconCheck from '../../../../shared/images/icon/iconCheck';
import { useTypeUser } from '../../../../store/reducers/typeUser';

type PModalShowPermissions = {
  openModal: boolean;
  setOpenModal: Dispatch<boolean>;
};

const ModalShowPermissions = ({ openModal, setOpenModal }: PModalShowPermissions) => {
  const { showPermissions, getShowPermissions } = useTypeUser();
  useEffect(() => {
    if (showPermissions.length === 0) {
      getShowPermissions();
    }
  }, []);

  const renderRowsTable = () => {
    if (showPermissions[0]?.typesUser) {
      return showPermissions.map(permission => {
        if (permission?.typesUser?.length) {
          const columns = [<>{permission.name}</>];

          permission.typesUser.forEach(typeUser => {
            if (typeUser.hasPermission) {
              columns.push(<IconCheck color={colors.pinkRegular} />);
            } else {
              columns.push(<></>);
            }
          });
          return {
            columns,
          };
        }
        return { columns: [] };
      });
    }
    return [];
  };

  const renderHeaderTable = () => {
    if (showPermissions[0]?.typesUser) {
      return [
        {
          name: '',
          openClick: true,
          type: 'string',
        },
      ].concat(
        showPermissions[0].typesUser.map(typeUser => ({
          name: typeUser.name,
          openClick: true,
          type: 'string',
        })),
      );
    }
    return [];
  };

  return (
    <Modal maxWidth="1200px" open={openModal} onClose={() => setOpenModal(false)}>
      <TitleSemiBold>Tipos de membros</TitleSemiBold>
      <Table
        nameReferenceReduxTable="table_types_user"
        listRowsPerPage={[100]}
        titles={renderHeaderTable()}
        rows={renderRowsTable()}
      />
    </Modal>
  );
};

export default ModalShowPermissions;
