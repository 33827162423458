import { ConnectApiGet, urls } from '../functions/connection';
import { doLogout } from '../functions/connection/auth';
import { ERROR_MAINTENANCE } from '../constants/messages';

type MechanicInterface = {
  id: number;
  name: string;
  autoApproved: boolean;
};

export const getAll = async () => {
  try {
    const response = await ConnectApiGet(`${urls.CHALLENGES_URL}/mechanics`);
    const mechanics: MechanicInterface[] = response.data;
    return mechanics;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      // dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }

  return [];
};
