import { useDispatch, useSelector } from 'react-redux';
import { BodyInsertLibrary, BodyImportLibrary } from '../../../shared/modals/library/bodies';

import { Library } from '../../../shared/modals/library/library';
import { RootStateGlobal } from '../../reducer';
import * as libraryOperations from './libraryOperations';
import * as libraryActions from './libraryActions';

export const useLibrary = () => {
  const dispatch = useDispatch();
  const { libraries, library } = useSelector((state: RootStateGlobal) => state.libraryReducer);

  const getLibraries = async () => libraryOperations.getLibraries()(dispatch);
  const getLibrary = async (idLibrary: string) => libraryOperations.getLibrary(idLibrary)(dispatch);
  const insertLibrary = async (body: BodyInsertLibrary) =>
    libraryOperations.insertLibrary(body)(dispatch);
  const editLibrary = async (idLibrary: string, body: BodyInsertLibrary) =>
    libraryOperations.editLibrary(idLibrary, body)(dispatch);
  const deleteLibrary = async (idLibrary: string) =>
    libraryOperations.deleteLibrary(idLibrary)(dispatch);
  const importLibrary = async (body: BodyImportLibrary) =>
    libraryOperations.importLibrary(body)(dispatch);
  const importLibraryInsertCategory = async (body: BodyImportLibrary, nameCategory: string) =>
    libraryOperations.importLibraryInsertCategory(body, nameCategory)(dispatch);

  const setLibrary = (x?: Library) => dispatch(libraryActions.setLibrary(x));

  return {
    libraries,
    library,
    getLibraries,
    insertLibrary,
    getLibrary,
    importLibrary,
    editLibrary,
    deleteLibrary,
    setLibrary,
    importLibraryInsertCategory,
  };
};
