import { ConnectApiGet, urls } from '../functions/connection';
import { doLogout } from '../functions/connection/auth';
import { ERROR_MAINTENANCE } from '../constants/messages';

type ListAreaInterface = {
  idArea: string;
  name: string;
};

export const getAll = async () => {
  try {
    const response = await ConnectApiGet(urls.URL_AREA);
    const areas: ListAreaInterface[] = response.data;
    return areas;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      // dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }

  return [];
};
