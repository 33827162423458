import React, { useState, useEffect } from 'react';
import { useGeneral } from '../../../store/reducers/general';
import { colors } from '../../functions/colors';
import IconAlert from '../../images/icon/iconAlert';
import IconCheck from '../../images/icon/iconCheck';
import IconClose from '../../images/icon/iconClose';
import ButtonIcon from '../buttons/buttonIcon/ButtonIcon';
import { DisplayFlexColumn } from '../styled/general';
import { TextDefault } from '../styled/text';

import { Container, BoxMessage } from './styles';

export const SUCCESS_SNACKBAR = 'success';

const TIME_SHOW = 30000;

const SnackBar = () => {
  const { snackbar, setSnackbar } = useGeneral();
  const [position, setPosition] = useState(-3000);
  const color = snackbar.type === SUCCESS_SNACKBAR ? colors.success : colors.error;

  const handleCloseSnackBar = () => {
    setPosition(-3000);
    setSnackbar(snackbar.type, snackbar.message, false);
  };

  useEffect(() => {
    if (snackbar.show) {
      setPosition(0);
      setTimeout(() => {
        handleCloseSnackBar();
      }, TIME_SHOW);
    }
  }, [snackbar]);

  return (
    <Container position={position} style={{ pointerEvents: 'none' }}>
      <BoxMessage type={snackbar.type} style={{ pointerEvents: 'all', zIndex: 1 }}>
        {snackbar.type === SUCCESS_SNACKBAR ? <IconCheck color={color} /> : <IconAlert />}
        <DisplayFlexColumn style={{ margin: '0px 16px' }}>
          <TextDefault color={color}>
            <strong>
              {snackbar.type === SUCCESS_SNACKBAR ? 'Sucesso!' : 'Ops, ocorreu um erro!'}
            </strong>
          </TextDefault>
          <TextDefault style={{ marginTop: 8, whiteSpace: 'pre-line' }} color={color}>
            {snackbar.message}
          </TextDefault>
        </DisplayFlexColumn>
        <ButtonIcon onClick={handleCloseSnackBar}>
          <IconClose width={24} height={24} color={color} />
        </ButtonIcon>
      </BoxMessage>
    </Container>
  );
};

export default SnackBar;
