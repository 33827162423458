import React, { InputHTMLAttributes, FC } from 'react';
import moment from 'moment';
import { CSSProperties } from 'styled-components';
import { colors } from '../../../functions/colors';
import Calendar from '../../calendar/Calendar';
import Input from '../Input';
import { useComponentVisible } from '../../../hooks/ComponentVisible';
import { Container, Dropdown } from './styles';
import 'moment/locale/pt-br';

export interface PInputDate extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  value: string;
  onChangeDate: (e: string) => void;
  style?: CSSProperties;
  placement?: string;
  disabled?: boolean;
}

const InputDate: FC<PInputDate> = ({
  value,
  onChangeDate,
  title,
  placement,
  style,
  disabled,
  ...props
}: PInputDate) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const listDays = [
    {
      date: value,
      backgroundColor: colors.pinkDark,
    },
    {
      date: moment().format('YYYY-MM-DD'),
      backgroundColor: colors.white,
      borderColor: colors.pinkDark,
    },
  ];

  const handleSelectDate = (date: string) => {
    onChangeDate(date);
    setIsComponentVisible(false);
  };

  return (
    <Container style={style}>
      <Input
        value={value === '' ? '' : moment(value).format('DD/MM/YYYY')}
        iconRight="calendar"
        title={title}
        onClick={() => setIsComponentVisible(true)}
        disabled={disabled}
        {...props}
        onClickIcon={() => {
          if (!disabled) {
            setIsComponentVisible(!isComponentVisible);
          }
        }}
        placeholder="dd/mm/aaaa"
      />
      {isComponentVisible && (
        <Dropdown placement={placement} ref={ref}>
          <Calendar
            monthStart={parseInt(moment(value).format('MM'), 10)}
            selectDate={handleSelectDate}
            listDays={listDays}
          />
        </Dropdown>
      )}
    </Container>
  );
};

InputDate.defaultProps = {
  placement: '',
  style: {},
  title: undefined,
  disabled: false,
};

export default InputDate;
