import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../../functions/colors';

type PIconArrowUp = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconArrowUp = ({ width, height, color, style }: PIconArrowUp) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" style={style}>
    <mask id="prefix_IconArrowUp" maskUnits="userSpaceOnUse" x={4} y={4} width={16} height={16}>
      <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" fill="#fff" />
    </mask>
    <g mask="url(#prefix_IconArrowUp)">
      <path fill={color} d="M0 0h24v24H0z" />
    </g>
  </svg>
);

IconArrowUp.defaultProps = {
  width: 24,
  height: 24,
  color: colors.success,
  style: {},
};

export default IconArrowUp;
