import { SelfSignUpCampaignInterface as ListSelfSignUpCampaignInterface } from '../../features/selfSignUpCampaigns/SelfSignUpCampaigns';
import { SelfSignUpCampaignInterface } from '../../features/selfSignUpCampaigns/createEdit/CreateEditSelfSignUpCampaign';
import {
  ConnectApiGet,
  ConnectApiPost,
  ConnectApiPUT,
  ConnectApiDelete,
  urls,
} from '../functions/connection';
import { doLogout } from '../functions/connection/auth';
import { ERROR_MAINTENANCE } from '../constants/messages';
import { setSnackbar, SnackBarActionInterface } from '../../store/reducers/general/generalActions';

export const getAll = async (dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    const response = await ConnectApiGet(urls.SELF_SIGNUP_CAMPAIGNS_URL);
    const campaigns: ListSelfSignUpCampaignInterface[] = response.data;
    return campaigns;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }

  return [];
};

export const get = async (id: string, dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    const response = await ConnectApiGet(`${urls.SELF_SIGNUP_CAMPAIGNS_URL}/${id}`);
    const selfSignUpCampaign: SelfSignUpCampaignInterface = response.data;
    return selfSignUpCampaign;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }

  return null;
};

export const create = async (
  attrs: Partial<SelfSignUpCampaignInterface>,
  dispatch: (arg: SnackBarActionInterface) => void,
) => {
  try {
    await ConnectApiPost(urls.SELF_SIGNUP_CAMPAIGNS_URL, { selfSignUpCampaign: attrs });
    dispatch(setSnackbar('Campanha inserida com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const update = async (
  attrs: Partial<SelfSignUpCampaignInterface> | { enabled: boolean },
  id: string,
  dispatch: (arg: SnackBarActionInterface) => void,
) => {
  try {
    await ConnectApiPUT(`${urls.SELF_SIGNUP_CAMPAIGNS_URL}/${id}`, {
      selfSignUpCampaign: attrs,
    });
    dispatch(setSnackbar('Campanha modificada com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const destroy = async (id: string, dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    await ConnectApiDelete(`${urls.SELF_SIGNUP_CAMPAIGNS_URL}/${id}`);
    dispatch(setSnackbar('Campanha excluída com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const duplicate = async (id: string, dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    await ConnectApiPost(`${urls.SELF_SIGNUP_CAMPAIGNS_URL}/${id}/duplicate`, {});
    dispatch(setSnackbar('Campanha duplicada com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};
