import fileDownload from 'js-file-download';
import {
  ConnectApiGet,
  ConnectApiPost,
  ConnectApiPUT,
  ConnectApiDelete,
  urls,
} from '../functions/connection';
import { generateQueryString } from '../functions/connection/queryString';
import { BodyCreateContest } from '../modals/contest/bodies';

export const getContests = async (
  startDate?: string,
  endDate?: string,
  idAreas?: string[],
  idCategories?: string[],
  enabled?: boolean,
) => {
  const url = generateQueryString(urls.URL_CONTEST, {
    startDate,
    endDate,
    idAreas,
    idCategories,
    enabled,
  });
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const insertContest = async (bodyCreateContest: BodyCreateContest) => {
  const respostaServico = await ConnectApiPost(urls.URL_CONTEST, bodyCreateContest);
  return respostaServico.data;
};

export const editContest = async (bodyCreateContest: BodyCreateContest, idContest: string) => {
  const url = urls.URL_CONTEST_ID.replace('{idContest}', `${idContest}`);
  const respostaServico = await ConnectApiPUT(url, bodyCreateContest);
  return respostaServico.data;
};

export const deleteContest = async (idContest: string) => {
  const url = urls.URL_CONTEST_ID.replace('{idContest}', `${idContest}`);
  await ConnectApiDelete(url);
};

export const duplicateContest = async (idContest: string) => {
  const url = urls.URL_CONTEST_ID_DUPLICATE.replace('{idContest}', `${idContest}`);
  await ConnectApiPost(url, {});
};

export const getContest = async (idContest: string) => {
  const url = urls.URL_CONTEST_ID.replace('{idContest}', `${idContest}`);
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const changeContestEnable = async (idContest: string) => {
  const url = urls.URL_ENABLE_CONTEST.replace('{idContest}', `${idContest}`);
  const respostaServico = await ConnectApiPUT(url, {});
  return respostaServico.data;
};

export const getContestReport = async (idContest: string) => {
  const url = urls.URL_CONTEST_ID_REPORT.replace('{idContest}', `${idContest}`);
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const downloadOnePageCsv = async (idContest: string) => {
  const url = urls.URL_CONTEST_ID_REPORT_DOWNLOAD.replace('{idContest}', `${idContest}`);
  const respostaServico = await ConnectApiGet(url);

  fileDownload(respostaServico.data, 'Relatório.csv');
  return respostaServico.data;
};

export const importContestList = async (body: FormData) => {
  const respostaServico = await ConnectApiPost(urls.URL_CONTEST_CSV, body);
  return respostaServico.data;
};
