import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as missionsService from '../../../shared/services/missions';

import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { IMission } from '../Mission';

type PToggleMissionEnabled = {
  mission: IMission;
  open: boolean;
  handleCloseModal: () => void;
  refreshList: () => void;
};

const ToggleMissionEnabled = ({
  mission,
  open,
  handleCloseModal,
  refreshList,
}: PToggleMissionEnabled) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  if (!mission) return null;

  const handleToggleEnabled = async () => {
    setLoading(true);
    await missionsService.update({ enabled: !mission.enabled }, mission.id, dispatch);
    refreshList();
    setLoading(false);
    handleCloseModal();
  };

  const title = mission.enabled ? 'Desativar missão' : 'Ativar missão';
  const message = mission.enabled
    ? 'Essa missão irá desaparecer para os usuários. Tem certeza que deseja desativar essa missão?'
    : 'Essa missão irá aparecer para os usuários. Tem certeza que deseja ativar essa missão?';

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title={title}
      message={message}
      onClose={handleCloseModal}
      open={open}
      onClickPrimary={handleToggleEnabled}
      onClickSecondary={handleCloseModal}
    />
  );
};

export default ToggleMissionEnabled;
