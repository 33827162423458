import { ConnectApiGet, ConnectApiPUT, urls } from '../functions/connection';
import { doLogout } from '../functions/connection/auth';
import { ERROR_MAINTENANCE } from '../constants/messages';
import { setSnackbar, SnackBarActionInterface } from '../../store/reducers/general/generalActions';
import { ApprovalInterface } from '../../features/approvals/ChallengeApprovals';

export const getAll = async (dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    const response = await ConnectApiGet(`${urls.CHALLENGES_URL}/pending_approvals`);
    const answers: ApprovalInterface[] = response.data;
    return answers;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }

  return [];
};

export const update = async (
  id: string,
  status: boolean,
  dispatch: (arg: SnackBarActionInterface) => void,
) => {
  try {
    await ConnectApiPUT(`${urls.CHALLENGES_URL}/pending_approvals/${id}`, {
      status: status.toString(),
    });
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }

  return [];
};
