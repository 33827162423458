import {
  ConnectApiGet,
  ConnectApiPost,
  ConnectApiPUT,
  ConnectApiDelete,
  urls,
  ConnectApiPatch,
} from '../functions/connection';
import { BodyCreateStage } from '../modals/stage/bodies';

export const getStages = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_STAGE);
  return respostaServico.data;
};

export const getStagesIdTrail = async (idTrail: string) => {
  const url = urls.URL_STAGE_ID_TRAIL.replace('{idTrail}', `${idTrail}`);
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const getNextPositionStage = async (idTrail: string) => {
  const url = urls.URL_STAGE_ID_TRAIL_NEXT_POSITION.replace('{idTrail}', `${idTrail}`);
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const changePositionStage = async (
  idTrail: string,
  idStage: string,
  upPosition: boolean,
) => {
  let url = urls.URL_STAGE_ID_TRAIL_ID_STAGE.replace('{idStage}', `${idStage}`);
  url = url.replace('{idTrail}', `${idTrail}`);
  url += `?upPosition=${upPosition}`;
  const respostaServico = await ConnectApiPatch(url, {});
  return respostaServico.data;
};

export const insertStage = async (bodyCreateStage: BodyCreateStage, idTrail: string) => {
  const url = urls.URL_STAGE_ID_TRAIL.replace('{idTrail}', `${idTrail}`);
  const respostaServico = await ConnectApiPost(url, bodyCreateStage);
  return respostaServico.data;
};

export const editStage = async (
  bodyCreateStage: BodyCreateStage,
  idStage: string,
  idTrail: string,
) => {
  let url = urls.URL_STAGE_ID_TRAIL_ID_STAGE.replace('{idStage}', `${idStage}`);
  url = url.replace('{idTrail}', `${idTrail}`);
  const respostaServico = await ConnectApiPUT(url, bodyCreateStage);
  return respostaServico.data;
};

export const deleteStage = async (idStage: string) => {
  const url = urls.URL_STAGE_ID.replace('{idStage}', `${idStage}`);
  await ConnectApiDelete(url);
};

export const duplicateStage = async (idStage: string) => {
  const url = urls.URL_STAGE_ID_DUPLICATE.replace('{idStage}', `${idStage}`);
  await ConnectApiPost(url, {});
};

export const getStage = async (idStage: string) => {
  const url = urls.URL_STAGE_ID.replace('{idStage}', `${idStage}`);
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};
