/* eslint-disable no-plusplus */
const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = () => resolve(image);
    image.onerror = error => reject(error);
    // References:
    // https://stackoverflow.com/a/72620918/3587075
    // https://stackoverflow.com/a/24068227/3587075
    image.src = `${url}?foo=bar`;
  });

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');

  const mime = arr[0].match(/:(.*?);/)[1];

  const bstr = atob(arr[1]);

  let n = bstr.length;

  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export default async function getCroppedImg(imageSrc, pixelCrop, dimensions) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // 3 times the final exhibition so that the image quality is still good
  canvas.width = 3 * dimensions.width;
  canvas.height = 3 * dimensions.height;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    canvas.width,
    canvas.height,
  );

  return dataURLtoFile(canvas.toDataURL('image/png'), 'cropped-image.png');
}
