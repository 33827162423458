import styled from 'styled-components';

export const Container = styled.button`
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 62px;
  right: 43px;
  border: none;
  background: none;
  text-align: center;

  cursor: pointer;
`;
