import styled from 'styled-components';
import { colors } from '../../shared/functions/colors';

export const Container = styled.div`
  padding: 32px;
  height: calc(100vh - 80px);
  background-color: ${colors.ice};
`;

export const Title = styled.h1`
  margin-bottom: 32px;
`;

export const BoxHead = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
