import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

import Button from '../../shared/components/buttons/Button';
import { isLogged } from '../../shared/functions/connection/auth';
import { useUser } from '../../store/reducers/user';
import { getImageCompany } from '../../shared/functions/imageCompany';
import Input from '../../shared/components/input/Input';
import {
  Item,
  Grid,
  Content,
  Form,
  InputContainer,
  Title,
  Logo,
  LoginContainer,
  ButtonContainer,
  TitleLogin,
  ForgotPasswordButton,
} from './styles';
import { actionAnalitycs } from '../../shared/functions/analytics';
import { ACTIONS_LOGIN, CATEGORIES } from '../../shared/constants/constantsAnalytics';
import ForgotPassword from './forgotPassword/ForgotPassword';
import ChangePassword from './changePassword/ChangePassword';

type ParamTypes = {
  token?: string;
  email?: string;
};

const Login = () => {
  const { token, email } = useParams<ParamTypes>();
  const history = useHistory();
  const { reqLogin, getUserData } = useUser();
  const [openForgotPassword, setOpenForgotPassword] = useState<boolean>(false);
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [login, setLogin] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    actionAnalitycs(CATEGORIES.LOGIN, ACTIONS_LOGIN.OPEN, window.location.pathname);
    if (token && email) {
      setOpenChangePassword(true);
    }
  }, []);

  useEffect(() => {
    const verifyLogged = async () => {
      if (isLogged()) {
        setLoading(true);
        try {
          await getUserData();
          history.push('/home');
          setLoading(false);
        } catch (e) {
          setLoading(false);
        }
      }
    };
    verifyLogged();
  }, []);

  const handleLogin = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      await reqLogin(login);
      history.push('/home');
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setLogin({
      ...login,
      email: event.target.value,
    });
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setLogin({
      ...login,
      password: event.target.value,
    });
  };

  return (
    <Grid>
      <ForgotPassword
        openForgotPassword={openForgotPassword}
        setOpenForgotPassword={setOpenForgotPassword}
      />
      <ChangePassword
        openChangePassword={openChangePassword}
        setOpenChangePassword={setOpenChangePassword}
        token={token}
        email={email}
      />
      <Item>
        <Content>
          <Logo style={{ backgroundImage: `url(${getImageCompany()})` }} />
          <TitleLogin>
            Seja bem-vindo
            <br />
            <span>à TuTo Business</span>
          </TitleLogin>
        </Content>
      </Item>
      <Item>
        <LoginContainer>
          <Title>Acesse a TuTo</Title>
          <Form onSubmit={handleLogin}>
            <InputContainer>
              <Input
                error={error}
                title="Digite o seu email"
                placeholder="Digite o seu email"
                type="text"
                value={login.email}
                onChange={handleChangeEmail}
                autoFocus
                name="email"
              />
            </InputContainer>
            <InputContainer>
              <Input
                error={error}
                title="Digite a sua senha"
                placeholder="Digite a sua senha"
                type="password"
                value={login.password}
                onChange={handleChangePassword}
                name="password"
              />
            </InputContainer>
            <ForgotPasswordButton type="button" onClick={() => setOpenForgotPassword(true)}>
              Esqueci minha senha
            </ForgotPasswordButton>
            <ButtonContainer>
              <Button type="submit" icon="arrowRight" loading={loading}>
                Acesse agora
              </Button>
            </ButtonContainer>
          </Form>
        </LoginContainer>
      </Item>
    </Grid>
  );
};

export default Login;
