import { combineReducers } from 'redux';
import { RootStateOrAny } from 'react-redux';
import { ContestState } from './reducers/contest/contestTypes';
import { QuestionState } from './reducers/question/questionTypes';
import { CompanyState } from './reducers/company/companyTypes';
import { TrailState } from './reducers/trail/trailTypes';
import { StageState } from './reducers/stage/stageTypes';
import { userReducer } from './reducers/user';
import { companyReducer } from './reducers/company';
import { questionReducer } from './reducers/question';
import { contestReducer } from './reducers/contest';
import { reportReducer } from './reducers/report';
import { trailReducer } from './reducers/trail';
import { stageReducer } from './reducers/stage';
import { UserState } from './reducers/user/userTypes';
import { ReportState } from './reducers/report/reportTypes';
import { generalReducer } from './reducers/general';
import { libraryReducer } from './reducers/library';
import { typeUserReducer } from './reducers/typeUser';
import { TypeUserState } from './reducers/typeUser/typeUserTypes';
import { LibraryState } from './reducers/library/libraryTypes';
import { GeneralState } from './reducers/general/generalTypes';
import { RewardState } from './reducers/reward/rewardTypes';
import { rewardReducer } from './reducers/reward';
import { AreaState } from './reducers/area/areaTypes';
import { areaReducer } from './reducers/area';

export interface RootStateGlobal extends RootStateOrAny {
  userReducer: UserState;
  companyReducer: CompanyState;
  questionReducer: QuestionState;
  contestReducer: ContestState;
  trailReducer: TrailState;
  stageReducer: StageState;
  reportReducer: ReportState;
  generalReducer: GeneralState;
  libraryReducer: LibraryState;
  typeUserReducer: TypeUserState;
  rewardReducer: RewardState;
  areaReducer: AreaState;
}

export default combineReducers<RootStateGlobal>({
  userReducer,
  companyReducer,
  questionReducer,
  contestReducer,
  trailReducer,
  stageReducer,
  reportReducer,
  generalReducer,
  libraryReducer,
  typeUserReducer,
  rewardReducer,
  areaReducer,
});
