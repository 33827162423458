import { Dispatch, SetStateAction } from 'react';
import { POptions } from '../components/select/selectNotBox/SelectNotBox';
import { getAll as getAllAreas } from '../services/areas';

export const fetchFormattedAreas = async (setState: Dispatch<SetStateAction<POptions[]>>) => {
  const formattedAreas = (await getAllAreas()).map(area => ({
    value: area.idArea.toString(),
    viewValue: area.name,
  }));

  setState(formattedAreas);
  return formattedAreas;
};
