import { Reducer } from 'redux';

import { ReportTypes, ReportState } from './reportTypes';

const INITIAL_STATE: ReportState = {
  report: undefined,
  presenceGraphic: undefined,
};

const reducer: Reducer<ReportState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReportTypes.SET_REPORT:
      return {
        ...state,
        report: action.payload,
      };
    case ReportTypes.SET_REPORT_PRESENCE:
      return {
        ...state,
        presenceGraphic: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
