import styled from 'styled-components';
import { fonts } from '../../functions/fonts';
import { colors } from '../../functions/colors';

export const ContainerMain = styled.div`
  width: 100%;
  padding: 16px 40px;
`;

export const HeaderMain = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 24px 0px;
  justify-content: space-between;
`;

export const TextHeaderMain = styled.p`
  color: ${colors.black};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

export const BoxInfo = styled.div`
  background-color: ${colors.white};
  padding: 24px;
  width: 100%;
`;

export const DisplayFlex = styled.div`
  display: flex;
  align-items: baseline;
`;

export const DisplayFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DisplayFlexAlignCenter = styled(DisplayFlex)`
  align-items: center;
`;

export const DisplayFlexCenterCenter = styled(DisplayFlex)`
  align-items: center;
  justify-content: center;
`;

export const DisplayFlexJustifyContent = styled(DisplayFlexAlignCenter)`
  justify-content: space-between;
`;

export const DisplayFlexColumnAlignCenter = styled(DisplayFlexColumn)`
  align-items: center;
`;
