import React, { useState, Dispatch, SetStateAction } from 'react';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Area } from '../../../shared/modals/area/area';
import { useArea } from '../../../store/reducers/area';

type PEnableArea = {
  openEnableArea: boolean;
  setOpenEnableArea: Dispatch<SetStateAction<boolean>>;
  area?: Area;
};

const EnableArea = ({ openEnableArea, setOpenEnableArea, area }: PEnableArea) => {
  const [loading, setLoading] = useState(false);
  const { editArea } = useArea();

  const handleEnableArea = async () => {
    setLoading(true);
    if (area) {
      const updatedArea = { ...area, enabled: !area.enabled };
      await editArea(area.idArea, updatedArea);
    }
    setLoading(false);
    setOpenEnableArea(false);
  };

  const title = area && area.enabled ? 'Desativar área' : 'Ativar área';
  const message =
    area && area.enabled
      ? 'Os usuários não poderão ser associados a esta área. Tem certeza que deseja desativar a área?'
      : 'Os usuários poderão ser associados a esta área. Tem certeza que deseja ativar a área?';

  return (
    <>
      <ModalConfirm
        loadingPrimary={loading}
        title={title}
        message={message}
        onClose={() => setOpenEnableArea(false)}
        open={openEnableArea}
        onClickPrimary={handleEnableArea}
        onClickSecondary={() => setOpenEnableArea(false)}
      />
    </>
  );
};

EnableArea.defaultProps = {
  area: {
    idArea: '',
    name: '',
    amountUsers: '',
    enabled: false,
  },
};

export default EnableArea;
