import styled from 'styled-components';
import { fonts } from '../../functions/fonts';
import { colors } from '../../functions/colors';

export const Wrapper = styled.div`
  width: 330px;
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 5px;
`;

export const InputColor = styled.input`
  cursor: pointer;
  margin-left: 10px;
  -webkit-appearance: none;
  border: none;
  width: 24px;
  height: 24px;
  outline: none;
  border-radius: 100%;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  &::-webkit-color-swatch {
    border-radius: 100%;
  }
`;

export const Title = styled.h5`
  font-family: ${fonts.semiBold};
  font-size: 10px;
  line-height: 15px;
  padding-top: 15px;
  display: flex;
  align-items: center;
  color: ${colors.strongPurple};
`;

export const SubTitle = styled.p`
  font-family: ${fonts.regular};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  display: flex;
  align-items: center;
  padding: 7px;
  color: ${colors.grey100};
`;
