/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, BoxInfo, BoxPadding, BoxFlex, BoxBottom } from '../../mission/create/styles';
import InputDate from '../../../shared/components/input/inputDateTime/InputDateTime';
import MultipleSelect from '../../../shared/components/select/multipleSelect/MultipleSelect';
import BreadCrumbs from '../../../shared/components/breadCrumbs/BreadCrumbs';
import Input from '../../../shared/components/input/Input';
import Button from '../../../shared/components/buttons/Button';
import { TitleContest } from '../../../shared/components/styled/text';
import * as selfSignUpCampaignsService from '../../../shared/services/selfSignUpCampaigns';
import * as selfSignUpFieldsService from '../../../shared/services/selfSignUpFields';
import { POptions } from '../../../shared/components/select/Select';
import { dateTimeStampToDateMaterialUi } from '../../../shared/functions/date';
import Checkbox from '../../../shared/components/input/checkbox/Checkbox';
import { TextPlaceHolder } from '../../../shared/components/input/styles';
import { handleChangeFieldValue } from '../../../shared/functions/handlers';
import { fetchFormattedAreas } from '../../../shared/functions/fetchers';
import { DisplayFlex, Title, Line, SubTitle } from '../../../shared/components/presentation/styles';
import InputFileImage from '../../../shared/components/input/inputFileImage/InputFile';

export type SelfSignUpCampaignInterface = {
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  freezeUsersAt: Date;
  imageUrl: string;
  areaIds: string[];
  selfSignUpCampaignsFieldsAttributes: {
    id?: string;
    selfSignUpFieldId: string;
    value?: string;
    _destroy?: string;
  }[];
  memberGetMemberConfigAttributes: {
    enabled: boolean;
    rewards: number;
    rewardPerUsersCount: number;
  };
};

export type SelfSignUpFieldInterface = {
  id: string;
  name: string;
  label: string;
  required: boolean;
  fieldType: string;
  instructions: string;
};

const CreateEditSelfSignUpCampaign = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [areas, setAreas] = useState<POptions[]>([]);
  const [selfSignUpFields, setSelfSignUpFields] = useState<SelfSignUpFieldInterface[]>([]);
  const [selectedAreas, setSelectedAreas] = useState<POptions[]>([]);
  const [selfSignUpCampaign, setSelfSignUpCampaign] = useState<
    Partial<SelfSignUpCampaignInterface>
  >({});

  const fetchSelfSignUpCampaign = async (
    formattedAreas: POptions[],
    fields: SelfSignUpFieldInterface[],
  ) => {
    const { id } = params as { id: string };

    if (id) {
      const campaign = await selfSignUpCampaignsService.get(id, dispatch);

      if (campaign) {
        const campaignAreas = formattedAreas.filter(area => campaign.areaIds.includes(area.value));

        setSelfSignUpCampaign(campaign);
        setSelectedAreas(campaignAreas);
      }
    } else {
      const campaign: Partial<SelfSignUpCampaignInterface> = {
        selfSignUpCampaignsFieldsAttributes: fields
          .filter(field => field.required)
          .map(field => ({ selfSignUpFieldId: field.id })),
      };
      setSelfSignUpCampaign(campaign);
    }
  };

  const fetchSelfSignUpFields = async () => {
    const fields = await selfSignUpFieldsService.getAll(dispatch);
    setSelfSignUpFields(fields);
    return fields;
  };

  const handleRequireField = (checked: boolean, selfSignUpFieldId: string) => {
    setSelfSignUpCampaign(previousState => {
      const selfSignUpCampaignsFieldsAttributes =
        previousState.selfSignUpCampaignsFieldsAttributes || [];
      let changeFieldIndex = -1;
      const selfSignUpFieldCanBeChanged = !!selfSignUpCampaignsFieldsAttributes.find(
        (field, index) => {
          changeFieldIndex = index;
          return field.selfSignUpFieldId === selfSignUpFieldId;
        },
      );

      if (checked)
        selfSignUpFieldCanBeChanged
          ? (selfSignUpCampaignsFieldsAttributes[changeFieldIndex]._destroy = '0')
          : selfSignUpCampaignsFieldsAttributes.push({ selfSignUpFieldId, _destroy: '0' });
      else selfSignUpCampaignsFieldsAttributes[changeFieldIndex]._destroy = '1'; // Mark to destroy

      return {
        ...previousState,
        selfSignUpCampaignsFieldsAttributes,
      };
    });
  };

  useEffect(() => {
    (async () => {
      const formattedAreas = await fetchFormattedAreas(setAreas);
      const fields = await fetchSelfSignUpFields();
      await fetchSelfSignUpCampaign(formattedAreas, fields);
    })();
  }, []);

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleChangeFieldValue<Partial<SelfSignUpCampaignInterface>>(event, setSelfSignUpCampaign);
  };

  const handleAreaSelected = (newSelectedAreas: POptions[]) => {
    setSelectedAreas(newSelectedAreas);
    setSelfSignUpCampaign(previousState => ({
      ...previousState,
      areaIds: newSelectedAreas.map(area => area.value),
    }));
  };

  const handleAcceptanceFieldChange = (event: ChangeEvent, selfSignUpFieldId: string) => {
    const { value } = event.target as HTMLInputElement;

    setSelfSignUpCampaign(previousState => {
      const selfSignUpCampaignsFieldsAttributes =
        previousState.selfSignUpCampaignsFieldsAttributes || [];

      selfSignUpCampaignsFieldsAttributes.forEach(field => {
        // eslint-disable-next-line no-param-reassign
        if (field.selfSignUpFieldId === selfSignUpFieldId) field.value = value;
      });

      return {
        ...previousState,
        selfSignUpCampaignsFieldsAttributes,
      };
    });
  };

  const goToIndex = () => {
    history.push('/selfSignUpCampaigns');
  };

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      if (selfSignUpCampaign.id) {
        await selfSignUpCampaignsService.update(
          selfSignUpCampaign,
          selfSignUpCampaign.id,
          dispatch,
        );
      } else {
        await selfSignUpCampaignsService.create(selfSignUpCampaign, dispatch);
      }
      goToIndex();
      // eslint-disable-next-line no-empty
    } catch (_) {}

    setIsLoading(false);
  };

  const validMemberGetMemberConfig = () => {
    const { memberGetMemberConfigAttributes } = selfSignUpCampaign;
    if (!memberGetMemberConfigAttributes) return true; // The MGM will be blank for new campaigns

    const { enabled, rewards, rewardPerUsersCount } = memberGetMemberConfigAttributes;
    const isRewardProvided = rewards || rewards === 0;
    const isrewardPerUsersCountProvided = rewardPerUsersCount || rewardPerUsersCount === 0;

    // The MGM config will be valid if disabled OR if all data fields are provided.
    return !enabled || !!(isRewardProvided && isrewardPerUsersCountProvided);
  };

  const isSubmissionEnabled = () => {
    const { name, startDate, endDate, areaIds } = selfSignUpCampaign;

    if (name && startDate && endDate && areaIds && areaIds.length && validMemberGetMemberConfig())
      return true;

    return false;
  };

  const renderSignUpFields = () => {
    const signUpFields = selfSignUpFields.filter(field => field.fieldType !== 'acceptance');

    return signUpFields.map(signUpField => (
      <>
        <Input value={signUpField.label} disabled />
        <Checkbox
          idCheckbox={signUpField.name}
          name={signUpField.name}
          checked={
            !!selfSignUpCampaign.selfSignUpCampaignsFieldsAttributes?.find(
              field =>
                field.selfSignUpFieldId === signUpField.id &&
                (field._destroy ? field._destroy === '0' : true),
            )
          }
          onChange={event => handleRequireField(event.target.checked, signUpField.id)}
          text="Solicitar"
          disabled={signUpField.required}
        />
      </>
    ));
  };

  const renderAcceptanceFields = () => {
    const acceptanceFields = selfSignUpFields.filter(field => field.fieldType === 'acceptance');
    const extractValueFor = (acceptanceField: SelfSignUpFieldInterface) => {
      const foundField = selfSignUpCampaign.selfSignUpCampaignsFieldsAttributes?.find(
        field => field.selfSignUpFieldId === acceptanceField.id,
      );

      return foundField ? foundField.value : undefined;
    };

    return acceptanceFields.map(acceptanceField => (
      <div style={{ marginTop: 8 }}>
        <Input
          name={acceptanceField.name}
          title={acceptanceField.label}
          placeholder="https://..."
          instructions={acceptanceField.instructions}
          value={extractValueFor(acceptanceField)}
          onChange={event => handleAcceptanceFieldChange(event, acceptanceField.id)}
        />
      </div>
    ));
  };

  const renderMemberGetMemberFields = () => {
    const { memberGetMemberConfigAttributes } = selfSignUpCampaign;

    return (
      <>
        <Checkbox
          idCheckbox="enableMGM"
          name="memberGetMemberConfigAttributes.enabled"
          checked={memberGetMemberConfigAttributes?.enabled}
          onChange={handleChangeValue}
          text="Habilitar"
          style={{ marginBottom: 8 }}
        />
        {memberGetMemberConfigAttributes?.enabled && (
          <>
            <Input
              name="memberGetMemberConfigAttributes.rewards"
              title="Moedas"
              instructions="Informe quantas moedas o funcionário ganhará para cada grupo de funcionários cadastrados."
              value={memberGetMemberConfigAttributes.rewards}
              onChange={handleChangeValue}
            />
            <Input
              name="memberGetMemberConfigAttributes.rewardPerUsersCount"
              title="Grupo"
              instructions="Informe quantos funcionários compoem um grupo de premiação."
              value={memberGetMemberConfigAttributes.rewardPerUsersCount}
              onChange={handleChangeValue}
            />
          </>
        )}
      </>
    );
  };

  return (
    <Container onSubmit={handleSubmit}>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Auto cadastro',
            action: goToIndex,
          },
          {
            name: 'Nova campanha',
          },
        ]}
      />
      <BoxInfo>
        <TitleContest>CONFIGURAÇÕES</TitleContest>
        <BoxPadding>
          <BoxFlex style={{ width: '100%' }}>
            <BoxFlex style={{ width: '48%' }}>
              <Input
                title="Título da campanha*"
                name="name"
                placeholder="Digite"
                onChange={handleChangeValue}
                value={selfSignUpCampaign.name}
                style={{ width: '100%' }}
              />
              <BoxFlex style={{ width: '100%' }}>
                <InputDate
                  title="Data de início*"
                  name="startDate"
                  onChange={handleChangeValue}
                  type="datetime-local"
                  value={dateTimeStampToDateMaterialUi(`${selfSignUpCampaign.startDate}`)}
                  InputLabelProps={{ shrink: true }}
                  style={{ flexGrow: 1 }}
                />
                <InputDate
                  title="Data de fim*"
                  name="endDate"
                  onChange={handleChangeValue}
                  type="datetime-local"
                  value={dateTimeStampToDateMaterialUi(`${selfSignUpCampaign.endDate}`)}
                  style={{ flexGrow: 1 }}
                />
              </BoxFlex>
              <BoxFlex style={{ width: '100%' }}>
                <InputDate
                  title="Congelar usuários em"
                  instructions="Os usuários congelados não serão considerados nos relatórios até que façam login novamente."
                  name="freezeUsersAt"
                  onChange={handleChangeValue}
                  type="datetime-local"
                  value={dateTimeStampToDateMaterialUi(`${selfSignUpCampaign.freezeUsersAt}`)}
                  InputLabelProps={{ shrink: true }}
                  style={{ flexGrow: 1 }}
                />
              </BoxFlex>
              <div>
                <TextPlaceHolder>Campos do cadastro*</TextPlaceHolder>
                <p style={{ color: '#929898', fontSize: 11 }}>
                  Escolha quais dados o usuário deverá informar no cadastro. A senha é de
                  preenchimento obrigatório e não pode ser removida do formulário de cadastro.
                </p>
              </div>
              <div style={{ display: 'grid', gridTemplateColumns: '5fr 1fr', columnGap: 32 }}>
                {renderSignUpFields()}
              </div>
            </BoxFlex>
            <BoxFlex style={{ width: '48%' }}>
              <MultipleSelect
                listOptions={areas}
                itensSelected={selectedAreas}
                setItensSelected={handleAreaSelected}
                title="Área(s)*"
              />
              <InputFileImage
                title="Inserir imagem (opcional)"
                subTitle="Você pode inserir imagem em .png .jpeg .gif."
                urlImage={`${selfSignUpCampaign.imageUrl}`}
                setUrlImage={imageUrl => setSelfSignUpCampaign({ ...selfSignUpCampaign, imageUrl })}
                dimensions={{ width: 484, height: 150 }}
                isCrop
              />
            </BoxFlex>
          </BoxFlex>

          <DisplayFlex style={{ marginTop: 24 }}>
            <Title style={{ whiteSpace: 'nowrap' }}>Habilitar Indicação</Title>
            <Line />
          </DisplayFlex>
          <SubTitle>
            Aumente o engajamento dos seus funcionários permitindo que eles possam convidar outros
            colaboradores para a TuTo.
          </SubTitle>
          <div style={{ display: 'grid', width: '100%' }}>{renderMemberGetMemberFields()}</div>

          <DisplayFlex style={{ marginTop: 24 }}>
            <Title style={{ whiteSpace: 'nowrap' }}>Aceites Necessários</Title>
            <Line />
          </DisplayFlex>
          <SubTitle>
            Configure abaixo as URLs que exibem seus termos de uso, política de privacidade e
            regulamento.
          </SubTitle>
          <div style={{ display: 'grid', width: '100%' }}>{renderAcceptanceFields()}</div>
        </BoxPadding>

        <BoxBottom>
          <Button onClick={goToIndex} style={{ width: 120 }} typeButton="primary-white">
            CANCELAR
          </Button>
          <Button
            loading={isLoading}
            disabled={!isSubmissionEnabled()}
            type="submit"
            style={{ width: 120, marginLeft: 16 }}
          >
            CONCLUIR
          </Button>
        </BoxBottom>
      </BoxInfo>
    </Container>
  );
};

export default CreateEditSelfSignUpCampaign;
