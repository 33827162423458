import { useDispatch, useSelector } from 'react-redux';
import { BodyCreateReward } from '../../../shared/modals/reward/bodies';

import { RootStateGlobal } from '../../reducer';
import * as rewardOperations from './rewardOperations';

export const useRewards = () => {
  const dispatch = useDispatch();
  const { rewards, reward, rewardsRequests } = useSelector(
    (state: RootStateGlobal) => state.rewardReducer,
  );

  const getRewards = async () => rewardOperations.getRewards()(dispatch);
  const getReward = async (idReward: string) => rewardOperations.getReward(idReward)(dispatch);
  const insertReward = async (body: BodyCreateReward) =>
    rewardOperations.insertReward(body)(dispatch);
  const editReward = async (body: BodyCreateReward, idReward: string) =>
    rewardOperations.editReward(body, idReward)(dispatch);
  const getRewardsManagers = async () => rewardOperations.getRewardsManagers()(dispatch);
  const deleteReward = async (idReward: string) =>
    rewardOperations.deleteReward(idReward)(dispatch);
  const changeStatusRewardManager = async (idRewardManager: string, status: number) =>
    rewardOperations.changeStatusRewardManager(idRewardManager, status)(dispatch);
  const changeRewardEnable = async (idReward: string) =>
    rewardOperations.changeRewardEnable(idReward)(dispatch);
  const duplicateReward = async (idReward: string) =>
    rewardOperations.duplicateReward(idReward)(dispatch);

  return {
    rewards,
    rewardsRequests,
    reward,
    getRewards,
    deleteReward,
    getRewardsManagers,
    insertReward,
    getReward,
    editReward,
    changeStatusRewardManager,
    changeRewardEnable,
    duplicateReward,
  };
};
