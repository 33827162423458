import React, { ReactNode } from 'react';
import { useHistory } from 'react-router';
import { useGeneral } from '../../../store/reducers/general';
import IconChevronRight from '../../images/icon/iconChevronRight';
import Button from '../buttons/Button';
import ButtonIcon from '../buttons/buttonIcon/ButtonIcon';
import { listOnBoarding } from './listOnBoarding';

import { Container, Box, ContainerFlex, Title, Text, Shadow, BoxSpotlight } from './styles';

type PBoxOnBoarding = {
  children: ReactNode;
  idsShowOnBoarding: number[];
};

export type OnBoarding = {
  id: number;
  arrowDirection: string;
  title: string;
  text: string;
  url: string;
  top?: string;
  left?: string;
  isPadding?: boolean;
  isClickNext?: boolean;
};

const BoxOnBoarding = ({ children, idsShowOnBoarding }: PBoxOnBoarding) => {
  const history = useHistory();
  const {
    currentOnBoarding,
    currentIndexOnBoarding,
    setCurrentOnBoarding,
    setCurrentIndexOnBoarding,
  } = useGeneral();

  if (
    idsShowOnBoarding.filter(
      id => currentOnBoarding && id === currentOnBoarding[currentIndexOnBoarding],
    ).length === 0
  ) {
    return <>{children}</>;
  }

  const onBoarding: OnBoarding | undefined = listOnBoarding.find(
    item => currentOnBoarding && item.id === currentOnBoarding[currentIndexOnBoarding],
  );

  if (!onBoarding || !currentOnBoarding) {
    return <>{children}</>;
  }

  const handleOnClickClose = () => {
    setCurrentOnBoarding();
    setCurrentIndexOnBoarding(0);
  };

  const handleOnClickNext = () => {
    if (currentIndexOnBoarding + 1 < currentOnBoarding.length) {
      setCurrentIndexOnBoarding(currentIndexOnBoarding + 1);
      const newOnBoarding: OnBoarding | undefined = listOnBoarding.find(
        item => currentOnBoarding && item.id === currentOnBoarding[currentIndexOnBoarding + 1],
      );
      if (newOnBoarding) {
        history.push(newOnBoarding.url);
      }
    } else {
      handleOnClickClose();
    }
  };

  const handleOnClickBefore = () => {
    if (currentIndexOnBoarding > 0) {
      setCurrentIndexOnBoarding(currentIndexOnBoarding - 1);
      const newOnBoarding: OnBoarding | undefined = listOnBoarding.find(
        item => currentOnBoarding && item.id === currentOnBoarding[currentIndexOnBoarding - 1],
      );
      if (newOnBoarding) {
        history.push(newOnBoarding.url);
      }
    }
  };

  const handleOnClickBoxSpotlight = () => {
    if (onBoarding.isClickNext) {
      handleOnClickNext();
    }
  };

  return (
    <>
      <Shadow />
      <Container>
        <Box top={onBoarding.top} left={onBoarding.left} arrowDirection={onBoarding.arrowDirection}>
          <ContainerFlex>
            <Title>
              {currentIndexOnBoarding > 0 && (
                <ButtonIcon onClick={handleOnClickBefore}>
                  <IconChevronRight style={{ transform: 'rotate(180deg)', marginRight: 8 }} />
                </ButtonIcon>
              )}
              {onBoarding.title}
            </Title>
            <Title>{`${currentIndexOnBoarding + 1}/${currentOnBoarding.length}`}</Title>
          </ContainerFlex>
          <Text>{onBoarding.text}</Text>
          <ContainerFlex>
            <Button
              onClick={handleOnClickClose}
              typeButton="primary-white"
              style={{ width: '48%' }}
            >
              PULAR
            </Button>
            <Button onClick={handleOnClickNext} style={{ width: '48%' }}>
              PRÓXIMO
            </Button>
          </ContainerFlex>
        </Box>
      </Container>
      <BoxSpotlight onClick={handleOnClickBoxSpotlight} isPadding={onBoarding.isPadding}>
        {children}
      </BoxSpotlight>
    </>
  );
};

export default BoxOnBoarding;
