import React, { useState } from 'react';
import moment from 'moment';

import IconChevronRight from '../../images/icon/iconChevronRight';
import ButtonIcon from '../buttons/buttonIcon/ButtonIcon';
import { colors } from '../../functions/colors';
import { Container, ContainerFlex, Title, HeaderDays, TextDays } from './styles';
import 'moment/locale/pt-br';

const HEADER_DAYS = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];

type ListDays = {
  date: string;
  backgroundColor?: string;
  borderColor?: string;
};

type PCalendar = {
  listDays?: ListDays[];
  selectDate?: (date: string) => void;
  monthStart?: number;
};

const Calendar = ({ listDays, selectDate, monthStart }: PCalendar) => {
  const [currentMoment, setCurrentMoment] = useState(
    monthStart !== undefined ? moment(`1/${monthStart}`, 'DD/MM') : moment('1', 'DD'),
  );

  const handleChangeMonth = (insertMonth: number) => {
    setCurrentMoment(moment(currentMoment).subtract(insertMonth, 'month'));
  };

  const handleOnClickDay = (currentDay: string) => {
    if (selectDate) {
      selectDate(currentDay);
    }
  };

  const daysOfWeek = (indexMonth: number) => {
    let currentDay = 1 + (indexMonth * 7 - (indexMonth === 0 ? 0 : currentMoment.day()));

    const listReturn = [];
    for (let i = 0; i < 7; i += 1) {
      if (
        (i < currentMoment.day() && indexMonth === 0) ||
        currentDay > currentMoment.daysInMonth()
      ) {
        listReturn.push(<TextDays />);
      } else {
        let colorMatch;
        let borderColorMatch;
        const currentDayMoment = moment(currentMoment).add(currentDay - 1, 'days');
        if (listDays?.length) {
          listDays.forEach(day => {
            const momentDay = moment(day.date);
            if (momentDay.format('MMM Do YY') === currentDayMoment.format('MMM Do YY')) {
              colorMatch = day.backgroundColor ? day.backgroundColor : colors.primary;
              borderColorMatch = day.borderColor;
            }
          });
        }
        listReturn.push(
          <TextDays
            isFunction={selectDate !== undefined}
            backgroundColor={colorMatch}
            borderColor={borderColorMatch}
            onClick={() => handleOnClickDay(currentDayMoment.format('YYYY-MM-DD'))}
          >
            {currentDay}
          </TextDays>,
        );
        currentDay += 1;
      }
    }
    return listReturn;
  };

  const renderWeeks = () => {
    const returnWeeks = [];
    for (let i = 0; i < 6; i += 1) {
      returnWeeks.push(<ContainerFlex>{daysOfWeek(i)}</ContainerFlex>);
    }
    return returnWeeks;
  };

  return (
    <Container>
      <ContainerFlex style={{ margin: 0 }}>
        <ButtonIcon onClick={() => handleChangeMonth(1)}>
          <IconChevronRight style={{ transform: 'rotate(180deg)' }} />
        </ButtonIcon>
        <Title>{`${currentMoment.format('MMMM')} ${currentMoment.format('YYYY')}`}</Title>
        <ButtonIcon onClick={() => handleChangeMonth(-1)}>
          <IconChevronRight />
        </ButtonIcon>
      </ContainerFlex>
      <ContainerFlex>
        {HEADER_DAYS.map(day => (
          <HeaderDays>{day}</HeaderDays>
        ))}
      </ContainerFlex>
      {renderWeeks()}
    </Container>
  );
};

Calendar.defaultProps = {
  listDays: [],
  selectDate: undefined,
  monthStart: undefined,
};

export default Calendar;
