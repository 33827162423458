import React, { useState, Dispatch, SetStateAction } from 'react';

import { useRewards } from '../../../store/reducers/reward';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Reward } from '../../../shared/modals/reward/reward';

type PDuplicateReward = {
  openDuplicateReward: boolean;
  setOpenDuplicateReward: Dispatch<SetStateAction<boolean>>;
  reward: Reward | undefined;
};

const DuplicateReward = ({
  openDuplicateReward,
  setOpenDuplicateReward,
  reward,
}: PDuplicateReward) => {
  const [loading, setLoading] = useState(false);
  const { duplicateReward } = useRewards();

  if (!reward) return null;

  const handleDuplicateReward = async () => {
    setLoading(true);
    await duplicateReward(reward.idAward);
    setLoading(false);
    setOpenDuplicateReward(false);
  };

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title="Duplicar prêmio"
      message="Tem certeza que deseja duplicar esse prêmio?"
      onClose={() => setOpenDuplicateReward(false)}
      open={openDuplicateReward}
      onClickPrimary={handleDuplicateReward}
      onClickSecondary={() => setOpenDuplicateReward(false)}
    />
  );
};

export default DuplicateReward;
