import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import Button from '../../shared/components/buttons/Button';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import IconEdit from '../../shared/images/icon/iconEdit';
import IconTrash from '../../shared/images/icon/iconTrash';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import Table from '../../shared/components/table/Table';
import { colors } from '../../shared/functions/colors';
import { filterArraySearch } from '../../shared/functions/utils';
import IconCheck from '../../shared/images/icon/iconCheck';
import IconBlock from '../../shared/images/icon/iconBlock';
import IconDuplicate from '../../shared/images/icon/iconDuplicate';
import {
  ContainerMain,
  DisplayFlexAlignCenter,
  HeaderMain,
  TextHeaderMain,
} from '../../shared/components/styled/general';
import DeleteJourney from './delete/DeleteJourney';
import DuplicateJourney from './duplicate/DuplicateJourney';
import * as journeysService from '../../shared/services/journeys';
import ToggleJourneyEnabled from './toggleEnabled/ToggleJourneyEnabled';
import IconChevronBottom from '../../shared/images/icon/iconChevronBottom';
import { setLoadingBlockScreen } from '../../store/reducers/general/generalActions';
import SearchInput from '../../shared/components/input/searchInput/SearchInput';
import { useGeneral } from '../../store/reducers/general';

export type JourneyInterface = {
  id: string;
  name: string;
  image: string;
  enabled: boolean;
};

const Journeys = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { filterInput } = useGeneral();
  const [journeys, setJourneys] = useState<JourneyInterface[]>([]);
  const [journey, setJourney] = useState<JourneyInterface>();
  const [searchJourney, setSearchJourney] = useState('');
  const [journeysFilter, setJourneysFilter] = useState<JourneyInterface[]>([]);
  const [isDeleteModalEnabled, setIsDeleteModalEnabled] = useState(false);
  const [isDuplicateModalEnabled, setIsDuplicateModalEnabled] = useState(false);
  const [isToggleModalEnabled, setIsToggleModalEnabled] = useState(false);

  const refreshList = () => {
    journeysService.getAll(dispatch).then(jrns => {
      setJourneys(jrns);
      setJourneysFilter(jrns);
    });
    if (filterInput !== '') {
      setSearchJourney(filterInput);
    }
  };

  useEffect(refreshList, []);

  useEffect(() => {
    setJourneysFilter(filterArraySearch(journeys, 'name', filterInput));
  }, [journeys]);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchJourney(event.target.value);
    setJourneysFilter(filterArraySearch(journeys, 'name', event.target.value));
  };

  const handleDelete = (jrn: JourneyInterface) => {
    setIsDeleteModalEnabled(true);
    setJourney(jrn);
  };

  const handleDuplicate = (jrn: JourneyInterface) => {
    setIsDuplicateModalEnabled(true);
    setJourney(jrn);
  };

  const handleToggle = (jrn: JourneyInterface) => {
    setIsToggleModalEnabled(true);
    setJourney(jrn);
  };

  const handleCloseModal = () => {
    setIsDeleteModalEnabled(false);
    setIsDuplicateModalEnabled(false);
    setIsToggleModalEnabled(false);

    setJourney(undefined);
  };

  const goToNew = () => {
    history.push('/journeys/new');
  };

  const goToEdit = (jrn: JourneyInterface) => {
    history.push(`/journeys/${jrn.id}/edit`);
  };

  const handleOnClickChangePosition = async (journey: JourneyInterface, upPosition: boolean) => {
    setLoadingBlockScreen(true);
    await journeysService.update({ upPosition }, journey.id, dispatch);
    refreshList();
    setLoadingBlockScreen(false);
  };

  const isLast = (index: number) => index === journeysFilter.length - 1;

  const renderRowsTable = () => {
    return journeysFilter.map((jrn, index) => ({
      columns: [
        jrn.name,
        <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleToggle(jrn)}>
          {jrn.enabled ? <IconCheck color={colors.green} /> : <IconBlock color={colors.red} />}
        </ButtonIcon>,
        <>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => goToEdit(jrn)}>
            <IconEdit />
          </ButtonIcon>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleDuplicate(jrn)}>
            <IconDuplicate />
          </ButtonIcon>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleDelete(jrn)}>
            <IconTrash />
          </ButtonIcon>
          <ButtonIcon onClick={() => index && handleOnClickChangePosition(jrn, false)}>
            <IconChevronBottom
              style={{ transform: 'rotate(180deg)' }}
              color={index === 0 ? colors.white : colors.grey100}
            />
          </ButtonIcon>
          <ButtonIcon onClick={() => !isLast(index) && handleOnClickChangePosition(jrn, true)}>
            <IconChevronBottom color={isLast(index) ? colors.white : colors.grey100} />
          </ButtonIcon>
        </>,
      ],
    }));
  };

  return (
    <ContainerMain>
      {journey && (
        <>
          <DeleteJourney
            id={journey.id}
            open={isDeleteModalEnabled}
            handleCloseModal={handleCloseModal}
            refreshList={refreshList}
          />
          <DuplicateJourney
            id={journey.id}
            open={isDuplicateModalEnabled}
            handleCloseModal={handleCloseModal}
            refreshList={refreshList}
          />
          <ToggleJourneyEnabled
            journey={journey}
            open={isToggleModalEnabled}
            handleCloseModal={handleCloseModal}
            refreshList={refreshList}
          />
        </>
      )}
      <BreadCrumbs
        listMenus={[
          {
            name: 'Jornada',
          },
        ]}
      />
      <HeaderMain>
        <TextHeaderMain>
          Você pode criar, excluir e editar as jornadas conforme a necessidade da empresa.
        </TextHeaderMain>
        <DisplayFlexAlignCenter>
          <SearchInput
            placeholder="Buscar jornada..."
            search={searchJourney}
            onChange={handleSearch}
          />
          <Button style={{ width: 160 }} onClick={goToNew} icon="plus">
            NOVA JORNADA
          </Button>
        </DisplayFlexAlignCenter>
      </HeaderMain>
      <Table
        nameReferenceReduxTable="table_journey"
        listRowsPerPage={[10, 25, 50, 100]}
        titles={[
          {
            name: 'Jornada',
            openClick: true,
            type: 'string',
          },
          {
            name: 'Status ativar/desativar',
            openClick: false,
            type: 'ReactNode',
          },
          {
            name: 'Ações',
            openClick: false,
            type: 'ReactNode',
            widthTd: '20%',
          },
        ]}
        rows={renderRowsTable()}
      />
    </ContainerMain>
  );
};

export default Journeys;
