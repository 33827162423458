import React, { useState, Dispatch, SetStateAction } from 'react';

import { useQuestion } from '../../../store/reducers/question';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Question } from '../../../shared/modals/question/question';

type PDuplicateQuestion = {
  openDuplicateQuestion: boolean;
  setOpenDuplicateQuestion: Dispatch<SetStateAction<boolean>>;
  question: Question | undefined;
  idLibrary?: string;
};

const DuplicateQuestion = ({
  openDuplicateQuestion,
  setOpenDuplicateQuestion,
  question,
  idLibrary,
}: PDuplicateQuestion) => {
  const [loading, setLoading] = useState(false);
  const { duplicateQuestion, questionPagination } = useQuestion();

  if (!question) return null;

  const handleDuplicateQuestion = async () => {
    setLoading(true);
    await duplicateQuestion(question.idQuestion, questionPagination);
    setLoading(false);
    setOpenDuplicateQuestion(false);
  };

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title="Duplicar pergunta"
      message="Tem certeza que deseja duplicar a pergunta?"
      onClose={() => setOpenDuplicateQuestion(false)}
      open={openDuplicateQuestion}
      onClickPrimary={handleDuplicateQuestion}
      onClickSecondary={() => setOpenDuplicateQuestion(false)}
    />
  );
};

DuplicateQuestion.defaultProps = {
  idLibrary: undefined,
};

export default DuplicateQuestion;
