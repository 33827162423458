import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, BoxInfo, BoxPadding, BoxFlex, BoxBottom } from './styles';
import InputDate from '../../../shared/components/input/inputDateTime/InputDateTime';
import MultipleSelect from '../../../shared/components/select/multipleSelect/MultipleSelect';
import BreadCrumbs from '../../../shared/components/breadCrumbs/BreadCrumbs';
import Input from '../../../shared/components/input/Input';
import Button from '../../../shared/components/buttons/Button';
import { TitleContest } from '../../../shared/components/styled/text';
import {
  get as getMission,
  create as createMission,
  update as updateMission,
} from '../../../shared/services/missions';
import InputFileImage from '../../../shared/components/input/inputFileImage/InputFile';
import { getAll as getAllAreas } from '../../../shared/services/areas';
import { POptions } from '../../../shared/components/select/Select';
import { dateTimeStampToDateMaterialUi } from '../../../shared/functions/date';
import { useUser } from '../../../store/reducers/user';

export type IMission = {
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  image: string;
  areaIds: string[];
};

export type MissionState = {
  id: string | undefined;
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  image: string;
  loading: boolean;
  areas: POptions[];
  selectedAreas: POptions[];
};

const CreateEditMission = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { userData } = useUser();
  const [mission, setMission] = useState<MissionState>({
    id: undefined,
    name: '',
    startDate: '',
    endDate: '',
    description: '',
    image: '',
    loading: false,
    areas: [],
    selectedAreas: [],
  });
  const [loading, setLoading] = useState(false);

  const fetchFormattedAreas = async (): Promise<POptions[]> => {
    const areas = await getAllAreas();
    const formattedAreas = areas.map(area => ({
      value: area.idArea.toString(),
      viewValue: area.name,
    }));
    return formattedAreas;
  };

  const fetchMission = async (formattedAreas: POptions[]) => {
    const { id } = params as { id: string };
    let missionState = {};

    if (id) {
      const mission = await getMission(id, dispatch);
      if (mission) {
        const { name, startDate, endDate, description, image, areaIds } = mission;

        missionState = {
          id,
          name,
          image,
          description,
          startDate: dateTimeStampToDateMaterialUi(startDate),
          endDate: dateTimeStampToDateMaterialUi(endDate),
          selectedAreas: formattedAreas.filter(area =>
            areaIds.map(areaId => areaId.toString()).includes(area.value),
          ),
        };
      }
    }

    return missionState;
  };

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setMission(prevState => ({ ...prevState, [name]: value }));
  };

  const handleAreaSelected = (selectedAreas: POptions[]) => {
    setMission(prevState => ({ ...prevState, selectedAreas }));
  };

  const goToMissionsList = () => {
    history.push('/missions');
  };

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setMission(prevState => ({ ...prevState, loading: true }));

    const { id, name, startDate, endDate, description, image, selectedAreas } = mission;
    const areaIds = selectedAreas.map(area => area.value);
    const body: IMission = {
      name,
      startDate,
      endDate,
      description,
      image,
      areaIds,
    };

    try {
      if (id) {
        await updateMission(body, id, dispatch);
      } else {
        await createMission(body, dispatch);
      }
      setLoading(false);
      goToMissionsList();
    } catch (_) {
      setLoading(false);
    }
  };

  const isSubmissionDisabled = () => {
    const { name, startDate, endDate, selectedAreas } = mission;

    return !(name && startDate && endDate && selectedAreas.length);
  };

  useEffect(() => {
    async function fetchData() {
      const formattedAreas = await fetchFormattedAreas();
      const missionState = await fetchMission(formattedAreas);
      setMission(prevState => ({ ...prevState, areas: formattedAreas, ...missionState }));
    }
    fetchData();
  }, []);

  if (!userData) return null;

  return (
    <Container onSubmit={handleSubmit}>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Missões',
            action: goToMissionsList,
          },
          {
            name: 'Nova missão',
          },
        ]}
      />
      <BoxInfo>
        <TitleContest>CONFIGURAÇÕES</TitleContest>
        <BoxPadding>
          <BoxFlex style={{ width: '100%' }}>
            <BoxFlex style={{ width: '48%' }}>
              <Input
                title="Nome da missão*"
                name="name"
                placeholder="Digite"
                onChange={handleChangeValue}
                value={mission.name}
                style={{ width: '100%' }}
              />
              <BoxFlex style={{ width: '100%' }}>
                <InputDate
                  title="Data e hora de início*"
                  name="startDate"
                  onChange={handleChangeValue}
                  type="datetime-local"
                  value={mission.startDate}
                  InputLabelProps={{ shrink: true }}
                  style={{ flexGrow: 1 }}
                />
                <InputDate
                  title="Data e hora de fim*"
                  name="endDate"
                  onChange={handleChangeValue}
                  type="datetime-local"
                  value={mission.endDate}
                  style={{ flexGrow: 1 }}
                />
              </BoxFlex>
              <Input
                title="Descrição (opcional)"
                name="description"
                placeholder="Descrição (opcional)"
                onChange={handleChangeValue}
                value={mission.description}
                style={{ width: '100%' }}
                maxLength={150}
                showLenghtMax
              />
            </BoxFlex>
            <BoxFlex style={{ width: '48%' }}>
              <InputFileImage
                title="Inserir imagem (opcional)"
                subTitle="Você pode inserir imagem em .png .jpeg .gif."
                urlImage={mission.image}
                setUrlImage={img => setMission({ ...mission, image: img })}
                style={{ width: 600 }}
                dimensions={{ width: 97, height: 97 }}
                isCrop
              />
            </BoxFlex>
          </BoxFlex>
        </BoxPadding>
        <TitleContest>DETALHAMENTOS</TitleContest>
        <BoxPadding>
          <BoxFlex style={{ marginBottom: 16 }}>
            <MultipleSelect
              listOptions={mission.areas}
              itensSelected={mission.selectedAreas}
              setItensSelected={handleAreaSelected}
              title="Área(s)*"
            />
          </BoxFlex>
        </BoxPadding>
        <BoxBottom>
          <Button onClick={goToMissionsList} style={{ width: 120 }} typeButton="primary-white">
            CANCELAR
          </Button>
          {!userData.company.migrated && (
            <Button
              loading={loading}
              disabled={isSubmissionDisabled()}
              type="submit"
              style={{ width: 120, marginLeft: 16 }}
            >
              CONCLUIR
            </Button>
          )}
        </BoxBottom>
      </BoxInfo>
    </Container>
  );
};

// export default withRouter(connect(null, { showAlertMessage: setSnackbar })(CreateEditMission));
export default CreateEditMission;
