import React, { Component, ChangeEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  ContainerMain,
  DisplayFlexAlignCenter,
  HeaderMain,
  TextHeaderMain,
} from '../../shared/components/styled/general';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import Input from '../../shared/components/input/Input';
import { colors } from '../../shared/functions/colors';
import Button from '../../shared/components/buttons/Button';
import { filterArraySearch } from '../../shared/functions/utils';
import Table from '../../shared/components/table/Table';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import IconEdit from '../../shared/images/icon/iconEdit';
import IconTrash from '../../shared/images/icon/iconTrash';
import IconDuplicate from '../../shared/images/icon/iconDuplicate';
import IconChevronBottom from '../../shared/images/icon/iconChevronBottom';
import * as challengesService from '../../shared/services/challenges';
import DeleteChallenge from './delete/DeleteChallenge';
import DuplicateChallenge from './duplicate/DuplicateChallenge';
import { Presentation as PresentationObj } from '../../shared/modals/trail/trail';
import {
  setLoadingBlockScreen,
  SnackBarActionInterface,
} from '../../store/reducers/general/generalActions';

// TODO: prefix all interfaces with "I"
// See: https://github.com/typescript-eslint/typescript-eslint/blob/v1.13.0/packages/eslint-plugin/docs/rules/interface-name-prefix.md
export type ChallengeInterface = {
  id: string;
  title: string;
  manuallyApproved: boolean;
  prize: number | undefined;
  entryLimit: number | undefined;
  instructions: string;
  mission: { id: string; name: string };
  mechanics: { id: number; name: string };
  question: {
    id: string;
    text: string;
    alternatives: { id: string; trueQuestion: boolean; text: string }[];
  };
  presentations: PresentationObj[];
  duration: number | undefined;
};

type ChallengeState = {
  filter: string;
  isDeleteModalEnabled: boolean;
  isDuplicateModalEnabled: boolean;
  challengeId: string | undefined;
  missionId: string;
  challenges: ChallengeInterface[];
  filtered: ChallengeInterface[];
};

type DispatchProps = {
  setLoading: (x: boolean) => void;
  dispatch: (arg: SnackBarActionInterface) => void;
};

type PropsType = RouteComponentProps & DispatchProps;

class Challenge extends Component<PropsType, ChallengeState> {
  constructor(props: PropsType) {
    super(props);

    const {
      match: { params },
    } = this.props;
    const { missionId } = params as Record<string, string>;

    this.state = {
      filter: '',
      isDeleteModalEnabled: false,
      isDuplicateModalEnabled: false,
      challengeId: undefined,
      missionId,
      challenges: [],
      filtered: [],
    };
  }

  componentDidMount() {
    this.refreshList();
  }

  refreshList = () => {
    const { dispatch } = this.props;
    const { missionId } = this.state;
    const promise = missionId
      ? challengesService.getAllByMission(missionId, dispatch)
      : challengesService.getAll(dispatch);

    promise.then(challenges => {
      this.setState({ challenges, filtered: challenges });
    });
  };

  handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { challenges } = this.state;
    const filteredChallenges = filterArraySearch(challenges, 'title', event.target.value);

    this.setState({
      filter: event.target.value,
      filtered: filteredChallenges,
    });
  };

  handleDelete = (challenge: ChallengeInterface) => {
    this.setState({ isDeleteModalEnabled: true, challengeId: challenge.id });
  };

  handleDuplicate = (challenge: ChallengeInterface) => {
    this.setState({ isDuplicateModalEnabled: true, challengeId: challenge.id });
  };

  handleCloseModal = () => {
    this.setState({
      isDeleteModalEnabled: false,
      isDuplicateModalEnabled: false,
      challengeId: undefined,
    });
  };

  handleChangePosition = async (challenge: ChallengeInterface, upPosition: boolean) => {
    const { setLoading, dispatch } = this.props;

    setLoading(true);
    await challengesService.update({ upPosition }, challenge.id, dispatch);
    this.refreshList();
    setLoading(false);
  };

  goToNew = () => {
    const { history } = this.props;
    const { missionId } = this.state;
    history.push(`/missions/${missionId}/challenges/new`);
  };

  goToEdit = (challenge: ChallengeInterface) => {
    const { history } = this.props;
    const { missionId } = this.state;
    history.push(`/missions/${missionId}/challenges/${challenge.id}/edit`);
  };

  goToMissionsList = () => {
    const { history } = this.props;
    history.push(`/missions`);
  };

  renderRowsTable = () => {
    const { challenges, filtered, missionId } = this.state;

    return filtered.map((challenge, index) => ({
      columns: [
        challenge.mission.name,
        challenge.title,
        challenge.mechanics.name,
        missionId && (
          <>
            <ButtonIcon style={{ marginRight: 16 }} onClick={() => this.goToEdit(challenge)}>
              <IconEdit />
            </ButtonIcon>
            <ButtonIcon style={{ marginRight: 16 }} onClick={() => this.handleDuplicate(challenge)}>
              <IconDuplicate />
            </ButtonIcon>
            <ButtonIcon style={{ marginRight: 16 }} onClick={() => this.handleDelete(challenge)}>
              <IconTrash />
            </ButtonIcon>
            {index !== 0 && (
              <ButtonIcon onClick={() => this.handleChangePosition(challenge, false)}>
                <IconChevronBottom style={{ transform: 'rotate(180deg)' }} />
              </ButtonIcon>
            )}
            {index !== challenges.length - 1 && (
              <ButtonIcon onClick={() => this.handleChangePosition(challenge, true)}>
                <IconChevronBottom />
              </ButtonIcon>
            )}
          </>
        ),
      ],
    }));
  };

  render() {
    const {
      filter,
      isDeleteModalEnabled,
      isDuplicateModalEnabled,
      challengeId,
      missionId,
    } = this.state;

    return (
      <ContainerMain>
        <DeleteChallenge
          id={challengeId}
          open={isDeleteModalEnabled}
          handleCloseModal={this.handleCloseModal}
          refreshList={this.refreshList}
        />
        <DuplicateChallenge
          id={challengeId}
          open={isDuplicateModalEnabled}
          handleCloseModal={this.handleCloseModal}
          refreshList={this.refreshList}
        />
        <BreadCrumbs
          listMenus={[
            {
              name: 'Missões',
              action: this.goToMissionsList,
            },
            {
              name: 'Desafios',
            },
          ]}
        />
        <HeaderMain>
          <TextHeaderMain>
            Você pode criar, excluir ou editar os desafios cadastrados.
          </TextHeaderMain>
          <DisplayFlexAlignCenter>
            <Input
              style={{ marginRight: 16, width: 260 }}
              backgroundColor={colors.grey40}
              showBorder={false}
              icon="search"
              placeholder="Buscar desafio..."
              value={filter}
              onChange={this.handleSearch}
            />
            {missionId && (
              <Button style={{ width: 160 }} onClick={this.goToNew} icon="plus">
                NOVO DESAFIO
              </Button>
            )}
          </DisplayFlexAlignCenter>
        </HeaderMain>
        <Table
          nameReferenceReduxTable="table_challenge"
          listRowsPerPage={[10, 25, 50, 100]}
          titles={[
            {
              name: 'Missão',
              openClick: true,
              type: 'string',
            },
            {
              name: 'Nome do desafio',
              openClick: true,
              type: 'string',
            },
            {
              name: 'Tipo',
              openClick: true,
              type: 'string',
            },
            {
              name: missionId ? 'Ações' : '',
              openClick: false,
              type: 'ReactNode',
              widthTd: missionId ? '20%' : '',
            },
          ]}
          rows={this.renderRowsTable()}
        />
      </ContainerMain>
    );
  }
}

const mapDispatchToProps = (dispatch: (arg: SnackBarActionInterface) => void) => {
  return {
    dispatch,
    setLoading: setLoadingBlockScreen,
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Challenge));
