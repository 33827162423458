import { Reducer } from 'redux';

import { RewardTypes, RewardState } from './rewardTypes';

const INITIAL_STATE: RewardState = {
  reward: undefined,
  rewards: [],
  rewardsRequests: [],
};

const reducer: Reducer<RewardState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RewardTypes.SET_REWARDS:
      return {
        ...state,
        rewards: action.payload,
      };
    case RewardTypes.SET_REWARDS_REQUESTS:
      return {
        ...state,
        rewardsRequests: action.payload,
      };
    case RewardTypes.SET_REWARD:
      return {
        ...state,
        reward: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
