import styled from 'styled-components';
import { colors } from '../../shared/functions/colors';
import { fonts } from '../../shared/functions/fonts';

export const Container = styled.div`
  width: 100%;
  padding: 16px 40px;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 24px 0px;
  justify-content: space-between;
`;

export const TextHeader = styled.p`
  color: ${colors.black};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

export const BoxInfos = styled.div`
  width: 100%;
  background-color: ${colors.white};
  padding: 24px;
`;

export const ButtonsFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const BoxGraphic = styled.div`
  width: 48%;
  border-radius: 4px;
  border: 1px solid ${colors.grey80};
  padding: 16px;
  margin: 16px 0px;
  height: auto;
`;

export const BoxAllGraphics = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const BoxRanking = styled.div`
  border-radius: 4px;
  border: 1px solid ${colors.grey80};
  margin-top: 24px;
`;
