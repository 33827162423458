import React, { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import CheckboxAccordion, {
  PListAccordion,
} from '../../../shared/components/input/checkboxAccordion/CheckboxAccordion';
import { colors } from '../../../shared/functions/colors';
import Input from '../../../shared/components/input/Input';
import { filterArraySearchInsertShow } from '../../../shared/functions/utils';
import FilterModal from '../../../shared/components/modal/filterModal/FilterModal';
import { BoxAccordions } from '../../../shared/components/styled/box';
import { useQuestion } from '../../../store/reducers/question';
import { useContest } from '../../../store/reducers/contest';
import InputDate from '../../../shared/components/input/inputDate/InputDate';
import { DisplayFlexJustifyContent } from '../../../shared/components/styled/general';
import { useArea } from '../../../store/reducers/area';

type PFilter = {
  openFilter: boolean;
  setOpenFilter: (x: boolean) => void;
};

export type ClickFilterHandle = {
  onClickFilter: () => void;
};

const FilterContest = forwardRef(
  ({ openFilter, setOpenFilter }: PFilter, ref: React.Ref<ClickFilterHandle | undefined>) => {
    const { areas, getAllAreas } = useArea();
    const { categories, getCategories } = useQuestion();
    const { getContests } = useContest();
    const [textSearch, setTextSearch] = useState('');
    const [changeSearch, setChangeSearch] = useState<undefined | boolean>();
    const [loading, setLoading] = useState(false);
    const [clear, setClear] = useState(false);
    const [listAccordionArea, setListAccordionArea] = useState<PListAccordion[]>([]);
    const [listAccordionCategory, setListAccordionCategory] = useState<PListAccordion[]>([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [enabled, setEnabled] = useState<boolean | undefined>();

    const convertAreasToAccordion = (): PListAccordion[] => {
      if (!areas || areas.length <= 0) {
        return [];
      }
      return filterArraySearchInsertShow(
        areas.map(area => ({
          id: area.idArea,
          label: area.name,
          selected: false,
        })),
        'label',
        textSearch,
      );
    };

    const convertCategoriesToAccordion = (): PListAccordion[] => {
      if (!categories || categories.length <= 0) {
        return [];
      }
      return filterArraySearchInsertShow(
        categories.map(category => ({
          id: category.idCategory,
          label: category.name,
          selected: false,
        })),
        'label',
        textSearch,
      );
    };

    useEffect(() => {
      setListAccordionArea(convertAreasToAccordion());
      setListAccordionCategory(convertCategoriesToAccordion());

      setEnabled(undefined);
      setStartDate('');
      setEndDate('');
    }, [areas, clear, categories]);

    useEffect(() => {
      getCategories();
      getAllAreas();
    }, []);

    const handleOnClickFilter = async () => {
      setLoading(true);
      const idsArea = listAccordionArea
        .filter(accordion => accordion.selected)
        .map(accordion => accordion.id);
      const idsCategory = listAccordionCategory
        .filter(accordion => accordion.selected)
        .map(accordion => accordion.id);
      await getContests(
        startDate,
        endDate,
        idsArea.length > 0 ? idsArea : undefined,
        idsCategory.length > 0 ? idsCategory : undefined,
        enabled,
      );
      setLoading(false);
      setOpenFilter(false);
    };

    const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
      const valueSearch = event.target.value;
      setTextSearch(valueSearch);
      setListAccordionArea([
        ...filterArraySearchInsertShow(listAccordionArea, 'label', valueSearch),
      ]);
      setChangeSearch(!changeSearch);
    };

    useImperativeHandle(ref, () => ({
      async onClickFilter() {
        await handleOnClickFilter();
      },
    }));

    const handleOnChangeStart = (e: string) => {
      setStartDate(e);
    };

    const handleOnChangeEnd = (e: string) => {
      setEndDate(e);
    };

    return (
      <FilterModal
        title="Filtrar por"
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        loading={loading}
        onClickClear={() => setClear(!clear)}
        onClickFilter={handleOnClickFilter}
      >
        <Input
          style={{ padding: 24 }}
          backgroundColor={colors.grey40}
          showBorder={false}
          icon="search"
          placeholder="Buscar"
          value={textSearch}
          onChange={handleChangeSearch}
        />
        <DisplayFlexJustifyContent style={{ padding: 24 }}>
          <InputDate
            title="Data de início"
            onChangeDate={handleOnChangeStart}
            value={startDate}
            style={{ marginBottom: -8 }}
          />
          <InputDate
            style={{ marginLeft: 24, marginBottom: -8 }}
            title="Data de fim"
            onChangeDate={handleOnChangeEnd}
            value={endDate}
            placement="right"
          />
        </DisplayFlexJustifyContent>
        <BoxAccordions>
          <CheckboxAccordion
            title="Por categoria"
            idCheckbox="por_categoria"
            listAccordion={listAccordionCategory}
            setListAccordion={setListAccordionCategory}
            changeSearch={changeSearch}
          />
          <CheckboxAccordion
            title="Por área"
            idCheckbox="por_area"
            listAccordion={listAccordionArea}
            setListAccordion={setListAccordionArea}
            changeSearch={changeSearch}
          />
        </BoxAccordions>
      </FilterModal>
    );
  },
);

export default FilterContest;
