import styled from 'styled-components';
import { fonts } from '../../functions/fonts';
import { colors } from '../../functions/colors';

type PTitleSemiBold = {
  color?: string;
};

export const TitleContest = styled.h1`
  width: 100%;
  height: 64px;
  border-bottom: 4px solid ${colors.pinkDark};
  padding: 24px;

  font-family: ${fonts.bold};
  color: ${colors.pinkDark};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

export const TitleBold = styled.h1<PTitleSemiBold>`
  font-family: ${fonts.bold};
  color: ${props => (props.color ? props.color : colors.pinkDark)};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
`;

export const TitleSemiBold = styled.h2<PTitleSemiBold>`
  font-family: ${fonts.semiBold};
  color: ${props => (props.color ? props.color : colors.pinkDark)};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

export const DefaultText = styled.p`
  color: ${colors.black};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

export const TextSmall = styled.p`
  color: ${colors.black};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
`;

export const TextButton = styled.h3`
  cursor: pointer;
  color: ${colors.black};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: underline;
`;

export const TextDefault = styled.p<PTitleSemiBold>`
  color: ${props => (props.color ? props.color : colors.black)};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  strong {
    font-family: ${fonts.bold};
  }
`;

export const TextLarge = styled.p<PTitleSemiBold>`
  color: ${props => (props.color ? props.color : colors.black)};
  font-family: ${fonts.bold};
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;

  strong {
    font-family: ${fonts.bold};
  }
`;
