import * as React from 'react';
import { colors } from '../../functions/colors';

type PIconDuplicate = {
  width?: number;
  height?: number;
  color?: string;
};

const IconDuplicate = ({ width, height, color }: PIconDuplicate) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="prefix__IconDuplicate"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x={2}
      y={1}
      width={19}
      height={22}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM8 21H19V7H8V21Z"
        fill="white"
      />
    </mask>
    <g mask="url(#prefix__IconDuplicate)">
      <rect width="24" height="24" fill={color} />
    </g>
  </svg>
);

IconDuplicate.defaultProps = {
  width: 24,
  height: 24,
  color: colors.grey100,
};

export default IconDuplicate;
