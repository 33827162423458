import styled from 'styled-components';
import { colors } from '../../functions/colors';
import { fonts } from '../../functions/fonts';

type PBox = {
  top?: string;
  left?: string;
  arrowDirection: string;
};

type PBoxSpotlight = {
  isPadding?: boolean;
};

export const Container = styled.div`
  position: relative;
`;

export const Shadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.background};
  opacity: 0.7;
  z-index: 10;
`;

export const Box = styled.div<PBox>`
  z-index: 11;
  position: absolute;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  background-color: ${colors.white};
  padding: 24px;
  width: 480px;
  ${props => (props.top ? `top: ${props.top};` : '')}
  ${props => (props.left ? `left: ${props.left};` : '')}


  :before {
    content: ' ';
    height: 32px;
    position: absolute;
    width: 32px;
    left: ${props =>
      props.arrowDirection === 'left'
        ? '-12px'
        : `${
            props.arrowDirection === 'topLeft' || props.arrowDirection === 'bottomLeft'
              ? '48px'
              : '462px'
          }`};
    top: ${props =>
      props.arrowDirection === 'topLeft'
        ? '-14px'
        : `${props.arrowDirection === 'bottomLeft' ? 'auto' : '20px'}`};
    ${props => (props.arrowDirection === 'bottomLeft' ? 'bottom: -14px;' : '')}
    background-color: ${colors.white};
    transform: rotate(45deg);
    border-radius: 4px;
  }
`;

export const ContainerFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h1`
  color: ${colors.pinkDark};
  font-family: ${fonts.bold};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 16px;
  align-items: center;
  display: flex;
`;

export const Text = styled.p`
  text-align: justify;
  white-space: break-spaces;
  color: ${colors.black};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 16px;

  strong {
    font-family: ${fonts.bold};
  }
`;

export const BoxSpotlight = styled.div<PBoxSpotlight>`
  z-index: 12;
  background-color: ${colors.white};
  position: sticky;
  padding: ${props => (props.isPadding ? '16px' : '0px')};
`;
