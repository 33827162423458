import React, { useState, Dispatch, SetStateAction } from 'react';

import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Reward } from '../../../shared/modals/reward/reward';
import { useRewards } from '../../../store/reducers/reward';

type PEnableReward = {
  openEnableReward: boolean;
  setOpenEnableReward: Dispatch<SetStateAction<boolean>>;
  reward: Reward | undefined;
};

const EnableReward = ({ openEnableReward, setOpenEnableReward, reward }: PEnableReward) => {
  const [loading, setLoading] = useState(false);
  const { changeRewardEnable } = useRewards();

  if (!reward) return null;

  const handleEnableReward = async () => {
    setLoading(true);
    await changeRewardEnable(reward.idAward);
    setLoading(false);
    setOpenEnableReward(false);
  };

  const title = reward.enabled ? 'Desativar prêmio' : 'Ativar prêmio';
  const message = reward.enabled
    ? 'Esse prêmio irá desaparecer para os usuários. Tem certeza que deseja desativar esse prêmio?'
    : 'Esse prêmio irá aparecer para os usuários. Tem certeza que deseja ativar esse prêmio?';

  return (
    <>
      <ModalConfirm
        loadingPrimary={loading}
        title={title}
        message={message}
        onClose={() => setOpenEnableReward(false)}
        open={openEnableReward}
        onClickPrimary={handleEnableReward}
        onClickSecondary={() => setOpenEnableReward(false)}
      />
    </>
  );
};

export default EnableReward;
