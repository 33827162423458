import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import ModalConfirm from '../../shared/components/modal/modalConfirm/ModalConfirm';
import Tooltip from '../../shared/components/tooltip/Tooltip';
import { colors } from '../../shared/functions/colors';
import { dateTimeStampToString } from '../../shared/functions/date';
import IconBlockCircle from '../../shared/images/icon/iconBlockCircle';
import IconCheckCircle from '../../shared/images/icon/iconCheckCircle';
import { ApprovalInterface } from './ChallengeApprovals';
import * as approvalsService from '../../shared/services/approvals';

type ApproveRejectInterface = {
  approval: ApprovalInterface;
  onStatusChanged: () => void;
};

const ApproveReject = ({ approval, onStatusChanged }: ApproveRejectInterface) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<boolean>();
  const dispatch = useDispatch();

  const handleOnCloseModal = () => {
    setOpenModal(false);
  };

  const handleChooseStatus = (newStatus: boolean) => {
    setStatus(newStatus);
    setOpenModal(true);
  };

  const handleConfirm = async () => {
    if (status !== undefined) {
      setLoading(true);
      await approvalsService.update(approval.id, status, dispatch);
      await onStatusChanged();
      handleOnCloseModal();
      setLoading(false);
    }
  };

  const renderButtons = () => {
    switch (approval.status) {
      case true:
        return (
          <div className="spaced" style={{ width: '72px' }}>
            <Tooltip title={approval.updatedAt ? dateTimeStampToString(approval.updatedAt) : ''}>
              <IconCheckCircle />
            </Tooltip>
            <IconBlockCircle color={colors.grey60} />
          </div>
        );
      case false:
        return (
          <div className="spaced" style={{ width: '72px' }}>
            <IconCheckCircle color={colors.grey60} />
            <Tooltip title={approval.updatedAt ? dateTimeStampToString(approval.updatedAt) : ''}>
              <IconBlockCircle />
            </Tooltip>
          </div>
        );
      case null:
      default:
        return (
          <div className="spaced" style={{ width: '72px' }}>
            <ButtonIcon onClick={() => handleChooseStatus(true)}>
              <IconCheckCircle color={colors.grey100} />
            </ButtonIcon>
            <ButtonIcon onClick={() => handleChooseStatus(false)}>
              <IconBlockCircle color={colors.grey100} />
            </ButtonIcon>
          </div>
        );
    }
  };

  return (
    <>
      <ModalConfirm
        title={status ? 'Aprovar' : 'Rejeitar'}
        message={`Tem certeza que deseja ${status ? 'aprovar' : 'rejeitar'} a resposta do usuário?`}
        open={openModal}
        onClose={handleOnCloseModal}
        onClickSecondary={handleOnCloseModal}
        onClickPrimary={handleConfirm}
        loadingPrimary={loading}
      />
      {renderButtons()}
    </>
  );
};

export default ApproveReject;
