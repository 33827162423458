import { Reducer } from 'redux';

import { ContestTypes, ContestState } from './contestTypes';

const INITIAL_STATE: ContestState = {
  contests: [],
  contest: undefined,
  contestReport: undefined,
  errors: [],
};

const reducer: Reducer<ContestState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContestTypes.SET_CONTESTS:
      return {
        ...state,
        contests: action.payload,
      };
    case ContestTypes.SET_CONTEST:
      return {
        ...state,
        contest: action.payload,
      };
    case ContestTypes.SET_CONTEST_REPORT:
      return {
        ...state,
        contestReport: action.payload,
      };
    case ContestTypes.SET_CONTEST_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
