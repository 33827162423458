import { Level } from '../../../shared/modals/level/level';
import {
  ImportUserError,
  User,
  UserPagination,
  UserReport,
} from '../../../shared/modals/user/user';

export enum UserTypes {
  SET_DATA_USER = '@user/SET_DATA_USER',
  SET_ALL_USERS = '@user/SET_ALL_USERS',
  SET_USER = '@user/SET_USER',
  SET_ALL_LEVELS = '@user/SET_ALL_LEVELS',
  SET_IMPORT_ERRORS = '@user/SET_IMPORT_ERRORS',
  SET_USER_REPORT = '@user/SET_USER_REPORT',
  SET_TOTAL_COUNT = '@user/SET_TOTAL_COUNT',
  SET_USER_PAGINATION = '@user/SET_USER_PAGINATION',
}

export type UserState = {
  readonly userData?: User;
  readonly users?: User[];
  readonly user?: User;
  readonly levels: Level[];
  readonly errors: ImportUserError[];
  readonly userReport?: UserReport;
  readonly totalCount: number;
  readonly userPagination: UserPagination;
};
