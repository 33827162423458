import React, { CSSProperties } from 'react';
import { colors } from '../../functions/colors';

type PIconCheckCircle = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconCheckCircle = ({ width, height, color, style }: PIconCheckCircle) => (
  <svg width={width} height={height} fill="none" style={style}>
    <mask
      id="prefix_IconCheckCircle"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={2}
      width={width ? width - 4 : 0}
      height={height ? height - 4 : 0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12zm3 0l5 5 9-9-1.41-1.42L10 14.17l-3.59-3.58L5 12z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#prefix_IconCheckCircle)">
      <path fill={color} d="M0 0h24v24H0z" />
    </g>
  </svg>
);

IconCheckCircle.defaultProps = {
  width: 24,
  height: 24,
  color: colors.success,
  style: {},
};

export default IconCheckCircle;
