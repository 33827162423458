import React, { useState, Dispatch, SetStateAction } from 'react';

import { useRewards } from '../../../store/reducers/reward';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Reward } from '../../../shared/modals/reward/reward';

type PDeleteReward = {
  openDeleteReward: boolean;
  setOpenDeleteReward: Dispatch<SetStateAction<boolean>>;
  reward: Reward | undefined;
};

const DeleteReward = ({ openDeleteReward, setOpenDeleteReward, reward }: PDeleteReward) => {
  const [loadingBlock, setLoadingBlock] = useState(false);
  const { deleteReward } = useRewards();

  if (!reward) return null;

  const handleDeleteReward = async () => {
    setLoadingBlock(true);
    await deleteReward(reward.idAward);
    setLoadingBlock(false);
    setOpenDeleteReward(false);
  };

  return (
    <ModalConfirm
      loadingPrimary={loadingBlock}
      message="Tem certeza que deseja excluir o prêmio?"
      title="Excluir prêmio"
      onClose={() => setOpenDeleteReward(false)}
      open={openDeleteReward}
      onClickPrimary={handleDeleteReward}
      onClickSecondary={() => setOpenDeleteReward(false)}
    />
  );
};

export default DeleteReward;
