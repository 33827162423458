import { Dispatch } from 'redux';
import {
  BodyEditCompany,
  BodyEditPassword,
  BodyInsertCompany,
} from '../../../shared/modals/company/bodies';

import * as companyActions from './companyActions';
import * as serviceCompany from '../../../shared/services/serviceCompany';
import {
  CHANGE_PASSWORD,
  COMPANY_BLOCK,
  COMPANY_CREATE,
  COMPANY_EDIT,
  COMPANY_UNLOCK,
  ERROR_MAINTENANCE,
  PREFERENCES_EDIT,
} from '../../../shared/constants/messages';
import { doLogout } from '../../../shared/functions/connection/auth';
import { getUserData } from '../user/userOperations';
import { setSnackbar } from '../general/generalActions';
import { SUCCESS_SNACKBAR } from '../../../shared/components/snackbar/Snackbar';
import { CompanyStyle } from '../../../shared/modals/user/user';
import { ConnectApiGet, urls } from '../../../shared/functions/connection';
import { SecurityPreferences } from '../../../features/preferences/Security';

export const getCompanies = () => async (dispatch: Dispatch) => {
  try {
    const companies = await serviceCompany.getCompanys();
    dispatch(companyActions.setCompanies(companies));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getCompany = () => async (dispatch: Dispatch) => {
  try {
    const company = await serviceCompany.getCompany();
    dispatch(companyActions.setCompany(company));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getCompanyPreferenceStyles = () => async (dispatch: Dispatch) => {
  try {
    const response = await ConnectApiGet(urls.URL_COMPANY_PREFERENCES_STYLES);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
  return {};
};

export const getCompanySecurityPreferences = () => async (dispatch: Dispatch) => {
  try {
    const response = await ConnectApiGet(urls.URL_COMPANY_SECURITY_PREFERENCES);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
  return {};
};

export const insertCompany = (bodyInsertCompany: BodyInsertCompany) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceCompany.insertCompany(bodyInsertCompany);
    getCompanies()(dispatch);
    dispatch(setSnackbar(COMPANY_CREATE, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const verifyAndCreateCompany = (bodyInsertCompany: BodyInsertCompany) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceCompany.verifyAndCreateCompany(bodyInsertCompany);
    dispatch(setSnackbar(COMPANY_CREATE, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const verifyCompany = (token: string) => async (dispatch: Dispatch) => {
  try {
    await serviceCompany.verifyCompany(token);
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const editCompany = (idCompany: string, bodyInsertCompany: BodyInsertCompany) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceCompany.editCompany(idCompany, bodyInsertCompany);
    getCompanies()(dispatch);
    dispatch(setSnackbar(COMPANY_EDIT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const editCompanyPreferences = (bodyEditCompany: BodyEditCompany) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceCompany.editCompanyPreferences(bodyEditCompany);
    getUserData()(dispatch);
    dispatch(setSnackbar(PREFERENCES_EDIT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const editCompanyPreferencesStyles = (companyStyle: CompanyStyle) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceCompany.editCompanyPreferencesStyles(companyStyle);
    if (companyStyle.live) {
      dispatch(setSnackbar(PREFERENCES_EDIT, SUCCESS_SNACKBAR));
    }
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const editCompanySecurityPreferences = (companyStyle: SecurityPreferences) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceCompany.editCompanySecurityPreferences(companyStyle);
    dispatch(setSnackbar(PREFERENCES_EDIT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const editPassword = (bodyEditPassword: BodyEditPassword) => async (dispatch: Dispatch) => {
  try {
    await serviceCompany.editPassword(bodyEditPassword);
    dispatch(setSnackbar(CHANGE_PASSWORD, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const blockCompany = (idCompany: string) => async (dispatch: Dispatch) => {
  try {
    await serviceCompany.blockCompany(idCompany);
    getCompanies()(dispatch);
    dispatch(setSnackbar(COMPANY_BLOCK, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const unlockCompany = (idCompany: string) => async (dispatch: Dispatch) => {
  try {
    await serviceCompany.unlockCompany(idCompany);
    getCompanies()(dispatch);
    dispatch(setSnackbar(COMPANY_UNLOCK, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};
