import styled from 'styled-components';

import { colors } from '../../functions/colors';

export const Container = styled.menu`
  width: 192px;
  height: 100vh;
  background-color: ${colors.primary};
  display: flex;
  flex-direction: column;

  @media print {
    display: none;
  }
`;

export const Img = styled.img`
  max-width: 100px;
  max-height: 100px;
  margin: 40px auto;
`;
