import { useDispatch, useSelector } from 'react-redux';

import { BodyCreateStage } from '../../../shared/modals/stage/bodies';
import { RootStateGlobal } from '../../reducer';
import * as stageActions from './stageActions';
import * as stageOperations from './stageOperations';
import { Stage } from '../../../shared/modals/stage/stage';

export const useStage = () => {
  const dispatch = useDispatch();
  const { stages, stage } = useSelector((state: RootStateGlobal) => state.stageReducer);

  const getStages = async () => stageOperations.getStages()(dispatch);
  const getStagesIdTrail = async (idTrail: string) =>
    stageOperations.getStagesIdTrail(idTrail)(dispatch);
  const insertStage = async (bodyCreateStage: BodyCreateStage, idTrail: string) =>
    stageOperations.insertStage(bodyCreateStage, idTrail)(dispatch);
  const changePositionStage = async (idTrail: string, idStage: string, upPosition: boolean) =>
    stageOperations.changePositionStage(idTrail, idStage, upPosition)(dispatch);
  const editStage = async (bodyCreateStage: BodyCreateStage, idStage: string, idTrail: string) =>
    stageOperations.editStage(bodyCreateStage, idStage, idTrail)(dispatch);
  const getStage = async (idStage: string) => stageOperations.getStage(idStage)(dispatch);
  const deleteStage = async (idStage: string, idTrail?: string) =>
    stageOperations.deleteStage(idStage, idTrail)(dispatch);
  const duplicateStage = async (idStage: string, idTrail?: string) =>
    stageOperations.duplicateStage(idStage, idTrail)(dispatch);
  const setStage = (x?: Stage) => dispatch(stageActions.setStage(x));

  return {
    stages,
    stage,
    getStages,
    getStagesIdTrail,
    insertStage,
    changePositionStage,
    editStage,
    getStage,
    deleteStage,
    duplicateStage,
    setStage,
  };
};
