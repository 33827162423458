import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import BreadCrumbs from '../../../shared/components/breadCrumbs/BreadCrumbs';
import { BoxWhite } from '../../../shared/components/styled/box';
import {
  ContainerMain,
  HeaderMain,
  TextHeaderMain,
} from '../../../shared/components/styled/general';
import { TitleSemiBold } from '../../../shared/components/styled/text';
import Table from '../../../shared/components/table/Table';
import { MenuUrl } from '../../../shared/enums/menuUrl';
import { useLibrary } from '../../../store/reducers/library';
import ModalQuestionMoreInfo from '../../../shared/components/modal/modalQuestionMoreInfo/ModalQuestionMoreInfo';
import ButtonIcon from '../../../shared/components/buttons/buttonIcon/ButtonIcon';
import Checkbox from '../../../shared/components/input/checkbox/Checkbox';
import Button from '../../../shared/components/buttons/Button';
import ModalSelectCategory from './modalSelectCategory/ModalSelectCategory';

type PQuestionsLibrary = {
  idLibrary: string;
};

const QuestionsLibrary = ({ idLibrary }: PQuestionsLibrary) => {
  const [idQuestionMoreInfo, setIdQuestionMoreInfo] = useState<string | undefined>();
  const [questionsSelected, setQuestionsSelected] = useState<string[]>([]);
  const [openModalSelectCategory, setOpenModalSelectCategory] = useState<boolean>(false);
  const { library, getLibrary } = useLibrary();

  const handleSelectQuestion = (idQuestion: string, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      questionsSelected.push(idQuestion);
      setQuestionsSelected([...questionsSelected]);
    } else {
      questionsSelected.filter(idQuestionFilter => idQuestionFilter !== idQuestion);
      setQuestionsSelected([
        ...questionsSelected.filter(idQuestionFilter => idQuestionFilter !== idQuestion),
      ]);
    }
  };

  const handleSelectAllQuestions = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && library?.questions?.length) {
      setQuestionsSelected(library.questions.map(question => question.idQuestion));
    } else {
      setQuestionsSelected([]);
    }
  };

  useEffect(() => {
    getLibrary(idLibrary);
  }, []);

  const renderRowsTable = () => {
    if (library?.questions?.length) {
      return library.questions.map(question => ({
        columns: [
          question.text,
          <>
            <ButtonIcon onClick={() => setIdQuestionMoreInfo(question.idQuestion)}>
              <p>Ver detalhes</p>
            </ButtonIcon>
          </>,
          <>
            <Checkbox
              checked={!!questionsSelected.find(idQuestion => idQuestion === question.idQuestion)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleSelectQuestion(question.idQuestion, event);
              }}
            />
          </>,
        ],
      }));
    }
    return [];
  };

  const history = useHistory();
  return (
    <ContainerMain>
      <ModalSelectCategory
        openModal={openModalSelectCategory}
        setOpenModal={setOpenModalSelectCategory}
        idQuestions={questionsSelected}
      />
      <ModalQuestionMoreInfo
        setIdQuestion={setIdQuestionMoreInfo}
        idQuestion={idQuestionMoreInfo}
      />
      <BreadCrumbs
        listMenus={[
          {
            name: 'Biblioteca',
            action: () => history.push(MenuUrl.library),
          },
          {
            name: 'Perguntas',
          },
        ]}
      />
      <HeaderMain>
        <TextHeaderMain>
          Preparamos alguns materiais para te auxiliar, confira abaixo.
        </TextHeaderMain>
      </HeaderMain>
      {library && (
        <BoxWhite>
          <TitleSemiBold style={{ padding: 24 }}>{library.name}</TitleSemiBold>
          <Table
            nameReferenceReduxTable="table_question_library"
            listRowsPerPage={[10, 25, 50, 100]}
            titles={[
              {
                name: 'Perguntas',
                openClick: true,
                type: 'string',
                widthTd: '65%',
              },
              {
                name: 'Clique para ver detalhes',
                openClick: false,
                type: 'string',
                widthTd: '15%',
              },
              {
                name: (
                  <>
                    <Checkbox
                      text="Selecionar todos"
                      checked={questionsSelected.length === library?.questions?.length}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleSelectAllQuestions(event);
                      }}
                    />
                  </>
                ),
                openClick: false,
                type: 'string',
                widthTd: '20%',
              },
            ]}
            rows={renderRowsTable()}
          />
          {!!questionsSelected.length && (
            <Button
              onClick={() => setOpenModalSelectCategory(true)}
              typeButton="primary-white"
              style={{ width: 220, marginLeft: 32, marginTop: -52 }}
            >
              IMPORTAR PERGUNTAS
            </Button>
          )}
        </BoxWhite>
      )}
    </ContainerMain>
  );
};

export default QuestionsLibrary;
