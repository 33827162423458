import React, { forwardRef } from 'react';

import { Container } from './styles';
import Checkbox from '../../../shared/components/input/checkbox/Checkbox';

type PFilter = {
  checkboxesSelected: boolean[];
  handleCheckboxesSelected: (x: number, y: boolean) => void;
  labelDataSet: string;
};

export type ClickFilterHandle = {
  onClickFilter: () => void;
};

const Types = forwardRef(
  ({ checkboxesSelected, handleCheckboxesSelected, labelDataSet }: PFilter) => {
    const verifyAllChecked = () => {
      let allChecked = true;

      if (
        !checkboxesSelected[1] ||
        !checkboxesSelected[2] ||
        !checkboxesSelected[3] ||
        !checkboxesSelected[4] ||
        !checkboxesSelected[5] ||
        !checkboxesSelected[6]
      ) {
        allChecked = false;
      }
      return allChecked;
    };

    const handleChangeCheckboxGeral = () => {
      if (checkboxesSelected[0]) {
        handleCheckboxesSelected(8, false);
      } else {
        handleCheckboxesSelected(8, true);
      }
    };

    return (
      <Container>
        <Checkbox
          text="Todos"
          idCheckbox="todos"
          onChange={handleChangeCheckboxGeral}
          checked={verifyAllChecked()}
        />
        <Checkbox
          text="Desempenho"
          idCheckbox="desempenho"
          onChange={() => handleCheckboxesSelected(2, !checkboxesSelected[2])}
          checked={checkboxesSelected[2]}
        />
        <Checkbox
          text="Engajamento"
          idCheckbox="engajamento"
          onChange={() => handleCheckboxesSelected(1, !checkboxesSelected[1])}
          checked={checkboxesSelected[1]}
        />
        <Checkbox
          text="Ranking"
          idCheckbox="ranking"
          onChange={() => handleCheckboxesSelected(5, !checkboxesSelected[5])}
          checked={checkboxesSelected[5]}
        />
        <Checkbox
          text="Taxa de acerto"
          idCheckbox="acerto"
          onChange={() => handleCheckboxesSelected(3, !checkboxesSelected[3])}
          checked={checkboxesSelected[3]}
        />
        <Checkbox
          text="Taxa de erros"
          idCheckbox="erros"
          onChange={() => handleCheckboxesSelected(4, !checkboxesSelected[4])}
          checked={checkboxesSelected[4]}
        />
        {(labelDataSet === 'Torneios' ||
          labelDataSet === 'Trilhas' ||
          labelDataSet === 'Fases') && (
          <Checkbox
            text="Dedo duro"
            idCheckbox="dedo_duro"
            onChange={() => handleCheckboxesSelected(6, !checkboxesSelected[6])}
            checked={checkboxesSelected[6]}
          />
        )}
        {(labelDataSet === 'Torneios' ||
          labelDataSet === 'Trilhas' ||
          labelDataSet === 'Fases') && (
          <Checkbox
            text="Lista de presença"
            idCheckbox="lista_presenca"
            onChange={() => handleCheckboxesSelected(7, !checkboxesSelected[7])}
            checked={checkboxesSelected[7]}
          />
        )}
      </Container>
    );
  },
);

export default Types;
