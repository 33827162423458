import styled from 'styled-components';
import { DisplayFlex } from '../../styled/general';
import { colors } from '../../../functions/colors';
import { fonts } from '../../../functions/fonts';

type TrueQuestion = {
  trueQuestion?: boolean;
};

export const ContainerAlternatives = styled.div`
  width: 420px;
`;

export const Title = styled.h1`
  width: 100;
  text-align: left;
  color: ${colors.pinkDark};
  font-family: ${fonts.bold};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
`;

export const TitleAlternatives = styled(DisplayFlex)`
  border-bottom: 1px solid ${colors.grey80};
  justify-content: space-between;
  margin: 16px 0px;
`;

export const Alternatives = styled(DisplayFlex)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

export const TextAlternatives = styled.p<TrueQuestion>`
  color: ${props => (props.trueQuestion ? colors.pinkRegular : colors.black)};
  font-family: ${props => (props.trueQuestion ? fonts.semiBold : fonts.regular)};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

export const FooterAlternatives = styled(DisplayFlex)`
  justify-content: flex-end;
  border-top: 1px solid ${colors.grey80};
  align-items: right;
  margin-bottom: 32px;
`;

export const ContainerPercentage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0px;

  @media print {
    margin: 8px 0px;
  }
`;

export const TextPercentage = styled.h3`
  color: ${colors.orangeDark};
  font-family: ${fonts.bold};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;

  @media print {
    font-size: 13px;
  }
`;

export const ExtraText = styled.p`
  color: ${colors.grey100};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;

  @media print {
    font-size: 9px;
  }
`;

export const Text = styled.p`
  color: ${colors.black};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  strong {
    font-family: ${fonts.bold};
  }

  @media print {
    font-size: 11px;
  }
`;
