import { TypeUser, Permission } from '../../../shared/modals/typeUser/typeUser';
import { TypeUserTypes } from './typeUserTypes';

export const setTypesUser = (typesUser: TypeUser[]) => ({
  type: TypeUserTypes.SET_TYPES_USER,
  payload: typesUser,
});

export const setPermissions = (permissions: Permission[]) => ({
  type: TypeUserTypes.SET_PERMISSIONS,
  payload: permissions,
});

export const setShowPermissions = (permissions: Permission[]) => ({
  type: TypeUserTypes.SET_SHOW_PERMISSIONS,
  payload: permissions,
});
