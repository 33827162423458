import * as React from 'react';

const OrderedListIcon = ({ active, disabled }: { active: boolean; disabled: boolean }) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <mask
      id="orderedListIconMask"
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width="100%"
      height="100%"
    >
      <rect x="0" y="0" width="100%" height="100%" fill={disabled ? '#777' : '#fff'} />
    </mask>
    <g>
      <path
        fill={active ? 'blue' : '#5f6368'}
        fillRule="evenodd"
        d="M0,11 L2,11 L2,11.5 L1,11.5 L1,12.5 L2,12.5 L2,13 L0,13 L0,14 L3,14 L3,10 L0,10 L0,11 Z M0,6 L1.8,6 L0,8.1 L0,9 L3,9 L3,8 L1.2,8 L3,5.9 L3,5 L0,5 L0,6 Z M1,4 L2,4 L2,0 L0,0 L0,1 L1,1 L1,4 Z M5,1 L5,3 L14,3 L14,1 L5,1 Z M5,13 L14,13 L14,11 L5,11 L5,13 Z M5,8 L14,8 L14,6 L5,6 L5,8 Z"
        transform="translate(2 2)"
        mask="url(#orderedListIconMask)"
      />
    </g>
  </svg>
);

export default OrderedListIcon;
