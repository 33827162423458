import React from 'react';
import { useHistory } from 'react-router';

import { MenuUrl } from '../../enums/menuUrl';
import UserData from '../../../store/objects/UserData';
import IconCompany from '../../images/icon/iconCompany';
import IconHome from '../../images/icon/iconHome';
import IconQuestion from '../../images/icon/iconQuestion';
import ButtonMenu from '../buttons/buttonMenu/ButtonMenu';
import { Container, Img } from './styles';
import IconUsers from '../../images/icon/iconUsers';
import IconContest from '../../images/icon/iconContest';
import { colors } from '../../functions/colors';
import { getImageCompany } from '../../functions/imageCompany';
import IconTrail from '../../images/icon/iconTrail';
import IconReport from '../../images/icon/iconReport';
import BoxOnBoarding from '../onBoarding/BoxOnBoarding';
import { useUser } from '../../../store/reducers/user';
import IconLibrary from '../../images/icon/iconLibrary';
import IconPreferences from '../../images/icon/iconPreferences';
import IconReward from '../../images/icon/iconReward';
import IconDice from '../../images/icon/iconDice';
import IconMaps from '../../images/icon/iconMaps';
import { useGeneral } from '../../../store/reducers/general';

const verifyStringInPahname = (pathname: string) => {
  const pathnameSplit = window.location.pathname.split(pathname);
  return pathnameSplit.length > 1;
};

const Menu = () => {
  const history = useHistory();
  const { userData } = useUser();
  const { setCurrentSelectedTab, setFilterInput, setFilterSelect } = useGeneral();
  if (!userData) {
    return null;
  }
  const userDataObj = new UserData(userData);

  const verifyPath = (idMenu: number) => {
    switch (idMenu) {
      case 23:
        return verifyStringInPahname(MenuUrl.rollouts);
      case 22:
        return verifyStringInPahname(MenuUrl.journeys);
      case 21:
        return verifyStringInPahname(MenuUrl.selfSignUpCampaigns);
      case 20:
        return verifyStringInPahname('/approvals');
      case 19:
        return verifyStringInPahname('/challenges');
      case 18:
        return verifyStringInPahname(MenuUrl.missions);
      case 17:
        return verifyStringInPahname(MenuUrl.rewardManage);
      case 16:
        return verifyStringInPahname(MenuUrl.reward);
      case 15:
        return verifyStringInPahname(MenuUrl.permission);
      case 14:
        return verifyStringInPahname(MenuUrl.preferences);
      case 13:
        return verifyStringInPahname(MenuUrl.library);
      case 12:
        return verifyStringInPahname(MenuUrl.libraryAdmin);
      case 11:
        return verifyStringInPahname(MenuUrl.area);
      case 10:
        return verifyStringInPahname(MenuUrl.report);
      case 7:
        return verifyStringInPahname(MenuUrl.trail);
      case 9:
        return verifyStringInPahname(MenuUrl.stage);
      case 8:
        return verifyStringInPahname(MenuUrl.category);
      case 6:
        return verifyStringInPahname(MenuUrl.contest);
      case 4:
        return verifyStringInPahname(MenuUrl.user);
      case 3:
        return verifyStringInPahname(MenuUrl.question);
      case 2:
        return verifyStringInPahname(MenuUrl.company);
      case 1:
      default:
        return verifyStringInPahname(MenuUrl.home);
    }
  };

  const handleClickMenu = (idMenu: number) => {
    setCurrentSelectedTab(0); // Resets the tab to default when user changes page by menu
    setFilterInput(''); // Resets filter input when user changes page
    setFilterSelect([]); // Resets selectable filters when user changes pages

    switch (idMenu) {
      case 23:
        history.push(MenuUrl.rollouts);
        break;
      case 22:
        history.push(MenuUrl.journeys);
        break;
      case 21:
        history.push(MenuUrl.selfSignUpCampaigns);
        break;
      case 20:
        history.push(MenuUrl.approvals);
        break;
      case 19:
        history.push(MenuUrl.challenges);
        break;
      case 18:
        history.push(MenuUrl.missions);
        break;
      case 17:
        history.push(MenuUrl.rewardManage);
        break;
      case 16:
        history.push(MenuUrl.reward);
        break;
      case 15:
        history.push(MenuUrl.permission);
        break;
      case 14:
        history.push(MenuUrl.preferences);
        break;
      case 13:
        history.push(MenuUrl.library);
        break;
      case 12:
        history.push(MenuUrl.libraryAdmin);
        break;
      case 11:
        history.push(MenuUrl.area);
        break;
      case 10:
        history.push(MenuUrl.report);
        break;
      case 9:
        history.push(MenuUrl.stage);
        break;
      case 7:
        history.push(MenuUrl.trail);
        break;
      case 8:
        history.push(MenuUrl.category);
        break;
      case 6:
        history.push(MenuUrl.contest);
        break;
      case 4:
        history.push(MenuUrl.user);
        break;
      case 3:
        history.push(MenuUrl.question);
        break;
      case 2:
        history.push(MenuUrl.company);
        break;
      case 1:
      default:
        history.push(MenuUrl.home);
        break;
    }
  };

  const renderIcon = (idMenu: number) => {
    switch (idMenu) {
      case 2:
        return <IconCompany color={verifyPath(idMenu) ? colors.yellowRegular : colors.white} />;
      case 3:
        return <IconQuestion color={verifyPath(idMenu) ? colors.yellowRegular : colors.white} />;
      case 4:
        return <IconUsers color={verifyPath(idMenu) ? colors.yellowRegular : colors.white} />;
      case 6:
        return <IconContest color={verifyPath(idMenu) ? colors.yellowRegular : colors.white} />;
      case 7:
        return <IconTrail color={verifyPath(idMenu) ? colors.yellowRegular : colors.white} />;

      case 10:
        return <IconReport color={verifyPath(idMenu) ? colors.yellowRegular : colors.white} />;
      case 12:
      case 13:
        return <IconLibrary color={verifyPath(idMenu) ? colors.yellowRegular : colors.white} />;
      case 14:
        return <IconPreferences color={verifyPath(idMenu) ? colors.yellowRegular : colors.white} />;
      case 16:
        return <IconReward color={verifyPath(idMenu) ? colors.yellowRegular : colors.white} />;
      case 18:
        return <IconDice color={verifyPath(idMenu) ? colors.yellowRegular : colors.white} />;
      case 22:
        return <IconMaps color={verifyPath(idMenu) ? colors.yellowRegular : colors.white} />;
      case 1:
        return (
          <>
            <IconHome color={verifyPath(idMenu) ? colors.yellowRegular : colors.white} />
          </>
        );
      default:
        return <IconContest color={colors.primary} />;
    }
  };

  return (
    <BoxOnBoarding idsShowOnBoarding={[1, 2, 3, 4, 5, 6, 15]}>
      <Container>
        <Img src={getImageCompany()} alt="Logo" />
        {userDataObj.getMenus().map(menu => {
          if (!menu.father || verifyPath(menu.father) || verifyPath(menu.idMenu)) {
            return (
              <ButtonMenu
                key={`menu_${menu.idMenu}`}
                onClick={() => handleClickMenu(menu.idMenu)}
                icon={renderIcon(menu.idMenu)}
                isSelected={verifyPath(menu.idMenu)}
              >
                {menu.name}
              </ButtonMenu>
            );
          }
          return null;
        })}
      </Container>
    </BoxOnBoarding>
  );
};

export default Menu;
