import React from 'react';

import { colors } from '../../functions/colors';
import { doLogout } from '../../functions/connection/auth';
import IconLogout from '../../images/icon/iconLogout';
import IconUser from '../../images/icon/iconUser';
import { User } from '../../modals/user/user';
import ButtonIcon from '../buttons/buttonIcon/ButtonIcon';
import BoxOnBoarding from '../onBoarding/BoxOnBoarding';

import { Container, TextHeader, BoxUser, BoxNameUser, NameUser, AreaUser } from './styles';

type PHeader = {
  userData?: User;
};

const Header = ({ userData }: PHeader) => {
  if (!userData) return null;

  return (
    <Container>
      <TextHeader>{`Olá, ${userData.company ? userData.company.name : userData.name}!`}</TextHeader>
      <div>
        <BoxOnBoarding idsShowOnBoarding={[8]}>
          <BoxUser>
            <IconUser />
            <BoxNameUser>
              <NameUser>{userData.name}</NameUser>
              {userData.typeUser && <AreaUser>{userData.typeUser.name.toUpperCase()}</AreaUser>}
            </BoxNameUser>
            <ButtonIcon onClick={doLogout}>
              <IconLogout color={colors.primary} />
            </ButtonIcon>
          </BoxUser>
        </BoxOnBoarding>
      </div>
    </Container>
  );
};

Header.defaultProps = {
  userData: undefined,
};

export default Header;
