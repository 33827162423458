import React from 'react';
import GraphicBars from '../../../shared/components/graphics/GraphicBars/GraphicBars';
import { BoxRadiusBorder } from '../../../shared/components/styled/box';
import { DisplayFlexJustifyContent } from '../../../shared/components/styled/general';
import { colors } from '../../../shared/functions/colors';
import { useUser } from '../../../store/reducers/user';

const CategoriesUserReport = () => {
  const { userReport } = useUser();

  if (!userReport) {
    return null;
  }

  return (
    <DisplayFlexJustifyContent style={{ marginTop: 32 }}>
      <BoxRadiusBorder style={{ width: '48%', paddingTop: 32 }}>
        <GraphicBars
          data={userReport.graphic_best_categories.map((itemGraphic, index) => ({
            id: index + 1,
            value: itemGraphic.value,
            tickText: itemGraphic.text,
          }))}
          graphicColor={colors.pinkDark}
          title="Categorias com maior acerto"
        />
      </BoxRadiusBorder>
      <BoxRadiusBorder style={{ width: '48%', paddingTop: 32 }}>
        <GraphicBars
          data={userReport.graphic_worst_categories.map((itemGraphic, index) => ({
            id: index + 1,
            value: itemGraphic.value,
            tickText: itemGraphic.text,
          }))}
          graphicColor={colors.pinkDark}
          title="Categorias com maior erro"
        />
      </BoxRadiusBorder>
    </DisplayFlexJustifyContent>
  );
};

export default CategoriesUserReport;
