import styled from 'styled-components';
import { fonts } from '../../../../functions/fonts';
import { colors } from '../../../../functions/colors';

export const LabelTitle = styled.p`
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: ${colors.black};
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: solid 1px;
  border-color: #b1bbc2;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

export const Container = styled.label`
  display: flex;
  position: relative;
  padding-left: 21px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${Checkmark}:after {
 	top: 2px;
	left: 2px;
	width: 9px;
	height: 9px;
	border-radius: 50%;
	background: #7500e5;
`;

export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked ~ ${Checkmark} {
    border: solid px;
    border-color: #7500e5;
  }

  &:checked ~ ${Checkmark}:after {
    display: block;
  }
`;
