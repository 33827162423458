import styled from 'styled-components';
import { colors } from '../../../shared/functions/colors';
import { fonts } from '../../../shared/functions/fonts';

export const Container = styled.div``;

export const Title = styled.h1`
  font-family: ${fonts.bold};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 16px;
  color: ${colors.secondary};
`;

export const Paragraph = styled.p`
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.black};
  margin-bottom: 16px;
`;

export const BoxButtons = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: flex-end;
`;
