import React, { InputHTMLAttributes, FC } from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../../functions/colors';
import IconCalendar from '../../images/icon/iconCalendar';
import IconSearch from '../../images/icon/iconSearch';
import ButtonIcon from '../buttons/buttonIcon/ButtonIcon';

import { Container, StyledInput, TextPlaceHolder, TextErrorMessage, TextLenght } from './styles';

export interface PInput extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  placeholder?: string;
  title?: string;
  instructions?: string;
  error?: boolean;
  messageError?: string;
  style?: CSSProperties;
  backgroundColor?: string;
  showBorder?: boolean;
  icon?: string;
  iconRight?: string;
  showLenghtMax?: boolean;
  onClickIcon?: () => void;
}

const Input: FC<PInput> = ({
  error,
  name,
  placeholder,
  messageError,
  style,
  title,
  instructions,
  backgroundColor,
  showBorder,
  icon,
  iconRight,
  showLenghtMax,
  onClickIcon,
  ...props
}: PInput) => {
  const { value, maxLength, disabled } = props;

  const styleIcon = (): CSSProperties => {
    if (icon) {
      return {
        margin: '8px -16px 8px 12px',
        zIndex: 2,
      };
    }
    return {
      position: 'absolute',
      right: '8px',
      bottom: '8px',
    };
  };

  const renderIcon = (currentIcon: string) => {
    switch (currentIcon) {
      case 'search':
        return <IconSearch style={styleIcon()} />;
      case 'calendar':
        return (
          <ButtonIcon onClick={onClickIcon} style={styleIcon()}>
            <IconCalendar color={colors.grey80} />
          </ButtonIcon>
        );
      default:
        return null;
    }
  };

  return (
    <Container style={style} icon={icon !== undefined}>
      {title && <TextPlaceHolder>{title}</TextPlaceHolder>}
      {instructions && (
        <p style={{ color: '#929898', fontSize: 11, margin: '-8px 0px 8px 4px' }}>{instructions}</p>
      )}
      {icon && renderIcon(icon)}
      <StyledInput
        error={error}
        name={name}
        placeholder={placeholder}
        backgroundColor={backgroundColor}
        showBorder={showBorder}
        icon={icon !== undefined}
        disabled={disabled}
        {...props}
      />
      {iconRight && renderIcon(iconRight)}
      {showLenghtMax && maxLength && (
        <TextLenght>
          {`${value ? value.toLocaleString().length : '0'}/${maxLength} caracteres`}
        </TextLenght>
      )}
      <TextErrorMessage>{messageError}</TextErrorMessage>
    </Container>
  );
};

Input.defaultProps = {
  name: '',
  error: false,
  placeholder: '',
  messageError: '',
  style: {},
  title: undefined,
  instructions: undefined,
  backgroundColor: colors.white,
  showBorder: true,
  icon: undefined,
  iconRight: undefined,
  showLenghtMax: false,
  onClickIcon: () => null,
};

export default Input;
