import { Reducer } from 'redux';

import { UserState, UserTypes } from './userTypes';

const INITIAL_STATE: UserState = {
  userData: undefined,
  users: [],
  user: undefined,
  levels: [],
  errors: [],
  userReport: undefined,
  totalCount: 0,
  userPagination: { limit: 10, page: 1, constraints: {} },
};

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.SET_DATA_USER:
      return {
        ...state,
        userData: action.payload,
      };
    case UserTypes.SET_ALL_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case UserTypes.SET_ALL_LEVELS:
      return {
        ...state,
        levels: action.payload,
      };
    case UserTypes.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case UserTypes.SET_IMPORT_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case UserTypes.SET_USER_REPORT:
      return {
        ...state,
        userReport: action.payload,
      };
    case UserTypes.SET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case UserTypes.SET_USER_PAGINATION:
      return {
        ...state,
        userPagination: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
