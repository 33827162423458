import { BodyEditCompany, BodyEditPassword, BodyInsertCompany } from '../modals/company/bodies';
import {
  ConnectApiGet,
  ConnectApiPatch,
  ConnectApiPost,
  ConnectApiPUT,
  urls,
} from '../functions/connection';

import { CompanyStyle } from '../modals/user/user';
import { SecurityPreferences } from '../../features/preferences/Security';

export const getCompanys = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_COMPANY);
  return respostaServico.data;
};

export const getCompany = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_COMPANY_PREFERENCES);
  return respostaServico.data;
};

export const insertCompany = async (bodyInsertCompany: BodyInsertCompany) => {
  const respostaServico = await ConnectApiPost(urls.URL_COMPANY, bodyInsertCompany);
  return respostaServico.data;
};

export const verifyAndCreateCompany = async (bodyInsertCompany: BodyInsertCompany) => {
  const respostaServico = await ConnectApiPost(urls.URL_COMPANY_CREATE, bodyInsertCompany);
  return respostaServico.data;
};

export const verifyCompany = async (token: string) => {
  const url = urls.URL_COMPANY_VERIFY.replace('{token}', `${token}`);
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const blockCompany = async (idCompany: string) => {
  const url = urls.URL_COMPANY_BLOCK.replace('{idCompany}', `${idCompany}`);
  const respostaServico = await ConnectApiPatch(url, {});
  return respostaServico.data;
};

export const unlockCompany = async (idCompany: string) => {
  const url = urls.URL_COMPANY_UNLOCK.replace('{idCompany}', `${idCompany}`);
  const respostaServico = await ConnectApiPatch(url, {});
  return respostaServico.data;
};

export const editCompany = async (idCompany: string, bodyInsertCompany: BodyInsertCompany) => {
  const url = urls.URL_COMPANY_ID.replace('{idCompany}', `${idCompany}`);
  const respostaServico = await ConnectApiPUT(url, bodyInsertCompany);
  return respostaServico.data;
};

export const editCompanyPreferences = async (bodyEditCompany: BodyEditCompany) => {
  const respostaServico = await ConnectApiPUT(urls.URL_COMPANY_PREFERENCES, bodyEditCompany);
  return respostaServico.data;
};

export const editCompanyPreferencesStyles = async (companyStyles: CompanyStyle) => {
  const response = await ConnectApiPatch(urls.URL_COMPANY_PREFERENCES_STYLES, companyStyles);
  return response.data;
};

export const editCompanySecurityPreferences = async (
  companySecurityPreference: SecurityPreferences,
) => {
  const response = await ConnectApiPatch(urls.URL_COMPANY_SECURITY_PREFERENCES, {
    preference: companySecurityPreference,
  });
  return response.data;
};

export const editPassword = async (bodyEditPassword: BodyEditPassword) => {
  const respostaServico = await ConnectApiPatch(urls.URL_COMPANY_PASSWORD, bodyEditPassword);
  return respostaServico.data;
};
