import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import IconAlert from '../../../images/icon/iconAlert';
import IconClose from '../../../images/icon/iconClose';

import {
  Container,
  BoxMessage,
  Table,
  TableSlot,
  CloseButton,
  CloseBox,
  TitleBox,
  Title,
  Subtitle,
} from './styles';

type ImportError = {
  index: number;
  name?: string;
  text?: string;
  email?: string;
  description: string;
};

export type PFixedSnackbar = {
  type: string;
  errors: ImportError[];
  title: string;
  subtitle: string;
  show: boolean;
  setSnackBar?: Dispatch<SetStateAction<PFixedSnackbar>>;
};

const FixedSnackBar = ({ type, errors, show, title, subtitle, setSnackBar }: PFixedSnackbar) => {
  const [position, setPosition] = useState(-3000);

  useEffect(() => {
    if (show && setSnackBar) {
      setPosition(0);
    }
  }, [show]);

  const closeSnackbar = () => {
    if (show && setSnackBar) {
      setPosition(-3000);
      setSnackBar({
        type,
        errors: [],
        title,
        subtitle,
        show: false,
      });
    }
  };

  return (
    <Container position={position}>
      <BoxMessage type={type}>
        <CloseBox>
          <CloseButton type="button" onClick={closeSnackbar}>
            FECHAR
            <IconClose style={{ marginBottom: -4 }} color="#F95D4A" width={20} height={20} />
          </CloseButton>
        </CloseBox>
        <TitleBox>
          <IconAlert style={{ marginLeft: -10, marginRight: 10 }} />
          <Title>{title}</Title>
        </TitleBox>
        <Subtitle>{subtitle}</Subtitle>
        <Table>
          <tbody>
            {errors.map(error => {
              if (error.name) {
                return (
                  <tr key={error.index}>
                    <TableSlot>{`Linha ${error.index}`}</TableSlot>
                    <TableSlot>{error.name}</TableSlot>
                    <TableSlot>{error.email}</TableSlot>
                    <TableSlot>{error.description}</TableSlot>
                  </tr>
                );
              }
              return (
                <tr key={error.index}>
                  <TableSlot>{`Linha ${error.index}`}</TableSlot>
                  <TableSlot>{error.text}</TableSlot>
                  <TableSlot>{error.description}</TableSlot>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </BoxMessage>
    </Container>
  );
};

FixedSnackBar.defaultProps = {
  setSnackBar: undefined,
};

export default FixedSnackBar;
