import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import BreadCrumbs from '../../../shared/components/breadCrumbs/BreadCrumbs';

import Button from '../../../shared/components/buttons/Button';
import Input from '../../../shared/components/input/Input';
import InputFileImage from '../../../shared/components/input/inputFileImage/InputFile';
import { BoxWhite } from '../../../shared/components/styled/box';
import {
  HeaderMain,
  TextHeaderMain,
  ContainerMain,
} from '../../../shared/components/styled/general';
import { MenuUrl } from '../../../shared/enums/menuUrl';
import { BodyInsertLibrary } from '../../../shared/modals/library/bodies';
import { useGeneral } from '../../../store/reducers/general';
import { useLibrary } from '../../../store/reducers/library';
import { BoxButtons } from './styles';

type PInsertEditLibrary = {
  idLibrary?: string;
};

const INITIAL_BODY = {
  name: '',
  image: '',
};

const InsertEditLibrary = ({ idLibrary }: PInsertEditLibrary) => {
  const history = useHistory();
  const { insertLibrary, getLibrary, editLibrary, setLibrary, library } = useLibrary();
  const { setLoadingBlockScreen } = useGeneral();
  const [loading, setLoading] = useState(false);
  const [bodyInsertLibrary, setBodyInsertLibrary] = useState<BodyInsertLibrary>(INITIAL_BODY);

  useEffect(() => {
    if (idLibrary) {
      const getLibraryBlock = async () => {
        setLoadingBlockScreen(true);
        await getLibrary(idLibrary);
        setLoadingBlockScreen(false);
      };

      getLibraryBlock();
    }
  }, [idLibrary]);

  useEffect(() => {
    if (library) {
      setBodyInsertLibrary({
        name: library.name,
        image: library.image || '',
      });
    } else {
      setBodyInsertLibrary({
        ...INITIAL_BODY,
      });
    }
  }, [library]);

  const handleCloseInsert = () => {
    setLibrary();
    history.push(MenuUrl.libraryAdmin);
  };

  const handleInsertLibrary = async () => {
    setLoading(true);
    if (idLibrary) {
      await editLibrary(idLibrary, bodyInsertLibrary);
    } else {
      await insertLibrary(bodyInsertLibrary);
    }
    setLoading(false);
    handleCloseInsert();
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBodyInsertLibrary({
      ...bodyInsertLibrary,
      name: event.target.value,
    });
  };

  const handleChangeImage = (image: string) => {
    setBodyInsertLibrary({
      ...bodyInsertLibrary,
      image,
    });
  };

  return (
    <ContainerMain>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Biblioteca',
            action: handleCloseInsert,
          },
          {
            name: 'Nova biblioteca',
          },
        ]}
      />
      <HeaderMain>
        <TextHeaderMain>Preencha abaixo os dados da nova biblioteca:</TextHeaderMain>
      </HeaderMain>
      <BoxWhite style={{ padding: 32 }}>
        <Input
          title="Nome"
          placeholder="Nova biblioteca"
          value={bodyInsertLibrary.name}
          onChange={handleChangeName}
        />
        <InputFileImage
          title="Inserir imagem (opcional)"
          subTitle="Você pode inserir imagem em .png .jpeg .gif."
          urlImage={bodyInsertLibrary.image || ''}
          setUrlImage={image => handleChangeImage(image)}
          style={{ marginTop: 32, width: '40%' }}
          dimensions={{ width: 276, height: 148 }}
          isCrop
        />
        <BoxButtons>
          <Button onClick={handleCloseInsert} typeButton="primary-white" style={{ width: 120 }}>
            CANCELAR
          </Button>
          <Button
            disabled={bodyInsertLibrary.name === ''}
            loading={loading}
            onClick={handleInsertLibrary}
            style={{ width: 120, marginLeft: 16 }}
          >
            CONFIRMAR
          </Button>
        </BoxButtons>
      </BoxWhite>
    </ContainerMain>
  );
};

InsertEditLibrary.defaultProps = {
  idLibrary: undefined,
};

export default InsertEditLibrary;
