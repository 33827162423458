import styled from 'styled-components';
import { colors } from '../../../functions/colors';

export const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.grey40};
  padding: 24px;
`;

export const ContainerAccodionExpanded = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
`;

export const DisplayFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;
