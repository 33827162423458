/* eslint-disable @typescript-eslint/no-explicit-any */
export function generateQueryString(url: string, params: any): string {
  const queryString = Object.keys(params)
    .filter(key => params[key] !== null && params[key] !== '' && params[key] !== undefined)
    .map(key =>
      Array.isArray(params[key])
        ? params[key]
            .map((value: any) => `${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`)
            .join('&')
        : `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
    )
    .join('&');
  let stringReturn = '';
  if (queryString !== '') {
    stringReturn = `?${queryString}`;
  }

  return url + stringReturn;
}

export function generatePaginateQueryString(url: string, params: any): string {
  const { page, limit, constraints } = params;

  let apiUrl = url;
  const queryParameters: string[] = [];

  if (page) {
    queryParameters.push(`paginate[page]=${encodeURIComponent(page)}`);
  }

  if (limit) {
    queryParameters.push(`paginate[limit]=${encodeURIComponent(limit)}`);
  }

  if (constraints) {
    const { status, search, idAreas, idCategories } = constraints;

    if (status) {
      queryParameters.push(`paginate[constraints][status]=${encodeURIComponent(status)}`);
    }

    if (search) {
      queryParameters.push(`paginate[constraints][search]=${encodeURIComponent(search)}`);
    }

    if (idAreas && Array.isArray(idAreas) && idAreas.length > 0) {
      const flattenedIdAreas = idAreas.flatMap(area => area);
      const encodedIdAreas = flattenedIdAreas.map(area => encodeURIComponent(area));
      queryParameters.push(
        `paginate[constraints][idAreas][]=${encodedIdAreas.join(
          '&paginate[constraints][idAreas][]=',
        )}`,
      );
    }

    if (idCategories && Array.isArray(idCategories) && idCategories.length > 0) {
      const flattenedIdCategories = idCategories.flatMap(area => area);
      const encodedIdCategories = flattenedIdCategories.map(area => encodeURIComponent(area));
      queryParameters.push(
        `paginate[constraints][idCategories][]=${encodedIdCategories.join(
          '&paginate[constraints][idCategories][]=',
        )}`,
      );
    }
  }

  if (queryParameters.length > 0) {
    apiUrl += `?${queryParameters.join('&')}`;
  }

  return apiUrl;
}
