import {
  ConnectApiGet,
  ConnectApiPost,
  ConnectApiPUT,
  ConnectApiDelete,
  urls,
} from '../functions/connection';
import { generateQueryString } from '../functions/connection/queryString';
import { BodyCreateTrail } from '../modals/trail/bodies';

export const getTrails = async (
  startDate?: string,
  endDate?: string,
  idAreas?: string[],
  enabled?: boolean,
) => {
  const url = generateQueryString(urls.URL_TRAIL, {
    startDate,
    endDate,
    idAreas,
    enabled,
  });
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const insertTrail = async (bodyCreateTrail: BodyCreateTrail) => {
  const respostaServico = await ConnectApiPost(urls.URL_TRAIL, bodyCreateTrail);
  return respostaServico.data;
};

export const editTrail = async (bodyCreateTrail: BodyCreateTrail, idTrail: string) => {
  const url = urls.URL_TRAIL_ID.replace('{idTrail}', `${idTrail}`);
  const respostaServico = await ConnectApiPUT(url, bodyCreateTrail);
  return respostaServico.data;
};

export const changeTrailEnable = async (idTrail: string) => {
  const url = urls.URL_ENABLE_TRAIL.replace('{idTrail}', `${idTrail}`);
  const respostaServico = await ConnectApiPUT(url, {});
  return respostaServico.data;
};

export const deleteTrail = async (idTrail: string) => {
  const url = urls.URL_TRAIL_ID.replace('{idTrail}', `${idTrail}`);
  await ConnectApiDelete(url);
};

export const duplicateTrail = async (idTrail: string) => {
  const url = urls.URL_TRAIL_ID_DUPLICATE.replace('{idTrail}', `${idTrail}`);
  await ConnectApiPost(url, {});
};

export const getTrail = async (idTrail: string) => {
  const url = urls.URL_TRAIL_ID.replace('{idTrail}', `${idTrail}`);
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const getTrailReport = async (idTrail: string) => {
  const url = urls.URL_TRAIL_ID_REPORT.replace('{idTrail}', `${idTrail}`);
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};
