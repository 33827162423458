import { Reducer } from 'redux';

import { LibraryTypes, LibraryState } from './libraryTypes';

const INITIAL_STATE: LibraryState = {
  libraries: [],
  library: undefined,
};

const reducer: Reducer<LibraryState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LibraryTypes.SET_LIBRARIES:
      return {
        ...state,
        libraries: action.payload,
      };
    case LibraryTypes.SET_LIBRARY:
      return {
        ...state,
        library: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
