import styled from 'styled-components';
import { colors } from '../../../shared/functions/colors';
import Input from '../../../shared/components/input/Input';

export const Container = styled.div``;

export const Title = styled.h1`
  color: ${colors.primary};
  margin-bottom: 32px;
`;

export const BoxButtons = styled.div`
  display: flex;
  width: 400px;
  margin: 48px auto 8px auto;
  justify-content: space-between;
`;

export const InputUser = styled(Input)`
  display: block;
  margin-top: 16px;
  width: 90%;
  text-align: left;
`;
