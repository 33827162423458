import React, { Dispatch, SetStateAction, useState, useEffect, ChangeEvent } from 'react';

import Button from '../../../shared/components/buttons/Button';
import Input from '../../../shared/components/input/Input';
import Modal from '../../../shared/components/modal/Modal';
import { BodyTypeUser } from '../../../shared/modals/typeUser/bodies';
import { BoxButtons, Title, Paragraph } from './styles';
import { TypeUser } from '../../../shared/modals/typeUser/typeUser';
import { useTypeUser } from '../../../store/reducers/typeUser';

type PInsertTypeUser = {
  openInsertTypeUser: boolean;
  setOpenInsertTypeUser: Dispatch<SetStateAction<boolean>>;
  typeUser?: TypeUser;
};

const initialBody = {
  name: '',
};

const InsertTypeUser = ({
  openInsertTypeUser,
  setOpenInsertTypeUser,
  typeUser,
}: PInsertTypeUser) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [bodyInsertTypeUser, setBodyInsertTypeUser] = useState<BodyTypeUser>(initialBody);
  const { insertTypeUser, editTypeUser } = useTypeUser();

  useEffect(() => {
    if (typeUser) {
      setBodyInsertTypeUser({ name: editTypeUser.name });
    } else {
      setBodyInsertTypeUser(initialBody);
    }
    setErrorMessage('');
  }, [typeUser, openInsertTypeUser]);

  const handleInsertTypeUser = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (typeUser) {
        await editTypeUser(typeUser.idTypeUser, bodyInsertTypeUser);
      } else {
        await insertTypeUser(bodyInsertTypeUser);
      }
      setOpenInsertTypeUser(false);
      setBodyInsertTypeUser(initialBody);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
    setLoading(false);
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setBodyInsertTypeUser({
      ...bodyInsertTypeUser,
      name: event.target.value,
    });
  };

  return (
    <Modal onClose={() => setOpenInsertTypeUser(false)} open={openInsertTypeUser}>
      <form onSubmit={handleInsertTypeUser}>
        <Title>{typeUser ? 'Editar' : 'Novo tipo de usuário'}</Title>
        <Paragraph>
          {typeUser
            ? 'Edite o tipo de usuário abaixo e depois salve suas alterações'
            : 'Preencha abaixo os dados do novo tipo de usuário:'}
        </Paragraph>
        <Input
          title="Tipo de usuário*"
          placeholder="Tipo de usuário*"
          value={bodyInsertTypeUser.name}
          onChange={handleChangeName}
          error={errorMessage !== ''}
          messageError={errorMessage}
        />
        <BoxButtons>
          <Button
            onClick={() => setOpenInsertTypeUser(false)}
            typeButton="primary-white"
            style={{ width: 120 }}
          >
            CANCELAR
          </Button>
          <Button
            disabled={bodyInsertTypeUser.name === '' || loading}
            loading={loading}
            type="submit"
            style={{ width: 120, marginLeft: 16 }}
          >
            {typeUser ? 'SALVAR' : 'CONFIRMAR'}
          </Button>
        </BoxButtons>
      </form>
    </Modal>
  );
};

InsertTypeUser.defaultProps = {
  typeUser: null,
};

export default InsertTypeUser;
