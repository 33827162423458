import { Dispatch } from 'redux';

import * as typeUserActions from './typeUserActions';
import * as serviceTypeUser from '../../../shared/services/serviceTypeUser';
import {
  ERROR_MAINTENANCE,
  TYPE_USER_DELETE,
  TYPE_USER_EDIT,
  TYPE_USER_INSERT,
} from '../../../shared/constants/messages';
import { doLogout } from '../../../shared/functions/connection/auth';
import { setSnackbar } from '../general/generalActions';
import { BodyTypeUser } from '../../../shared/modals/typeUser/bodies';
import { SUCCESS_SNACKBAR } from '../../../shared/components/snackbar/Snackbar';

export const getTypeUser = () => async (dispatch: Dispatch) => {
  try {
    const typeUsers = await serviceTypeUser.getTypeUser();
    dispatch(typeUserActions.setTypesUser(typeUsers));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getPermissions = () => async (dispatch: Dispatch) => {
  try {
    const permissions = await serviceTypeUser.getPermissions();
    dispatch(typeUserActions.setPermissions(permissions));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getShowPermissions = () => async (dispatch: Dispatch) => {
  try {
    const permissions = await serviceTypeUser.getShowPermissions();
    dispatch(typeUserActions.setShowPermissions(permissions));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const insertTypeUser = (body: BodyTypeUser) => async (dispatch: Dispatch) => {
  try {
    await serviceTypeUser.insertTypeUser(body);
    await getTypeUser()(dispatch);
    dispatch(setSnackbar(TYPE_USER_INSERT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const editTypeUser = (idTypeUser: number, body: BodyTypeUser) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceTypeUser.editTypeUser(idTypeUser, body);
    await getTypeUser()(dispatch);
    dispatch(setSnackbar(TYPE_USER_EDIT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const deleteTypeUser = (idTypeUser: number) => async (dispatch: Dispatch) => {
  try {
    await serviceTypeUser.deleteTypeUser(idTypeUser);
    await getTypeUser()(dispatch);
    dispatch(setSnackbar(TYPE_USER_DELETE, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};
