import styled from 'styled-components';

import { useTypeContainer } from './hooks';

export const Container = styled.button`
  ${useTypeContainer}
`;

export const BoxIcon = styled.div`
  right: 60px;
  top: 18px;
`;
