import { Company } from '../../../shared/modals/user/user';
import { CompanyTypes } from './companyTypes';

export const setCompanies = (companies: Company[]) => ({
  type: CompanyTypes.SET_COMPANIES,
  payload: companies,
});

export const setCompany = (company: Company) => ({
  type: CompanyTypes.SET_COMPANY,
  payload: company,
});
