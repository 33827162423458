import React from 'react';
import Table from '../Table';
import { headerTypes } from '../../../enums/headerTypes';
import { insertPercentagemInNumber } from '../../../functions/number';
import { HeaderTable, Container } from './styles';
import { TitleSemiBold } from '../../styled/text';
import { colors } from '../../../functions/colors';

export type HeaderTableObj = {
  title: string;
  typeHeader: number;
};

export type Columns = {
  columns: number[];
};

export type TableObj = {
  title: string;
  header: HeaderTableObj[];
  data: Columns[];
};

type PTableDefault = {
  infoTable: TableObj;
  isPrint?: boolean;
};

const TableDefault = ({ infoTable, isPrint }: PTableDefault) => {
  const renderRowsTable = () =>
    infoTable.data.map(data => ({
      columns: data.columns.map((column: number, index) =>
        infoTable.header[index].typeHeader === headerTypes.PERCENTAGE
          ? insertPercentagemInNumber(column)
          : column,
      ),
    }));

  return (
    <Container>
      <HeaderTable>
        <TitleSemiBold color={colors.pinkDark}>{infoTable.title}</TitleSemiBold>
      </HeaderTable>
      <Table
        listRowsPerPage={isPrint ? [1000] : [10, 25, 50, 100]}
        titles={infoTable.header.map(header => ({
          name: header.title,
          openClick: true,
          type:
            header.typeHeader === headerTypes.NUMBER
              ? 'number'
              : `${header.typeHeader === headerTypes.PERCENTAGE ? 'percentage' : 'string'}`,
        }))}
        rows={renderRowsTable()}
      />
    </Container>
  );
};

TableDefault.defaultProps = {
  isPrint: false,
};

export default TableDefault;
