import styled from 'styled-components';
import { colors } from '../../shared/functions/colors';
import { fonts } from '../../shared/functions/fonts';

export const Container = styled.div`
  width: 100%;
  padding: 16px 40px;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 24px 0px;
  justify-content: space-between;
`;

export const TextHeader = styled.p`
  color: ${colors.black};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

export const BoxHeaderButtons = styled.div`
  display: flex;
`;
