import React, { useState, Dispatch, SetStateAction } from 'react';

import { useTrail } from '../../../store/reducers/trail';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Trail } from '../../../shared/modals/trail/trail';

type PEnableTrail = {
  openEnableTrail: boolean;
  setOpenEnableTrail: Dispatch<SetStateAction<boolean>>;
  trail: Trail | undefined;
};

const EnableTrail = ({ openEnableTrail, setOpenEnableTrail, trail }: PEnableTrail) => {
  const [loading, setLoading] = useState(false);
  const { changeTrailEnable } = useTrail();

  if (!trail) return null;

  const handleDeleteTrail = async () => {
    setLoading(true);
    await changeTrailEnable(trail.idTrail);
    setLoading(false);
    setOpenEnableTrail(false);
  };

  const title = trail.enabled ? 'Desativar trilha' : 'Ativar trilha';
  const message = trail.enabled
    ? 'Essa trilha irá desaparecer para os usuários. Tem certeza que deseja desativar essa trilha?'
    : 'Essa trilha irá aparecer para os usuários. Tem certeza que deseja ativar essa trilha?';

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title={title}
      message={message}
      onClose={() => setOpenEnableTrail(false)}
      open={openEnableTrail}
      onClickPrimary={handleDeleteTrail}
      onClickSecondary={() => setOpenEnableTrail(false)}
    />
  );
};

export default EnableTrail;
