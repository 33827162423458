import React, { ChangeEvent, Dispatch, SetStateAction, useState, useEffect } from 'react';
import Button from '../../../shared/components/buttons/Button';
import Input from '../../../shared/components/input/Input';
import InputDate from '../../../shared/components/input/inputDateTime/InputDateTime';
import { POptions } from '../../../shared/components/select/Select';
import { Category } from '../../../shared/modals/question/category';
import { BodyCreateContest } from '../../../shared/modals/contest/bodies';
import { useContest } from '../../../store/reducers/contest';
import { useQuestion } from '../../../store/reducers/question';
import { Container, BoxInfo, BoxPadding, BoxFlex, BoxBottom } from './styles';
import {
  dateTimeStampToString,
  dateTimeStringToDateMaterialUi,
} from '../../../shared/functions/date';
import { Area } from '../../../shared/modals/area/area';
import BreadCrumbs from '../../../shared/components/breadCrumbs/BreadCrumbs';
import { TitleContest } from '../../../shared/components/styled/text';
import MultipleSelect from '../../../shared/components/select/multipleSelect/MultipleSelect';
import InputFileImage from '../../../shared/components/input/inputFileImage/InputFile';
import BoxStartShowFeedback from '../../../shared/components/box/boxStartShowFeedback/BoxStartShowFeedback';
import ButtonIcon from '../../../shared/components/buttons/buttonIcon/ButtonIcon';
import IconAddBox from '../../../shared/images/icon/iconAddBox';
import { useGeneral } from '../../../store/reducers/general';
import { TextPlaceHolder } from '../../../shared/components/input/styles';
import { ContainerRadios } from '../../preferences/styles';
import RadioPurple from '../../../shared/components/input/inputRadio/radioPurple/radioPurple';
import { handleChangeFieldValue } from '../../../shared/functions/handlers';
import { useArea } from '../../../store/reducers/area';
import { useUser } from '../../../store/reducers/user';

const PRIZE_START = {
  position: 1,
  prize: 0,
};

const BODY_START = {
  name: '',
  password: '',
  description: '',
  startDate: '',
  endDate: '',
  entryLimit: undefined,
  categories: [],
  theme: '',
  questionAmount: undefined,
  image: '',
  areas: [],
  duplicated: undefined,
  startShowFeedback: undefined,
  duration: undefined,
  showRightAnswer: true,
  prizes: [
    {
      ...PRIZE_START,
    },
  ],
};

type PInsertContest = {
  setOpenInsertContest: Dispatch<SetStateAction<boolean>>;
  idContestEdit?: string;
};

const InsertContest = ({ setOpenInsertContest, idContestEdit }: PInsertContest) => {
  const [loading, setLoading] = useState(false);
  const { setLoadingBlockScreen } = useGeneral();
  const { areas, getAllAreas } = useArea();
  const { categories, getCategories } = useQuestion();
  const { contest, insertContest, editContest, getContest, setContest } = useContest();
  const [bodyContest, setBodyContest] = useState<BodyCreateContest>({ ...BODY_START });
  const [itensAreaSelected, setItensAreaSelected] = useState<POptions[]>([]);
  const [itensCategorySelected, setItensCategorySelected] = useState<POptions[]>([]);
  const { userData } = useUser();

  useEffect(() => {
    const runStartScreen = async () => {
      setLoadingBlockScreen(true);
      if (!categories || categories.length === 0) {
        await getCategories();
      }

      await getAllAreas();

      if (idContestEdit) {
        await getContest(idContestEdit);
      }
      setLoadingBlockScreen(false);
    };

    runStartScreen();
  }, []);

  useEffect(() => {
    if (contest && idContestEdit) {
      const startDate = dateTimeStampToString(new Date(contest.startDate));
      const endDate = dateTimeStampToString(new Date(contest.endDate));
      const startShowFeedback = contest.startShowFeedback
        ? dateTimeStampToString(new Date(contest.startShowFeedback))
        : undefined;
      const startShowRanking = contest.startShowRanking
        ? dateTimeStampToString(new Date(contest.startShowRanking))
        : undefined;

      setBodyContest({
        name: contest.name,
        password: '',
        description: contest.description,
        startDate: dateTimeStringToDateMaterialUi(startDate),
        endDate: dateTimeStringToDateMaterialUi(endDate),
        entryLimit: contest.entryLimit,
        theme: contest.theme,
        questionAmount: contest.questionAmount,
        image: contest.image,
        categories: contest.categories.map(category => category.idCategory),
        areas: contest.areas.map(area => area.idArea),
        duplicated: contest.duplicated,
        showRightAnswer: contest.showRightAnswer,
        startShowFeedback: startShowFeedback
          ? dateTimeStringToDateMaterialUi(startShowFeedback)
          : undefined,
        startShowRanking: startShowRanking
          ? dateTimeStringToDateMaterialUi(startShowRanking)
          : undefined,
        duration: contest.duration,
        prizes:
          contest.prizes.length > 0
            ? contest.prizes
            : [
                {
                  ...PRIZE_START,
                },
              ],
      });

      const newListAreas = contest.areas.map(area => {
        const areaFilter = areas.filter(newArea => newArea.idArea === area.idArea);
        let nameArea = '';
        if (areaFilter.length > 0) {
          nameArea = areaFilter[0].name;
        }
        return {
          value: `${area.idArea}`,
          viewValue: nameArea,
        };
      });
      setItensAreaSelected(newListAreas.filter(item => item.viewValue !== ''));

      const newListCategories = contest.categories.map(category => {
        const categoryFilter = categories.filter(
          newCategory => newCategory.idCategory === category.idCategory,
        );
        let nameCategory = '';
        if (categoryFilter.length > 0) {
          nameCategory = categoryFilter[0].name;
        }
        return {
          value: `${category.idCategory}`,
          viewValue: nameCategory,
        };
      });
      setItensCategorySelected(newListCategories.filter(item => item.viewValue !== ''));
    } else if (!idContestEdit) {
      setContest();
    }
  }, [contest]);

  useEffect(() => {
    setBodyContest({
      ...bodyContest,
      areas: itensAreaSelected.map(option => option.value),
    });
  }, [itensAreaSelected]);

  useEffect(() => {
    setBodyContest({
      ...bodyContest,
      categories: itensCategorySelected.map(option => option.value),
    });
  }, [itensCategorySelected]);

  const verifyEnableSubmit = () => {
    if (bodyContest.name === '') {
      return false;
    }
    if (bodyContest.categories.length <= 0) {
      return false;
    }
    if (!bodyContest.entryLimit || bodyContest.entryLimit <= 0) {
      return false;
    }
    if (bodyContest.prizes[0].prize < 0) {
      return false;
    }
    if (!bodyContest.questionAmount || bodyContest.questionAmount <= 0) {
      return false;
    }
    if (!bodyContest.startDate || bodyContest.startDate === '') {
      return false;
    }
    if (!bodyContest.endDate || bodyContest.endDate === '') {
      return false;
    }
    if (bodyContest.areas.length <= 0) {
      return false;
    }

    return true;
  };

  const convertAreasToPOptions = () => {
    const arrayReturn: POptions[] = [];

    areas.forEach((area: Area) => {
      arrayReturn.push({
        value: `${area.idArea}`,
        viewValue: area.name,
      });
    });

    return arrayReturn;
  };

  const convertCategoriesToPOptions = () => {
    const arrayReturn: POptions[] = [];

    categories.forEach((category: Category) => {
      arrayReturn.push({
        value: `${category.idCategory}`,
        viewValue: category.name,
      });
    });

    return arrayReturn;
  };

  const handleCloseInsertContest = () => {
    setOpenInsertContest(false);
  };

  const handleInsertContest = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (idContestEdit) {
        await editContest({ ...bodyContest, image: bodyContest.image }, idContestEdit);
      } else {
        await insertContest({ ...bodyContest, image: bodyContest.image });
      }
      handleCloseInsertContest();
      setLoading(false);
    } catch (_) {
      setLoading(false);
    }
  };

  const handleChangeBody = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    setBodyContest({
      ...bodyContest,
      [name]: event.target.value,
    });
  };

  const handleChangeBodyPrize = (event: ChangeEvent<HTMLInputElement>, position: number) => {
    setBodyContest({
      ...bodyContest,
      prizes: bodyContest.prizes.map(prizeObject => {
        if (prizeObject.position === position) {
          return {
            ...prizeObject,
            prize: parseInt(event.target.value, 10) ? parseInt(event.target.value, 10) : 0,
          };
        }
        return { ...prizeObject };
      }),
    });
  };

  const handleInsertPrize = () => {
    setBodyContest({
      ...bodyContest,
      prizes: [...bodyContest.prizes, { ...PRIZE_START, position: bodyContest.prizes.length + 1 }],
    });
  };

  if (!userData) return null;

  return (
    <Container onSubmit={handleInsertContest}>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Torneios',
            action: handleCloseInsertContest,
          },
          {
            name: 'Novo torneio',
          },
        ]}
      />
      <BoxInfo>
        <TitleContest>CONFIGURAÇÕES</TitleContest>
        <BoxPadding>
          <BoxFlex style={{ marginBottom: 16 }}>
            <Input
              title="Nome do torneio*"
              placeholder="Digite"
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'name')}
              value={bodyContest.name}
              style={{ width: '100%' }}
            />
            <InputDate
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'startDate')}
              type="datetime-local"
              title="Data e hora de início*"
              value={bodyContest.startDate}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginLeft: 32, width: 320 }}
            />
            <InputDate
              title="Data e hora de fim*"
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'endDate')}
              type="datetime-local"
              value={bodyContest.endDate}
              style={{ marginLeft: 32, width: 320 }}
            />
          </BoxFlex>
          <BoxFlex style={{ marginBottom: 16 }}>
            <Input
              title="Descrição (opcional)"
              placeholder="Descrição (opcional)"
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'description')}
              value={bodyContest.description}
              style={{ width: '100%' }}
            />
          </BoxFlex>
          <MultipleSelect
            listOptions={convertAreasToPOptions()}
            itensSelected={itensAreaSelected}
            setItensSelected={setItensAreaSelected}
            title="Área(s)*"
          />
        </BoxPadding>
        <TitleContest>DETALHAMENTOS</TitleContest>
        <BoxPadding>
          <BoxFlex style={{ marginBottom: 16 }}>
            <MultipleSelect
              listOptions={convertCategoriesToPOptions()}
              itensSelected={itensCategorySelected}
              setItensSelected={setItensCategorySelected}
              title="Categoria(s)*"
            />
          </BoxFlex>
          <BoxFlex>
            <Input
              title="Nº de tentativas*"
              placeholder="0"
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'entryLimit')}
              value={bodyContest.entryLimit}
              style={{ width: '15%' }}
            />
            <Input
              title="Quant. de perguntas*"
              placeholder="0"
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'questionAmount')}
              value={bodyContest.questionAmount}
              style={{ marginLeft: 32, width: '15%' }}
            />
            <Input
              title="Carga Horária"
              placeholder="0"
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'duration')}
              value={bodyContest.duration}
              style={{ marginLeft: 32, width: '15%' }}
            />
            <div style={{ marginLeft: 32, width: 220 }}>
              <TextPlaceHolder>Revelar resposta certa quando usuário errar?</TextPlaceHolder>
              <ContainerRadios>
                <RadioPurple
                  checked={bodyContest.showRightAnswer}
                  value="true"
                  labelTitle="sim"
                  id="show-yes"
                  name="showRightAnswer"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeFieldValue(e, setBodyContest)
                  }
                />
                <RadioPurple
                  checked={!bodyContest.showRightAnswer}
                  value="false"
                  labelTitle="não"
                  id="show-no"
                  name="showRightAnswer"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeFieldValue(e, setBodyContest)
                  }
                  style={{ marginLeft: 16 }}
                />
              </ContainerRadios>
            </div>
          </BoxFlex>
          <BoxFlex style={{ alignItems: 'center' }}>
            {bodyContest.prizes.map(prize => (
              <Input
                title={`Premiação - ${prize.position}º lugar`}
                placeholder="0"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChangeBodyPrize(e, prize.position)
                }
                value={prize.prize === 0 ? '' : prize.prize}
                style={{ width: '15%', marginRight: 32 }}
              />
            ))}
            <ButtonIcon onClick={handleInsertPrize}>
              <IconAddBox />
            </ButtonIcon>
          </BoxFlex>
          <BoxFlex>
            <InputFileImage
              title="Inserir imagem (opcional)"
              subTitle="Você pode inserir imagem em .png .jpeg .gif."
              urlImage={bodyContest.image}
              setUrlImage={image => setBodyContest({ ...bodyContest, image })}
              style={{ marginTop: 16, width: 420 }}
              duplicated={bodyContest.duplicated}
              dimensions={{ width: 64, height: 129 }}
              isCrop
            />
            <BoxStartShowFeedback
              attribute={bodyContest.startShowFeedback}
              attributeName="startShowFeedback"
              setStartShow={startShowFeedback =>
                setBodyContest({ ...bodyContest, startShowFeedback })
              }
            />
          </BoxFlex>
          <BoxFlex>
            <BoxStartShowFeedback
              attribute={bodyContest.startShowRanking}
              attributeName="startShowRanking"
              setStartShow={startShowRanking =>
                setBodyContest({ ...bodyContest, startShowRanking })
              }
            />
          </BoxFlex>
        </BoxPadding>
        <BoxBottom>
          <Button
            onClick={handleCloseInsertContest}
            style={{ width: 120 }}
            typeButton="primary-white"
          >
            CANCELAR
          </Button>
          {!userData.company.migrated && (
            <Button
              loading={loading}
              disabled={!verifyEnableSubmit()}
              type="submit"
              style={{ width: 120, marginLeft: 16 }}
            >
              CONCLUIR
            </Button>
          )}
        </BoxBottom>
      </BoxInfo>
    </Container>
  );
};

InsertContest.defaultProps = {
  idContestEdit: undefined,
};

export default InsertContest;
