import React, { useState, Dispatch, SetStateAction } from 'react';

import { useUser } from '../../../store/reducers/user';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { User } from '../../../shared/modals/user/user';
import { Paragraph } from '../../../shared/components/modal/modalConfirm/styles';

type PDeleteUser = {
  openDeleteUser: boolean;
  setOpenDeleteUser: Dispatch<SetStateAction<boolean>>;
  user: User | undefined;
};

const DeleteUser = ({ openDeleteUser, setOpenDeleteUser, user }: PDeleteUser) => {
  const [loadingBlock, setLoadingBlock] = useState(false);
  const { deleteUser, userPagination } = useUser();

  if (!user) return null;

  const handleDeleteUser = async () => {
    setLoadingBlock(true);
    await deleteUser(user.idUser, userPagination);
    setLoadingBlock(false);
    setOpenDeleteUser(false);
  };

  const warningMsg = () => (
    <>
      <Paragraph style={{ marginBottom: '20px' }}>
        CUIDADO! Esta ação impacta as estatísticas dos relatórios, pois remove PERMANENTEMENTE:
      </Paragraph>
      <Paragraph>- O usuário;</Paragraph>
      <Paragraph>- As compras (associadas ao usuário);</Paragraph>
      <Paragraph>- As partidas (associadas ao usuário);</Paragraph>
      <Paragraph>- As respostas (associadas às partidas do usuário).</Paragraph>
    </>
  );

  return (
    <ModalConfirm
      loadingPrimary={loadingBlock}
      title="Tem certeza que deseja excluir o membro?"
      message={warningMsg()}
      onClose={() => setOpenDeleteUser(false)}
      open={openDeleteUser}
      onClickPrimary={handleDeleteUser}
      onClickSecondary={() => setOpenDeleteUser(false)}
    />
  );
};

export default DeleteUser;
