import * as React from 'react';

const BoldIcon = ({ active, disabled }: { active: boolean; disabled: boolean }) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <mask id="boldIconMask" maskUnits="userSpaceOnUse" x={0} y={0} width="100%" height="100%">
      <rect x="0" y="0" width="100%" height="100%" fill={disabled ? '#777' : '#fff'} />
    </mask>
    <g>
      <path
        fill={active ? 'blue' : '#5f6368'}
        fillRule="evenodd"
        d="M9,3.5 C9,1.57 7.43,0 5.5,0 L1.77635684e-15,0 L1.77635684e-15,12 L6.25,12 C8.04,12 9.5,10.54 9.5,8.75 C9.5,7.45 8.73,6.34 7.63,5.82 C8.46,5.24 9,4.38 9,3.5 Z M5,2 C5.82999992,2 6.5,2.67 6.5,3.5 C6.5,4.33 5.82999992,5 5,5 L3,5 L3,2 L5,2 Z M3,10 L3,7 L5.5,7 C6.32999992,7 7,7.67 7,8.5 C7,9.33 6.32999992,10 5.5,10 L3,10 Z"
        transform="translate(4 3)"
        mask="url(#boldIconMask)"
      />
    </g>
  </svg>
);

export default BoldIcon;
