import { Dispatch } from 'redux';
import {
  BodyUserLogin,
  BodyCreateUser,
  BodyForgotPassword,
  BodyChangePassword,
} from '../../../shared/modals/user/bodies';
import {
  ADMIN_INSERT,
  CHANGE_PASSWORD,
  ERROR_INVALID_PASSWORD,
  ERROR_MAINTENANCE,
  ERROR_NOT_FOUND,
  SEND_PASSWORD,
  USER_BLOCK,
  USER_DELETE,
  USER_EDIT,
  USER_IMPORT,
  USER_INSERT,
  USER_UNLOCK,
} from '../../../shared/constants/messages';

import * as userActions from './userActions';
import * as serviceUser from '../../../shared/services/serviceUser';
import { generalActions } from '../general';
import { doLogout, setAuthorizationToken } from '../../../shared/functions/connection/auth';
import { Company, User, UserPagination } from '../../../shared/modals/user/user';
import { setImageCompany } from '../../../shared/functions/imageCompany';
import { setSnackbar } from '../general/generalActions';
import { SUCCESS_SNACKBAR } from '../../../shared/components/snackbar/Snackbar';

const defaultPagination = { page: 1, limit: 10, constraints: {} };

const updateImageCompany = (user: User) => {
  if (user.company) setImageCompany(user.company.image);
};

export const reqLogin = (bodyUserLogin: BodyUserLogin) => async (dispatch: Dispatch) => {
  try {
    const userData = await serviceUser.login(bodyUserLogin);
    if (userData.user.firstAccessDashboard) {
      dispatch(generalActions.setCurrentOnBoarding([1, 2, 3, 4, 5, 6, 7, 8, 15]));
    }
    updateImageCompany(userData.user);
    dispatch(userActions.setUserData(userData.user));
    setAuthorizationToken(userData.token);
  } catch (error) {
    if (error instanceof Error) {
      let { message } = error;

      if (!message || message === ERROR_NOT_FOUND) {
        message = ERROR_INVALID_PASSWORD;
        dispatch(setSnackbar(message));
        throw new Error(message);
      } else if (message.startsWith('Para concluir o login')) {
        dispatch(setSnackbar(message, 'success'));
      } else if (message.startsWith('O link de acesso expirou')) {
        dispatch(setSnackbar(message));
      } else {
        dispatch(setSnackbar(message));
        throw new Error(message);
      }
    }
  }
};

export const getUserData = () => async (dispatch: Dispatch) => {
  try {
    const userData = await serviceUser.getUserData();
    updateImageCompany(userData.user);
    dispatch(userActions.setUserData(userData.user));
    setAuthorizationToken(userData.token);
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getUsersFiltered = (pagination?: UserPagination) => async (dispatch: Dispatch) => {
  try {
    const users = await serviceUser.getAllUsers(pagination || defaultPagination);
    dispatch(userActions.setUsers(users.users));
    dispatch(userActions.setTotalCount(users.paginate.totalCount));
  } catch (error) {
    dispatch(userActions.setUsers([]));
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getUser = (idUser: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(userActions.setUser());
    const user = await serviceUser.getUser(idUser);
    dispatch(userActions.setUser(user));
  } catch (error) {
    dispatch(userActions.setUser());
    if (error instanceof Error) {
      dispatch(setSnackbar(error.message));
    }
  }
};

export const blockUser = (idUser: string, pagination: UserPagination) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceUser.blockUser(idUser);
    getUsersFiltered(pagination)(dispatch);
    dispatch(setSnackbar(USER_BLOCK, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const unlockUser = (idUser: string, pagination: UserPagination) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceUser.unlockUser(idUser);
    getUsersFiltered(pagination)(dispatch);
    dispatch(setSnackbar(USER_UNLOCK, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      dispatch(setSnackbar(error.message));
    }
  }
};

export const insertUser = (body: BodyCreateUser, pagination: UserPagination) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceUser.insertUser(body);
    getUsersFiltered(pagination)(dispatch);
    dispatch(setSnackbar(USER_INSERT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const importUserList = (body: FormData) => async (dispatch: Dispatch) => {
  try {
    const result = await serviceUser.importUserList(body);
    dispatch(userActions.setImportErrors(result));
    getUsersFiltered()(dispatch);
    if (!result.length) {
      dispatch(setSnackbar(USER_IMPORT, SUCCESS_SNACKBAR));
    }
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const insertAdmin = (body: BodyCreateUser) => async (dispatch: Dispatch) => {
  try {
    await serviceUser.insertAdmin(body);
    getUsersFiltered()(dispatch);
    dispatch(setSnackbar(ADMIN_INSERT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const insertAdminGlobal = (
  body: BodyCreateUser,
  company: Company,
  skipGetUsersFiltered = false,
) => async (dispatch: Dispatch) => {
  try {
    await serviceUser.insertAdminGlobal(body, company);
    if (!skipGetUsersFiltered) {
      getUsersFiltered()(dispatch);
    }
    dispatch(setSnackbar(ADMIN_INSERT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const editUser = (
  body: BodyCreateUser,
  idUser: string,
  pagination: UserPagination,
) => async (dispatch: Dispatch) => {
  try {
    await serviceUser.editUser(body, idUser);
    getUsersFiltered(pagination)(dispatch);
    dispatch(setSnackbar(USER_EDIT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const requestPasswordEmail = (body: BodyForgotPassword) => async (dispatch: Dispatch) => {
  try {
    await serviceUser.requestPasswordEmail(body);
    dispatch(setSnackbar(SEND_PASSWORD, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const redefinePassword = (body: BodyChangePassword) => async (dispatch: Dispatch) => {
  try {
    await serviceUser.redefinePassword(body);
    dispatch(setSnackbar(CHANGE_PASSWORD, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const deleteUser = (idUser: string, pagination: UserPagination) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceUser.deleteUser(idUser);
    getUsersFiltered(pagination)(dispatch);
    dispatch(setSnackbar(USER_DELETE, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getAllLevels = () => async (dispatch: Dispatch) => {
  try {
    const levels = await serviceUser.getAllLevels();
    dispatch(userActions.setLevels(levels));
  } catch (error) {
    dispatch(userActions.setLevels([]));
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const editPasswordUser = (idUser: string, password: string) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceUser.editPasswordUser(idUser, password);
    dispatch(setSnackbar(CHANGE_PASSWORD, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const getUserReport = (idUser: string, startDate: string, endDate: string) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(userActions.setUserReport());
    const userReport = await serviceUser.getUserReport(idUser, startDate, endDate);
    dispatch(userActions.setUserReport(userReport));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};
