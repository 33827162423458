import { Reducer } from 'redux';

import { TypeUserTypes, TypeUserState } from './typeUserTypes';

const INITIAL_STATE: TypeUserState = {
  typesUser: [],
  permissions: [],
  showPermissions: [],
};

const reducer: Reducer<TypeUserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TypeUserTypes.SET_TYPES_USER:
      return {
        ...state,
        typesUser: action.payload,
      };
    case TypeUserTypes.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    case TypeUserTypes.SET_SHOW_PERMISSIONS:
      return {
        ...state,
        showPermissions: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
