import { GraphicTypes } from '../enums/graphicTypes';
import { GraphicData, Legend } from '../modals/report/report';
import { colors } from './colors';
import { fonts } from './fonts';

type TooltipItem = {
  value: number;
  index: number;
};

type Dataset = {
  data: number[];
};

type TooltipData = {
  datasets: Dataset[];
};

const functionLabel = (tooltipItem: TooltipItem) => `${tooltipItem.value}%`;

const functionLabelPie = (tooltipItem: TooltipItem, data: TooltipData) => {
  const currentData = data.datasets[0].data[tooltipItem.index];
  return `${currentData}%`;
};

export const generateOptionsChartjsHozontalBar = (
  title: string,
  colorGraphic: string,
  typeGraphicId: number,
) => ({
  maintainAspectRatio: false,
  elements: {
    bar: {
      borderWidth: 0,
      backgroundColor: 'black',
    },
  },
  tooltips: {
    callbacks: {
      label: functionLabel,
    },
    displayColors: false,
    position: 'nearest',
  },
  title: {
    display: true,
    text: title,
    fontColor: colorGraphic,
    fontFamily: fonts.semiBold,
    padding: 24,
    fontSize: 16,
  },
  legend: {
    position: 'bottom',
    labels: {
      fontColor: colors.black,
      fontFamily: fonts.regular,
      fontSize: 10,
    },
  },
  scales: {
    xAxes: [
      {
        offset: false,
        gridLines: {
          display: true,
        },
        ticks: {
          callback: (value: string) =>
            typeGraphicId === GraphicTypes.horizontalBar ? `${value}%` : value,
          beginAtZero: true,
          min: 0,
          max: 100,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
        },
        ticks: {
          callback: (value: string) =>
            typeGraphicId === GraphicTypes.verticalBar ? `${value}%` : value,
          beginAtZero: true,
          min: 0,
          max: 100,
        },
      },
    ],
  },
});

export const generateOptionsChartjsPie = (title: string, colorGraphic: string) => ({
  maintainAspectRatio: false,
  elements: {
    bar: {
      borderWidth: 0,
      backgroundColor: 'black',
    },
  },
  tooltips: {
    callbacks: {
      label: functionLabelPie,
    },
    displayColors: false,
    position: 'nearest',
  },
  title: {
    display: true,
    text: title,
    fontColor: colorGraphic,
    fontFamily: fonts.semiBold,
    padding: 24,
    fontSize: 16,
  },
  legend: {
    position: 'bottom',
    labels: {
      fontColor: colors.black,
      fontFamily: fonts.regular,
      fontSize: 10,
    },
  },
});

export const convertDataGraphicToDataChartjs = (
  data: GraphicData[],
  idType: number,
  labelDataSet: string,
  colorGraphic: string,
  average?: number,
  extraLegends?: Legend[],
) => {
  const labels = [''].concat(data.map(item => item.text)).concat(['']);
  const typeGraphic = GraphicTypes.verticalBar === idType ? 'bar' : 'horizontalBar';
  const dataset = {
    type: typeGraphic,
    label: labelDataSet,
    data: ['0'].concat(data.map(item => (item.value * 100).toFixed(1))).concat(['0']),
    backgroundColor: [data[0]?.color || colorGraphic]
      .concat(data.map(item => (item.color ? item.color : colorGraphic)))
      .concat([colorGraphic]),
    barPercentage: 1,
  };
  let datasets = [];
  if (average) {
    datasets = [
      {
        type: 'line',
        label: 'Taxa média',
        data: [(average * 100).toFixed(1)]
          .concat(data.map(() => (average * 100).toFixed(1)))
          .concat([(average * 100).toFixed(1)]),
        borderColor: colors.orangeRegular,
        borderWidth: 2,
        fill: false,
      },
      dataset,
    ];
  } else {
    datasets = [dataset];
  }
  if (extraLegends) {
    extraLegends.forEach(legend => {
      datasets.push({
        label: legend.title,
        backgroundColor: legend.color,
      });
    });
  }
  return {
    labels,
    datasets,
  };
};
