import { ConnectApiGet, urls } from '../functions/connection';
import { doLogout } from '../functions/connection/auth';
import { ERROR_MAINTENANCE } from '../constants/messages';
import { GameableInterface } from '../../features/journeys/createEdit/CreateEditJourney';

export const getAll = async () => {
  try {
    const response = await ConnectApiGet(`${urls.JOURNEYS_URL}/steps`);
    const steps: GameableInterface[] = response.data;
    return steps;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      // dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }

  return [];
};
