import * as React from 'react';
import { colors } from '../../functions/colors';

type PIconStatus = {
  width?: number;
  height?: number;
  color?: string;
};

const IconStatus = ({ width, height, color }: PIconStatus) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="prefix_IconStatus"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={2}
      width={width}
      height={height}
    >
      <circle cx={8.001} cy={8.001} r={5.333} fill="#fff" />
    </mask>
    <g mask="url(#prefix_IconStatus)">
      <path fill={color} d="M0 0h16v16H0z" />
    </g>
  </svg>
);

IconStatus.defaultProps = {
  width: 12,
  height: 12,
  color: colors.grey80,
};

export default IconStatus;
