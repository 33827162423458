import { useDispatch, useSelector } from 'react-redux';
import { BodyInsertArea, GetAreasParams } from '../../../shared/modals/area/bodies';
import { RootStateGlobal } from '../../reducer';
import * as areaOperations from './areaOperations';
import * as areaActions from './areaActions';

export const useArea = () => {
  const dispatch = useDispatch();

  const { areas, errors } = useSelector((state: RootStateGlobal) => state.areaReducer);

  const getAllAreas = async ({ withDisabled }: GetAreasParams = {}) =>
    areaOperations.getAllAreas({ withDisabled })(dispatch);
  const insertArea = async (body: BodyInsertArea) => areaOperations.insertArea(body)(dispatch);
  const deleteArea = async (idArea: string) => areaOperations.deleteArea(idArea)(dispatch);
  const importAreas = async (body: FormData) => areaOperations.importAreas(body)(dispatch);
  const editArea = async (idArea: string, body: BodyInsertArea) =>
    areaOperations.editArea(idArea, body)(dispatch);
  const clearErrors = () => {
    dispatch(areaActions.setImportErrors([]));
  };

  return {
    areas,
    errors,
    getAllAreas,
    insertArea,
    deleteArea,
    editArea,
    importAreas,
    clearErrors,
  };
};
