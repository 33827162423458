import { Reducer } from 'redux';

import { StageTypes, StageState } from './stageTypes';

const INITIAL_STATE: StageState = {
  stages: [],
  stage: undefined,
};

const reducer: Reducer<StageState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StageTypes.SET_STAGES:
      return {
        ...state,
        stages: action.payload,
      };
    case StageTypes.SET_STAGE:
      return {
        ...state,
        stage: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
