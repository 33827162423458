import styled from 'styled-components';
import Button from '../../shared/components/buttons/Button';
import { DisplayFlexJustifyContent } from '../../shared/components/styled/general';
import { colors } from '../../shared/functions/colors';
import { fonts } from '../../shared/functions/fonts';

export const ContainerMoreError = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
  flex-flow: wrap;
  justify-content: center;
`;

export const BoxFlex = styled.div`
  display: flex;
`;

export const Title = styled.h1`
  font-size: 18px;
  color: ${colors.pinkDark};
  font-family: ${fonts.semiBold};
  margin-bottom: 15px;
`;

export const ButtonPrint = styled(Button)`
  margin-top: 16px;
  width: 220px;

  @media print {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderRanking = styled(DisplayFlexJustifyContent)`
  padding: 32px 40px;
`;
