import React from 'react';
import Hello from './hello/Hello';
import 'moment/locale/pt-br';
import { useUser } from '../../store/reducers/user';

const Home = () => {
  const { userData } = useUser();

  if (userData?.reportUrl) {
    return (
      <iframe
        title="TuToBI"
        width={window.innerWidth - 192}
        height={window.innerHeight}
        src={userData.reportUrl}
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
      />
    );
  }
  return <Hello />;
};

export default Home;
