import { Trail, TrailReport } from '../../../shared/modals/trail/trail';
import { TrailTypes } from './trailTypes';

export const setTrails = (trails: Trail[]) => ({
  type: TrailTypes.SET_TRAILS,
  payload: trails,
});

export const setTrail = (trail: Trail) => ({
  type: TrailTypes.SET_TRAIL,
  payload: trail,
});

export const setTrailReport = (trail?: TrailReport) => ({
  type: TrailTypes.SET_TRAIL_REPORT,
  payload: trail,
});
