import { Dispatch } from 'redux';
import { BodyInsertQuestion, BodyInsertCategory } from '../../../shared/modals/question/bodies';

import * as companyActions from './questionActions';
import * as serviceQuestion from '../../../shared/services/serviceQuestion';
import {
  CATEGORY_DELETE,
  CATEGORY_EDIT,
  CATEGORY_INSERT,
  ERROR_MAINTENANCE,
  QUESTION_DELETE,
  QUESTION_DUPLICATE,
  QUESTION_EDIT,
  QUESTION_IMPORT,
  QUESTION_INSERT,
} from '../../../shared/constants/messages';
import { doLogout } from '../../../shared/functions/connection/auth';
import { questionActions } from '.';
import { QuestionPagination } from '../../../shared/modals/question/question';
import { setSnackbar } from '../general/generalActions';
import { SUCCESS_SNACKBAR } from '../../../shared/components/snackbar/Snackbar';

const defaultPagination = { page: 1, limit: 10, constraints: {} };

export const getCategories = () => async (dispatch: Dispatch) => {
  try {
    const categories = await serviceQuestion.getCategories();
    dispatch(companyActions.setCategories(categories));
  } catch (error) {
    dispatch(companyActions.setCategories([]));
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const insertCategory = (body: BodyInsertCategory) => async (dispatch: Dispatch) => {
  try {
    await serviceQuestion.insertCategory(body);
    await getCategories()(dispatch);
    dispatch(setSnackbar(CATEGORY_INSERT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const editCategory = (body: BodyInsertCategory, idCategory: string) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceQuestion.editCategory(body, idCategory);
    await getCategories()(dispatch);
    dispatch(setSnackbar(CATEGORY_EDIT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const deleteCategory = (idCategory: string) => async (dispatch: Dispatch) => {
  try {
    await serviceQuestion.deleteCategory(idCategory);
    await getCategories()(dispatch);
    dispatch(setSnackbar(CATEGORY_DELETE, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getAllDataQuestion = (idQuestion: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(companyActions.setQuestion());
    const question = await serviceQuestion.getAllDataQuestion(idQuestion);
    dispatch(companyActions.setQuestion(question));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getQuestionsFiltered = (questionPagination?: QuestionPagination) => async (
  dispatch: Dispatch,
) => {
  try {
    const questions = await serviceQuestion.getQuestions(questionPagination || defaultPagination);
    dispatch(companyActions.setQuestions(questions.questions));
    dispatch(questionActions.setTotalCount(questions.paginate.totalCount));
  } catch (error) {
    dispatch(companyActions.setQuestions([]));
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const insertQuestion = (
  body: BodyInsertQuestion,
  questionPagination: QuestionPagination,
) => async (dispatch: Dispatch) => {
  try {
    const question = await serviceQuestion.insertQuestion(body);
    await getQuestionsFiltered(questionPagination)(dispatch);
    dispatch(setSnackbar(QUESTION_INSERT, SUCCESS_SNACKBAR));
    return question;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
    return undefined;
  }
};

export const importQuestionList = (
  body: FormData,
  questionPagination: QuestionPagination,
  idLibrary?: string,
) => async (dispatch: Dispatch) => {
  try {
    const question = await serviceQuestion.importQuestionList(body, !!idLibrary);
    dispatch(questionActions.setImportErrors(question));
    await getQuestionsFiltered(questionPagination)(dispatch);
    if (question.length === 0) {
      dispatch(setSnackbar(QUESTION_IMPORT, SUCCESS_SNACKBAR));
    }
    return question;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
    throw new Error('');
  }
};

export const importResearchList = (
  body: FormData,
  questionPagination: QuestionPagination,
  idLibrary?: string,
) => async (dispatch: Dispatch) => {
  try {
    const question = await serviceQuestion.importResearchList(body, !!idLibrary);
    dispatch(questionActions.setImportErrors(question));
    await getQuestionsFiltered(questionPagination)(dispatch);
    if (question.length === 0) {
      dispatch(setSnackbar(QUESTION_IMPORT, SUCCESS_SNACKBAR));
    }
    return question;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
    throw new Error('');
  }
};

export const editQuestion = (
  body: BodyInsertQuestion,
  idQuestion: string,
  pagination: QuestionPagination,
) => async (dispatch: Dispatch) => {
  try {
    const question = await serviceQuestion.editQuestion(body, idQuestion);
    await getQuestionsFiltered(pagination)(dispatch);
    dispatch(setSnackbar(QUESTION_EDIT, SUCCESS_SNACKBAR));
    return question;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
    return undefined;
  }
};

export const deleteQuestion = (idQuestion: string, pagination: QuestionPagination) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceQuestion.deleteQuestion(idQuestion);
    await getQuestionsFiltered(pagination)(dispatch);
    dispatch(setSnackbar(QUESTION_DELETE, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const duplicateQuestion = (idQuestion: string, pagination: QuestionPagination) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceQuestion.duplicateQuestion(idQuestion);
    await getQuestionsFiltered(pagination)(dispatch);
    dispatch(setSnackbar(QUESTION_DUPLICATE, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getInfoAnswerQuestion = (
  idQuestion: string,
  idContest?: string,
  idStage?: string,
) => async (dispatch: Dispatch) => {
  try {
    dispatch(questionActions.setQuestionInfoAnswer());
    const question = await serviceQuestion.getInfoAnswerQuestion(idQuestion, idContest, idStage);
    dispatch(questionActions.setQuestionInfoAnswer(question));
  } catch (error) {
    dispatch(questionActions.setQuestionInfoAnswer());
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};
