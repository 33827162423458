import { useDispatch, useSelector } from 'react-redux';

import { BodyTypeUser } from '../../../shared/modals/typeUser/bodies';
import { RootStateGlobal } from '../../reducer';
import * as typeUserOperations from './typeUserOperations';

export const useTypeUser = () => {
  const dispatch = useDispatch();

  const { typesUser, permissions, showPermissions } = useSelector(
    (state: RootStateGlobal) => state.typeUserReducer,
  );

  const getPermissions = async () => typeUserOperations.getPermissions()(dispatch);
  const getTypeUser = async () => typeUserOperations.getTypeUser()(dispatch);
  const insertTypeUser = async (bodyTypeUser: BodyTypeUser) =>
    typeUserOperations.insertTypeUser(bodyTypeUser)(dispatch);
  const editTypeUser = async (idTypeUser: number, bodyTypeUser: BodyTypeUser) =>
    typeUserOperations.editTypeUser(idTypeUser, bodyTypeUser)(dispatch);
  const deleteTypeUser = async (idTypeUser: number) =>
    typeUserOperations.deleteTypeUser(idTypeUser)(dispatch);
  const getShowPermissions = async () => typeUserOperations.getShowPermissions()(dispatch);

  return {
    typesUser,
    permissions,
    showPermissions,
    getPermissions,
    getTypeUser,
    insertTypeUser,
    editTypeUser,
    deleteTypeUser,
    getShowPermissions,
  };
};
