import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../../functions/colors';

type PIconAddBox = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconAddBox = ({ width, height, color, style }: PIconAddBox) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" style={style}>
    <mask id="prefix_IconAddBox" maskUnits="userSpaceOnUse" x={3} y={3} width={18} height={18}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2zm8 10h4v-2h-4V7h-2v4H7v2h4v4h2v-4z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#prefix_IconAddBox)">
      <path fill={color} d="M0 0h24v24H0z" />
    </g>
  </svg>
);

IconAddBox.defaultProps = {
  width: 24,
  height: 24,
  color: colors.grey80,
  style: {},
};

export default IconAddBox;
