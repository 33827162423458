import * as React from 'react';

const LinkIcon = ({ active, disabled }: { active: boolean; disabled: boolean }) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <mask id="linkIconMask" maskUnits="userSpaceOnUse" x={0} y={0} width="100%" height="100%">
      <rect x="0" y="0" width="100%" height="100%" fill={disabled ? '#777' : '#fff'} />
    </mask>
    <g>
      <path
        fill={active ? 'blue' : '#5f6368'}
        fillRule="evenodd"
        d="M1.9,4 C1.9,2.84 2.84,1.9 4,1.9 L8,1.9 L8,0 L4,0 C1.79,0 0,1.79 0,4 C0,6.21 1.79,8 4,8 L8,8 L8,6.1 L4,6.1 C2.84,6.1 1.9,5.16 1.9,4 L1.9,4 Z M14,0 L10,0 L10,1.9 L14,1.9 C15.16,1.9 16.1,2.84 16.1,4 C16.1,5.16 15.16,6.1 14,6.1 L10,6.1 L10,8 L14,8 C16.21,8 18,6.21 18,4 C18,1.79 16.21,0 14,0 L14,0 Z M6,5 L12,5 L12,3 L6,3 L6,5 L6,5 Z"
        transform="translate(0 5)"
        mask="url(#linkIconMask)"
      />
    </g>
  </svg>
);

export default LinkIcon;
