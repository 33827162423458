import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../../functions/colors';

type PIconThumbDown = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconThumbDown = ({ width, height, color, style }: PIconThumbDown) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" style={style}>
    <mask id="prefix_IconThumbDown" maskUnits="userSpaceOnUse" x={1} y={3} width={22} height={20}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3h9c1.1 0 2 .9 2 2v10c0 .55-.22 1.05-.59 1.41L9.83 23l-1.06-1.05c-.27-.27-.44-.65-.44-1.06l.03-.32.95-4.57H3c-1.1 0-2-.9-2-2v-2c0-.26.05-.5.14-.73l3.02-7.05C4.46 3.5 5.17 3 6 3zm17 0h-4v12h4V3z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#prefix_IconThumbDown)">
      <path fill={color} d="M0 0h24v24H0z" />
    </g>
  </svg>
);

IconThumbDown.defaultProps = {
  width: 24,
  height: 24,
  color: colors.orangeDark,
  style: {},
};

export default IconThumbDown;
