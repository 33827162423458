import React, { useEffect, useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ContainerMain, HeaderMain, TextHeaderMain } from '../../shared/components/styled/general';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import BoxHorizontalButtons from '../../shared/components/box/boxHorizontalButtons/BoxHorizontalButtons';
import { colors } from '../../shared/functions/colors';
import Table from '../../shared/components/table/Table';
import * as approvalsService from '../../shared/services/approvals';
import { filterArraySearch } from '../../shared/functions/utils';
import Tooltip from '../../shared/components/tooltip/Tooltip';
import IconStatus from '../../shared/images/icon/iconStatus';
import ApproveReject from './AproveReject';
import IconEye from '../../shared/images/icon/iconEye';
import ShowDetailsModal from './ShowDetailsModal';
import SearchInput from '../../shared/components/input/searchInput/SearchInput';

export type ApprovalInterface = {
  id: string;
  user: string;
  title: string;
  type: string;
  typeId: number;
  status: boolean | null;
  mission: string;
  question: string;
  answer: string;
  updatedAt: Date;
};

const ChallengeApprovals = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [approvals, setApprovals] = useState<ApprovalInterface[]>([]);
  const [filtered, setFiltered] = useState<ApprovalInterface[]>([]);
  const [currentApproval, setCurrentApproval] = useState<ApprovalInterface>();
  const [isDetailsModalEnabled, setIsDetailsModalEnabled] = useState<boolean>(false);

  const refresh = () => {
    approvalsService.getAll(dispatch).then(setApprovals);
  };

  useEffect(refresh, []);

  useEffect(() => {
    setFiltered(filterArraySearch(approvals, 'title', searchText));
  }, [approvals]);

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setFiltered(filterArraySearch(approvals, 'title', event.target.value));
  };

  const handleShowDetails = (approval: ApprovalInterface) => {
    setCurrentApproval(approval);
    setIsDetailsModalEnabled(true);
  };

  const handleCloseModal = () => {
    setCurrentApproval(undefined);
    setIsDetailsModalEnabled(false);
  };

  const renderIconStatus = (approval: ApprovalInterface) => {
    switch (approval.status) {
      case true:
        return (
          <div className="centered">
            <Tooltip title="Aprovado">
              <IconStatus color={colors.success} />
            </Tooltip>
          </div>
        );
      case false:
        return (
          <div className="centered">
            <Tooltip title="Rejeitado">
              <IconStatus color={colors.error} />
            </Tooltip>
          </div>
        );
      default:
        return (
          <div className="centered">
            <Tooltip title="Pendente">
              <IconStatus />
            </Tooltip>
          </div>
        );
    }
  };

  const renderRowsTable = (status?: (boolean | null)[]) =>
    filtered
      .filter(approval => !status || status.indexOf(approval.status) >= 0)
      .map((approval: ApprovalInterface) => ({
        columns: [
          renderIconStatus(approval),
          approval.user,
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleShowDetails(approval);
            }}
          >
            <IconEye />
          </div>,
          approval.title,
          approval.type,
          <ApproveReject approval={approval} onStatusChanged={refresh} />,
        ],
      }));

  const renderTable = (status?: (boolean | null)[]) => {
    return (
      <Table
        nameReferenceReduxTable="table_challenge_approvals"
        listRowsPerPage={[10, 25, 50, 100]}
        titles={[
          {
            name: 'Status',
            openClick: false,
            type: 'ReactNode',
            widthTd: '40px',
          },
          {
            name: 'Membro',
            openClick: true,
            type: 'string',
          },
          {
            name: '',
            openClick: false,
            type: 'ReactNode',
          },
          {
            name: 'Desafio',
            openClick: true,
            type: 'string',
          },
          {
            name: 'Tipo',
            openClick: true,
            type: 'string',
          },
          {
            name: 'Aprovar ou cancelar',
            openClick: false,
            type: 'ReactNode',
            widthTd: '200px',
          },
        ]}
        rows={renderRowsTable(status)}
      />
    );
  };

  return (
    <ContainerMain>
      {currentApproval && (
        <ShowDetailsModal
          title="Ver detalhes"
          approval={currentApproval}
          open={isDetailsModalEnabled}
          handleCloseModal={handleCloseModal}
        />
      )}
      <BreadCrumbs
        listMenus={[
          {
            name: 'Missões',
            action: () => {
              history.push('/missions');
            },
          },
          {
            name: 'Aprovações',
          },
        ]}
      />
      <HeaderMain>
        <TextHeaderMain>
          Abaixo os desafios que necessitam de aprovação. Clique para ver mais detalhes.
        </TextHeaderMain>
        <SearchInput
          placeholder="Buscar desafio..."
          search={searchText}
          onChange={handleChangeSearch}
        />
      </HeaderMain>
      <BoxHorizontalButtons
        items={[
          {
            textButton: 'Todas as respostas',
            children: renderTable(),
          },
          {
            textButton: 'Pendentes',
            children: renderTable([null]),
          },
          {
            textButton: 'Finalizadas',
            children: renderTable([true, false]),
          },
        ]}
      />
    </ContainerMain>
  );
};

export default ChallengeApprovals;
