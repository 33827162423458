import React, { ChangeEvent } from 'react';
import { CSSProperties } from 'styled-components';

import ReactPlayer from 'react-player';
import IconTrash from '../../../images/icon/iconTrash';
import ButtonIcon from '../../buttons/buttonIcon/ButtonIcon';

import { Container, BoxImage } from './styles';
import { Title, SubTitle, ContainerInput } from '../styles';

import Input from '../Input';

type PInputWebsite = {
  url: string;
  setUrl: (x: string) => void;
  title: string;
  subTitle?: string;
  style?: CSSProperties;
  isVideo?: boolean;
};

const InputWebsite = ({ title, subTitle, style, url, isVideo, setUrl }: PInputWebsite) => {
  const handleDeleteUrl = () => setUrl('');

  const handleChangeUrl = async (event: ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };

  return (
    <Container style={style}>
      <Title>{title}</Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      <ContainerInput>
        <Input onChange={handleChangeUrl} value={url} style={{ width: 'calc(100% - 40px)' }} />
        <ButtonIcon onClick={handleDeleteUrl}>
          <IconTrash />
        </ButtonIcon>
      </ContainerInput>
      <BoxImage>
        {url &&
          (isVideo ? (
            ReactPlayer.canPlay(url) && <ReactPlayer url={url} width="100%" height="100%" />
          ) : (
            <iframe src={url} title={url} />
          ))}
      </BoxImage>
    </Container>
  );
};

InputWebsite.defaultProps = {
  subTitle: '',
  isVideo: false,
  style: {},
};

export default InputWebsite;
