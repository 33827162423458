import React, { MouseEventHandler, ReactNode } from 'react';

import { Container, BoxChildren, Shadow } from './styles';

type PModal = {
  open: boolean;
  children: ReactNode;
  onClose: MouseEventHandler;
  maxWidth?: string;
};

const Modal = ({ open, children, onClose, maxWidth }: PModal) => {
  if (!open) {
    return null;
  }

  return (
    <Container>
      <Shadow onClick={onClose} />
      <BoxChildren maxWidth={maxWidth}>{children}</BoxChildren>
    </Container>
  );
};

Modal.defaultProps = {
  maxWidth: '600px',
};

export default Modal;
