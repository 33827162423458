import styled from 'styled-components';
import color from '../../shared/functions/colors';
import { fonts } from '../../shared/functions/fonts';

// const logo = require('../../shared/images/logo.png');

export const Item = styled.div`
  display: flex;
  justify-content: center;
`;

export const TopBar = styled.div`
  background-image: linear-gradient(90deg, ${color.purpleRegular} 0%, ${color.pinkRegular} 100%);
  width: 100%;
  height: 15vh;
  position: fixed;
  top: 0;
`;

export const BottomBar = styled.div`
  background-image: linear-gradient(90deg, ${color.purpleRegular} 0%, ${color.pinkRegular} 100%);
  width: 100%;
  height: 15vh;
  position: fixed;
  bottom: 0;
`;

export const Image = styled.img`
  max-height: 320px;
  max-width: 290px;
  margin-left: 80px;
  margin-right: 80px;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10%;
`;

export const BeginBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const ImageBox = styled.div`
  height: 400px;
  width: 366px;
  display: flex;
  background-color: ${color.grey40};
  justify-content: center;
  align-items: center;
  margin-left: 80px;
  margin-right: 80px;
`;

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10%;
`;

export const Logo = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 120px;
  min-width: 60px;
  max-height: 40px;
  height: 100%;
  margin-left: 35%;
  margin-bottom: 20px;
  min-height: 60px;
`;

export const StepTracker = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 150px;
  min-width: 60px;
  max-height: 40px;
  height: 100%;
  margin-left: 33%;
  min-height: 60px;
`;

export const BeginTitle = styled.h1`
  color: ${color.pinkDark};
  font-size: 18px;
  margin-top: 100px;
  font-family: ${fonts.semiBold};
`;

export const BeginSubtitle = styled.div`
  font-size: 10px;
  margin-top: 30px;
  font-family: ${fonts.regular};
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 50px;
  padding-top: 180px;
  text-align: center;
  color: ${color.primary};
  font-weight: 500;
`;

export const TitleLogin = styled.h1`
  font-size: 56px;
  margin-top: 40px;
  margin-left: 50px;
  color: ${color.primary};
  font-weight: 500;
`;

export const Form = styled.form`
  margin: 60px auto;
  width: 60%;
`;

export const ContainerInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BoxInput = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  background-color: transparent;
  border: 1px solid ${color.grey80};
  border-radius: 4px;
  padding: 8px;
  color: ${color.black};
  height: 40px;
  width: calc(100% - 40px);

  z-index: 1;
`;

export const InfoLocalFile = styled.p`
  color: ${color.black};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  width: calc(100% - 40px);

  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InputFile = styled.input`
  position: absolute;
  width: 100%;
  z-index: 2;
  opacity: 0;
`;
