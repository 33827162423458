import { Dispatch } from 'redux';
import { BodyInsertArea, GetAreasParams } from '../../../shared/modals/area/bodies';
import {
  AREA_DELETE,
  AREA_EDIT,
  AREA_IMPORT,
  AREA_INSERT,
  ERROR_MAINTENANCE,
} from '../../../shared/constants/messages';

import * as areaActions from './areaActions';
import * as serviceArea from '../../../shared/services/serviceArea';
import { doLogout } from '../../../shared/functions/connection/auth';
import { setSnackbar } from '../general/generalActions';
import { SUCCESS_SNACKBAR } from '../../../shared/components/snackbar/Snackbar';
import { getUsersFiltered } from '../user/userOperations';

export const getAllAreas = ({ withDisabled }: GetAreasParams = {}) => async (
  dispatch: Dispatch,
) => {
  try {
    const areas = await serviceArea.getAllAreas({ withDisabled });
    dispatch(areaActions.setAreas(areas));
  } catch (error) {
    dispatch(areaActions.setAreas([]));
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const insertArea = (body: BodyInsertArea) => async (dispatch: Dispatch) => {
  try {
    await serviceArea.insertArea(body);
    await getAllAreas({ withDisabled: true })(dispatch);
    await getUsersFiltered()(dispatch);
    dispatch(setSnackbar(AREA_INSERT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const deleteArea = (idArea: string) => async (dispatch: Dispatch) => {
  try {
    await serviceArea.deleteArea(idArea);
    await getAllAreas({ withDisabled: true })(dispatch);
    await getUsersFiltered()(dispatch);
    dispatch(setSnackbar(AREA_DELETE, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const editArea = (idArea: string, body: BodyInsertArea) => async (dispatch: Dispatch) => {
  try {
    await serviceArea.editArea(idArea, body);
    await getAllAreas({ withDisabled: true })(dispatch);
    await getUsersFiltered()(dispatch);
    dispatch(setSnackbar(AREA_EDIT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const importAreas = (body: FormData) => async (dispatch: Dispatch) => {
  try {
    const result = await serviceArea.importAreas(body);
    dispatch(areaActions.setImportErrors(result));
    if (!result.length) {
      dispatch(setSnackbar(AREA_IMPORT, SUCCESS_SNACKBAR));
    }
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};
