import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import Button from '../../shared/components/buttons/Button';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import IconEdit from '../../shared/images/icon/iconEdit';
import IconTrash from '../../shared/images/icon/iconTrash';
import { Trail as ITrail } from '../../shared/modals/trail/trail';
import { useTrail } from '../../store/reducers/trail';
import InsertTrail from './insertTrail/InsertTrail';
import DeleteTrail from './deleteTrail/DeleteTrail';
import { Container, Header, TextHeader, BoxHeaderButtons, DisplayFlex } from './styles';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import Table from '../../shared/components/table/Table';
import { MenuUrl } from '../../shared/enums/menuUrl';
import { colors } from '../../shared/functions/colors';
import { filterArraySearch } from '../../shared/functions/utils';
import { dateTimeStampToString } from '../../shared/functions/date';
import IconCheck from '../../shared/images/icon/iconCheck';
import IconBlock from '../../shared/images/icon/iconBlock';
import EnableTrail from './enableTrail/EnableTrail';
import IconDuplicate from '../../shared/images/icon/iconDuplicate';
import DuplicateTrail from './duplicateTrail/DuplicateTrail';
import BoxOnBoarding from '../../shared/components/onBoarding/BoxOnBoarding';
import IconReport from '../../shared/images/icon/iconReport';
import FilterTrail, { ClickFilterHandle } from './filterTrail/FilterTrail';
import BoxHorizontalButtons from '../../shared/components/box/boxHorizontalButtons/BoxHorizontalButtons';
import SearchInput from '../../shared/components/input/searchInput/SearchInput';

type ParamTypes = {
  idTrail?: string;
};

const Trail = () => {
  const { idTrail } = useParams<ParamTypes>();
  const { trails, getTrails } = useTrail();
  const history = useHistory();
  const [openInsertTrail, setOpenInsertTrail] = useState(false);
  const [openDeleteTrail, setOpenDeleteTrail] = useState(false);
  const [openEnableTrail, setOpenEnableTrail] = useState(false);
  const [openDuplicateTrail, setOpenDuplicateTrail] = useState(false);
  const [trailAction, setTrailAction] = useState<ITrail>();
  const [idTrailEdit, setIdTrailEdit] = useState<string | undefined>(undefined);
  const [searchTrail, setSearchTrail] = useState('');
  const [trailFilter, setTrailFilter] = useState<ITrail[]>([]);
  const [openFilter, setOpenFilter] = useState(false);
  const childRef = useRef<ClickFilterHandle>();

  useEffect(() => {
    getTrails();
    if (idTrail) {
      setIdTrailEdit(idTrail);
      setOpenInsertTrail(true);
    }
  }, []);

  useEffect(() => {
    setTrailFilter(filterArraySearch(trails, 'name', searchTrail));
  }, [trails]);

  const handleChangeSearchTrail = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTrail(event.target.value);
    setTrailFilter(filterArraySearch(trails, 'name', event.target.value));
  };

  const handleOpenInsertTrail = (idTrailCurrent: string | undefined) => {
    setIdTrailEdit(idTrailCurrent);
    setOpenInsertTrail(true);
  };

  const handleOnClickDeleteTrail = (trail: ITrail) => {
    setTrailAction(trail);
    setOpenDeleteTrail(true);
  };

  const handleOnClickDuplicateTrail = (trail: ITrail) => {
    setTrailAction(trail);
    setOpenDuplicateTrail(true);
  };

  const handleOnClickEnableTrail = (trail: ITrail) => {
    setTrailAction(trail);
    setOpenEnableTrail(true);
  };

  const handleOnClickTrailStages = (idTrailCurrent: string) => {
    history.push(MenuUrl.stage, { idTrail: idTrailCurrent });
  };

  const handleOpenReportTrail = (trail: ITrail) => {
    history.push(`${MenuUrl.trailReport}/${trail.idTrail}`);
  };

  if (openInsertTrail) {
    return <InsertTrail setOpenInsertTrail={setOpenInsertTrail} idTrailEdit={idTrailEdit} />;
  }

  const renderRowsTable = (trailStatusFiltered: ITrail[]) =>
    trailStatusFiltered.map((trail, index) => ({
      columns: [
        trail.startDate ? dateTimeStampToString(trail.startDate) : '',
        trail.endDate ? dateTimeStampToString(trail.endDate) : '',
        trail.name,
        <>
          {index === 0 ? (
            <BoxOnBoarding idsShowOnBoarding={[7]}>
              <DisplayFlex>
                {trail.stages?.length}
                <ButtonIcon
                  style={{ marginLeft: 16 }}
                  onClick={() => handleOnClickTrailStages(trail.idTrail)}
                >
                  <IconEdit />
                </ButtonIcon>
              </DisplayFlex>
            </BoxOnBoarding>
          ) : (
            <DisplayFlex>
              {trail.stages?.length}
              <ButtonIcon
                style={{ marginLeft: 16 }}
                onClick={() => handleOnClickTrailStages(trail.idTrail)}
              >
                <IconEdit />
              </ButtonIcon>
            </DisplayFlex>
          )}
        </>,
        <>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleOnClickEnableTrail(trail)}>
            {trail.enabled ? <IconCheck color={colors.green} /> : <IconBlock color={colors.red} />}
          </ButtonIcon>
        </>,
        <>
          <ButtonIcon
            style={{ marginRight: 16 }}
            onClick={() => handleOpenInsertTrail(trail.idTrail)}
          >
            <IconEdit />
          </ButtonIcon>
          <ButtonIcon
            style={{ marginRight: 16 }}
            onClick={() => handleOnClickDuplicateTrail(trail)}
          >
            <IconDuplicate />
          </ButtonIcon>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleOnClickDeleteTrail(trail)}>
            <IconTrash />
          </ButtonIcon>
          {!!trail.stages?.length && (
            <ButtonIcon onClick={() => handleOpenReportTrail(trail)}>
              <IconReport color={colors.grey100} />
            </ButtonIcon>
          )}
        </>,
      ],
    }));

  const renderTable = (trailStatusFiltered: ITrail[]) => {
    return (
      <Table
        nameReferenceReduxTable="table_trail"
        listRowsPerPage={[10, 25, 50, 100]}
        titles={[
          {
            name: 'Início',
            openClick: true,
            type: 'date',
            widthTd: '15%',
          },
          {
            name: 'Fim',
            openClick: true,
            type: 'date',
            widthTd: '15%',
          },
          {
            name: 'Nome da trilha',
            openClick: true,
            type: 'string',
            widthTd: '30%',
          },
          {
            name: (
              <BoxOnBoarding idsShowOnBoarding={trailFilter.length > 0 ? [] : [7]}>
                Fases da trilha
              </BoxOnBoarding>
            ),
            openClick: false,
            type: 'ReactNode',
            widthTd: '10%',
          },
          {
            name: 'Permissões',
            openClick: false,
            type: 'ReactNode',
            widthTd: '10%',
          },
          {
            name: 'Ações',
            openClick: false,
            type: 'ReactNode',
            widthTd: '20%',
          },
        ]}
        rows={renderRowsTable(trailStatusFiltered)}
        withFilter
        setOpenFilter={setOpenFilter}
      />
    );
  };

  return (
    <Container>
      <FilterTrail openFilter={openFilter} setOpenFilter={setOpenFilter} ref={childRef} />
      <EnableTrail
        trail={trailAction}
        openEnableTrail={openEnableTrail}
        setOpenEnableTrail={setOpenEnableTrail}
      />
      <DeleteTrail
        trail={trailAction}
        openDeleteTrail={openDeleteTrail}
        setOpenDeleteTrail={setOpenDeleteTrail}
      />
      <DuplicateTrail
        trail={trailAction}
        openDuplicateTrail={openDuplicateTrail}
        setOpenDuplicateTrail={setOpenDuplicateTrail}
      />
      <BreadCrumbs
        listMenus={[
          {
            name: 'Trilhas',
          },
        ]}
      />
      <Header>
        <TextHeader>
          Você pode criar, excluir, editar ou adicionar fases nas trilhas cadastradas.
        </TextHeader>
        <BoxHeaderButtons>
          <SearchInput
            placeholder="Buscar trilha..."
            search={searchTrail}
            onChange={handleChangeSearchTrail}
          />
          <Button
            style={{ width: 180 }}
            onClick={() => handleOpenInsertTrail(undefined)}
            icon="plus"
          >
            NOVA TRILHA
          </Button>
        </BoxHeaderButtons>
      </Header>
      <BoxHorizontalButtons
        items={[
          {
            textButton: 'Trilhas Ativas',
            children: renderTable(trailFilter.filter(trail => trail.enabled)),
          },
          {
            textButton: 'Trilhas Inativas',
            children: renderTable(trailFilter.filter(trail => !trail.enabled)),
          },
        ]}
      />
    </Container>
  );
};

export default Trail;
