import React, { useState, Dispatch, SetStateAction } from 'react';

import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Contest } from '../../../shared/modals/contest/contest';
import { useContest } from '../../../store/reducers/contest';

type PEnableContest = {
  openEnableContest: boolean;
  setOpenEnableContest: Dispatch<SetStateAction<boolean>>;
  contest: Contest | undefined;
};

const EnableContest = ({ openEnableContest, setOpenEnableContest, contest }: PEnableContest) => {
  const [loading, setLoading] = useState(false);
  const { changeContestEnable } = useContest();

  if (!contest) return null;

  const handleEnableContest = async () => {
    setLoading(true);
    await changeContestEnable(contest.idTourney);
    setLoading(false);
    setOpenEnableContest(false);
  };

  const title = contest.active ? 'Desativar torneio' : 'Ativar torneio';
  const message = contest.active
    ? 'Esse torneio irá desaparecer para os usuários. Tem certeza que deseja desativar esse torneio?'
    : 'Esse torneio irá aparecer para os usuários. Tem certeza que deseja ativar esse torneio?';

  return (
    <>
      <ModalConfirm
        loadingPrimary={loading}
        title={title}
        message={message}
        onClose={() => setOpenEnableContest(false)}
        open={openEnableContest}
        onClickPrimary={handleEnableContest}
        onClickSecondary={() => setOpenEnableContest(false)}
      />
    </>
  );
};

export default EnableContest;
