import React, {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Editor,
  EditorState,
  RichUtils,
  CompositeDecorator,
  Modifier,
  getDefaultKeyBinding,
  convertToRaw,
  ContentState,
  ContentBlock,
  convertFromRaw,
  EditorChangeType,
} from 'draft-js';
import './RichTextEditor.css';
import 'draft-js/dist/Draft.css';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as RichEditorIcons from '../../images/icon/richEditor';
import { TextPlaceHolder } from '../input/styles';

type RichTextEditorInterface = {
  value: string;
  maxLength?: number;
  style?: CSSProperties;
  label?: string;
  onChange: (content: string) => void;
};

const findLinkEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
};

const Link = (props: { contentState: ContentState; entityKey: string; children: ReactNode }) => {
  const { contentState, entityKey, children } = props;
  const { url } = contentState.getEntity(entityKey).getData();

  return (
    <a href={url} style={{ color: '#3b5998', textDecoration: 'underline' }}>
      {children}
    </a>
  );
};

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block: ContentBlock) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return '';
  }
}

type StyleButtonInterface = {
  style: inlineStyle | blockStyle;
  active: boolean;
  children: ({ active, disabled }: { active: boolean; disabled: boolean }) => ReactNode;
  onToggle: (x: inlineStyle | blockStyle) => void;
};

const StyleButton = ({ style, active, children, onToggle }: StyleButtonInterface) => {
  let className = 'RichEditor-styleButton';
  if (active) className += ' RichEditor-activeButton';

  return (
    <span
      className={className}
      onMouseDown={e => {
        e.preventDefault();
        onToggle(style);
      }}
    >
      {children({ active, disabled: false })}
    </span>
  );
};

type blockStyle =
  | 'header-one'
  | 'header-two'
  | 'header-three'
  | 'header-four'
  | 'header-five'
  | 'header-six'
  | 'blockquote'
  | 'unordered-list-item'
  | 'ordered-list-item'
  | 'code-block';
type BlockTypeInterface = {
  style: blockStyle;
  children: ({ active, disabled }: { active: boolean; disabled: boolean }) => ReactNode;
};
const BLOCK_TYPES: BlockTypeInterface[] = [
  { style: 'blockquote', children: RichEditorIcons.BlockQuoteIcon },
  { style: 'unordered-list-item', children: RichEditorIcons.UnorderedListIcon },
  { style: 'ordered-list-item', children: RichEditorIcons.OrderedListIcon },
];

const BlockStyleControls = ({
  editorState,
  onToggle,
}: {
  editorState: EditorState;
  onToggle: (x: string) => void;
}) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.style}
          active={type.style === blockType}
          onToggle={onToggle}
          style={type.style}
        >
          {type.children}
        </StyleButton>
      ))}
    </div>
  );
};

type inlineStyle = 'BOLD' | 'ITALIC' | 'UNDERLINE' | 'CODE';
type InlineStyle = {
  children: ({ active, disabled }: { active: boolean; disabled: boolean }) => ReactNode;
  style: inlineStyle;
};
const INLINE_STYLES: InlineStyle[] = [
  { style: 'BOLD', children: RichEditorIcons.BoldIcon },
  { style: 'ITALIC', children: RichEditorIcons.ItalicIcon },
  { style: 'UNDERLINE', children: RichEditorIcons.UnderlineIcon },
];

const InlineStyleControls = ({
  editorState,
  onToggle,
}: {
  editorState: EditorState;
  onToggle: (x: string) => void;
}) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.style}
          active={currentStyle.has(type.style)}
          onToggle={onToggle}
          style={type.style}
        >
          {type.children}
        </StyleButton>
      ))}
    </div>
  );
};

const RichTextEditor = ({
  label,
  value,
  maxLength,
  style,
  onChange: onChangeCallback,
}: RichTextEditorInterface) => {
  const [state, setState] = useState({
    editorState: EditorState.createEmpty(decorator),
    showURLInput: false,
    urlValue: '',
    textLength: 0,
    // Once user starts to make changes to the editor field, the initialLoad
    // attribute is set to false. It's helpful to load the presentation
    // content properly when editing.
    initialLoad: true,
  });

  useEffect(() => {
    if (!state.initialLoad) return;
    let initialContent: ContentState;

    // Our first version of presentations stored presentation.content as a
    // simple string. Below check makes the old stored values still loadable
    // by the rich-text editor.
    try {
      // If conversion succeeds, it's a valid Draft-js object
      initialContent = convertFromRaw(JSON.parse(value));
    } catch {
      // If it throws, that's because it's a simple string or a null value.
      // Thus, we create a valid Draft-js object from the string so that we
      // can initialize the editor.
      initialContent = ContentState.createFromText(value || '');
    }

    setState(currentState => ({
      ...currentState,
      editorState: EditorState.createWithContent(initialContent, decorator),
      textLength: initialContent.getPlainText().length,
    }));
  }, [value]);

  const editorRef = useRef<Editor>(null);
  const urlInputRef = useRef<HTMLInputElement>(null);

  const focus = () => editorRef.current?.editor?.focus();
  const onChange = (editorState: EditorState) => {
    const content = editorState.getCurrentContent();
    const textLength = content.getPlainText().length;
    const newState = {
      editorState,
      textLength,
      showURLInput: false,
      initialLoad: false,
    };

    onChangeCallback(JSON.stringify(convertToRaw(content)));
    setState(currentState => ({
      ...currentState,
      ...newState,
    }));
  };

  const promptForLink = () => {
    // e.preventDefault();

    const { editorState } = state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        // eslint-disable-next-line prefer-destructuring
        url = linkInstance.getData().url;
      }

      const newState = {
        showURLInput: true,
        urlValue: url,
      };

      // setTimeout(...) ensures the input associated to urlInputRef will be
      // available by the time callback is called.
      setTimeout(() => {
        if (urlInputRef.current) urlInputRef.current.focus();
      }, 0);

      setState(currentState => ({ ...currentState, ...newState }));
    }
  };

  const renderLinkControl = (editorState: EditorState) => {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
    const props = selection.isCollapsed()
      ? { active: false, disabled: true }
      : { active: !!linkKey, disabled: false };

    return (
      <span className="RichEditor-styleButton" onClick={promptForLink}>
        <RichEditorIcons.LinkIcon {...props} />
      </span>
    );
  };

  const onURLChange = (e: ChangeEvent<HTMLInputElement>) => {
    const urlValue = e.target.value;

    setState(currentState => ({ ...currentState, urlValue }));
  };

  const confirmLink = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const { editorState, urlValue } = state;
    let newState: { editorState: EditorState; showURLInput: boolean; urlValue: string };

    if (urlValue.length) {
      const regex = new RegExp('^[a-z]+://');
      const formattedUrl = regex.test(urlValue) ? urlValue : `http://${urlValue}`;
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
        url: formattedUrl,
      });
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      });
      newState = {
        editorState: RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey),
        showURLInput: false,
        urlValue: '',
      };
    } else {
      const selection = editorState.getSelection();

      newState = {
        editorState: RichUtils.toggleLink(editorState, selection, null),
        showURLInput: false,
        urlValue: '',
      };
    }

    focus();
    setState(currentState => ({ ...currentState, ...newState }));
  };

  const onLinkInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.which !== 13) return;

    confirmLink(e);
  };

  const handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      onChange(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const mapKeyToEditorCommand = (e: KeyboardEvent<Record<string, unknown>>) => {
    if (e.keyCode !== 9 /* TAB */) return getDefaultKeyBinding(e);

    const { editorState } = state;
    const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);
    if (newEditorState !== editorState) onChange(newEditorState);
    return null;
  };

  const toggleBlockType = (blockType: string) => {
    onChange(RichUtils.toggleBlockType(state.editorState, blockType));
  };

  const toggleInlineStyle = (inlineStl: string) => {
    onChange(RichUtils.toggleInlineStyle(state.editorState, inlineStl));
  };

  const getLengthOfSelectedText = () => {
    const { editorState } = state;
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);

      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const handleBeforeInput = (
    chars: string | KeyboardEvent<Record<string, unknown>>,
    editorState: EditorState,
  ) => {
    if (!maxLength) return 'not-handled';

    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText();

    return currentContentLength - selectedTextLength >= maxLength ? 'handled' : 'not-handled';
  };

  const handlePastedText = (pastedText: string, html: string, editorState: EditorState) => {
    if (!maxLength) return 'not-handled';

    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText();
    const selectionState = editorState.getSelection();

    if (currentContentLength + pastedText.length - selectedTextLength > maxLength) {
      const newContentState = Modifier.replaceText(
        currentContent,
        selectionState,
        pastedText.substring(0, maxLength - currentContentLength + selectedTextLength),
      );
      setState(currentState => ({
        ...currentState,
        editorState: EditorState.push(
          editorState,
          newContentState,
          'replace-text' as EditorChangeType,
        ),
        textLength: maxLength,
      }));

      return 'handled';
    }

    return 'not-handled';
  };

  const { editorState, textLength, showURLInput, urlValue } = state;

  let urlInput;
  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it. Let's just hide it now.
  let className = 'RichEditor-editor';
  const contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (
      contentState
        .getBlockMap()
        .first()
        .getType() !== 'unstyled'
    ) {
      className += ' RichEditor-hidePlaceholder';
    }
  }

  if (showURLInput) {
    urlInput = (
      <div style={{ marginBottom: 10 }}>
        <input
          onChange={onURLChange}
          ref={urlInputRef}
          style={{ marginRight: 10, padding: 3 }}
          type="text"
          value={urlValue}
          onKeyDown={onLinkInputKeyDown}
          placeholder="https://..."
        />
        <button
          style={{
            backgroundColor: '#512C85',
            color: '#fff',
            border: 'none',
            padding: 4,
            borderRadius: 3,
          }}
          onMouseDown={confirmLink}
          type="submit"
        >
          Inserir
        </button>
      </div>
    );
  }

  return (
    <>
      {label && <TextPlaceHolder>{label}</TextPlaceHolder>}
      <div className="RichEditor-root" style={style}>
        <div style={{ display: 'flex' }}>
          <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} />
          <BlockStyleControls editorState={editorState} onToggle={toggleBlockType} />
          {renderLinkControl(editorState)}
        </div>
        {urlInput}
        <div className={className} onClick={focus}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={onChange}
            placeholder="Digite..."
            ref={editorRef}
            handleKeyCommand={handleKeyCommand}
            handleBeforeInput={handleBeforeInput}
            handlePastedText={handlePastedText}
            handleReturn={handleBeforeInput}
            stripPastedStyles
            spellCheck
          />
          {!!maxLength && <p className="RichEditor__charCount">{`${textLength}/${maxLength}`}</p>}
        </div>
      </div>
    </>
  );
};

RichTextEditor.defaultProps = {
  maxLength: 0,
  style: undefined,
  label: '',
};

export default RichTextEditor;
