import { useDispatch, useSelector } from 'react-redux';
import { BodyCreateTrail } from '../../../shared/modals/trail/bodies';
import { RootStateGlobal } from '../../reducer';
import * as trailOperations from './trailOperations';

export const useTrail = () => {
  const dispatch = useDispatch();

  const { trails, trail, trailReport } = useSelector(
    (state: RootStateGlobal) => state.trailReducer,
  );

  const getTrails = async (
    startDate?: string,
    endDate?: string,
    idAreas?: string[],
    enabled?: boolean,
  ) => trailOperations.getTrails(startDate, endDate, idAreas, enabled)(dispatch);
  const insertTrail = async (bodyCreateTrail: BodyCreateTrail) =>
    trailOperations.insertTrail(bodyCreateTrail)(dispatch);
  const editTrail = async (bodyCreateTrail: BodyCreateTrail, idTrail: string) =>
    trailOperations.editTrail(bodyCreateTrail, idTrail)(dispatch);
  const changeTrailEnable = async (idTrail: string) =>
    trailOperations.changeTrailEnable(idTrail)(dispatch);
  const getTrail = async (idTrail: string) => trailOperations.getTrail(idTrail)(dispatch);
  const deleteTrail = async (idTrail: string) => trailOperations.deleteTrail(idTrail)(dispatch);
  const duplicateTrail = async (idTrail: string) =>
    trailOperations.duplicateTrail(idTrail)(dispatch);
  const getTrailReport = async (idTrail: string) =>
    trailOperations.getTrailReport(idTrail)(dispatch);

  return {
    trails,
    trail,
    trailReport,
    getTrails,
    insertTrail,
    editTrail,
    changeTrailEnable,
    getTrail,
    deleteTrail,
    duplicateTrail,
    getTrailReport,
  };
};
