import {
  ConnectApiDelete,
  ConnectApiGet,
  ConnectApiPost,
  ConnectApiPUT,
  urls,
} from '../functions/connection';
import { BodyInsertLibrary, BodyImportLibrary } from '../modals/library/bodies';

export const getLibraries = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_LIBRARY);
  return respostaServico.data;
};

export const getLibraryById = async (idLibrary: string) => {
  const url = urls.URL_LIBRARY_ID.replace('{idLibrary}', `${idLibrary}`);
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const deleteLibrary = async (idLibrary: string) => {
  const url = urls.URL_LIBRARY_ID.replace('{idLibrary}', `${idLibrary}`);
  const respostaServico = await ConnectApiDelete(url);
  return respostaServico.data;
};

export const insertLibrary = async (body: BodyInsertLibrary) => {
  const respostaServico = await ConnectApiPost(urls.URL_LIBRARY, body);
  return respostaServico.data;
};

export const editLibrary = async (idLibrary: string, body: BodyInsertLibrary) => {
  const url = urls.URL_LIBRARY_ID.replace('{idLibrary}', `${idLibrary}`);
  const respostaServico = await ConnectApiPUT(url, body);
  return respostaServico.data;
};

export const importLibrary = async (body: BodyImportLibrary) => {
  const respostaServico = await ConnectApiPost(urls.URL_LIBRARY_IMPORT, body);
  return respostaServico.data;
};
