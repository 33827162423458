import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as journeysService from '../../../shared/services/journeys';

import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { JourneyInterface } from '../Journeys';

type PToggleJourneyEnabled = {
  journey: JourneyInterface;
  open: boolean;
  handleCloseModal: () => void;
  refreshList: () => void;
};

const ToggleJourneyEnabled = ({
  journey,
  open,
  handleCloseModal,
  refreshList,
}: PToggleJourneyEnabled) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  if (!journey) return null;

  const handleToggleEnabled = async () => {
    setLoading(true);
    await journeysService.update({ enabled: !journey.enabled }, journey.id, dispatch);
    refreshList();
    setLoading(false);
    handleCloseModal();
  };

  const title = journey.enabled ? 'Desativar jornada' : 'Ativar jornada';
  const message = journey.enabled
    ? 'Essa jornada irá desaparecer para os usuários. Tem certeza que deseja desativar essa jornada?'
    : 'Essa jornada irá aparecer para os usuários. Tem certeza que deseja ativar essa jornada?';

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title={title}
      message={message}
      onClose={handleCloseModal}
      open={open}
      onClickPrimary={handleToggleEnabled}
      onClickSecondary={handleCloseModal}
    />
  );
};

export default ToggleJourneyEnabled;
