import React, { Dispatch, SetStateAction, useState, useEffect, useCallback, useMemo } from 'react';

import { useCompany } from '../../../store/reducers/company';
import Button from '../../../shared/components/buttons/Button';
import Input from '../../../shared/components/input/Input';
import Modal from '../../../shared/components/modal/Modal';
import { BodyInsertCompany } from '../../../shared/modals/company/bodies';
import { BoxButtons, Title } from './styles';
import { Company } from '../../../shared/modals/user/user';
import { handleChangeFieldValue } from '../../../shared/functions/handlers';
import Checkbox from '../../../shared/components/input/checkbox/Checkbox';
import InputDate from '../../../shared/components/input/inputDate/InputDate';
import { useUser } from '../../../store/reducers/user';

type PInsertCompany = {
  openInsertCompany: boolean;
  setOpenInsertCompany: Dispatch<SetStateAction<boolean>>;
  isEdit: boolean;
  company: Company | undefined;
};

const initialBody = {
  name: '',
  usersLimit: 1_000_000_000,
  password: '',
  image: '',
  partner: false,
  admins: [],
  trialUntilDate: null,
};

const InsertCompany = ({
  openInsertCompany,
  setOpenInsertCompany,
  isEdit,
  company,
}: PInsertCompany) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [bodyInsertCompany, setBodyInsertCompany] = useState<BodyInsertCompany>(initialBody);
  const { insertCompany, editCompany } = useCompany();
  const { userData } = useUser();
  const userType = userData?.typeUser.name;

  useEffect(() => {
    if (isEdit && company) {
      setBodyInsertCompany({
        ...bodyInsertCompany,
        name: company.name,
        usersLimit: company.usersLimit,
        token: company.token,
        partner: company.partner,
        trialUntilDate: company.trialUntilDate,
      });
    } else {
      setBodyInsertCompany(initialBody);
    }
  }, [isEdit, company, openInsertCompany]);

  const handleInsertCompany = async () => {
    setLoading(true);
    try {
      if (isEdit && company) {
        await editCompany(company.idCompany, bodyInsertCompany);
      } else {
        await insertCompany(bodyInsertCompany);
      }
      setOpenInsertCompany(false);
      setBodyInsertCompany(initialBody);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
    setLoading(false);
  };

  const handleTrialUntilDateChange = useCallback(
    (date: string) => {
      if (userType === 'Vendedor') {
        if (new Date(date) > new Date(new Date().setDate(new Date().getDate() + 30))) {
          // eslint-disable-next-line no-alert
          alert('A data de trial tem de ser no máximo 30 dias a partir de hoje');
          return;
        }
      }

      setBodyInsertCompany(previousValue => ({ ...previousValue, trialUntilDate: date }));
    },
    [userType],
  );

  const submitButtonDisabled = useMemo(() => {
    let isDisabled = bodyInsertCompany.name === '';

    if (userType === 'Vendedor') {
      isDisabled = isDisabled || bodyInsertCompany.trialUntilDate === null;
    }

    return isDisabled;
  }, [userType, bodyInsertCompany.name, bodyInsertCompany.trialUntilDate]);

  const isTrialUntilDateDisabled = useMemo(() => {
    return userType === 'Vendedor' && isEdit;
  }, [userType, isEdit]);

  const handleClearTrial = useCallback(() => {
    setBodyInsertCompany(previousValue => ({ ...previousValue, trialUntilDate: null }));
  }, []);

  return (
    <Modal onClose={() => setOpenInsertCompany(false)} open={openInsertCompany}>
      <Title>
        {isEdit ? 'Editar' : 'Inserir'}
        {' Empresa'}
      </Title>
      <Input
        title="Nome da empresa"
        placeholder="Nome da empresa"
        value={bodyInsertCompany.name}
        name="name"
        onChange={event => handleChangeFieldValue(event, setBodyInsertCompany)}
      />
      <Input
        title="Número de usuários"
        placeholder="Número de usuários"
        value={bodyInsertCompany.usersLimit}
        name="usersLimit"
        type="number"
        onChange={event => handleChangeFieldValue(event, setBodyInsertCompany)}
      />
      <Input
        title="Token da empresa"
        placeholder="Esse campo é gerado automaticamente"
        value={bodyInsertCompany.token}
        disabled
      />
      <Checkbox
        idCheckbox="partner"
        name="partner"
        checked={bodyInsertCompany.partner}
        onChange={event => handleChangeFieldValue(event, setBodyInsertCompany)}
        text="Parceiro?"
      />
      {errorMessage}
      <InputDate
        title="Data limite de acesso(trial)"
        placeholder="Data limite de acesso(trial)"
        name="trialUntilDate"
        value={bodyInsertCompany.trialUntilDate || ''}
        onChangeDate={handleTrialUntilDateChange}
        disabled={isTrialUntilDateDisabled}
      />
      {userType === 'Administrador Geral' && (
        <Button style={{ width: 180 }} onClick={handleClearTrial}>
          Limpar trial
        </Button>
      )}
      <BoxButtons>
        <Button
          typeButton="primary-blue"
          style={{ width: 180 }}
          onClick={() => setOpenInsertCompany(false)}
        >
          Cancelar
        </Button>
        <Button
          disabled={submitButtonDisabled}
          loading={loading}
          style={{ width: 180 }}
          onClick={handleInsertCompany}
        >
          {isEdit ? 'Editar' : 'Inserir'}
        </Button>
      </BoxButtons>
    </Modal>
  );
};

export default InsertCompany;
