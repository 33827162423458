import React, { useState, Dispatch, SetStateAction } from 'react';

import { useLibrary } from '../../../store/reducers/library';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Library } from '../../../shared/modals/library/library';

type PDeleteLibrary = {
  openDeleteLibrary: boolean;
  setOpenDeleteLibrary: Dispatch<SetStateAction<boolean>>;
  library: Library | undefined;
};

const DeleteLibrary = ({ openDeleteLibrary, setOpenDeleteLibrary, library }: PDeleteLibrary) => {
  const [loadingBlock, setLoadingBlock] = useState(false);
  const { deleteLibrary } = useLibrary();

  if (!library) return null;

  const handleDeleteLibrary = async () => {
    setLoadingBlock(true);
    await deleteLibrary(library.idLibrary);
    setLoadingBlock(false);
    setOpenDeleteLibrary(false);
  };

  return (
    <ModalConfirm
      loadingPrimary={loadingBlock}
      message="Tem certeza que deseja excluir a biblioteca?"
      title="Excluir biblioteca"
      onClose={() => setOpenDeleteLibrary(false)}
      open={openDeleteLibrary}
      onClickPrimary={handleDeleteLibrary}
      onClickSecondary={() => setOpenDeleteLibrary(false)}
    />
  );
};

export default DeleteLibrary;
