/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import IconChevronBottom from '../../images/icon/iconChevronBottom';
import ButtonIcon from '../buttons/buttonIcon/ButtonIcon';
import InputFileImage from '../input/inputFileImage/InputFile';
import Button from '../buttons/Button';
import { colors } from '../../functions/colors';
import {
  Container,
  DisplayFlex,
  Title,
  Line,
  SubTitle,
  BoxShowPresentation,
  NameApresentation,
  AttachTitle,
} from './styles';
import { BodyPresentation, MediaType } from '../../modals/trail/bodies';
import InputRadio from '../input/inputRadio/InputRadio';
import RichTextEditor from '../richEditor/RichTextEditor';
import InputWebsite from '../input/inputWebsite/InputWebsite';
import { isVideo, isWebSite, noMedia, isFile, isSelfHostedVideo } from '../../functions/mediaType';
import InputPandaVideo from '../input/inputPandaVideo/InputPandaVideo';
import { useUser } from '../../../store/reducers/user';

export const BODY_START_PRESENTATION = {
  id: '',
  description: '',
  mediaUrl: '',
  mediaType: 'image' as MediaType,
  allowDownload: true,
};

type PPresentation = {
  presentations: BodyPresentation[];
  setPresentations: (x: BodyPresentation[]) => void;
};

const Presentation = ({ presentations, setPresentations }: PPresentation) => {
  const [showPresentation, setShowPresentation] = useState(true);
  const { userData } = useUser();

  const handleChangeMedia = (
    index: number,
    mediaType: MediaType,
    mediaUrl = '',
    mediaWidth?: number,
    mediaHeight?: number,
    mediaId?: string,
  ) => {
    if (presentations[index]) {
      presentations[index] = {
        ...presentations[index],
        mediaUrl,
        mediaType,
        mediaWidth,
        mediaHeight,
        mediaId,
      };
      setPresentations([...presentations]);
    }
  };

  const handleChangeAllowDownload = (allowDownload: boolean, index: number) => {
    if (presentations[index]) {
      presentations[index] = {
        ...presentations[index],
        allowDownload,
      };
      setPresentations([...presentations]);
    }
  };

  const handleChangeBody = (content: string, index: number) => {
    if (presentations[index]) {
      presentations[index] = {
        ...presentations[index],
        description: content,
      };
      setPresentations([...presentations]);
    }
  };

  const handleInsertSlide = () => {
    setPresentations([...presentations, { ...BODY_START_PRESENTATION }]);
  };

  const renderPreview = (
    index: number,
    mediaType: MediaType,
    mediaUrl: string,
    allowDownload: boolean,
    duplicated?: boolean,
    mediaId?: string,
  ) => {
    if (isFile(mediaType) || noMedia(mediaType)) {
      return (
        <InputFileImage
          title=" "
          subTitle=" "
          urlImage={mediaUrl}
          mediaType={mediaType}
          setUrlImage={(image, mediaType = 'image') => handleChangeMedia(index, mediaType, image)}
          setAllowDownload={allowDownload => handleChangeAllowDownload(allowDownload, index)}
          allowDownload={allowDownload}
          duplicated={duplicated}
          style={{ width: '50%' }}
          key={`img-${index}`}
        />
      );
    }
    if (isVideo(mediaType)) {
      return (
        <InputWebsite
          title=" "
          subTitle=" "
          url={mediaUrl}
          setUrl={url => handleChangeMedia(index, 'video', url)}
          style={{ width: '50%' }}
          key={`video-${index}`}
          isVideo
        />
      );
    }
    if (isSelfHostedVideo(mediaType)) {
      return (
        <InputPandaVideo
          title=" "
          subTitle=" "
          videoUrl={mediaUrl}
          videoId={mediaId}
          setMediaId={(id, width, height) =>
            handleChangeMedia(index, 'self-hosted-video', '', width, height, id)
          }
          style={{ width: '50%' }}
          key={`video-${index}`}
        />
      );
    }
    if (isWebSite(mediaType)) {
      return (
        <InputWebsite
          title=" "
          subTitle=" "
          url={mediaUrl}
          setUrl={url => handleChangeMedia(index, 'website', url)}
          style={{ width: '50%' }}
          key={`website-${index}`}
        />
      );
    }

    return null;
  };

  return (
    <Container>
      <DisplayFlex>
        <Title>Apresentação</Title>
        <Line />
        <ButtonIcon onClick={() => setShowPresentation(!showPresentation)}>
          <IconChevronBottom
            style={{
              transform: showPresentation ? 'rotate(180deg)' : 'rotate(0)',
              transition: '0.5s',
            }}
          />
        </ButtonIcon>
      </DisplayFlex>
      <BoxShowPresentation showPresentation={showPresentation} amountSlides={presentations.length}>
        <SubTitle>
          Insira abaixo quantos slides você desejar para criar a apresentação da trilha acima.
          (opcional).
        </SubTitle>
        {presentations.map((presentation: BodyPresentation, index: number) => {
          const {
            mediaType,
            mediaUrl,
            mediaId,
            description,
            allowDownload,
            duplicated,
          } = presentation;

          return (
            <>
              <NameApresentation key={`title-${index}`}>{`${index + 1}. SLIDE`}</NameApresentation>
              <DisplayFlex style={{ alignItems: 'baseline' }} key={`content-${index}`}>
                <RichTextEditor
                  value={description}
                  maxLength={500}
                  onChange={(content: string) => handleChangeBody(content, index)}
                  key={`richText-${index}`}
                />
                <div style={{ width: '40%' }} key={`attachWrapper-${index}`}>
                  <AttachTitle key={`attachTitle-${index}`}>
                    Inserir anexo(s) ou link para vídeo (opcional)
                  </AttachTitle>
                  <InputRadio
                    text="Anexos"
                    name={`slide_radio_${index}`}
                    id={`slide_radio_image_${index}`}
                    subtext="Inserir anexos (imagens, planilhas, pdfs...)"
                    onChange={() => handleChangeMedia(index, 'image')}
                    checked={isFile(mediaType) || noMedia(mediaType)}
                    key={`1radioAttach-${index}`}
                  />
                  <InputRadio
                    text="Link para vídeo"
                    name={`slide_radio_${index}`}
                    id={`slide_radio_video_${index}`}
                    subtext="Inserir link do YouTube ou Vimeo."
                    onChange={() => handleChangeMedia(index, 'video')}
                    checked={isVideo(mediaType)}
                    key={`radioLink-${index}`}
                  />
                  {[
                    '50297e22-ffe6-4d11-bf49-cc7d96ee0be4',
                    'c80ac265-1852-4bb9-af28-29e0977fb31e',
                    '8a6eca15-efe7-4c05-9139-8f7cc1ddaaf4',
                    '6d17892f-430c-4b94-9d74-2624740be7e8',
                    '01d9a846-818e-4f61-96cc-20c865f20e6e',
                    '9e0ea8d8-41c2-499b-9585-ac79fcf7b825',
                    '216f9a4b-12f3-4b94-9a46-71b73a798485',
                  ].includes(`${userData?.idCompany}`) && (
                    <InputRadio
                      text="Arquivo de vídeo"
                      name={`slide_radio_${index}`}
                      id={`slide_radio_video_file_${index}`}
                      subtext="Inserir arquivo de vídeo."
                      onChange={() => handleChangeMedia(index, 'self-hosted-video')}
                      checked={isSelfHostedVideo(mediaType)}
                      key={`radioVideoFile-${index}`}
                    />
                  )}
                  <InputRadio
                    text="Link externo"
                    name={`slide_radio_${index}`}
                    id={`slide_radio_website_${index}`}
                    subtext="Inserir link para página web externa."
                    onChange={() => handleChangeMedia(index, 'website')}
                    checked={mediaType === 'website'}
                    key={`radioWebsite-${index}`}
                  />
                </div>
                {renderPreview(index, mediaType, mediaUrl, allowDownload, duplicated, mediaId)}
              </DisplayFlex>
            </>
          );
        })}
        <Button
          onClick={handleInsertSlide}
          style={{ width: 220 }}
          typeButton="primary-white"
          icon="plusInnerBorder"
          colorIcon={colors.purpleRegular}
        >
          INSERIR NOVO SLIDE
        </Button>
      </BoxShowPresentation>
    </Container>
  );
};

export default Presentation;
