import React, { ChangeEvent, useEffect, useState } from 'react';
import Input from '../../../shared/components/input/Input';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import Select, { POptions } from '../../../shared/components/select/Select';
import { BodyInsertArea } from '../../../shared/modals/area/bodies';
import { Area } from '../../../shared/modals/area/area';
import Checkbox from '../../../shared/components/input/checkbox/Checkbox';
import { Container, Description, DisplayFlex } from './styles';
import { useArea } from '../../../store/reducers/area';

type PEditDeleteArea = {
  openModal: boolean;
  setOpenModal: (x: boolean) => void;
};

const initialBody = {
  name: '',
};

const EditDeleteArea = ({ openModal, setOpenModal }: PEditDeleteArea) => {
  const { areas, editArea, deleteArea } = useArea();
  const [loading, setLoading] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [isDeleteArea, setIsDeleteArea] = useState(false);
  const [bodyArea, setBodyArea] = useState<BodyInsertArea>(initialBody);

  useEffect(() => {
    setBodyArea(initialBody);
  }, [openModal]);

  const convertAreasToPOptions = (): POptions[] => {
    const arrayReturn: POptions[] = [
      {
        value: '',
        viewValue: 'Selecione uma área',
      },
    ];

    areas.forEach((area: Area) => {
      arrayReturn.push({
        value: `${area.idArea}`,
        viewValue: area.name,
      });
    });

    return arrayReturn;
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      if (bodyArea.idArea) {
        if (isDeleteArea) {
          await deleteArea(bodyArea.idArea);
        } else {
          await editArea(bodyArea.idArea, bodyArea);
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setMessageError(error.message);
      }
    }
    setOpenModal(false);
    setLoading(false);
  };

  const handleOnChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setBodyArea({
      ...bodyArea,
      name: event.target.value,
    });
  };

  const handleOnChangeCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
    setIsDeleteArea(event.target.checked);
  };

  const handleOnChangeSelectArea = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const idArea = event.target.value ? event.target.value : '';
    const areaSelected = areas.find(area => area.idArea === idArea);
    setBodyArea({
      name: areaSelected ? areaSelected.name : '',
      idArea,
    });
  };

  const disabledConfirm = () => {
    if (!bodyArea.idArea) {
      return true;
    }
    if (bodyArea.name === '' && !isDeleteArea) {
      return true;
    }
    return false;
  };

  const render = () => (
    <Container>
      <Description>
        Selecione abaixo a área que deseja editar ou excluir, e depois confirme:
      </Description>
      <DisplayFlex>
        <Select
          title="Área*"
          nameSelect="select_areas"
          listOptions={convertAreasToPOptions()}
          onChange={handleOnChangeSelectArea}
        />
        <Input
          title="Editar nome da área*"
          value={bodyArea.name}
          onChange={handleOnChangeInput}
          messageError={messageError}
          error={messageError !== ''}
          style={{ marginLeft: 16, marginRight: 16, width: 240 }}
        />
        <Checkbox onChange={handleOnChangeCheckBox} text="EXCLUIR" style={{ margin: 'auto' }} />
      </DisplayFlex>
    </Container>
  );

  return (
    <ModalConfirm
      loadingPrimary={loading}
      message={render()}
      onClickPrimary={handleConfirm}
      onClose={() => setOpenModal(false)}
      open={openModal}
      title="Editar/excluir área"
      onClickSecondary={() => setOpenModal(false)}
      textPrimary="CONFIRMAR"
      textSecondary="CANCELAR"
      disabledPrimary={disabledConfirm()}
    />
  );
};

export default EditDeleteArea;
