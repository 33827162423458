import styled from 'styled-components';
import ReactInputMask from 'react-input-mask';
import { colors } from '../../shared/functions/colors';
import Input from '../../shared/components/input/Input';
import { fonts } from '../../shared/functions/fonts';

export const InputPhone = styled(ReactInputMask)`
  display: block;
  text-align: left;
  margin-bottom: 16px;
  border: 1px solid #b1bbc2;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 0px 8px;
  color: #262824;
  height: 40px;
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

export const TitleWpp = styled.p`
  font-family: Poppins-SemiBold;
  color: #512c85;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  margin-bottom: 8px;
  margin-left: 4px;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 24px 0px;
  justify-content: space-between;
`;

export const TextHeader = styled.p`
  color: ${colors.black};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

export const SubTitle = styled.p`
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${colors.blueRegular};
`;

export const BoxHeaderButtons = styled.div`
  display: flex;
`;

export const ContainerFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const ImageIcon = styled.label`
  margin-left: 32px;
  cursor: pointer;
`;

export const ImageProfile = styled.img`
  margin: 24px auto 24px;
  max-width: 142px;
  max-height: 142px;
  width: 142px;
  height: 142px;
  border-radius: 71px;
  object-fit: contain;
  background-image: url(/img/transparent-bg_3.jpg);
`;

export const Container = styled.div`
  width: 100%;
  padding: 16px 40px;
`;

export const ContainerInsertUser = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-start;
`;

export const BoxInfo = styled.div`
  width: 100%;
  margin-top: 32px;
  background-color: ${colors.white};
`;

export const BoxButtonNewArea = styled.div`
  width: 200px;
  margin-left: 24px;
`;

export const BoxInsertUser = styled.div`
  width: 100%;
  padding: 24px;
  background-color: ${colors.white};
`;

export const InputUser = styled(Input)`
  display: block;
  text-align: left;
  margin-bottom: 16px;
`;

export const BoxBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const DisplayFlex = styled.div`
  width: 100%;
  display: flex;
`;

export const TextPlaceHolder = styled.p`
  font-family: ${fonts.semiBold};
  color: ${colors.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  margin-bottom: 8px;
  margin-left: 32px;
`;

export const TitleWrapper = styled.div`
  display: block;
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 330px;
`;

export const ContainerRadios = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
`;

export const Title = styled.h5`
  font-family: ${fonts.semiBold};
  font-size: 10px;
  line-height: 15px;
  padding-top: 15px;
  margin-left: 10px;
  color: ${colors.strongPurple};
`;

export const ContainerRadioText = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  justify-content: space-around;
`;

export const ContainerColors = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1600px) {
    justify-content: space-around;
  }
`;

export const ContainerHome = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 0;
  padding-bottom: 0;
  justify-content: flex-start;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
