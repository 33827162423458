import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import Button from '../../../shared/components/buttons/Button';
import Modal from '../../../shared/components/modal/Modal';
import {
  BoxButtons,
  Title,
  InputUser,
  Subtitle,
  Details,
  BoxSuccess,
  TitleSuccess,
  SubtitleSuccess,
  BoxButtonsSuccess,
} from './styles';
import { BodyChangePassword } from '../../../shared/modals/user/bodies';
import { useUser } from '../../../store/reducers/user';
import IconDoubleCheck from '../../../shared/images/icon/iconDoubleCheck';

type PChangePassword = {
  token?: string;
  email?: string;
  openChangePassword: boolean;
  setOpenChangePassword: Dispatch<SetStateAction<boolean>>;
};

const initialBody = {
  password: '',
};

const ChangePassword = ({
  token,
  email,
  openChangePassword,
  setOpenChangePassword,
}: PChangePassword) => {
  const { redefinePassword } = useUser();
  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [popupIndex, setPopupIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [bodyForgotPassword, setBodyForgotPassword] = useState<BodyChangePassword>({
    ...initialBody,
    passwordToken: token || '',
    email: email || '',
  });

  useEffect(() => {
    if (token && email)
      setBodyForgotPassword({
        ...initialBody,
        passwordToken: token,
        email,
      });
  }, [openChangePassword]);

  const handleConfirmPasswordChange = async () => {
    setLoading(true);
    try {
      await redefinePassword(bodyForgotPassword);
      setPopupIndex(1);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
    setLoading(false);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBodyForgotPassword({
      ...bodyForgotPassword,
      password: event.target.value,
    });
  };

  const handleChangeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  };

  const renderMessage = (index: number) => {
    switch (index) {
      case 0:
        return (
          <>
            <Title>Criar nova senha</Title>
            <Subtitle>Agora crie abaixo sua nova senha e confirme:</Subtitle>
            <InputUser
              title="Nova senha"
              placeholder="Digite a senha"
              type="password"
              value={bodyForgotPassword.password}
              onChange={handleChangePassword}
            />
            <InputUser
              title="Confirmar senha"
              placeholder="Digite a senha"
              type="password"
              value={confirmPassword}
              onChange={handleChangeConfirmPassword}
            />
            <Details>
              Atenção! Sua nova senha deve conter no mínimo 8 caracteres, uma letra maiúscula e um
              número.
            </Details>
            <BoxButtons>
              <Button
                style={{ width: 180, marginRight: 15 }}
                onClick={() => setOpenChangePassword(false)}
                typeButton="primary-white"
              >
                CANCELAR
              </Button>
              <Button
                disabled={bodyForgotPassword.password !== confirmPassword}
                loading={loading}
                style={{ width: 180 }}
                onClick={handleConfirmPasswordChange}
              >
                SALVAR
              </Button>
            </BoxButtons>
            {errorMessage}
          </>
        );
      case 1:
        return (
          <>
            <BoxSuccess>
              <IconDoubleCheck style={{ marginLeft: 'auto', marginRight: 'auto' }} />
              <TitleSuccess>Nova senha criada com sucesso!</TitleSuccess>
              <SubtitleSuccess>Agora, faça seu login com a nova senha e aproveite.</SubtitleSuccess>
              <BoxButtonsSuccess>
                <Button style={{ width: 180 }} onClick={() => setOpenChangePassword(false)}>
                  FAZER LOGIN
                </Button>
              </BoxButtonsSuccess>
            </BoxSuccess>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Modal onClose={() => setOpenChangePassword(false)} open={openChangePassword}>
      {renderMessage(popupIndex)}
    </Modal>
  );
};

ChangePassword.defaultProps = {
  token: null,
  email: null,
};

export default ChangePassword;
