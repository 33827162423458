import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { CSSProperties } from 'styled-components';
import IconChevronRight from '../../images/icon/iconChevronRight';
import ButtonIcon from '../buttons/buttonIcon/ButtonIcon';

import { Container, BoxPagination, Text } from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`;

type PPdfViewer = {
  urlImage: string;
  style?: CSSProperties;
  height?: number;
};

const PdfViewer = ({ urlImage, style, height }: PPdfViewer) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDocumentLoadSuccess = (pdf: any) => {
    setNumPages(pdf.numPages);
  };

  const handleChangePage = (newPage: number) => {
    if (newPage > 0 && newPage <= numPages) {
      setPageNumber(newPage);
    }
  };

  return (
    <Container style={style}>
      <Document file={urlImage} onLoadSuccess={onDocumentLoadSuccess}>
        <Page height={height} pageNumber={pageNumber} />
      </Document>
      <BoxPagination>
        <ButtonIcon onClick={() => handleChangePage(pageNumber - 1)}>
          <IconChevronRight style={{ transform: 'rotate(180deg)' }} />
        </ButtonIcon>
        <Text>{`Pág. ${pageNumber} de ${numPages}`}</Text>
        <ButtonIcon onClick={() => handleChangePage(pageNumber + 1)}>
          <IconChevronRight />
        </ButtonIcon>
      </BoxPagination>
    </Container>
  );
};

PdfViewer.defaultProps = {
  style: {},
  height: 200,
};

export default PdfViewer;
