import { TextField } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from '../../../shared/functions/colors';

export const Container = styled.form`
  width: 100%;
  padding: 16px 40px;
`;

export const BoxInfo = styled.div`
  width: 100%;
  margin-top: 32px;
  background-color: ${colors.white};
`;

export const BoxPadding = styled.div`
  padding: 24px;
`;

export const BoxFlex = styled.div`
  display: flex;
`;

export const BoxFlexVertical = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputDate = styled(TextField)`
  width: 48%;
  background-color: black;
`;

export const BoxBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 24px;
`;
