import React, { useState } from 'react';
import { insertPercentagemInNumber } from '../../../shared/functions/number';
import IconThumbDown from '../../../shared/images/icon/iconThumbDown';
import { QuestionContestReport } from '../../../shared/modals/contest/contest';
import MoreInfoQuestionAnswer from '../../../shared/components/modal/moreInfoQuestionAnswer/MoreInfoQuestionAnswer';

import { Container, ContainerPercentage, ExtraText, Text, TextPercentage, Title } from './styles';

type PBoxMoreErros = {
  position: number;
  idContest: string;
  question: QuestionContestReport;
};

const BoxMoreErros = ({ position, question, idContest }: PBoxMoreErros) => {
  const [openMoreInfoQuestionAnswer, setOpenMoreInfoQuestionAnswer] = useState(false);
  const [idQuestion, setIdQuestion] = useState<string | undefined>();

  const handleOpenModalMoreInfo = () => {
    setOpenMoreInfoQuestionAnswer(true);
    setIdQuestion(question.id_question);
  };

  const handleOnCloseModal = () => {
    setOpenMoreInfoQuestionAnswer(false);
    setIdQuestion(undefined);
  };

  const title = `${position}ª Pergunta mais errada`;

  return (
    <>
      <MoreInfoQuestionAnswer
        openMoreInfoQuestionAnswer={openMoreInfoQuestionAnswer}
        onClose={handleOnCloseModal}
        idQuestion={idQuestion}
        idContest={idContest}
        title={title}
      />
      <Container onClick={handleOpenModalMoreInfo}>
        <Title>{title}</Title>
        <ContainerPercentage>
          <IconThumbDown />
          <TextPercentage>
            {insertPercentagemInNumber(question.percentage_wrong_answer, 2)}
          </TextPercentage>
          <ExtraText>das pessoas erraram</ExtraText>
        </ContainerPercentage>
        <Text style={{ marginBottom: 24, height: 60 }}>{question.text}</Text>
        <Text>
          <strong>{`Resposta correta: ${question.true_alternative.text}`}</strong>
        </Text>
      </Container>
    </>
  );
};

export default BoxMoreErros;
