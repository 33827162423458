import * as React from 'react';

const ItalicIcon = ({ active, disabled }: { active: boolean; disabled: boolean }) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <mask id="italicIconMask" maskUnits="userSpaceOnUse" x={0} y={0} width="100%" height="100%">
      <rect x="0" y="0" width="100%" height="100%" fill={disabled ? '#777' : '#fff'} />
    </mask>
    <g>
      <polygon
        fill={active ? 'blue' : '#5f6368'}
        fillRule="evenodd"
        points="4 0 4 2 6.58 2 2.92 10 0 10 0 12 8 12 8 10 5.42 10 9.08 2 12 2 12 0"
        transform="translate(3 3)"
        mask="url(#orderedListIconMask)"
      />
    </g>
  </svg>
);

export default ItalicIcon;
