import React, { useState, Dispatch, SetStateAction } from 'react';

import { useQuestion } from '../../../store/reducers/question';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Question } from '../../../shared/modals/question/question';

type PDeleteQuestion = {
  openDeleteQuestion: boolean;
  setOpenDeleteQuestion: Dispatch<SetStateAction<boolean>>;
  question: Question | undefined;
  idLibrary?: string;
};

const DeleteQuestion = ({
  openDeleteQuestion,
  setOpenDeleteQuestion,
  question,
  idLibrary,
}: PDeleteQuestion) => {
  const [loading, setLoading] = useState(false);
  const { deleteQuestion, questionPagination } = useQuestion();

  if (!question) return null;

  const handleDeleteQuestion = async () => {
    setLoading(true);
    await deleteQuestion(question.idQuestion, questionPagination);
    setLoading(false);
    setOpenDeleteQuestion(false);
  };

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title="Excluir pergunta"
      message="Tem certeza que deseja excluir a pergunta?"
      onClose={() => setOpenDeleteQuestion(false)}
      open={openDeleteQuestion}
      onClickPrimary={handleDeleteQuestion}
      onClickSecondary={() => setOpenDeleteQuestion(false)}
    />
  );
};

DeleteQuestion.defaultProps = {
  idLibrary: undefined,
};

export default DeleteQuestion;
