import { Level } from '../../../shared/modals/level/level';
import {
  User,
  ImportUserError,
  UserReport,
  UserPagination,
} from '../../../shared/modals/user/user';

import { UserTypes } from './userTypes';

export const setUserData = (userData: User) => ({
  type: UserTypes.SET_DATA_USER,
  payload: userData,
});

export const setUsers = (users: User[]) => ({
  type: UserTypes.SET_ALL_USERS,
  payload: users,
});

export const setImportErrors = (errors: ImportUserError[]) => ({
  type: UserTypes.SET_IMPORT_ERRORS,
  payload: errors,
});

export const setUser = (user?: User) => ({
  type: UserTypes.SET_USER,
  payload: user,
});

export const setLevels = (level: Level[]) => ({
  type: UserTypes.SET_ALL_LEVELS,
  payload: level,
});

export const setUserReport = (userReport?: UserReport) => ({
  type: UserTypes.SET_USER_REPORT,
  payload: userReport,
});

export const setTotalCount = (totalCount: number) => ({
  type: UserTypes.SET_TOTAL_COUNT,
  payload: totalCount,
});

export const setUserPagination = (userPagination: UserPagination) => ({
  type: UserTypes.SET_USER_PAGINATION,
  payload: userPagination,
});
