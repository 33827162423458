import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import * as missionsService from '../../../shared/services/missions';

type PDuplicateMission = {
  open: boolean;
  handleCloseModal: () => void;
  refreshList: () => void;
  id: string | undefined;
};

const DuplicateMission = ({ id, open, handleCloseModal, refreshList }: PDuplicateMission) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  if (!id) return null;

  const handleDuplicate = async () => {
    setLoading(true);
    await missionsService.duplicate(id, dispatch);
    refreshList();
    setLoading(false);
    handleCloseModal();
  };

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title="Duplicar missão"
      message="Tem certeza que deseja duplicar a missão?"
      onClose={handleCloseModal}
      open={open}
      onClickPrimary={handleDuplicate}
      onClickSecondary={handleCloseModal}
    />
  );
};

export default DuplicateMission;
