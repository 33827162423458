import React, { ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router';
import { pick } from 'lodash';
import { Alert } from '@mui/material';
import Menu from '../../shared/components/menu/Menu';
import { isLogged } from '../../shared/functions/connection/auth';
import { useUser } from '../../store/reducers/user';
import { Container, BoxMenuMain, Main } from './styles';
import Header from '../../shared/components/header/Header';
import { actionAnalitycs } from '../../shared/functions/analytics';
import { useGeneral } from '../../store/reducers/general';
import ContactUsButton from '../../shared/components/buttons/contactUsButton/ContactUsButton';
import * as datadog from '../../shared/functions/datadog';

type PLogged = {
  children: ReactNode;
};

const Logged = ({ children }: PLogged) => {
  const history = useHistory();
  const { userData, getUserData } = useUser();
  const { setLoadingBlockScreen, setCurrentSelectedTab } = useGeneral();

  useEffect(() => {
    actionAnalitycs('', '', window.location.pathname);
  }, []);

  useEffect(() => {
    if (userData) {
      datadog.setUser(userData);

      // @ts-expect-error: hj is accessible through `window` variable and was
      // setup by Hotjar integration within Google Tag Manager.
      if (typeof hj !== 'undefined') {
        // @ts-expect-error: TS doesn't understand the check above and thus
        // keeps complaining.
        hj('identify', userData.idUser, {
          ...pick(userData, ['name', 'email', 'cpf', 'whatsapp', 'createdAt']),
          userType: userData.typeUser.name,
          companies: userData.companies.map(company => company.name),
          source: userData.memberGetMember?.title || 'Cadastrado manualmente',
        });
      }
    }
  }, [userData]);

  useEffect(() => {
    const verifyLogged = async () => {
      if (isLogged()) {
        if (!userData) {
          setLoadingBlockScreen(true);
          await getUserData();
          setLoadingBlockScreen(false);
        }
        return;
      }
      history.push('/');
    };
    verifyLogged();
  }, []);

  useEffect(() => {
    setCurrentSelectedTab(0);
  }, []);

  if (!userData) return null;

  return (
    <Container>
      <BoxMenuMain>
        <Menu />
        <Main>
          {userData.company.migrated && (
            <Alert
              severity="error"
              sx={{
                padding: 2,
                backgroundColor: '#D32F2F',
                color: '#fff',
                borderRadius: 0,
                '& .MuiAlert-icon': {
                  color: '#fff',
                },
              }}
            >
              Aviso: Estamos reestruturando a área administrativa para facilitar a criação de
              jornadas. Por conta disso, a criação de novas dinâmicas deve ser feita em&emsp;
              <a
                style={{ color: '#fff', textDecoration: 'underline' }}
                href="https://admin.tutodigital.com.br"
              >
                https://admin.tutodigital.com.br
              </a>
            </Alert>
          )}
          <Header userData={userData} />
          {children}
          <ContactUsButton />
        </Main>
      </BoxMenuMain>
    </Container>
  );
};

export default Logged;
