import React, { useState, Dispatch, SetStateAction } from 'react';

import { useStage } from '../../../store/reducers/stage';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Stage } from '../../../shared/modals/stage/stage';

type PDuplicateStage = {
  openDuplicateStage: boolean;
  setOpenDuplicateStage: Dispatch<SetStateAction<boolean>>;
  stage: Stage | undefined;
  idTrail?: string;
};

const DuplicateStage = ({
  openDuplicateStage,
  setOpenDuplicateStage,
  stage,
  idTrail,
}: PDuplicateStage) => {
  const [loading, setLoading] = useState(false);
  const { duplicateStage } = useStage();

  if (!stage) return null;

  const handleDuplicateStage = async () => {
    setLoading(true);
    await duplicateStage(stage.idPhase, idTrail);
    setLoading(false);
    setOpenDuplicateStage(false);
  };

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title="Tem certeza que deseja duplicar a fase?"
      message=""
      onClose={() => setOpenDuplicateStage(false)}
      open={openDuplicateStage}
      onClickPrimary={handleDuplicateStage}
      onClickSecondary={() => setOpenDuplicateStage(false)}
    />
  );
};

DuplicateStage.defaultProps = {
  idTrail: undefined,
};

export default DuplicateStage;
