import React, { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import CheckboxAccordion, {
  PListAccordion,
} from '../../../shared/components/input/checkboxAccordion/CheckboxAccordion';
import { colors } from '../../../shared/functions/colors';
import { useQuestion } from '../../../store/reducers/question';
import Input from '../../../shared/components/input/Input';
import { filterArraySearchInsertShow } from '../../../shared/functions/utils';
import FilterModal from '../../../shared/components/modal/filterModal/FilterModal';
import { BoxAccordions } from '../../../shared/components/styled/box';
import { useGeneral } from '../../../store/reducers/general';
import { QuestionPagination } from '../../../shared/modals/question/question';

type PFilter = {
  openFilter: boolean;
  setOpenFilter: (x: boolean) => void;
  pagination: QuestionPagination;
  setPagination: (x: QuestionPagination) => void;
};

export type ClickFilterHandle = {
  onClickFilter: () => void;
};

const Filter = forwardRef(
  (
    { openFilter, setOpenFilter, pagination, setPagination }: PFilter,
    ref: React.Ref<ClickFilterHandle | undefined>,
  ) => {
    const { categories } = useQuestion();
    const { setFilterSelect } = useGeneral();
    const [textSearch, setTextSearch] = useState('');
    const [changeSearch, setChangeSearch] = useState<undefined | boolean>();
    const [loading, setLoading] = useState(false);
    const [clear, setClear] = useState(false);
    const [listAccordionCategory, setListAccordionCategory] = useState<PListAccordion[]>([]);

    const convertCategoriesToAccordion = (): PListAccordion[] => {
      if (!categories || categories.length <= 0) {
        return [];
      }
      return filterArraySearchInsertShow(
        categories.map(categoty => ({
          id: categoty.idCategory,
          label: categoty.name,
          selected: false,
        })),
        'label',
        textSearch,
      );
    };

    useEffect(() => {
      setListAccordionCategory(convertCategoriesToAccordion());
    }, [categories, clear]);

    const handleOnClickFilter = () => {
      setLoading(true);
      setPagination({
        ...pagination,
        page: 1,
        constraints: {
          ...pagination.constraints,
          idCategories: listAccordionCategory
            .filter(accordion => accordion.selected)
            .map(accordion => accordion.id),
        },
      });

      setFilterSelect(listAccordionCategory.filter(accordion => accordion.selected));

      setLoading(false);
      setOpenFilter(false);
    };

    const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
      const valueSearch = event.target.value;
      setTextSearch(valueSearch);
      setListAccordionCategory([
        ...filterArraySearchInsertShow(listAccordionCategory, 'label', valueSearch),
      ]);
      setChangeSearch(!changeSearch);
    };

    useImperativeHandle(ref, () => ({
      async onClickFilter() {
        await handleOnClickFilter();
      },
    }));

    return (
      <FilterModal
        title="Filtrar por"
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        onClickClear={() => setClear(!clear)}
        onClickFilter={handleOnClickFilter}
        loading={loading}
      >
        <Input
          style={{ padding: 24 }}
          backgroundColor={colors.grey40}
          showBorder={false}
          icon="search"
          placeholder="Buscar"
          value={textSearch}
          onChange={handleChangeSearch}
        />
        <BoxAccordions>
          <CheckboxAccordion
            title="Por categoria"
            idCheckbox="por_categoria"
            listAccordion={listAccordionCategory}
            setListAccordion={setListAccordionCategory}
            changeSearch={changeSearch}
          />
        </BoxAccordions>
      </FilterModal>
    );
  },
);

export default Filter;
