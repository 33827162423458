export const removeSpecialCharacters = str =>
  str &&
  str !== '' &&
  str
    .normalize('NFD')
    .replace(/[^1-9a-zA-Zs]/g, '')
    .toUpperCase();

export const checkHasString = (string, search) =>
  string ? removeSpecialCharacters(string).indexOf(removeSpecialCharacters(search)) > -1 : '';

export const filterArraySearch = (array, idFilter, search) =>
  array.filter(object => checkHasString(object[idFilter], search));

export const filterArraySearchObject = (array, objectInObject, idFilterKey, search) =>
  array.filter(object => checkHasString(object[objectInObject][idFilterKey], search));

export const filterArraySearchObjectWithUser = (array, objectInObject, idFilterKey, search) =>
  array.filter(
    object =>
      checkHasString(object[objectInObject][idFilterKey], search) ||
      checkHasString(object.user.name, search),
  );

export const filterArraySearchInsertShow = (array, idFilter, search) =>
  array.map(object => ({
    ...object,
    show: checkHasString(object[idFilter], search),
  }));
