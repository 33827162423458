export const URL_AUTH = `${process.env.REACT_APP_API_URL}/auth`;
export const URL_REFRESH_TOKEN = `${process.env.REACT_APP_API_URL}/auth/refreshToken`;

export const URL_COMPANY = `${process.env.REACT_APP_API_URL}/company`;
export const URL_COMPANY_CREATE = `${process.env.REACT_APP_API_URL}/company/create`;
export const URL_COMPANY_VERIFY = `${process.env.REACT_APP_API_URL}/company/verify/{token}`;
export const URL_COMPANY_ID = `${process.env.REACT_APP_API_URL}/company/{idCompany}`;
export const URL_COMPANY_BLOCK = `${process.env.REACT_APP_API_URL}/company/{idCompany}/block`;
export const URL_COMPANY_UNLOCK = `${process.env.REACT_APP_API_URL}/company/{idCompany}/unlock`;
export const URL_COMPANY_PREFERENCES = `${process.env.REACT_APP_API_URL}/company/preferences`;
export const URL_COMPANY_PREFERENCES_STYLES = `${process.env.REACT_APP_API_URL}/preferences/styles`;
export const URL_COMPANY_SECURITY_PREFERENCES = `${process.env.REACT_APP_API_URL}/preferences/security`;
export const URL_COMPANY_PASSWORD = `${process.env.REACT_APP_API_URL}/company/editPassword`;

export const URL_USER = `${process.env.REACT_APP_API_URL}/user`;
export const URL_USER_LIST = `${process.env.REACT_APP_API_URL}/user/list`;
export const URL_USER_ADMIN_COMPANY = `${process.env.REACT_APP_API_URL}/user/adminCompany`;
export const URL_USER_ID = `${process.env.REACT_APP_API_URL}/user/{idUser}`;
export const URL_USER_INSERT_ADMIN_COMPANY = `${process.env.REACT_APP_API_URL}/user/insertAdminCompany`;
export const URL_USER_INSERT_ADMIN_COMPANY_GLOBAL = `${process.env.REACT_APP_API_URL}/user/admin/{idCompany}`;
export const URL_USER_REPORT = `${process.env.REACT_APP_REPORT_URL}/user/{idUser}/report`;
export const URL_USER_BLOCK = `${process.env.REACT_APP_API_URL}/user/{idUser}/block`;
export const URL_USER_UNLOCK = `${process.env.REACT_APP_API_URL}/user/{idUser}/unlock`;
export const URL_USER_REQUEST_EMAIL = `${process.env.REACT_APP_API_URL}/user/passEmail`;
export const URL_USER_REDEFINE_PASSWORD = `${process.env.REACT_APP_API_URL}/user/redefinePass`;
export const URL_USER_CHANGE_PASSWORD = `${process.env.REACT_APP_API_URL}/user/{idUser}/password`;

export const URL_CATEGORY = `${process.env.REACT_APP_API_URL}/category`;
export const URL_CATEGORY_ID = `${process.env.REACT_APP_API_URL}/category/{idCategory}`;
export const URL_QUESTION = `${process.env.REACT_APP_API_URL}/question`;
export const URL_QUESTION_CSV = `${process.env.REACT_APP_API_URL}/file/csv`;
export const URL_RESEARCH_CSV = `${process.env.REACT_APP_API_URL}/research/csv`;
export const URL_QUESTION_ID = `${process.env.REACT_APP_API_URL}/question/{idQuestion}`;
export const URL_QUESTION_ID_INFO_ANSWER = `${process.env.REACT_APP_API_URL}/question/{idQuestion}/infoAnswer`;
export const URL_QUESTION_ID_DUPLICATE = `${process.env.REACT_APP_API_URL}/question/duplicate/{idQuestion}`;
export const URL_QUESTIONS_CATEGORY = `${process.env.REACT_APP_API_URL}/question/{idCategory}`;

export const URL_CONTEST = `${process.env.REACT_APP_API_URL}/tourney`;
export const URL_CONTEST_ID = `${process.env.REACT_APP_API_URL}/tourney/{idContest}`;
export const URL_CONTEST_ID_REPORT = `${process.env.REACT_APP_REPORT_URL}/tourney/{idContest}/report`;
export const URL_CONTEST_ID_REPORT_DOWNLOAD = `${process.env.REACT_APP_API_URL}/tourney/{idContest}/download`;
export const URL_CONTEST_ID_DUPLICATE = `${process.env.REACT_APP_API_URL}/tourney/duplicate/{idContest}`;
export const URL_ENABLE_CONTEST = `${process.env.REACT_APP_API_URL}/tourney/enable/{idContest}`;
export const URL_CONTEST_CSV = `${process.env.REACT_APP_API_URL}/tourney/csv`;

export const URL_TRAIL = `${process.env.REACT_APP_API_URL}/trail`;
export const URL_TRAIL_ID = `${process.env.REACT_APP_API_URL}/trail/{idTrail}`;
export const URL_TRAIL_ID_REPORT = `${process.env.REACT_APP_REPORT_URL}/trail/{idTrail}/report`;
export const URL_TRAIL_ID_DUPLICATE = `${process.env.REACT_APP_API_URL}/trail/duplicate/{idTrail}`;
export const URL_ENABLE_TRAIL = `${process.env.REACT_APP_API_URL}/trail/enable/{idTrail}`;

export const URL_STAGE = `${process.env.REACT_APP_API_URL}/phase`;
export const URL_STAGE_ID_TRAIL = `${process.env.REACT_APP_API_URL}/phase/trail/{idTrail}`;
export const URL_STAGE_ID_TRAIL_NEXT_POSITION = `${process.env.REACT_APP_API_URL}/phase/trail/{idTrail}/nextPosition`;
export const URL_STAGE_ID_TRAIL_ID_STAGE = `${process.env.REACT_APP_API_URL}/phase/trail/{idTrail}/{idStage}`;
export const URL_STAGE_ID = `${process.env.REACT_APP_API_URL}/phase/{idStage}`;
export const URL_STAGE_ID_DUPLICATE = `${process.env.REACT_APP_API_URL}/phase/duplicate/{idStage}`;
export const URL_UPLOAD_IMAGE = `${process.env.REACT_APP_API_URL}/file/uploadImage`;
export const URL_UPLOAD_LOGO = `${process.env.REACT_APP_API_URL}/file/uploadLogo`;

export const URL_AREA = `${process.env.REACT_APP_API_URL}/area`;
export const URL_AREA_ID = `${process.env.REACT_APP_API_URL}/area/{idArea}`;
export const URL_IMPORT_AREAS = `${process.env.REACT_APP_API_URL}/areas/import`;

export const URL_LEVEL = `${process.env.REACT_APP_API_URL}/level`;

export const URL_REPORT_AREA = `${process.env.REACT_APP_REPORT_URL}/report/area`;
export const URL_REPORT_CATEGORY = `${process.env.REACT_APP_REPORT_URL}/report/category`;
export const URL_REPORT_STAGE = `${process.env.REACT_APP_REPORT_URL}/report/phase`;
export const URL_REPORT_USER = `${process.env.REACT_APP_REPORT_URL}/report/user`;
export const URL_REPORT_QUESTION = `${process.env.REACT_APP_REPORT_URL}/report/question`;
export const URL_REPORT_TRAIL = `${process.env.REACT_APP_REPORT_URL}/report/trail`;
export const URL_REPORT_CONTEST = `${process.env.REACT_APP_REPORT_URL}/report/tourney`;
export const URL_REPORT_CONTEST_PRESENCE = `${process.env.REACT_APP_REPORT_URL}/report/tourney/presence`;

export const URL_LIBRARY = `${process.env.REACT_APP_API_URL}/library`;
export const URL_LIBRARY_ID = `${process.env.REACT_APP_API_URL}/library/{idLibrary}`;
export const URL_LIBRARY_IMPORT = `${process.env.REACT_APP_API_URL}/library/import`;

export const URL_TYPE_USER = `${process.env.REACT_APP_API_URL}/typeUser`;
export const URL_TYPE_USER_PERMISSION = `${process.env.REACT_APP_API_URL}/typeUser/permission`;
export const URL_TYPE_USER_SHOW_PERMISSION = `${process.env.REACT_APP_API_URL}/typeUser/showPermission`;
export const URL_TYPE_USER_ID = `${process.env.REACT_APP_API_URL}/typeUser/{idTypeUser}`;

export const URL_REWARD = `${process.env.REACT_APP_API_URL}/rewards`;
export const URL_REWARD_ID = `${process.env.REACT_APP_API_URL}/rewards/{idReward}`;
export const URL_REWARD_MANAGER = `${process.env.REACT_APP_API_URL}/purchases`;
export const URL_REWARD_MANAGER_ID = `${process.env.REACT_APP_API_URL}/purchases/{idRewardManager}`;
export const URL_ENABLE_REWARD = `${process.env.REACT_APP_API_URL}/rewards/{idReward}/enable`;
export const URL_REWARD_ID_DUPLICATE = `${process.env.REACT_APP_API_URL}/rewards/{idReward}/duplicate`;

export const MISSIONS_URL = `${process.env.REACT_APP_API_URL}/missions`;
export const MISSIONS_REPORT_URL = `${process.env.REACT_APP_API_URL}/reports/missions`;
export const CHALLENGES_URL = `${process.env.REACT_APP_API_URL}/challenges`;

export const SELF_SIGNUP_CAMPAIGNS_URL = `${process.env.REACT_APP_API_URL}/self_sign_up_campaigns`;
export const SELF_SIGNUP_FIELDS_URL = `${process.env.REACT_APP_API_URL}/self_sign_up_fields`;

export const JOURNEYS_URL = `${process.env.REACT_APP_API_URL}/journeys`;

export const ROLLOUTS_URL = `${process.env.REACT_APP_API_URL}/rollouts`;

export const VIDEO_UPLOAD_PRESIGNED_URL = `${process.env.REACT_APP_API_URL}/panda/video/presigned_url`;

export const DATADOG_KEYS_URL = `${process.env.REACT_APP_API_URL}/credentials/datadog`;
