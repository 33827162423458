import React, { ButtonHTMLAttributes, ReactNode, FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import ArrowRight from '../../images/icon/arrows/arrowRight';
import { Container, BoxIcon } from './styles';
import IconPlus from '../../images/icon/iconPlus';
import { colors } from '../../functions/colors';
import IconPlusInnerBorder from '../../images/icon/iconPlusInnerBorder';
import IconFilter from '../../images/icon/iconFilter';
import IconChevronRight from '../../images/icon/iconChevronRight';
import IconPrint from '../../images/icon/iconPrint';
import IconList from '../../images/icon/iconList';
import IconBlock from '../../images/icon/iconBlock';
import IconCheck from '../../images/icon/iconCheck';

export interface OwnPropsButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string;
  iconLeft?: string;
  loading?: boolean;
  type?: 'button' | 'reset' | 'submit';
  colorIcon?: string;
  typeButton?:
    | 'primary'
    | 'primary-white'
    | 'primary-blue'
    | 'primary-pink'
    | 'purple-white'
    | 'pink-white'
    | 'disabled';
  children: ReactNode;
}

const Button: FC<OwnPropsButton> = ({
  children,
  icon,
  iconLeft,
  loading,
  type,
  colorIcon,
  typeButton,
  ...props
}: OwnPropsButton) => {
  const renderIcon = (typeIcon?: string, showLoading = true) => {
    if (loading && showLoading) {
      return (
        <BoxIcon>
          <CircularProgress size={24} color="inherit" />
        </BoxIcon>
      );
    }
    switch (typeIcon) {
      case 'arrowRight':
        return <ArrowRight />;
      case 'plus':
        return (
          <IconPlus
            color={colorIcon}
            style={{ margin: `${icon ? '0px 0px 0px 8px' : '0px 8px 0px 0px'}` }}
          />
        );
      case 'plusInnerBorder':
        return (
          <IconPlusInnerBorder
            color={colorIcon}
            style={{ margin: `${icon ? '0px 0px 0px 8px' : '0px 8px 0px 0px'}` }}
          />
        );
      case 'filter':
        return (
          <IconFilter
            color={colorIcon}
            style={{ margin: `${icon ? '0px 0px 0px 8px' : '0px 8px 0px 0px'}` }}
          />
        );
      case 'chevronRight':
        return (
          <IconChevronRight
            color={colorIcon}
            style={{ margin: `${icon ? '0px 0px 0px 8px' : '0px 8px 0px 0px'}` }}
          />
        );
      case 'block':
        return (
          <IconBlock
            color={colorIcon}
            style={{ margin: `${icon ? '0px 0px 0px 8px' : '0px 8px 0px 0px'}` }}
          />
        );
      case 'check':
        return (
          <IconCheck
            color={colorIcon}
            style={{ margin: `${icon ? '0px 0px 0px 8px' : '0px 8px 0px 0px'}` }}
          />
        );
      case 'iconPrint':
        return <IconPrint style={{ margin: `${icon ? '0px 0px 0px 8px' : '0px 8px 0px 0px'}` }} />;
      case 'iconList':
        return <IconList style={{ margin: `${icon ? '0px 0px 0px 8px' : '0px 8px 0px 0px'}` }} />;
      default:
        return null;
    }
  };

  return (
    <Container type={type} typeButton={typeButton} disabled={loading} {...props}>
      {renderIcon(iconLeft, false)}
      {children}
      {renderIcon(icon)}
    </Container>
  );
};

Button.defaultProps = {
  icon: '',
  iconLeft: '',
  loading: false,
  type: 'button',
  typeButton: 'primary',
  colorIcon: colors.white,
};

export default Button;
