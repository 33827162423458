import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Paragraph } from '../../../shared/components/modal/modalConfirm/styles';
import * as missionsService from '../../../shared/services/missions';

type PDeleteContest = {
  open: boolean;
  handleCloseModal: () => void;
  refreshList: () => void;
  id: string | undefined;
};

const DeleteMission = ({ id, open, handleCloseModal, refreshList }: PDeleteContest) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  if (!id) return null;

  const handleDelete = async () => {
    setLoading(true);
    await missionsService.destroy(id, dispatch);
    refreshList();
    setLoading(false);
    handleCloseModal();
  };

  const warningMsg = () => (
    <>
      <Paragraph style={{ marginBottom: '20px' }}>
        CUIDADO! Esta ação impacta as estatísticas dos relatórios, pois remove PERMANENTEMENTE:
      </Paragraph>
      <Paragraph>- A missão;</Paragraph>
      <Paragraph>- Os desafios (associados à missão);</Paragraph>
      <Paragraph>- As respostas (associadas aos desafios);</Paragraph>
    </>
  );

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title="Tem certeza que deseja excluir a missão?"
      message={warningMsg()}
      onClose={handleCloseModal}
      open={open}
      onClickPrimary={handleDelete}
      onClickSecondary={handleCloseModal}
    />
  );
};

export default DeleteMission;
