import React from 'react';
import Button from '../../shared/components/buttons/Button';
import Modal from '../../shared/components/modal/Modal';
import { dateTimeStampToString } from '../../shared/functions/date';
import { ApprovalInterface } from './ChallengeApprovals';
import EnrichedText from '../../shared/components/richEditor/EnrichedText';

type ShowDetailsModalInterface = {
  title: string;
  approval: ApprovalInterface;
  open: boolean;
  handleCloseModal: () => void;
};

const ShowDetailsModal = ({
  approval,
  title,
  open,
  handleCloseModal,
}: ShowDetailsModalInterface) => {
  const statusLabel = (status: boolean | null) => {
    switch (status) {
      case true:
        return 'Aprovado';
      case false:
        return 'Rejeitado';
      default:
        return 'Aguardando aprovação';
    }
  };

  const buildAnswer = () => {
    switch (approval.typeId) {
      case 1:
        return approval.answer;
      case 2:
        return approval.answer.split(', ').map((answer, i) => (
          <a href={answer} target="blank" style={{ marginLeft: '8px' }}>
            {`Arquivo ${i + 1}`}
          </a>
        ));
      default:
        return null;
    }
  };

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <div className="modal__title">{title}</div>

      <div className="modal__row">
        <div className="col-half">
          <b>Membro: </b>
          <span>{approval.user}</span>
        </div>
        <div className="col-half">
          <b>Status: </b>
          <span>{statusLabel(approval.status)}</span>
        </div>
      </div>

      <div className="modal__row">
        <div className="col-half">
          <b>Data: </b>
          <span>{approval.updatedAt && dateTimeStampToString(approval.updatedAt)}</span>
        </div>
        <div className="col-half">
          <b>Desafio: </b>
          <span>{approval.title}</span>
        </div>
      </div>

      <div className="modal__row">
        <b>Pergunta: </b>
        <EnrichedText value={approval.question} />
      </div>

      <div className="modal__row">
        <b>Resposta: </b>
        <span>{buildAnswer()}</span>
      </div>

      <Button typeButton="purple-white" onClick={handleCloseModal}>
        FECHAR
      </Button>
    </Modal>
  );
};

export default ShowDetailsModal;
