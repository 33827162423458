import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import ReactLoading from 'react-loading';
import moment from 'moment';

import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import {
  ContainerMain,
  HeaderMain,
  TextHeaderMain,
  BoxInfo,
  DisplayFlexJustifyContent,
  DisplayFlex,
} from '../../shared/components/styled/general';
import { ButtonContainer } from '../login/styles';
import { BoxFlex, ButtonPrint } from '../contestReport/styles';
import InputDate from '../../shared/components/input/inputDate/InputDate';
import { colors } from '../../shared/functions/colors';
import { useUser } from '../../store/reducers/user';
import TopUserReport from './topUserReport/TopUserReport';
import ContestsUserReport from './contestsUserReport/ContestsUserReport';
import StagesUserReport from './stagesUserReport/StagesUserReport';
import { BoxNameArea, BoxRadiusBorder } from '../../shared/components/styled/box';
import EngagementUserReport from './engagementUserReport/EngagementUserReport';
import CategoriesUserReport from './categoriesUserReport/CategoriesUserReport';
import { TitleSemiBold } from '../../shared/components/styled/text';

type ParamTypes = {
  idUser: string;
};

const UserReport = () => {
  const { idUser } = useParams<ParamTypes>();
  const history = useHistory();
  const { userReport, getUserReport } = useUser();
  const [isPrint, setIsPrint] = useState(false);
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(120, 'days')
      .format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(
    moment()
      .subtract(-1, 'days')
      .format('YYYY-MM-DD'),
  );

  useEffect(() => {
    getUserReport(idUser, startDate.toString(), endDate.toString());
  }, [startDate, endDate]);

  const handleOnChangeStart = (e: string) => {
    setStartDate(e);
  };

  const handleOnChangeEnd = (e: string) => {
    setEndDate(e);
  };

  const handlePrint = async () => {
    setIsPrint(true);
    setTimeout(() => {
      window.print();
      setIsPrint(false);
    }, 100);
  };

  return (
    <ContainerMain>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Usuários',
            action: () => history.go(-1),
          },
          {
            name: 'Relatório',
          },
        ]}
      />
      <HeaderMain>
        <TextHeaderMain>
          Você pode visualizar e personalizar seus relatórios e acompanhar diariamente.
        </TextHeaderMain>
      </HeaderMain>
      <BoxInfo>
        {userReport ? (
          <>
            <BoxFlex>
              <InputDate
                title="Data de início"
                onChangeDate={handleOnChangeStart}
                value={startDate}
                style={{ marginRight: 10, marginBottom: 30 }}
              />
              <InputDate
                style={{ marginBottom: 30 }}
                title="Data de fim"
                onChangeDate={handleOnChangeEnd}
                value={endDate}
              />
            </BoxFlex>
            <DisplayFlexJustifyContent>
              <TitleSemiBold>{`Relatório individual de performance - ${userReport.user.name}`}</TitleSemiBold>
              <DisplayFlex>
                {userReport.user.areas.map(area => (
                  <BoxNameArea>{area.name}</BoxNameArea>
                ))}
              </DisplayFlex>
            </DisplayFlexJustifyContent>
            <TopUserReport userReport={userReport} />
            <BoxRadiusBorder>
              <ContestsUserReport userReport={userReport} isPrint={isPrint} />
            </BoxRadiusBorder>
            <BoxRadiusBorder style={{ marginTop: 24 }}>
              <StagesUserReport userReport={userReport} isPrint={isPrint} />
            </BoxRadiusBorder>
            <EngagementUserReport />
            <CategoriesUserReport />
            <ButtonContainer>
              <ButtonPrint typeButton="primary-white" icon="iconPrint" onClick={handlePrint}>
                IMPRIMIR RELATÓRIO
              </ButtonPrint>
            </ButtonContainer>
          </>
        ) : (
          <ReactLoading type="bubbles" color={colors.primary} width={44} height={40} />
        )}
      </BoxInfo>
    </ContainerMain>
  );
};

export default UserReport;
