import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import Button from '../../../shared/components/buttons/Button';
import Input from '../../../shared/components/input/Input';
import Modal from '../../../shared/components/modal/Modal';
import { BodyInsertArea } from '../../../shared/modals/area/bodies';
import { BoxButtons, Title, Text } from './styles';
import { Area } from '../../../shared/modals/area/area';
import { useArea } from '../../../store/reducers/area';

type PInsertEditArea = {
  openInsertArea: boolean;
  setOpenInsertArea: Dispatch<SetStateAction<boolean>>;
  area?: Area;
};

const INITIAL_BODY = {
  name: '',
};

const InsertEditArea = ({ openInsertArea, setOpenInsertArea, area }: PInsertEditArea) => {
  const { editArea, insertArea } = useArea();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [bodyInsertArea, setBodyInsertArea] = useState<BodyInsertArea>(INITIAL_BODY);

  useEffect(() => {
    setBodyInsertArea(INITIAL_BODY);
  }, [openInsertArea]);

  useEffect(() => {
    if (area) {
      setBodyInsertArea({
        name: area.name,
      });
    } else {
      setBodyInsertArea(INITIAL_BODY);
    }
  }, [area]);

  const handleInsertArea = async () => {
    setLoading(true);
    try {
      if (area) {
        await editArea(area.idArea, bodyInsertArea);
      } else {
        await insertArea(bodyInsertArea);
      }
      setOpenInsertArea(false);
      setBodyInsertArea(INITIAL_BODY);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
    setLoading(false);
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBodyInsertArea({
      ...bodyInsertArea,
      name: event.target.value,
    });
  };

  return (
    <Modal onClose={() => setOpenInsertArea(false)} open={openInsertArea}>
      <Title>Nova área</Title>
      <Text>Preencha abaixo os dados da nova área:</Text>
      <Input
        title="Nova área"
        placeholder="Nova área"
        value={bodyInsertArea.name}
        onChange={handleChangeName}
        messageError={errorMessage}
        error={errorMessage !== ''}
      />
      <BoxButtons>
        <Button
          onClick={() => setOpenInsertArea(false)}
          typeButton="primary-white"
          style={{ width: 120 }}
        >
          CANCELAR
        </Button>
        <Button
          disabled={bodyInsertArea.name === ''}
          loading={loading}
          onClick={handleInsertArea}
          style={{ width: 120, marginLeft: 16 }}
        >
          CONFIRMAR
        </Button>
      </BoxButtons>
    </Modal>
  );
};

InsertEditArea.defaultProps = {
  area: undefined,
};

export default InsertEditArea;
