import React, { useState, useEffect, ChangeEvent } from 'react';
import { useHistory, useParams } from 'react-router';

import Button from '../../shared/components/buttons/Button';
import { BodyCreateUser } from '../../shared/modals/user/bodies';
import { useCompany } from '../../store/reducers/company';

import Input from '../../shared/components/input/Input';

import {
  Item,
  Content,
  TopBar,
  BottomBar,
  Image,
  BeginBox,
  BeginTitle,
  BeginSubtitle,
  Logo,
  StepTracker,
  ButtonBox,
  ImageBox,
} from './styles';
import { actionAnalitycs } from '../../shared/functions/analytics';
import { ACTIONS_LOGIN, CATEGORIES } from '../../shared/constants/constantsAnalytics';
import { BodyInsertCompany } from '../../shared/modals/company/bodies';
import InputFileImage from '../../shared/components/input/inputFileImage/InputFile';
import { typeUser } from '../../shared/enums/typeUser';

type ParamTypes = {
  token?: string;
};

const BODY_START_ADMIN = {
  name: '',
  email: '',
  areas: [],
  admin: true,
  idTypeUser: typeUser.ADMIN_EMPRESA,
};

const BODY_START = {
  name: '',
  image: '',
  password: '',
  trialUntilDate: null,
  admins: [BODY_START_ADMIN],
};

const Setup = () => {
  const { verifyCompany } = useCompany();
  const history = useHistory();

  const { token } = useParams<ParamTypes>();
  const [passConfirm, setPassConfirm] = useState<string>('');
  const [verified, setVerified] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [bodyCompany, setBodyCompany] = useState<BodyInsertCompany>(BODY_START);
  const [setupIndex, setSetupIndex] = useState<number>(0);
  const { verifyAndCreateCompany } = useCompany();

  const handleVerifyCompany = async (x: string) => {
    try {
      await verifyCompany(x);
      setVerified(true);
      setBodyCompany({ ...bodyCompany, token });
    } catch (e) {
      setVerified(false);
    }
  };

  useEffect(() => {
    actionAnalitycs(CATEGORIES.LOGIN, ACTIONS_LOGIN.OPEN, window.location.pathname);

    if (token) {
      handleVerifyCompany(token);
    }
  }, []);

  const handleChangeBody = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    setBodyCompany({
      ...bodyCompany,
      [name]: event.target.value,
    });
  };

  const handleChangeUsers = (event: ChangeEvent<HTMLInputElement>, index: number, name: string) => {
    if (bodyCompany.admins[index]) {
      bodyCompany.admins[index] = {
        ...bodyCompany.admins[index],
        [name]: event.target.value,
      };
      setBodyCompany({ ...bodyCompany });
    }
  };

  const verifyNextStepName = () => {
    if (bodyCompany.name === '') {
      return true;
    }

    return false;
  };

  const verifyNextStepAdmin = () => {
    if (bodyCompany.admins[0].name === '' || bodyCompany.admins[0].email === '') {
      return true;
    }

    return false;
  };

  const verifyNextStepPassword = () => {
    if (bodyCompany.password === '' || bodyCompany.password !== passConfirm) {
      return true;
    }

    return false;
  };

  const handleFinishSetup = async () => {
    setLoading(true);
    await verifyAndCreateCompany(bodyCompany);
    setLoading(false);
    setSetupIndex(5);
  };

  const renderSetupStep = () => {
    switch (setupIndex) {
      case 0:
        return (
          <Content>
            <Image src="../img/Setup_1.png" alt="" />
            <BeginBox>
              <Logo style={{ backgroundImage: 'url(../img/logo.png)' }} />
              <BeginTitle>Olá, estamos felizes em tê-lo conosco!</BeginTitle>
              <BeginSubtitle>Para iniciar, clique no botão começar.</BeginSubtitle>
              <ButtonBox>
                <Button
                  onClick={() => setSetupIndex(1)}
                  icon="arrowRight"
                  style={{ width: 120, marginTop: '50px', justifyContent: 'space-evenly' }}
                >
                  Começar
                </Button>
              </ButtonBox>
            </BeginBox>
          </Content>
        );

      case 1:
        return (
          <Content>
            <Image src="../img/Setup_2.png" alt="" />
            <BeginBox>
              <Logo style={{ backgroundImage: 'url(../img/logo.png)' }} />
              <StepTracker style={{ backgroundImage: 'url(../img/Setup-Step_1.png)' }} />
              <BeginSubtitle>Qual o nome da sua empresa?</BeginSubtitle>
              <Input
                title="Nome da empresa*"
                placeholder="Digite"
                style={{ width: '90%' }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'name')}
                value={bodyCompany.name}
              />
              <ButtonBox>
                <Button
                  onClick={() => setSetupIndex(2)}
                  icon="arrowRight"
                  style={{ width: 120, marginTop: '50px', justifyContent: 'space-evenly' }}
                  disabled={verifyNextStepName()}
                >
                  Próximo
                </Button>
              </ButtonBox>
            </BeginBox>
          </Content>
        );

      case 2:
        return (
          <Content>
            <ImageBox>
              <div
                style={{
                  width: 290,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {bodyCompany.image === '' ? (
                  <img src="../img/Setup-Preview.png" alt="" style={{ width: 120, height: 117 }} />
                ) : (
                  <Image src={bodyCompany.image} alt="" />
                )}
              </div>
            </ImageBox>
            <BeginBox>
              <Logo style={{ backgroundImage: 'url(../img/logo.png)' }} />
              <StepTracker style={{ backgroundImage: 'url(../img/Setup-Step_2.png)' }} />
              <BeginSubtitle>Qual a logo da sua empresa?</BeginSubtitle>
              <InputFileImage
                title="Inserir imagem (opcional)"
                subTitle="Você pode inserir imagem em .png .jpeg .gif."
                urlImage={bodyCompany.image}
                setUrlImage={image => setBodyCompany({ ...bodyCompany, image })}
                style={{ width: '90%' }}
                showImage={false}
                isSetup
                token={token}
              />
              <ButtonBox>
                <Button
                  onClick={() => setSetupIndex(3)}
                  icon="arrowRight"
                  style={{ width: 120, marginTop: '50px', justifyContent: 'space-evenly' }}
                >
                  Próximo
                </Button>
              </ButtonBox>
            </BeginBox>
          </Content>
        );

      case 3:
        return (
          <Content>
            <Image src="../img/Setup_3.png" style={{ position: 'fixed', top: '30%' }} alt="" />
            <BeginBox style={{ marginLeft: '35%' }}>
              <Logo style={{ backgroundImage: 'url(../img/logo.png)' }} />
              <StepTracker style={{ backgroundImage: 'url(../img/Setup-Step_3.png)' }} />
              <BeginSubtitle>Gostaria de adicionar novos adminsitradores?</BeginSubtitle>
              {bodyCompany.admins.map((admin: BodyCreateUser, index: number) => (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    title="Nome do administrador*"
                    placeholder="Digite"
                    style={{ width: '43%', marginRight: '5%' }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleChangeUsers(e, index, 'name')
                    }
                    value={bodyCompany.admins[index].name}
                  />
                  <Input
                    title="Email do administrador*"
                    placeholder="Digite"
                    style={{ width: '42%' }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleChangeUsers(e, index, 'email')
                    }
                    value={bodyCompany.admins[index].email}
                  />
                </div>
              ))}
              <ButtonBox>
                <Button
                  onClick={() => setSetupIndex(4)}
                  icon="arrowRight"
                  style={{ width: 120, marginTop: '50px', justifyContent: 'space-evenly' }}
                  disabled={verifyNextStepAdmin()}
                >
                  Próximo
                </Button>
              </ButtonBox>
            </BeginBox>
          </Content>
        );

      case 4:
        return (
          <Content>
            <Image src="../img/Setup_4.png" alt="" />
            <BeginBox>
              <Logo style={{ backgroundImage: 'url(../img/logo.png)' }} />
              <StepTracker style={{ backgroundImage: 'url(../img/Setup-Step_4.png)' }} />
              <BeginSubtitle>Crie uma nova senha</BeginSubtitle>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Input
                  title="Nova senha*"
                  placeholder="Digite"
                  type="password"
                  style={{ width: '43%', marginRight: '5%' }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'password')}
                  value={bodyCompany.password}
                />
                <Input
                  title="Confirmar senha*"
                  placeholder="Digite"
                  type="password"
                  style={{ width: '42%' }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setPassConfirm(e.target.value)}
                  value={passConfirm}
                />
              </div>
              <ButtonBox>
                <Button
                  onClick={handleFinishSetup}
                  icon="arrowRight"
                  style={{ width: 120, marginTop: '50px', justifyContent: 'space-evenly' }}
                  disabled={verifyNextStepPassword()}
                  loading={loading}
                >
                  Próximo
                </Button>
              </ButtonBox>
            </BeginBox>
          </Content>
        );

      case 5:
        return (
          <Content>
            <Image src="../img/Setup_5.png" alt="" />
            <BeginBox>
              <Logo style={{ backgroundImage: 'url(../img/logo.png)' }} />
              <BeginTitle>
                Parabéns! Sua empresa está pronta para começar o TuTo Business!
              </BeginTitle>
              <BeginSubtitle>Não perca tempo, inicie já e aproveite.</BeginSubtitle>
              <ButtonBox>
                <Button
                  onClick={() => setSetupIndex(6)}
                  style={{ width: 120, marginTop: '50px', justifyContent: 'space-evenly' }}
                >
                  Iniciar
                </Button>
              </ButtonBox>
            </BeginBox>
          </Content>
        );

      case 6:
        history.push('/');
        break;

      default:
        return null;
    }

    return null;
  };

  if (!verified) {
    return null;
  }

  return (
    <>
      <Item>
        <TopBar />
      </Item>
      <Item>{renderSetupStep()}</Item>
      <Item>
        <BottomBar />
      </Item>
    </>
  );
};

export default Setup;
