import { ImportContestError, Contest, ContestReport } from '../../../shared/modals/contest/contest';

export enum ContestTypes {
  SET_CONTESTS = '@contest/SET_CONTESTS',
  SET_CONTEST = '@contest/SET_CONTEST',
  SET_CONTEST_REPORT = '@contest/SET_CONTEST_REPORT',
  SET_CONTEST_ERROR = '@contest/SET_CONTEST_ERROR',
}

export type ContestState = {
  readonly contests: Contest[];
  readonly contest?: Contest;
  readonly contestReport?: ContestReport;
  readonly errors: ImportContestError[];
};
