import { JourneyInterface as ListJourneysInterface } from '../../features/journeys/Journeys';
import { JourneyInterface } from '../../features/journeys/createEdit/CreateEditJourney';
import {
  ConnectApiGet,
  ConnectApiPost,
  ConnectApiPUT,
  ConnectApiDelete,
  urls,
} from '../functions/connection';
import { doLogout } from '../functions/connection/auth';
import { ERROR_MAINTENANCE } from '../constants/messages';
import { setSnackbar, SnackBarActionInterface } from '../../store/reducers/general/generalActions';

export const getAll = async (dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    const response = await ConnectApiGet(urls.JOURNEYS_URL);
    const journeys: ListJourneysInterface[] = response.data;
    return journeys;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }

  return [];
};

export const get = async (id: string, dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    const response = await ConnectApiGet(`${urls.JOURNEYS_URL}/${id}`);
    const journey: JourneyInterface = response.data;
    return journey;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }

  return null;
};

export const create = async (
  attrs: JourneyInterface,
  dispatch: (arg: SnackBarActionInterface) => void,
) => {
  try {
    await ConnectApiPost(urls.JOURNEYS_URL, { journey: attrs });
    dispatch(setSnackbar('Jornada inserida com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const update = async (
  attrs: JourneyInterface | { upPosition: boolean } | { enabled: boolean },
  id: string,
  dispatch: (arg: SnackBarActionInterface) => void,
) => {
  try {
    await ConnectApiPUT(`${urls.JOURNEYS_URL}/${id}`, { journey: attrs });
    dispatch(setSnackbar('Jornada modificada com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const destroy = async (id: string, dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    await ConnectApiDelete(`${urls.JOURNEYS_URL}/${id}`);
    dispatch(setSnackbar('Jornada excluída com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const duplicate = async (id: string, dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    await ConnectApiPost(`${urls.JOURNEYS_URL}/${id}/duplicate`, {});
    dispatch(setSnackbar('Jornada duplicada com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};
