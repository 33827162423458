import React from 'react';
import { Pie } from 'react-chartjs-2';
import { generateOptionsChartjsPie } from '../../../shared/functions/chartJs';
import { colors } from '../../../shared/functions/colors';

import { Container, BoxRadius } from './styles';
import { insertPercentagemInNumber } from '../../../shared/functions/number';
import { TrailReport } from '../../../shared/modals/trail/trail';

const generateData = (value: number, backgroundColor: string, label1: string, label2: string) => {
  return {
    labels: [label1, label2],
    datasets: [
      {
        label: '# of Votes',
        data: [(value * 100).toFixed(2), ((1 - value) * 100).toFixed(2)],
        backgroundColor: [backgroundColor, colors.grey40],
        borderColor: [colors.white],
        borderWidth: 4,
      },
    ],
  };
};

type PGraphicsTrailReport = {
  trailReport: TrailReport;
};

const GraphicsTrailReport = ({ trailReport }: PGraphicsTrailReport) => {
  return (
    <Container>
      <BoxRadius>
        <Pie
          data={generateData(trailReport.engagement, colors.pinkRegular, 'Taxa de engajamento', '')}
          options={generateOptionsChartjsPie(
            `Engajamento: ${insertPercentagemInNumber(trailReport.engagement, 2)}`,
            colors.pinkRegular,
          )}
        />
      </BoxRadius>
      <BoxRadius>
        <Pie
          data={generateData(
            trailReport.right_answer,
            colors.pinkDark,
            'Taxa de acertos',
            'Taxa de erros',
          )}
          options={generateOptionsChartjsPie(
            `Taxa média de acerto: ${insertPercentagemInNumber(trailReport.right_answer, 2)}`,
            colors.pinkDark,
          )}
        />
      </BoxRadius>
    </Container>
  );
};

export default GraphicsTrailReport;
