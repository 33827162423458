import * as React from 'react';
import { colors } from '../../functions/colors';

type PIconLock = {
  width?: number;
  height?: number;
  color?: string;
};

const IconLock = ({ width, height, color }: PIconLock) => (
  <svg
    width={width}
    height={height}
    aria-hidden="true"
    data-prefix="fas"
    data-icon="lock"
    className="prefix__svg-inline--fa prefix__fa-lock prefix__fa-w-14"
    viewBox="0 0 448 512"
  >
    <path
      fill={color}
      d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
    />
  </svg>
);

IconLock.defaultProps = {
  width: 16,
  height: 16,
  color: colors.red,
};

export default IconLock;
