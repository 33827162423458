import styled from 'styled-components';
import { fonts } from '../../../functions/fonts';
/* eslint-disable no-nested-ternary */
import { colors } from '../../../functions/colors';

type PContainer = {
  error?: boolean;
  backgroundColor?: string;
  showBorder?: boolean;
  icon?: boolean;
  disabled?: boolean;
};

export const StyledInput = styled.textarea<PContainer>`
  ${props =>
    props.showBorder
      ? props.error
        ? `border: 1px solid ${colors.error}`
        : `border: 1px solid ${colors.grey80}`
      : 'border: none;'};
  background-color: ${props =>
    props.disabled ? colors.grey40 : props.backgroundColor ? props.backgroundColor : 'transparent'};
  border-radius: 4px;
  padding: ${props => (props.icon ? '0px 8px 0px 56px' : '8px 8px')};
  width: 100%;
  outline: none;
  color: ${props => (props.error ? colors.error : colors.black)};
  height: 40px;
  margin: ${props => (props.icon ? '0px 0px 0px -22px' : '0px')};

  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;
