import { css } from 'styled-components';
import { fonts } from '../../functions/fonts';

import { colors } from '../../functions/colors';

type PUseTypeContainer = {
  typeButton?: string;
  disabled?: boolean;
};

export const useTypeContainer = ({
  typeButton = 'primary',
  disabled = false,
}: PUseTypeContainer) => {
  switch (typeButton) {
    case 'disabled':
      return css`
        width: 100%;
        height: 40px;
        cursor: ${disabled ? 'default' : 'pointer'};
        background-color: ${disabled ? colors.disabled : colors.white};
        color: ${colors.grey80};
        border: 1px solid ${colors.grey80};
        border-radius: 4px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-family: ${fonts.regular};
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      `;
    case 'primary-pink':
      return css`
        width: 100%;
        height: 40px;
        cursor: ${disabled ? 'default' : 'pointer'};
        background-color: ${disabled ? colors.disabled : colors.pinkDark};
        color: ${colors.white};
        border: none;
        border-radius: 4px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-family: ${fonts.semiBold};
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      `;
    case 'primary-white':
      return css`
        width: 100%;
        height: 40px;
        cursor: ${disabled ? 'default' : 'pointer'};
        background-color: ${disabled ? colors.disabled : colors.white};
        border: 1px solid ${colors.purpleRegular};
        border-radius: 4px;

        display: flex;
        align-items: center;
        justify-content: center;

        color: ${colors.primary};
        font-family: ${fonts.semiBold};
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      `;
    case 'purple-white':
      return css`
        width: 100%;
        height: 40px;
        cursor: ${disabled ? 'default' : 'pointer'};
        background-color: ${disabled ? colors.disabled : colors.white};
        border: 1px solid ${colors.strongPurple};
        border-radius: 4px;

        display: flex;
        align-items: center;
        justify-content: center;

        color: ${colors.strongPurple};
        font-family: ${fonts.semiBold};
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      `;
    case 'pink-white':
      return css`
        width: 100%;
        height: 40px;
        cursor: ${disabled ? 'default' : 'pointer'};
        background-color: ${colors.white};
        color: ${colors.pinkDark};
        border: none;
        border-radius: 4px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-family: ${fonts.semiBold};
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      `;
    case 'primary':
    default:
      return css`
        width: 100%;
        height: 40px;
        cursor: ${disabled ? 'default' : 'pointer'};
        background-color: ${disabled ? colors.disabled : colors.primary};
        color: ${colors.white};
        border: none;
        border-radius: 4px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-family: ${fonts.semiBold};
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      `;
  }
};
