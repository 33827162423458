import React from 'react';
import { Provider } from 'react-redux';
import Routes from './Routes';
import './store';
import { startAnalitycs } from './shared/functions/analytics';
import { startGtm } from './shared/functions/tagManager';
import './app.css';
import './datadog';
import BlockScreen from './shared/components/loadings/blockScreen/BlockScreen';
import { useGeneral } from './store/reducers/general';
import SnackBar from './shared/components/snackbar/Snackbar';

const { store } = window as never;

startAnalitycs();
startGtm();

const AppProvider = () => {
  const { loadingBlockScreen } = useGeneral();

  return (
    <>
      <SnackBar />
      <BlockScreen isShow={loadingBlockScreen} />
      <Routes />
    </>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <AppProvider />
    </Provider>
  );
};

export default App;
