import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CSSProperties } from 'styled-components';

import { useDispatch } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';
import IconTrash from '../../../images/icon/iconTrash';
import { getVideoUploadPresignedURL } from '../../../services/serviceFile';
import ButtonIcon from '../../buttons/buttonIcon/ButtonIcon';

import { Container, InputFileField, BoxImage } from '../inputFileImage/styles';
import { Title, SubTitle, ContainerInput, BoxInput, InfoLocalFile } from '../styles';
import IconAttach from '../../../images/icon/iconAttach';
import { setSnackbar } from '../../../../store/reducers/general/generalActions';
import BarProgress from '../../bars/barProgress/BarProgress';

const INITIAL_MESSAGE_NOT_FILE = 'Nenhum vídeo selecionado.';

type PInputPandaVideo = {
  videoUrl: string;
  videoId?: string;
  setMediaId: (mediaId: string, width?: number, height?: number) => void;
  title?: string;
  subTitle?: string;
  style?: CSSProperties;
};

export type UploadState = 'provisioning' | 'uploading' | 'finishing' | 'previewing';

const InputPandaVideo = ({
  title,
  subTitle,
  style,
  videoUrl,
  videoId,
  setMediaId,
}: PInputPandaVideo) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [valueInput, setValueInput] = useState('');
  const [status, setStatus] = useState<UploadState>();
  const [progress, setProgress] = useState(0);
  const axiosSource = useRef<CancelTokenSource>();
  const newCancelToken = useCallback(() => {
    axiosSource.current = axios.CancelToken.source();
    return axiosSource.current.token;
  }, []);

  const reset = () => {
    axiosSource.current?.cancel('Upload canceled by user.');
    setValueInput('');
    setMediaId('');
    setLoading(false);
  };

  const handleDeleteImage = () => {
    reset();
  };

  useEffect(() => {
    if (status) setStatus('previewing');
  }, [videoUrl]);

  const onUploadProgress = (data: { loaded: number; total: number }) => {
    // Set the progress value to show the progress bar
    setProgress(Math.round((100 * data.loaded) / data.total));
  };

  const handleError = (message: string) => {
    return () => {
      dispatch(setSnackbar(message));
      reset();
    };
  };

  const submitVideo = (video: HTMLVideoElement, file: File) => {
    return async () => {
      await getVideoUploadPresignedURL(file, setStatus, {
        onUploadProgress,
        cancelToken: newCancelToken(),
      })
        .then(mediaId => {
          setMediaId(mediaId, video.videoWidth, video.videoHeight);
          setLoading(false);
        })
        .catch(
          handleError(
            'Houve um erro inesperado e nossa equipe técnica foi notificada. Tente novamente mais tarde.',
          ),
        );
    };
  };

  const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files?.length) {
      reset();
      setLoading(true);
      const file = files[0];

      // Extracting dimensions
      const video = document.createElement('video');
      video.onloadedmetadata = submitVideo(video, file);
      video.onerror = handleError(
        'Impossível carregar vídeo. Certifique-se de escolher um arquivo de vídeo válido.',
      );
      video.src = URL.createObjectURL(file);
    }
  };

  const renderStatus = () => {
    let text = '';
    switch (status) {
      case 'provisioning':
        text = 'Alocando recursos. Aguarde...';
        break;
      case 'uploading':
        text = 'Enviando o vídeo...';
        break;
      case 'finishing':
        text = 'Finalizando. Aguarde...';
        break;
      case 'previewing':
        text = 'Gerando preview...';
        break;
      default:
        break;
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={48} color="primary" />
        <div>{text}</div>
        {!!progress && status === 'uploading' && (
          <>
            <div style={{ width: 'calc(100% - 40px)', marginTop: 4 }}>
              <BarProgress width={progress} />
            </div>
            {`${progress}%`}
          </>
        )}
      </div>
    );
  };

  return (
    <Container style={style}>
      <Title>{title}</Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      <ContainerInput>
        <BoxInput>
          <InfoLocalFile>{valueInput || INITIAL_MESSAGE_NOT_FILE}</InfoLocalFile>
          <IconAttach />
          <InputFileField type="file" onChange={handleChangeFile} value={valueInput} />
        </BoxInput>
        <ButtonIcon onClick={handleDeleteImage}>
          <IconTrash />
        </ButtonIcon>
      </ContainerInput>
      <BoxImage style={{ position: style?.position }}>
        {videoUrl && (
          <iframe
            title="Preview de vídeo"
            id={`panda-${videoUrl.split('v=')[1]}`}
            src={videoUrl}
            style={{ border: 'none' }}
            allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
            allowFullScreen={false}
          />
        )}
        {!videoUrl && videoId && (
          <div style={{ background: 'white', marginTop: 8, padding: 4 }}>
            O vídeo foi enviado com sucesso e está sendo processado para exibição. Esta etapa pode
            levar vários minutos, mas você pode clicar em &quot;CONCLUIR&quot; para salvar seu
            trabalho.
          </div>
        )}
        {loading && renderStatus()}
      </BoxImage>
    </Container>
  );
};

InputPandaVideo.defaultProps = {
  title: '',
  subTitle: '',
  videoId: '',
  style: {},
};

export default InputPandaVideo;
