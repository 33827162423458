import React, { useState, useEffect, ChangeEvent } from 'react';

import { useHistory } from 'react-router';
import Button from '../../../shared/components/buttons/Button';
import { useUser } from '../../../store/reducers/user';
import { BodyCreateUser } from '../../../shared/modals/user/bodies';
import { Area } from '../../../shared/modals/area/area';
import InsertArea from '../../area/insertEditArea/InsertEditArea';
import BreadCrumbs from '../../../shared/components/breadCrumbs/BreadCrumbs';
import { colors } from '../../../shared/functions/colors';
import MultipleSelect from '../../../shared/components/select/multipleSelect/MultipleSelect';
import Select, { POptions } from '../../../shared/components/select/Select';
import {
  Container,
  BoxInsertUser,
  InputUser,
  BoxBottom,
  ContainerInsertUser,
  BoxButtonNewArea,
  DisplayFlex,
} from './styles';
import InputDate from '../../../shared/components/input/inputDateTime/InputDateTime';
import EditDeleteArea from '../../area/editDeleteArea/EditDeleteArea';
import BoxOnBoarding from '../../../shared/components/onBoarding/BoxOnBoarding';
import { MenuUrl } from '../../../shared/enums/menuUrl';
import { useGeneral } from '../../../store/reducers/general';
import ButtonIcon from '../../../shared/components/buttons/buttonIcon/ButtonIcon';
import IconEdit from '../../../shared/images/icon/iconEdit';
import EditPasswordUser from './editPasswordUser/EditPasswordUser';
import { useTypeUser } from '../../../store/reducers/typeUser';
import ModalShowPermissions from './modalShowPermissions/ModalShowPermissions';
import IconHelp from '../../../shared/images/icon/iconHelp';
import { useArea } from '../../../store/reducers/area';

const ID_TYPE_USER_USER = 3;

type PInsertUser = {
  isEdit: boolean;
  idUser?: string;
};

const INITIAL_BODY = {
  name: '',
  email: '',
  password: '',
  areas: [],
  admin: false,
  timeStart: '',
  timeEnd: '',
  idTypeUser: ID_TYPE_USER_USER,
};

const InsertUser = ({ isEdit, idUser }: PInsertUser) => {
  const history = useHistory();
  const { user, insertUser, editUser, getUser, userPagination } = useUser();
  const { areas, getAllAreas } = useArea();
  const { getTypeUser, typesUser } = useTypeUser();
  const { setLoadingBlockScreen } = useGeneral();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openInsertArea, setOpenInsertArea] = useState(false);
  const [itensAreaSelected, setItensAreaSelected] = useState<POptions[]>([]);
  const [bodyCreateUser, setBodyCreateUser] = useState<BodyCreateUser>(INITIAL_BODY);
  const [openEditPassword, setOpenEditPassword] = useState(false);
  const [openModalPermissions, setOpenModalPermissions] = useState(false);

  useEffect(() => {
    getAllAreas();

    if (typesUser.length === 0) {
      getTypeUser();
    }
  }, []);

  useEffect(() => {
    if (!idUser) return;

    (async () => {
      setLoadingBlockScreen(true);
      await getUser(idUser);
      setLoadingBlockScreen(false);
    })();
  }, [idUser]);

  useEffect(() => {
    setBodyCreateUser({
      ...bodyCreateUser,
      areas: itensAreaSelected.map(option => option.value),
    });
  }, [itensAreaSelected]);

  useEffect(() => {
    if (user && isEdit) {
      setBodyCreateUser({
        ...bodyCreateUser,
        name: user.name,
        email: user.email,
        admin: user.idTypeUser === 2,
        areas: user.areas.map(area => area.idArea),
        timeEnd: user.timeEnd,
        timeStart: user.timeStart,
        idTypeUser: user.idTypeUser,
      });
      setItensAreaSelected(
        user.areas.map(area => ({
          value: `${area.idArea}`,
          viewValue: area.name,
        })),
      );
    } else {
      setItensAreaSelected([]);
      setBodyCreateUser(INITIAL_BODY);
    }
  }, [isEdit, user]);

  const verifyDisabledButton = () => {
    if (
      bodyCreateUser.email === '' ||
      bodyCreateUser.name === '' ||
      bodyCreateUser.idTypeUser <= 0 ||
      bodyCreateUser.areas.length <= 0
    ) {
      return true;
    }
    return false;
  };

  const convertAreasToPOptions = () => {
    const arrayReturn: POptions[] = [];

    areas.forEach((area: Area) => {
      arrayReturn.push({
        value: `${area.idArea}`,
        viewValue: area.name,
      });
    });

    return arrayReturn;
  };

  const convertTypesUserToPOptions = (): POptions[] => {
    const typeUserUser = typesUser.find(typeUser => typeUser.idTypeUser === ID_TYPE_USER_USER);
    if (!typeUserUser) {
      return [];
    }

    const arrayReturn = [
      {
        value: `${typeUserUser.idTypeUser}`,
        viewValue: typeUserUser.name,
      },
    ];

    typesUser
      .filter(typeUser => typeUser.idTypeUser !== ID_TYPE_USER_USER)
      .forEach(typeUser => {
        arrayReturn.push({
          value: `${typeUser.idTypeUser}`,
          viewValue: typeUser.name,
        });
      });

    return arrayReturn;
  };

  const handleGoToUsers = () => {
    history.push(MenuUrl.user);
  };

  const handleInsertUser = async () => {
    setLoading(true);
    try {
      if (isEdit && user) {
        await editUser(bodyCreateUser, user.idUser, userPagination);
      } else {
        await insertUser(bodyCreateUser, userPagination);
      }
      handleGoToUsers();
      setLoading(false);
    } catch (_) {
      setLoading(false);
    }
  };

  const handleOnChangeSelectTypeUser = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setBodyCreateUser({
      ...bodyCreateUser,
      idTypeUser: event.target.value ? parseInt(event.target.value, 10) : 0,
    });
  };

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setBodyCreateUser({
      ...bodyCreateUser,
      name: event.target.value,
    });
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setBodyCreateUser({
      ...bodyCreateUser,
      email: event.target.value,
    });
  };

  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setBodyCreateUser({
      ...bodyCreateUser,
      password: event.target.value,
    });
  };

  const handleChangeBody = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    setBodyCreateUser({
      ...bodyCreateUser,
      [name]: event.target.value,
    });
  };

  return (
    <Container>
      {user && isEdit && (
        <EditPasswordUser
          openEditPassword={openEditPassword}
          setOpenEditPassword={setOpenEditPassword}
          idUser={user.idUser}
        />
      )}
      <ModalShowPermissions
        openModal={openModalPermissions}
        setOpenModal={setOpenModalPermissions}
      />
      <EditDeleteArea openModal={openModal} setOpenModal={setOpenModal} />
      <InsertArea openInsertArea={openInsertArea} setOpenInsertArea={setOpenInsertArea} />
      <BreadCrumbs
        listMenus={[
          {
            name: 'Membros',
            action: handleGoToUsers,
          },
          {
            name: 'Novo membro',
          },
        ]}
      />
      <ContainerInsertUser>
        <BoxInsertUser>
          <BoxOnBoarding idsShowOnBoarding={[10]}>
            <InputUser
              title="Nome completo*"
              placeholder="Digite"
              value={bodyCreateUser.name}
              onChange={handleChangeName}
            />
          </BoxOnBoarding>
          <BoxOnBoarding idsShowOnBoarding={[11]}>
            <InputUser
              title="E-mail*"
              placeholder="Digite"
              value={bodyCreateUser.email}
              onChange={handleChangeEmail}
            />
          </BoxOnBoarding>
          <DisplayFlex>
            <InputUser
              title="Senha (opcional)"
              placeholder="Digite"
              value={bodyCreateUser.password}
              onChange={handleChangePassword}
              style={{ width: 240 }}
              disabled={isEdit}
              type="password"
            />
            {user && isEdit && (
              <ButtonIcon onClick={() => setOpenEditPassword(true)} style={{ margin: 'auto 4px' }}>
                <IconEdit />
              </ButtonIcon>
            )}
            <InputDate
              title="Hora acesso inicial (opcional)"
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'timeStart')}
              type="time"
              value={bodyCreateUser.timeStart}
              style={{ marginLeft: 32, width: 160 }}
            />
            <InputDate
              title="Hora acesso final (opcional)"
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'timeEnd')}
              type="time"
              value={bodyCreateUser.timeEnd}
              style={{ marginLeft: 32, width: 160 }}
            />
          </DisplayFlex>

          <BoxOnBoarding idsShowOnBoarding={[12]}>
            <MultipleSelect
              listOptions={convertAreasToPOptions()}
              itensSelected={itensAreaSelected}
              setItensSelected={setItensAreaSelected}
              title="Área(s)*"
            />
          </BoxOnBoarding>
          <BoxOnBoarding idsShowOnBoarding={[13]}>
            <Select
              style={{ marginTop: 16 }}
              title="Tipo de membro* (Selecione o perfil do membro conforme suas permissões e necessidades)"
              nameSelect="select_type_user"
              listOptions={convertTypesUserToPOptions()}
              onChange={handleOnChangeSelectTypeUser}
              valueSelected={`${bodyCreateUser.idTypeUser}`}
              iconTitle={
                <ButtonIcon style={{ marginLeft: 8 }} onClick={() => setOpenModalPermissions(true)}>
                  <IconHelp />
                </ButtonIcon>
              }
            />
          </BoxOnBoarding>
          <BoxBottom>
            <Button onClick={handleGoToUsers} style={{ width: 120 }} typeButton="primary-white">
              Cancelar
            </Button>
            <BoxOnBoarding idsShowOnBoarding={[14]}>
              <Button
                loading={loading}
                onClick={handleInsertUser}
                disabled={verifyDisabledButton()}
                style={{ width: 120, marginLeft: 16 }}
              >
                SALVAR
              </Button>
            </BoxOnBoarding>
          </BoxBottom>
        </BoxInsertUser>
        <BoxButtonNewArea>
          <Button
            onClick={() => setOpenInsertArea(true)}
            icon="plus"
            colorIcon={colors.white}
            style={{ marginBottom: 16 }}
          >
            NOVA ÁREA
          </Button>
          <Button onClick={() => setOpenModal(true)} typeButton="primary-white">
            EDITAR ÁREAS
          </Button>
        </BoxButtonNewArea>
      </ContainerInsertUser>
    </Container>
  );
};

InsertUser.defaultProps = {
  idUser: undefined,
};

export default InsertUser;
