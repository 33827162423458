import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useQuestion } from '../../../../store/reducers/question';
import { colors } from '../../../functions/colors';
import Button from '../../buttons/Button';
import { DisplayFlex, TextHeaderMain } from '../../styled/general';
import { TextDefault, TitleBold } from '../../styled/text';
import Modal from '../Modal';
import { BoxAlternative, BoxTitleAlternative, Image } from './styles';

type PModalQuestionMoreInfo = {
  idQuestion?: string;
  setIdQuestion: Dispatch<SetStateAction<string | undefined>>;
};

const ModalQuestionMoreInfo = ({ idQuestion, setIdQuestion }: PModalQuestionMoreInfo) => {
  const [openModal, setOpenModal] = useState(false);
  const { question, getAllDataQuestion } = useQuestion();

  useEffect(() => {
    if (idQuestion) {
      setOpenModal(true);
      getAllDataQuestion(idQuestion);
    }
  }, [idQuestion]);

  const handleCloseModal = () => {
    setOpenModal(false);
    setIdQuestion(undefined);
  };

  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <TitleBold>Ver detalhes</TitleBold>
      {question ? (
        <div>
          <TextHeaderMain style={{ margin: '40px 0px' }}>{question.text}</TextHeaderMain>
          {question.image && <Image src={question.image} alt="image" />}
          <BoxTitleAlternative>Respostas</BoxTitleAlternative>
          {question.alternatives.map(alternative => {
            return (
              <BoxAlternative isTrueQuestion={alternative.trueQuestion}>
                {`${alternative.text} ${alternative.trueQuestion ? '(resposta)' : ''}`}
              </BoxAlternative>
            );
          })}
          {question.justification && (
            <>
              <TextDefault style={{ margin: '16px 0px' }}>
                <strong>Justificativa</strong>
              </TextDefault>
              <TextDefault>{question.justification}</TextDefault>
            </>
          )}
        </div>
      ) : (
        <DisplayFlex style={{ justifyContent: 'center', alignItems: 'center' }}>
          <ReactLoading type="bubbles" color={colors.primary} width={44} height={40} />
        </DisplayFlex>
      )}
      <Button
        typeButton="primary-white"
        style={{ width: 152, margin: '32px auto auto auto' }}
        onClick={handleCloseModal}
      >
        FECHAR
      </Button>
    </Modal>
  );
};

ModalQuestionMoreInfo.defaultProps = {
  idQuestion: undefined,
};

export default ModalQuestionMoreInfo;
