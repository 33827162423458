import React from 'react';
import { Pie } from 'react-chartjs-2';
import { TextDefault, TextLarge, TitleSemiBold } from '../../../shared/components/styled/text';
import { generateOptionsChartjsPie } from '../../../shared/functions/chartJs';
import { colors } from '../../../shared/functions/colors';
import { dateTimeStampToString } from '../../../shared/functions/date';
import { insertPercentagemInNumber } from '../../../shared/functions/number';
import { UserReport } from '../../../shared/modals/user/user';

import { Container, BoxRadius } from './styles';

const generateData = (value: number, backgroundColor: string, label1: string, label2: string) => {
  return {
    labels: [label1, label2],
    datasets: [
      {
        label: '# of Votes',
        data: [(value * 100).toFixed(2), ((1 - value) * 100).toFixed(2)],
        backgroundColor: [backgroundColor, colors.grey40],
        borderColor: [colors.white],
        borderWidth: 4,
      },
    ],
  };
};

type PTopUserReport = {
  userReport: UserReport;
};

const TopUserReport = ({ userReport }: PTopUserReport) => {
  return (
    <Container>
      <BoxRadius>
        <Pie
          data={generateData(userReport.engagement, colors.purpleLight, 'Taxa de engajamento', '')}
          options={generateOptionsChartjsPie(
            `Engajamento total: ${insertPercentagemInNumber(userReport.engagement, 2)}`,
            colors.pinkDark,
          )}
        />
      </BoxRadius>
      <BoxRadius>
        <Pie
          data={generateData(userReport.right_question, colors.pinkLight, 'Taxa de acertos', '')}
          options={generateOptionsChartjsPie(
            `Taxa média de acerto: ${insertPercentagemInNumber(userReport.right_question, 2)}`,
            colors.pinkDark,
          )}
        />
      </BoxRadius>
      <BoxRadius style={{ padding: 40 }}>
        <TitleSemiBold color={colors.pinkDark}>Acessos</TitleSemiBold>
        <TextLarge style={{ textAlign: 'center' }}>{userReport.minutes_amount}</TextLarge>

        <TextDefault color={colors.grey100}>(no período selecionado)</TextDefault>
        <TextDefault color={colors.grey100}>
          {`Último acesso: ${
            userReport.last_logged ? dateTimeStampToString(userReport.last_logged) : ' - '
          }`}
        </TextDefault>
      </BoxRadius>
    </Container>
  );
};

export default TopUserReport;
