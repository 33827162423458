import styled from 'styled-components';
import { colors } from '../../../shared/functions/colors';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;

  @media print {
    display: block;
  }
`;

export const BoxRadius = styled.div`
  border: 1px solid ${colors.grey80};
  border-radius: 4px;
  width: 30%;
  padding: 24px;
  height: 340px;
  margin-bottom: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media print {
    width: 100%;
  }
`;
