import React, { useState } from 'react';
import ButtonIcon from '../../../shared/components/buttons/buttonIcon/ButtonIcon';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import Tooltip from '../../../shared/components/tooltip/Tooltip';
import { RewardManagerTypes } from '../../../shared/enums/rewardRequestTypes';
import { colors } from '../../../shared/functions/colors';
import { dateTimeStampToString } from '../../../shared/functions/date';
import IconBlockCircle from '../../../shared/images/icon/iconBlockCircle';
import IconCheckCircle from '../../../shared/images/icon/iconCheckCircle';
import { RewardManager } from '../../../shared/modals/reward/reward';
import { useRewards } from '../../../store/reducers/reward';
import { Container } from './styles';

type PChangeStatusRewardManager = {
  rewardRequest: RewardManager;
};

const ChangeStatusRewardManager = ({ rewardRequest }: PChangeStatusRewardManager) => {
  const { changeStatusRewardManager } = useRewards();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<number>(0);

  const handleOnCloseModal = () => {
    setOpenModal(false);
  };

  const handleOnOpenModal = (currentStatus: number) => {
    setStatus(currentStatus);
    setOpenModal(true);
  };

  const handleOnClickConfirm = async () => {
    setLoading(true);
    changeStatusRewardManager(rewardRequest.idAwardManager, status);
    handleOnCloseModal();
    setLoading(false);
  };

  const renderButtons = () => {
    switch (rewardRequest.status) {
      case RewardManagerTypes.approved:
        return (
          <Container>
            <Tooltip
              title={
                rewardRequest.approvedDate ? dateTimeStampToString(rewardRequest.approvedDate) : ''
              }
            >
              <IconCheckCircle />
            </Tooltip>
            <IconBlockCircle color={colors.grey60} />
          </Container>
        );
      case RewardManagerTypes.cancelled:
        return (
          <Container>
            <IconCheckCircle color={colors.grey60} />
            <Tooltip
              title={
                rewardRequest.approvedDate ? dateTimeStampToString(rewardRequest.approvedDate) : ''
              }
            >
              <IconBlockCircle />
            </Tooltip>
          </Container>
        );
      case RewardManagerTypes.pending:
      default:
        return (
          <Container>
            <ButtonIcon onClick={() => handleOnOpenModal(RewardManagerTypes.approved)}>
              <IconCheckCircle color={colors.grey100} />
            </ButtonIcon>
            <ButtonIcon onClick={() => handleOnOpenModal(RewardManagerTypes.cancelled)}>
              <IconBlockCircle color={colors.grey100} />
            </ButtonIcon>
          </Container>
        );
    }
  };

  return (
    <>
      <ModalConfirm
        title={status === RewardManagerTypes.approved ? 'Aprovar' : 'Cancelar'}
        message={`Tem certeza que deseja ${
          status === RewardManagerTypes.approved ? 'aprovar' : 'cancelar'
        } a solicitação de compra desse usuário?`}
        open={openModal}
        onClose={handleOnCloseModal}
        onClickSecondary={handleOnCloseModal}
        onClickPrimary={handleOnClickConfirm}
        loadingPrimary={loading}
      />
      {renderButtons()}
    </>
  );
};

export default ChangeStatusRewardManager;
