import React, { ChangeEventHandler, CSSProperties } from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '../../../tooltip/Tooltip';

import { Checkmark, Container, LabelTitle, RadioInput } from './style';
import { colors } from '../../../../functions/colors';

type PRadioPurple = {
  labelTitle: string;
  name: string;
  checked?: boolean;
  value: string;
  details?: string;
  style?: CSSProperties;

  onChange: ChangeEventHandler<HTMLInputElement> | undefined;
  id: string;
};

const RadioPurple = ({
  labelTitle,
  name,
  checked,
  onChange,
  id,
  value,
  details,
  style,
}: PRadioPurple) => {
  return (
    <Container style={style}>
      <LabelTitle>{labelTitle}</LabelTitle>
      <RadioInput
        type="radio"
        name={name}
        checked={checked}
        onChange={onChange}
        id={id}
        value={value}
      />
      <Checkmark />
      {details && (
        <Tooltip title={details}>
          <HelpOutlineIcon fontSize="small" style={{ marginLeft: 8, color: colors.greyDark }} />
        </Tooltip>
      )}
    </Container>
  );
};

RadioPurple.defaultProps = {
  checked: false,
  details: undefined,
  style: {},
};

export default RadioPurple;
