export enum colors {
  black = '#262824',
  blue = '#008DB8',
  blueLight = '#008DB8',
  blueRegular = '#0597f2',
  greyDark = '#9f9f9f',
  grey = '#B2CBD3',
  grey40 = '#ECEEF0',
  grey60 = '#D8DDE1',
  grey80 = '#B1BBC2',
  grey100 = '#929898',
  white = '#FFFFFF',
  ice = '#f7f8fa',
  disabled = '#CCCCCC',
  error = '#E11900',
  errorLight = '#FCD0CF',
  success = '#05A357',
  successLight = '#D8FFE0',
  greenLight = '#D8FFE0',
  green = '#00B934',
  redLight = '#FFD1D1',
  red = '#B90000',
  yellow = '#ffd33a',
  secondary = '#8C29B0',
  primary = '#512C85',
  yellowRegular = '#FFF85C',
  orangeRegular = '#F09838',
  orangeDark = '#B73D21',
  pinkDark = '#8C29B0',
  pinkRegular = '#C833DB',
  pinkLight = '#EF8ADE',
  purpleRegular = '#512C85',
  purpleLight = '#AA74F7',
  strongPurple = '#7500E5',
  background = '#181818',
}

export default colors;
