import React, { Dispatch, SetStateAction, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { BoxRadiusBorder } from '../../../shared/components/styled/box';
import { TitleSemiBold } from '../../../shared/components/styled/text';
import { HeaderRanking } from '../styles';
import { colors } from '../../../shared/functions/colors';
import { DisplayFlexCenterCenter } from '../../../shared/components/styled/general';
import RankingReport from '../../../shared/components/table/tableDefault/TableDefault';
import Button from '../../../shared/components/buttons/Button';
import { useReport } from '../../../store/reducers/report';

type PReportPresence = {
  idContest: string;
  setShowPresence: Dispatch<SetStateAction<boolean>>;
};

const ReportPresence = ({ idContest, setShowPresence }: PReportPresence) => {
  const { presenceGraphic, getReportPresence } = useReport();

  useEffect(() => {
    getReportPresence([idContest]);
  }, []);

  return (
    <BoxRadiusBorder style={{ marginBottom: 32 }}>
      {!presenceGraphic ? (
        <DisplayFlexCenterCenter>
          <ReactLoading type="bubbles" color={colors.primary} width={44} height={40} />
        </DisplayFlexCenterCenter>
      ) : (
        <>
          <HeaderRanking>
            <TitleSemiBold color={colors.pinkDark}>Relatório &quot;Dedo duro&quot;</TitleSemiBold>
            <Button
              style={{ width: 180 }}
              typeButton="primary-white"
              onClick={() => setShowPresence(false)}
            >
              OCULTAR TABELA
            </Button>
          </HeaderRanking>
          <RankingReport infoTable={presenceGraphic} />
        </>
      )}
    </BoxRadiusBorder>
  );
};

export default ReportPresence;
