import { Dispatch } from 'redux';

import * as serviceReward from '../../../shared/services/serviceReward';
import * as libraryActions from './rewardActions';
import { setSnackbar } from '../general/generalActions';
import { BodyCreateReward } from '../../../shared/modals/reward/bodies';
import { SUCCESS_SNACKBAR } from '../../../shared/components/snackbar/Snackbar';
import {
  REWARD_INSERT,
  REWARD_EDIT,
  REWARD_DELETE,
  REWARD_PERMISSION,
  ERROR_MAINTENANCE,
  REWARD_DUPLICATE,
} from '../../../shared/constants/messages';
import { doLogout } from '../../../shared/functions/connection/auth';

export const getRewards = () => async (dispatch: Dispatch) => {
  try {
    const rewardsRequests = await serviceReward.getRewards();
    dispatch(libraryActions.setRewards(rewardsRequests));
  } catch (error) {
    if (error instanceof Error) {
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getReward = (idReward: string) => async (dispatch: Dispatch) => {
  try {
    const rewardRequest = await serviceReward.getReward(idReward);
    dispatch(libraryActions.setReward(rewardRequest));
  } catch (error) {
    if (error instanceof Error) {
      dispatch(setSnackbar(error.message));
    }
  }
};

export const insertReward = (body: BodyCreateReward) => async (dispatch: Dispatch) => {
  try {
    await serviceReward.insertReward(body);
    await getRewards()(dispatch);
    dispatch(setSnackbar(REWARD_INSERT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      dispatch(setSnackbar(error.message));
    }
  }
};

export const editReward = (body: BodyCreateReward, idReward: string) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceReward.editReward(body, idReward);
    await getRewards()(dispatch);
    dispatch(setSnackbar(REWARD_EDIT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      dispatch(setSnackbar(error.message));
    }
  }
};

export const deleteReward = (idReward: string) => async (dispatch: Dispatch) => {
  try {
    await serviceReward.deleteReward(idReward);
    await getRewards()(dispatch);
    dispatch(setSnackbar(REWARD_DELETE, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getRewardsManagers = () => async (dispatch: Dispatch) => {
  try {
    const rewardManagers = await serviceReward.getRewardManagers();
    dispatch(libraryActions.setRewardsManagers(rewardManagers));
  } catch (error) {
    if (error instanceof Error) {
      dispatch(setSnackbar(error.message));
    }
  }
};

export const changeStatusRewardManager = (idRewardManager: string, status: number) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceReward.setStatusRewardManager(idRewardManager, status);
    await getRewardsManagers()(dispatch);
  } catch (error) {
    if (error instanceof Error) {
      dispatch(setSnackbar(error.message));
    }
  }
};

export const changeRewardEnable = (idReward: string) => async (dispatch: Dispatch) => {
  try {
    await serviceReward.changeRewardEnable(idReward);
    await getRewards()(dispatch);
    dispatch(setSnackbar(REWARD_PERMISSION, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const duplicateReward = (idReward: string) => async (dispatch: Dispatch) => {
  try {
    await serviceReward.duplicateReward(idReward);
    await getRewards()(dispatch);
    dispatch(setSnackbar(REWARD_DUPLICATE, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};
