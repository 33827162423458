import { RolloutInterface } from '../../features/rollout/Rollout';
import {
  ConnectApiGet,
  ConnectApiPost,
  ConnectApiPUT,
  ConnectApiDelete,
  urls,
} from '../functions/connection';
import { doLogout } from '../functions/connection/auth';
import { ERROR_MAINTENANCE } from '../constants/messages';
import { setSnackbar, SnackBarActionInterface } from '../../store/reducers/general/generalActions';

export const getAll = async (dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    const response = await ConnectApiGet(urls.ROLLOUTS_URL);
    const rollouts: RolloutInterface[] = response.data;
    return rollouts;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }

  return [];
};

export const get = async (id: string, dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    const response = await ConnectApiGet(`${urls.ROLLOUTS_URL}/${id}`);
    const rollouts: RolloutInterface = response.data;
    return rollouts;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }

  return null;
};

export const create = async (
  attrs: RolloutInterface,
  dispatch: (arg: SnackBarActionInterface) => void,
) => {
  try {
    await ConnectApiPost(urls.ROLLOUTS_URL, { rollout: attrs });
    dispatch(setSnackbar('Rollout inserido com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const update = async (
  attrs: RolloutInterface | { enabled: boolean },
  id: string,
  dispatch: (arg: SnackBarActionInterface) => void,
) => {
  try {
    await ConnectApiPUT(`${urls.ROLLOUTS_URL}/${id}`, { rollout: attrs });
    dispatch(setSnackbar('Rollout modificado com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const destroy = async (id: string, dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    await ConnectApiDelete(`${urls.ROLLOUTS_URL}/${id}`);
    dispatch(setSnackbar('Rollout excluído com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};
