import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../../functions/colors';

type PIconCalendar = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconCalendar = ({ width, height, color, style }: PIconCalendar) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" style={style}>
    <mask id="prefix_IconCalendar" maskUnits="userSpaceOnUse" x={2} y={1} width={20} height={22}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3h1c1.1 0 2 .9 2 2v16c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h1V1h2v2h10V1h2v2zM4 21h16V8H4v13z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#prefix_IconCalendar)">
      <path fill={color} d="M0 0h24v24H0z" />
    </g>
  </svg>
);

IconCalendar.defaultProps = {
  width: 24,
  height: 24,
  color: colors.white,
  style: {},
};

export default IconCalendar;
