import React, { useState, ChangeEvent, useEffect } from 'react';

import { useHistory } from 'react-router';
import Button from '../../../shared/components/buttons/Button';
import { Container, BoxInsertUser, BoxBottom, ContainerInsertUser } from './styles';
import BreadCrumbs from '../../../shared/components/breadCrumbs/BreadCrumbs';
import InputFile from '../../../shared/components/input/inputFile/InputFile';
import FixedSnackBar, {
  PFixedSnackbar,
} from '../../../shared/components/snackbar/fixedSnackbar/FixedSnackbar';
import { MenuUrl } from '../../../shared/enums/menuUrl';
import { useContest } from '../../../store/reducers/contest';
import { useUser } from '../../../store/reducers/user';

const ImportContestList = () => {
  const history = useHistory();
  const { importContestList, clearErrors, errors } = useContest();
  const [loading, setLoading] = useState(false);
  const { userData } = useUser();
  const [fixedSnackBar, setFixedSnackbar] = useState<PFixedSnackbar>({
    type: 'error',
    errors: [],
    title: 'Ops, ocorreu um problema!',
    subtitle:
      'Ao carregar sua planilha houve um erro e a(s) linha(s) descrita(s) abaixo não foram possíveis de importar. Por favor, corrija as linhas e tente importar novamente seu arquivo.',
    show: false,
  });
  const [formData] = useState<FormData>(new FormData());
  const [disabled, setDisabled] = useState(true);
  const [valueInput, setValueInput] = useState('');

  const handleImportContestList = async () => {
    setLoading(true);
    try {
      await importContestList(formData);
    } catch (error) {
      setFixedSnackbar({
        type: 'error',
        title: 'Ops, ocorreu um problema!',
        subtitle:
          'Ao carregar sua planilha houve um erro e a(s) linha(s) descrita(s) abaixo não foram possíveis de importar. Por favor, corrija as linhas e tente importar novamente seu arquivo.',
        errors,
        show: true,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    if (!errors.length && valueInput !== '') {
      history.push(MenuUrl.contest);
    } else if (errors.length > 0) {
      setFixedSnackbar({
        type: 'error',
        title: 'Ops, ocorreu um problema!',
        subtitle:
          'Ao carregar sua planilha houve um erro e a(s) linha(s) descrita(s) abaixo não foram possíveis de importar. Por favor, corrija as linhas e tente importar novamente seu arquivo.',
        errors,
        show: true,
      });
    }
  }, [errors]);

  const handleDelete = () => {
    setValueInput('');
  };

  const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    setValueInput(event.target.value);
    const { files } = event.target;
    if (files?.length) {
      setLoading(true);
      formData.set('tourneyListCsv', files[0]);
      setDisabled(false);
      setLoading(false);
    }
  };

  const closeScreen = () => {
    clearErrors();
    history.push(MenuUrl.contest);
  };

  if (!userData) return null;

  return (
    <Container>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Torneios',
            action: () => history.go(-1),
          },
          {
            name: 'Importar lista',
          },
        ]}
      />
      <ContainerInsertUser>
        <BoxInsertUser>
          <InputFile
            title="Arquivo CSV*"
            subTitle="As áreas e categorias listadas no arquivo precisam ser criadas previamente"
            onChange={handleChangeFile}
            handleDelete={handleDelete}
            valueInput={valueInput}
            style={{ marginLeft: 32, width: '40%' }}
          />
          <BoxBottom>
            <Button onClick={closeScreen} style={{ width: 120 }} typeButton="primary-white">
              Cancelar
            </Button>
            {!userData.company.migrated && (
              <Button
                loading={loading}
                onClick={handleImportContestList}
                disabled={disabled}
                style={{ width: 120, marginLeft: 16 }}
              >
                SALVAR
              </Button>
            )}
            <Button style={{ width: 130, marginLeft: 16 }}>
              <a
                href="/modelo_torneio.csv"
                download="modelo_torneio.csv"
                style={{ color: 'white', textDecoration: 'none' }}
              >
                BAIXAR MODELO
              </a>
            </Button>
          </BoxBottom>
        </BoxInsertUser>
      </ContainerInsertUser>
      <FixedSnackBar
        type={fixedSnackBar.type}
        title={fixedSnackBar.title}
        subtitle={fixedSnackBar.subtitle}
        errors={fixedSnackBar.errors}
        show={fixedSnackBar.show}
        setSnackBar={setFixedSnackbar}
      />
    </Container>
  );
};

export default ImportContestList;
