import React, { useState, Dispatch, SetStateAction } from 'react';

import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Area } from '../../../shared/modals/area/area';
import { useArea } from '../../../store/reducers/area';

type PDeleteArea = {
  openDeleteArea: boolean;
  setOpenDeleteArea: Dispatch<SetStateAction<boolean>>;
  area?: Area;
};

const DeleteArea = ({ openDeleteArea, setOpenDeleteArea, area }: PDeleteArea) => {
  const [loadingBlock, setLoadingBlock] = useState(false);
  const { deleteArea } = useArea();

  if (!area) return null;

  const handleDeleteArea = async () => {
    setLoadingBlock(true);
    await deleteArea(area.idArea);
    setLoadingBlock(false);
    setOpenDeleteArea(false);
  };

  return (
    <ModalConfirm
      loadingPrimary={loadingBlock}
      message="Tem certeza que deseja excluir a área?"
      title="Excluir área"
      onClose={() => setOpenDeleteArea(false)}
      open={openDeleteArea}
      onClickPrimary={handleDeleteArea}
      onClickSecondary={() => setOpenDeleteArea(false)}
    />
  );
};

DeleteArea.defaultProps = {
  area: undefined,
};

export default DeleteArea;
