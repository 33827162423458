import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { CSSProperties } from 'styled-components';

import { Container, Select as StyledSelect, Title } from './styles';

export type POptions = {
  value: string;
  viewValue: string;
  selected?: boolean;
};

interface PSelect extends ButtonHTMLAttributes<HTMLSelectElement> {
  valueSelected?: string;
  nameSelect: string;
  listOptions: POptions[];
  style?: CSSProperties;
  title: string;
  iconTitle?: ReactNode;
}

const Select: FC<PSelect> = ({
  valueSelected,
  listOptions,
  style,
  title,
  nameSelect,
  iconTitle,
  ...props
}: PSelect) => (
  <Container style={style}>
    <Title>
      {title}
      <>{iconTitle}</>
    </Title>
    <StyledSelect name={nameSelect} {...props}>
      {listOptions.map((option: POptions) => (
        <option
          key={option.value}
          value={option.value}
          selected={valueSelected === option.value || option.selected}
        >
          {option.viewValue}
        </option>
      ))}
    </StyledSelect>
  </Container>
);

Select.defaultProps = {
  valueSelected: '',
  style: {},
  iconTitle: undefined,
};

export default Select;
