import React, { useState, Dispatch, SetStateAction } from 'react';

import { useTrail } from '../../../store/reducers/trail';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Trail } from '../../../shared/modals/trail/trail';

type PDuplicateTrail = {
  openDuplicateTrail: boolean;
  setOpenDuplicateTrail: Dispatch<SetStateAction<boolean>>;
  trail: Trail | undefined;
};

const DuplicateTrail = ({ openDuplicateTrail, setOpenDuplicateTrail, trail }: PDuplicateTrail) => {
  const [loading, setLoading] = useState(false);
  const { duplicateTrail } = useTrail();

  if (!trail) return null;

  const handleDuplicateTrail = async () => {
    setLoading(true);
    await duplicateTrail(trail.idTrail);
    setLoading(false);
    setOpenDuplicateTrail(false);
  };

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title="Duplicar trilha"
      message="Tem certeza que deseja duplicar essa trilha?"
      onClose={() => setOpenDuplicateTrail(false)}
      open={openDuplicateTrail}
      onClickPrimary={handleDuplicateTrail}
      onClickSecondary={() => setOpenDuplicateTrail(false)}
    />
  );
};

export default DuplicateTrail;
