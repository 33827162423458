import { ImportContestError, Contest, ContestReport } from '../../../shared/modals/contest/contest';
import { ContestTypes } from './contestTypes';

export const setContests = (contests: Contest[]) => ({
  type: ContestTypes.SET_CONTESTS,
  payload: contests,
});

export const setContest = (contest?: Contest) => ({
  type: ContestTypes.SET_CONTEST,
  payload: contest,
});

export const setContestReport = (contestReport?: ContestReport) => ({
  type: ContestTypes.SET_CONTEST_REPORT,
  payload: contestReport,
});

export const setContestErros = (importContestError?: ImportContestError[]) => ({
  type: ContestTypes.SET_CONTEST_ERROR,
  payload: importContestError,
});
