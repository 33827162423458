import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../../functions/colors';

type PIconPrint = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconPrint = ({ width, height, color, style }: PIconPrint) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" style={style}>
    <mask id="prefix_IconPrint" maskUnits="userSpaceOnUse" x={2} y={3} width={20} height={18}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3h12v4H6V3zM5 8h14c1.66 0 3 1.34 3 3v6h-4v4H6v-4H2v-6c0-1.66 1.34-3 3-3zm3 11h8v-5H8v5zm11-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#prefix_IconPrint)">
      <path fill={color} d="M0 0h24v24H0z" />
    </g>
  </svg>
);

IconPrint.defaultProps = {
  width: 24,
  height: 24,
  color: colors.purpleRegular,
  style: {},
};

export default IconPrint;
