import styled from 'styled-components';
import { colors } from '../../../shared/functions/colors';
import { fonts } from '../../../shared/functions/fonts';

export const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 8;
`;

export const Shadow = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: ${colors.black}AA;
  z-index: 9;
`;

export const Menu = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 400px;
  background-color: ${colors.white};
  border-radius: 4px 0px 0px 4px;
  z-index: 10;
`;

export const BoxButtonsBottom = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 400px;
  display: flex;
  padding: 40px 24px;
  background-color: ${colors.white};
  justify-content: space-between;
`;

export const BoxAccordions = styled.div`
  overflow: auto;
  max-height: calc(100vh - 200px);
`;

export const Header = styled.div`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.grey40};
  align-items: center;
`;

export const Title = styled.h1`
  color: ${colors.pinkDark};
  font-family: ${fonts.bold};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
`;
