import {
  ConnectApiDelete,
  ConnectApiGet,
  ConnectApiPatch,
  ConnectApiPost,
  ConnectApiPUT,
  urls,
} from '../functions/connection';
import { BodyCreateReward } from '../modals/reward/bodies';

export const getRewards = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_REWARD);
  return respostaServico.data;
};

export const getRewardManagers = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_REWARD_MANAGER);
  return respostaServico.data;
};

export const setStatusRewardManager = async (idRewardManager: string, status: number) => {
  const respostaServico = await ConnectApiPatch(
    urls.URL_REWARD_MANAGER_ID.replace('{idRewardManager}', `${idRewardManager}`),
    { status },
  );
  return respostaServico.data;
};

export const getReward = async (idReward: string) => {
  const respostaServico = await ConnectApiGet(
    urls.URL_REWARD_ID.replace('{idReward}', `${idReward}`),
  );
  return respostaServico.data;
};

export const insertReward = async (bodyInsertReward: BodyCreateReward) => {
  const respostaServico = await ConnectApiPost(urls.URL_REWARD, bodyInsertReward);
  return respostaServico.data;
};

export const editReward = async (bodyInsertReward: BodyCreateReward, idReward: string) => {
  const respostaServico = await ConnectApiPUT(
    urls.URL_REWARD_ID.replace('{idReward}', `${idReward}`),
    bodyInsertReward,
  );
  return respostaServico.data;
};

export const deleteReward = async (idReward: string) => {
  const respostaServico = await ConnectApiDelete(
    urls.URL_REWARD_ID.replace('{idReward}', `${idReward}`),
  );
  return respostaServico.data;
};

export const changeRewardEnable = async (idReward: string) => {
  const url = urls.URL_ENABLE_REWARD.replace('{idReward}', `${idReward}`);
  const respostaServico = await ConnectApiPUT(url, {});
  return respostaServico.data;
};

export const duplicateReward = async (idReward: string) => {
  const url = urls.URL_REWARD_ID_DUPLICATE.replace('{idReward}', `${idReward}`);
  await ConnectApiPost(url, {});
};
