import React, { ChangeEvent, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import BreadCrumbs from '../../../shared/components/breadCrumbs/BreadCrumbs';
import Button from '../../../shared/components/buttons/Button';
import Input from '../../../shared/components/input/Input';
import InputFileImage from '../../../shared/components/input/inputFileImage/InputFile';
import RichTextEditor from '../../../shared/components/richEditor/RichTextEditor';
import MultipleSelect from '../../../shared/components/select/multipleSelect/MultipleSelect';
import { POptions } from '../../../shared/components/select/Select';
import { BoxButtonsMain } from '../../../shared/components/styled/box';
import {
  ContainerMain,
  BoxInfo,
  HeaderMain,
  DisplayFlex,
} from '../../../shared/components/styled/general';
import { TitleSemiBold } from '../../../shared/components/styled/text';
import { MenuUrl } from '../../../shared/enums/menuUrl';
import { BodyCreateReward } from '../../../shared/modals/reward/bodies';
import { Area } from '../../../shared/modals/area/area';
import { useRewards } from '../../../store/reducers/reward';
import { useGeneral } from '../../../store/reducers/general';
import InsertArea from '../../area/insertEditArea/InsertEditArea';
import { Title, Line } from '../../../shared/components/presentation/styles';
import Checkbox from '../../../shared/components/input/checkbox/Checkbox';
import { handleChangeFieldValue } from '../../../shared/functions/handlers';
import InputDate from '../../../shared/components/input/inputDateTime/InputDateTime';
import { dateTimeStampToDateMaterialUi } from '../../../shared/functions/date';
import { useArea } from '../../../store/reducers/area';

const BODY_START = {
  name: '',
  value: 0,
  maxStock: 0,
  areas: [],
  image: '',
  description: '',
  thankYouMessage: '',
  enabled: false,
  maxPurchases: undefined,
  showAvailableStock: true,
  autoApproved: false,
  productUrl: '',
  startDate: '',
  endDate: '',
};

type PInsertEditReward = {
  idReward?: string;
};

const InsertEditReward = ({ idReward }: PInsertEditReward) => {
  const history = useHistory();
  const { areas, getAllAreas } = useArea();
  const { insertReward, getReward, reward, editReward } = useRewards();
  const [bodyReward, setBodyReward] = useState<BodyCreateReward>({ ...BODY_START });
  const [itensAreaSelected, setItensAreaSelected] = useState<POptions[]>([]);
  const [openInsertArea, setOpenInsertArea] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setLoadingBlockScreen } = useGeneral();

  useEffect(() => {
    getAllAreas();
  }, []);

  useEffect(() => {
    const getRewardAsync = async () => {
      if (idReward) {
        setLoadingBlockScreen(true);
        await getReward(idReward);
        setLoadingBlockScreen(false);
      }
    };

    getRewardAsync();
  }, [idReward]);

  useEffect(() => {
    if (reward && reward.idAward === idReward) {
      setBodyReward({
        ...bodyReward,
        name: reward.name,
        value: reward.value,
        maxStock: reward.maxStock,
        image: reward.image || '',
        description: reward.description,
        enabled: reward.enabled,
        thankYouMessage: reward.thankYouMessage,
        maxPurchases: reward.maxPurchases,
        showAvailableStock: reward.showAvailableStock,
        autoApproved: reward.autoApproved,
        productUrl: reward.productUrl,
        startDate: dateTimeStampToDateMaterialUi(reward.startDate),
        endDate: dateTimeStampToDateMaterialUi(reward.endDate),
      });
      setItensAreaSelected(
        reward.areas.map(area => ({
          value: area.idArea,
          viewValue: area.name,
        })),
      );
    }
  }, [reward]);

  useEffect(() => {
    setBodyReward({
      ...bodyReward,
      areas: itensAreaSelected.map(option => option.value),
    });
  }, [itensAreaSelected]);

  const convertAreasToPOptions = () => {
    const arrayReturn: POptions[] = [];

    areas.forEach((area: Area) => {
      arrayReturn.push({
        value: area.idArea,
        viewValue: area.name,
      });
    });

    return arrayReturn;
  };

  const verifyDisabledButton = () => {
    if (
      bodyReward.name === '' ||
      bodyReward.value <= 0 ||
      bodyReward.maxStock <= 0 ||
      bodyReward.areas.length <= 0
    ) {
      return true;
    }
    return false;
  };

  const handleGoToReward = () => {
    history.push(MenuUrl.reward);
  };

  const handleInsertEditReward = async () => {
    setLoading(true);
    if (reward && reward.idAward === idReward) {
      await editReward(bodyReward, idReward);
    } else {
      await insertReward(bodyReward);
    }
    handleGoToReward();
    setLoading(false);
  };

  const handleChangeBody = (
    event: ChangeEvent<HTMLInputElement>,
    name: string,
    isString = true,
  ) => {
    setBodyReward({
      ...bodyReward,
      [name]: isString ? event.target.value : parseInt(event.target.value, 10),
    });
  };

  return (
    <ContainerMain>
      <InsertArea openInsertArea={openInsertArea} setOpenInsertArea={setOpenInsertArea} />
      <BreadCrumbs
        listMenus={[
          {
            name: 'Lojas de prêmios',
            action: handleGoToReward,
          },
          {
            name: 'Novo produto',
          },
        ]}
      />
      <HeaderMain />
      <BoxInfo>
        <TitleSemiBold style={{ marginBottom: 16 }}>Detalhes do produto</TitleSemiBold>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%', marginRight: 24 }}>
            <Input
              title="Nome do produto*"
              placeholder="Digite"
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'name')}
              value={bodyReward.name}
            />
            <DisplayFlex style={{ marginBottom: 8 }}>
              <InputDate
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'startDate')}
                type="datetime-local"
                title="Exibir na loja a partir de (opcional)"
                value={bodyReward.startDate}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: '100%', marginRight: 24 }}
              />
              <InputDate
                title="Exibir na loja até (opcional)"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'endDate')}
                type="datetime-local"
                value={bodyReward.endDate}
                style={{ width: '100%' }}
              />
            </DisplayFlex>
            <DisplayFlex>
              <Input
                title="Valor do produto (moedas)*"
                placeholder="Digite"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'value', false)}
                value={bodyReward.value}
                style={{ width: '33%', marginRight: 24 }}
                type="number"
              />
              <Input
                title="Estoque*"
                placeholder="Digite"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChangeBody(e, 'maxStock', false)
                }
                value={bodyReward.maxStock}
                style={{ width: '33%', marginRight: 24 }}
                type="number"
              />
              <Input
                title="Limite de compra (opcional)"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChangeBody(e, 'maxPurchases', false)
                }
                value={bodyReward.maxPurchases}
                style={{ width: '33%' }}
                type="number"
              />
            </DisplayFlex>
            <DisplayFlex>
              <Input
                style={{ width: '100%' }}
                title="Link do produto (se produto digital)"
                placeholder="Digite"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeBody(e, 'productUrl')}
                value={bodyReward.productUrl}
              />
            </DisplayFlex>
          </div>
          <div style={{ width: '50%' }}>
            <RichTextEditor
              label="Descrição do produto"
              value={bodyReward.description}
              style={{ width: '100%' }}
              onChange={(description: string) => setBodyReward({ ...bodyReward, description })}
              key="richTextProduct"
            />
          </div>
        </div>
        <DisplayFlex>
          <div style={{ width: '100%', marginRight: 24 }}>
            <DisplayFlex>
              <MultipleSelect
                listOptions={convertAreasToPOptions()}
                itensSelected={itensAreaSelected}
                setItensSelected={setItensAreaSelected}
                title="Área(s)*"
                textButton="NOVA ÁREA"
                onClickButton={() => setOpenInsertArea(true)}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '50%',
                  alignContent: 'center',
                  marginLeft: 24,
                }}
              >
                <InputFileImage
                  title="Inserir imagem (opcional)"
                  subTitle="Você pode inserir imagem em .png .jpeg .gif."
                  urlImage={bodyReward.image}
                  setUrlImage={image => setBodyReward({ ...bodyReward, image })}
                  style={{ width: '100%', marginBottom: '15px' }}
                  dimensions={{ width: 96, height: 96 }}
                  isCrop
                />
                <Checkbox
                  idCheckbox="showAvailableStock"
                  name="showAvailableStock"
                  checked={bodyReward?.showAvailableStock}
                  onChange={event => {
                    handleChangeFieldValue(event, setBodyReward);
                  }}
                  text="Marque para mostrar estoque restante"
                  disabled={false}
                />
                <Checkbox
                  idCheckbox="autoApproved"
                  name="autoApproved"
                  checked={bodyReward?.autoApproved}
                  onChange={event => {
                    handleChangeFieldValue(event, setBodyReward);
                  }}
                  text="Marque para aprovação automática"
                  disabled={false}
                />
              </div>
            </DisplayFlex>
          </div>
        </DisplayFlex>
        <DisplayFlex style={{ margin: '36px 0' }}>
          <Title>Página de conclusão</Title>
          <Line />
        </DisplayFlex>
        <RichTextEditor
          label="Instruções (exibido após a compra)"
          value={bodyReward.thankYouMessage}
          style={{ width: '100%' }}
          onChange={(thankYouMessage: string) => setBodyReward({ ...bodyReward, thankYouMessage })}
          key="richTextThankYou"
        />
        <BoxButtonsMain>
          <Button onClick={handleGoToReward} style={{ width: 120 }} typeButton="primary-white">
            CANCELAR
          </Button>
          <Button
            loading={loading}
            onClick={handleInsertEditReward}
            disabled={verifyDisabledButton()}
            style={{ width: 120, marginLeft: 16 }}
          >
            SALVAR
          </Button>
        </BoxButtonsMain>
      </BoxInfo>
    </ContainerMain>
  );
};

InsertEditReward.defaultProps = {
  idReward: undefined,
};

export default InsertEditReward;
