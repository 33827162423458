import React, { ButtonHTMLAttributes, FC } from 'react';

import { ContainerButton } from './styles';

export interface PButtonLineBottom extends ButtonHTMLAttributes<HTMLButtonElement> {
  textButton: string;
  isSelected?: boolean;
}

const ButtonLineBottom: FC<PButtonLineBottom> = ({
  textButton,
  isSelected,
  ...props
}: PButtonLineBottom) => {
  return (
    <ContainerButton isSelected={isSelected} {...props}>
      {textButton}
    </ContainerButton>
  );
};

ButtonLineBottom.defaultProps = {
  isSelected: true,
};

export default ButtonLineBottom;
