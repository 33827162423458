import styled from 'styled-components';
import color from '../../shared/functions/colors';

export const FirstLoginContainer = styled.div`
  position: relative;
  height: 100vh;
  z-index: 0;
  background-image: linear-gradient(180deg, ${color.blue} 0%, ${color.blueLight} 100%);
`;

export const BoxContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${color.white};

  @media (max-width: 767px) {
    width: 70vw;
    height: 70vh;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 75vw;
    height: 75vh;
  }

  @media (min-width: 1024px) {
    width: 80vw;
    height: 80vh;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 767px) {
    right: 2%;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    right: 2%;
  }

  @media (min-width: 1024px) {
    right: 2%;
  }
`;

export const TitleContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  word-break: break-word;
  @media (max-width: 767px) {
    left: 40%;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    left: 38%;
  }

  @media (min-width: 1024px) {
    left: 35%;
  }
`;

export const Title = styled.h1`
  text-align: left;
  color: ${color.blue};
  font-weight: 900;
  @media (max-width: 767px) {
    font-size: 35px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 38px;
  }

  @media (min-width: 1024px) {
    font-size: 46px;
  }
`;

export const Titleh4 = styled.h4`
  text-align: left;
  color: ${color.blue};
  font-weight: 400;

  @media (max-width: 767px) {
    font-size: 23px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 25px;
  }

  @media (min-width: 1024px) {
    font-size: 27px;
  }
`;
