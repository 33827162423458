import { useDispatch, useSelector } from 'react-redux';

import { BodyCreateContest } from '../../../shared/modals/contest/bodies';
import { RootStateGlobal } from '../../reducer';
import * as contestOperations from './contestOperations';
import * as contestActions from './contestActions';
import { Contest } from '../../../shared/modals/contest/contest';

export const useContest = () => {
  const dispatch = useDispatch();

  const { contests, contest, contestReport, errors } = useSelector(
    (state: RootStateGlobal) => state.contestReducer,
  );

  const getContests = async (
    startDate?: string,
    endDate?: string,
    idAreas?: string[],
    idCategories?: string[],
    enabled?: boolean,
  ) => contestOperations.getContests(startDate, endDate, idAreas, idCategories, enabled)(dispatch);
  const insertContest = async (bodyCreateContest: BodyCreateContest) =>
    contestOperations.insertContest(bodyCreateContest)(dispatch);
  const editContest = async (bodyCreateContest: BodyCreateContest, idContest: string) =>
    contestOperations.editContest(bodyCreateContest, idContest)(dispatch);
  const getContest = async (idContest: string) => contestOperations.getContest(idContest)(dispatch);
  const deleteContest = async (idContest: string) =>
    contestOperations.deleteContest(idContest)(dispatch);
  const duplicateContest = async (idContest: string) =>
    contestOperations.duplicateContest(idContest)(dispatch);
  const changeContestEnable = async (idContest: string) =>
    contestOperations.changeContestEnable(idContest)(dispatch);
  const getContestReport = async (idContest: string) =>
    contestOperations.getContestReport(idContest)(dispatch);
  const downloadOnePageCsv = async (idContest: string) =>
    contestOperations.downloadOnePageCsv(idContest)(dispatch);
  const importContestList = async (body: FormData) =>
    contestOperations.importContestList(body)(dispatch);

  const setContest = (x?: Contest) => dispatch(contestActions.setContest(x));
  const clearErrors = () => dispatch(contestActions.setContestErros([]));

  return {
    contests,
    contest,
    contestReport,
    errors,
    getContests,
    insertContest,
    editContest,
    getContest,
    deleteContest,
    duplicateContest,
    changeContestEnable,
    getContestReport,
    downloadOnePageCsv,
    setContest,
    clearErrors,
    importContestList,
  };
};
