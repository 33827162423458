import React, { Dispatch, SetStateAction, useState } from 'react';

import Button from '../../../../shared/components/buttons/Button';
import Input from '../../../../shared/components/input/Input';
import Modal from '../../../../shared/components/modal/Modal';
import { BoxButtons, Title, Text } from './styles';
import { useUser } from '../../../../store/reducers/user';

type PEditPasswordUser = {
  openEditPassword: boolean;
  setOpenEditPassword: Dispatch<SetStateAction<boolean>>;
  idUser: string;
};

const EditPasswordUser = ({ openEditPassword, setOpenEditPassword, idUser }: PEditPasswordUser) => {
  const { editPasswordUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState<string>('');

  const handleEditPassword = async () => {
    setLoading(true);
    try {
      await editPasswordUser(idUser, password);
      setOpenEditPassword(false);
      setPassword('');
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
    setLoading(false);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return (
    <Modal onClose={() => setOpenEditPassword(false)} open={openEditPassword}>
      <Title>Nova senha</Title>
      <Text>Preencha abaixo a nova senha para o usuário:</Text>
      <Input
        title="Nova senha"
        placeholder="Nova senha"
        value={password}
        onChange={handleChangePassword}
        messageError={errorMessage}
        error={errorMessage !== ''}
      />
      <BoxButtons>
        <Button
          onClick={() => setOpenEditPassword(false)}
          typeButton="primary-white"
          style={{ width: 120 }}
        >
          CANCELAR
        </Button>
        <Button
          disabled={password === ''}
          loading={loading}
          onClick={handleEditPassword}
          style={{ width: 120, marginLeft: 16 }}
        >
          CONFIRMAR
        </Button>
      </BoxButtons>
    </Modal>
  );
};

export default EditPasswordUser;
