import { Dispatch } from 'redux';

import * as trailActions from './trailActions';
import * as serviceTrail from '../../../shared/services/serviceTrail';
import { BodyCreateTrail } from '../../../shared/modals/trail/bodies';
import {
  ERROR_MAINTENANCE,
  TRAIL_DELETE,
  TRAIL_DUPLICATE,
  TRAIL_EDIT,
  TRAIL_INSERT,
  TRAIL_PERMISSION,
} from '../../../shared/constants/messages';
import { doLogout } from '../../../shared/functions/connection/auth';
import { setSnackbar } from '../general/generalActions';
import { SUCCESS_SNACKBAR } from '../../../shared/components/snackbar/Snackbar';

export const getTrails = (
  startDate?: string,
  endDate?: string,
  idAreas?: string[],
  enabled?: boolean,
) => async (dispatch: Dispatch) => {
  try {
    const trails = await serviceTrail.getTrails(startDate, endDate, idAreas, enabled);
    dispatch(trailActions.setTrails(trails));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const insertTrail = (bodyCreateTrail: BodyCreateTrail) => async (dispatch: Dispatch) => {
  try {
    await serviceTrail.insertTrail(bodyCreateTrail);
    await getTrails()(dispatch);
    dispatch(setSnackbar(TRAIL_INSERT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const editTrail = (bodyCreateTrail: BodyCreateTrail, idTrail: string) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceTrail.editTrail(bodyCreateTrail, idTrail);
    await getTrails()(dispatch);
    dispatch(setSnackbar(TRAIL_EDIT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const changeTrailEnable = (idTrail: string) => async (dispatch: Dispatch) => {
  try {
    await serviceTrail.changeTrailEnable(idTrail);
    await getTrails()(dispatch);
    dispatch(setSnackbar(TRAIL_PERMISSION, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getTrail = (idTrail: string) => async (dispatch: Dispatch) => {
  try {
    const trail = await serviceTrail.getTrail(idTrail);
    dispatch(trailActions.setTrail(trail));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const deleteTrail = (idTrail: string) => async (dispatch: Dispatch) => {
  try {
    await serviceTrail.deleteTrail(idTrail);
    await getTrails()(dispatch);
    dispatch(setSnackbar(TRAIL_DELETE, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const duplicateTrail = (idTrail: string) => async (dispatch: Dispatch) => {
  try {
    await serviceTrail.duplicateTrail(idTrail);
    await getTrails()(dispatch);
    dispatch(setSnackbar(TRAIL_DUPLICATE, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getTrailReport = (idTrail: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(trailActions.setTrailReport());
    const trail = await serviceTrail.getTrailReport(idTrail);
    dispatch(trailActions.setTrailReport(trail));
  } catch (error) {
    dispatch(trailActions.setTrailReport());
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};
