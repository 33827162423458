import Axios from 'axios';
import * as Constants from '../../constants/requests';
import * as Auth from './auth';
import {
  ERROR_CONNECTION,
  ERROR_INTERNAL,
  ERROR_NOT_FOUND,
  ERROR_MAINTENANCE,
  ERROR_USER_BLOCKED,
} from '../../constants/messages';
import { generalActions } from '../../../store/reducers/general';

export const headerRequests = async () => ({
  Authorization: await Auth.getAuthorizationToken(),
  'Content-Type': 'application/json',
});

class Connect {
  static async call(url: string, method: string, body: Record<string, unknown> | FormData) {
    let answer;
    let config = {};
    const headers = await headerRequests();

    try {
      config = {
        headers,
      };
    } catch (e) {
      config = {};
    }

    switch (method) {
      case Constants.GET:
        answer = Axios.get(url, config);
        break;
      case Constants.POST:
        answer = Axios.post(url, body, config);
        break;
      case Constants.DELETE:
        answer = Axios.delete(url, config);
        break;
      case Constants.PUT:
        answer = Axios.put(url, body, config);
        break;
      case Constants.PATCH:
        answer = Axios.patch(url, body, config);
        break;
      default:
        answer = Axios.get(url, config);
        break;
    }

    return answer;
  }

  // TODO: return proper message from backend and let error bubble up from here
  static async connect(url: string, method: string, body: Record<string, unknown> | FormData) {
    return Connect.call(url, method, body).catch(error => {
      if (error.response) {
        switch (error.response.status) {
          case 302:
            throw new Error(error.response.data.message);
          case 403:
          case 401:
            throw new Error(ERROR_USER_BLOCKED);
          case 404:
            throw new Error(ERROR_NOT_FOUND);
          case 400:
          case 422:
            if (error.response.data?.message) {
              throw new Error(error.response.data.message);
            }
            throw new Error(ERROR_INTERNAL);
          case 423:
            throw new Error(error.response.data.message);
          case 500:
            throw new Error(ERROR_INTERNAL);
          case 503:
            throw new Error(ERROR_MAINTENANCE);
          default:
            throw new Error(ERROR_CONNECTION);
        }
      }
      throw new Error(ERROR_CONNECTION);
    });
  }
}

const getStore = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any).store;
};

const setLoadingScreen = (enabled: boolean) => {
  getStore().dispatch(generalActions.setLoadingBlockScreen(enabled));
};

export async function ConnectApiPost(url: string, body: Record<string, unknown> | FormData) {
  setLoadingScreen(true);
  const promise = Connect.connect(url, Constants.POST, body);
  promise.then(() => setLoadingScreen(false)).catch(() => setLoadingScreen(false));
  return promise;
}

export async function ConnectApiPUT(url: string, body: Record<string, unknown>) {
  setLoadingScreen(true);
  const promise = Connect.connect(url, Constants.PUT, body);
  promise.then(() => setLoadingScreen(false)).catch(() => setLoadingScreen(false));
  return promise;
}

export async function ConnectApiPatch(url: string, body: Record<string, unknown>) {
  setLoadingScreen(true);
  const promise = Connect.connect(url, Constants.PATCH, body);
  promise.then(() => setLoadingScreen(false)).catch(() => setLoadingScreen(false));
  return promise;
}

export async function ConnectApiGet(url: string) {
  setLoadingScreen(true);
  const promise = Connect.connect(url, Constants.GET, {});
  promise.then(() => setLoadingScreen(false)).catch(() => setLoadingScreen(false));
  return promise;
}

export async function ConnectApiDelete(url: string) {
  setLoadingScreen(true);
  const promise = Connect.connect(url, Constants.DELETE, {});
  promise.then(() => setLoadingScreen(false)).catch(() => setLoadingScreen(false));
  return promise;
}
