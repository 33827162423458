import React, { useState } from 'react';
import ButtonIcon from '../../../shared/components/buttons/buttonIcon/ButtonIcon';
import MoreInfoQuestionAnswer from '../../../shared/components/modal/moreInfoQuestionAnswer/MoreInfoQuestionAnswer';
import { BoxRadiusBorder } from '../../../shared/components/styled/box';
import { DisplayFlexCenterCenter } from '../../../shared/components/styled/general';
import { TitleSemiBold } from '../../../shared/components/styled/text';
import Table from '../../../shared/components/table/Table';
import { colors } from '../../../shared/functions/colors';
import { insertPercentagemInNumber } from '../../../shared/functions/number';
import IconEye from '../../../shared/images/icon/iconEye';
import IconThumbDown from '../../../shared/images/icon/iconThumbDown';
import { QuestionTrailReport } from '../../../shared/modals/trail/trail';
import { HeaderRanking } from '../styles';

type PListQuestion = {
  listQuestion: QuestionTrailReport[];
  isPrint: boolean;
};

const ListQuestion = ({ listQuestion, isPrint }: PListQuestion) => {
  const [openMoreInfoQuestionAnswer, setOpenMoreInfoQuestionAnswer] = useState(false);
  const [idQuestion, setIdQuestion] = useState<string | undefined>();
  const [idStage, setIdStage] = useState<string | undefined>();
  const [title, setTitle] = useState<string>('');

  const handleOnCloseModal = () => {
    setIdQuestion(undefined);
    setIdStage(undefined);
    setOpenMoreInfoQuestionAnswer(false);
    setTitle('');
  };

  const handleOpenModalMoreInfo = (
    idQuestionCurrent: string,
    idStageCurrent: string,
    index: number,
  ) => {
    setIdQuestion(idQuestionCurrent);
    setIdStage(idStageCurrent);
    setTitle(`${index + 1}ª Pergunta mais errada`);
    setOpenMoreInfoQuestionAnswer(true);
  };

  const renderRowsTable = () =>
    listQuestion.map((questionReport, index) => ({
      columns: [
        index < 3 ? <IconThumbDown /> : '',
        insertPercentagemInNumber(questionReport.percentageWrongAnswer, 2),
        questionReport.text,
        questionReport.stage.name,
        <DisplayFlexCenterCenter>
          <ButtonIcon
            onClick={() =>
              handleOpenModalMoreInfo(
                questionReport.idQuestion,
                questionReport.stage.idPhase,
                index,
              )
            }
          >
            <IconEye />
          </ButtonIcon>
        </DisplayFlexCenterCenter>,
      ],
    }));

  return (
    <BoxRadiusBorder style={{ marginTop: 32 }}>
      <MoreInfoQuestionAnswer
        openMoreInfoQuestionAnswer={openMoreInfoQuestionAnswer}
        onClose={handleOnCloseModal}
        idQuestion={idQuestion}
        idStage={idStage}
        title={title}
      />
      <HeaderRanking>
        <TitleSemiBold color={colors.pinkDark}>Perguntas mais erradas</TitleSemiBold>
      </HeaderRanking>
      <Table
        nameReferenceReduxTable="table_list_question"
        listRowsPerPage={isPrint ? [1000] : [10, 25, 50, 100]}
        titles={[
          {
            name: '',
            openClick: false,
            type: 'ReactNode',
            widthTd: '20px',
          },
          {
            name: 'Taxa de erro %',
            openClick: false,
            type: 'string',
          },
          {
            name: 'Pergunta',
            openClick: true,
            type: 'string',
          },
          {
            name: 'Fase',
            openClick: true,
            type: 'string',
          },
          {
            name: 'Ver detalhes',
            openClick: false,
            type: 'ReactNode',
            widthTd: '10%',
          },
        ]}
        rows={renderRowsTable()}
      />
    </BoxRadiusBorder>
  );
};

export default ListQuestion;
