import React, { CSSProperties } from 'react';
import { TextButton, TextSmall, TitleSemiBold } from '../../styled/text';
import { Container, Image } from './styles';

type PBoxVerticalImage = {
  srcImage: string;
  titleBox: string;
  textBox: string;
  onClick: () => void;
  style?: CSSProperties;
};

const BoxVerticalImage = ({ style, srcImage, titleBox, textBox, onClick }: PBoxVerticalImage) => {
  return (
    <Container style={style}>
      <Image src={srcImage} alt="iconBox" />
      <TitleSemiBold style={{ padding: 16, textAlign: 'center' }}>{titleBox}</TitleSemiBold>
      <TextSmall style={{ marginBottom: 16 }}>{textBox}</TextSmall>
      <TextButton style={{ marginBottom: 16 }} onClick={onClick}>
        Saiba mais
      </TextButton>
    </Container>
  );
};

BoxVerticalImage.defaultProps = {
  style: {},
};

export default BoxVerticalImage;
