import { Stage } from '../../../shared/modals/stage/stage';
import { StageTypes } from './stageTypes';

export const setStages = (stages: Stage[]) => ({
  type: StageTypes.SET_STAGES,
  payload: stages,
});

export const setStage = (stage?: Stage) => ({
  type: StageTypes.SET_STAGE,
  payload: stage,
});
