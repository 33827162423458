import styled from 'styled-components';
import { colors } from '../../functions/colors';

type PBoxAbsolute = {
  left: number;
  isShow: boolean;
};

export const Container = styled.div`
  position: relative;
`;

export const BoxAbsolute = styled.div<PBoxAbsolute>`
  position: absolute;
  display: ${props => (props.isShow ? 'flex' : 'none')};
  left: ${props => props.left - 150}px;
  top: -64px;
  z-index: 900;
  width: 300px;
  justify-content: center;

  :before {
    content: ' ';
    height: 24px;
    position: absolute;
    width: 24px;
    left: 138px;
    bottom: -10px;
    background-color: ${colors.white};
    transform: rotate(45deg);
    border-radius: 4px;
  }
`;

export const BoxWhite = styled.div`
  padding: 16px;
  background: ${colors.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
`;
