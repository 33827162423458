import styled from 'styled-components';
import { colors } from '../../../functions/colors';

export const Container = styled.div`
  display: flex;
  width: 184px;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${colors.grey80};
  border-radius: 4px;
  background-color: ${colors.white};
`;

export const Image = styled.img`
  width: 100%;
  height: 86px;
`;
