import styled from 'styled-components';
import { DisplayFlex } from '../../shared/components/styled/general';

export const Image = styled.img`
  margin-right: 80px;
`;

export const ContainerBoxLibraries = styled(DisplayFlex)`
  flex-flow: wrap;
  justify-content: center;
`;
