import styled from 'styled-components';

type PDropdown = {
  placement?: string;
};

export const Container = styled.div`
  position: relative;
`;

export const Dropdown = styled.div<PDropdown>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: absolute;
  top: 72px;
  ${props => (props.placement === 'right' ? 'right: 0;' : 'left: 0;')}
  z-index: 9999;
`;
