import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import Button from '../../shared/components/buttons/Button';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import {
  ContainerMain,
  DisplayFlexAlignCenter,
  HeaderMain,
  TextHeaderMain,
} from '../../shared/components/styled/general';
import Table from '../../shared/components/table/Table';
import IconEdit from '../../shared/images/icon/iconEdit';
import IconTrash from '../../shared/images/icon/iconTrash';
import { Area as IArea } from '../../shared/modals/area/area';
import { filterArraySearch } from '../../shared/functions/utils';
import InsertArea from './insertEditArea/InsertEditArea';
import DeleteArea from './deleteArea/DeleteArea';
import IconCheck from '../../shared/images/icon/iconCheck';
import IconBlock from '../../shared/images/icon/iconBlock';
import BoxHorizontalButtons from '../../shared/components/box/boxHorizontalButtons/BoxHorizontalButtons';
import { useArea } from '../../store/reducers/area';
import SearchInput from '../../shared/components/input/searchInput/SearchInput';
import { colors } from '../../shared/functions/colors';
import EnableArea from './enableArea/EnableArea';

const Area = () => {
  const history = useHistory();
  const { areas, getAllAreas } = useArea();
  const [areasFilter, setAreasFilter] = useState<IArea[]>([]);
  const [searchArea, setSearchArea] = useState('');
  const [openInsertArea, setOpenInsertArea] = useState(false);
  const [openDeleteArea, setOpenDeleteArea] = useState(false);
  const [areaAction, setAreaAction] = useState<IArea>();
  const [openEnableArea, setOpenEnableArea] = useState(false);

  useEffect(() => {
    getAllAreas({ withDisabled: true });
  }, []);

  useEffect(() => {
    setAreasFilter(filterArraySearch(areas, 'name', searchArea));
  }, [areas]);

  const handleChangeSearchArea = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchArea(event.target.value);
    setAreasFilter(filterArraySearch(areas, 'name', event.target.value));
  };

  const handleEditArea = (area: IArea) => {
    setAreaAction(area);
    setOpenInsertArea(true);
  };

  const handleInsertArea = () => {
    setAreaAction(undefined);
    setOpenInsertArea(true);
  };

  const handleImportAreas = () => {
    history.push('/areas/import');
  };

  const handleOnClickDeleteArea = (area: IArea) => {
    setAreaAction(area);
    setOpenDeleteArea(true);
  };

  const handleOnClickEnableArea = (area: IArea) => {
    setAreaAction(area);
    setOpenEnableArea(true);
  };

  const renderRowsTable = (areaStatusFiltered: IArea[]) =>
    areaStatusFiltered.map(area => ({
      columns: [
        area.name,
        area.amountUsers,
        <>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleOnClickEnableArea(area)}>
            {area.enabled ? <IconCheck color={colors.green} /> : <IconBlock color={colors.red} />}
          </ButtonIcon>
        </>,
        <>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleEditArea(area)}>
            <IconEdit />
          </ButtonIcon>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleOnClickDeleteArea(area)}>
            <IconTrash />
          </ButtonIcon>
        </>,
      ],
    }));

  const renderTable = (areaStatusFiltered: IArea[]) => {
    return (
      <Table
        nameReferenceReduxTable="table_area"
        listRowsPerPage={[10, 25, 50, 100]}
        titles={[
          {
            name: 'Áreas',
            openClick: true,
            type: 'string',
          },
          {
            name: 'Número de membros relacionados',
            openClick: true,
            type: 'number',
          },
          {
            name: 'Permissões',
            openClick: false,
            type: 'ReactNode',
            widthTd: '10%',
          },
          {
            name: 'Ações',
            openClick: false,
            type: 'ReactNode',
            widthTd: '20%',
          },
        ]}
        rows={renderRowsTable(areaStatusFiltered)}
      />
    );
  };

  return (
    <ContainerMain>
      <EnableArea
        area={areaAction}
        openEnableArea={openEnableArea}
        setOpenEnableArea={setOpenEnableArea}
      />
      <InsertArea
        openInsertArea={openInsertArea}
        setOpenInsertArea={setOpenInsertArea}
        area={areaAction}
      />
      <DeleteArea
        openDeleteArea={openDeleteArea}
        area={areaAction}
        setOpenDeleteArea={setOpenDeleteArea}
      />
      <BreadCrumbs
        listMenus={[
          {
            name: 'Áreas',
          },
        ]}
      />
      <HeaderMain>
        <TextHeaderMain>Confira a lista de áreas cadastradas e/ou inserir uma nova.</TextHeaderMain>
        <DisplayFlexAlignCenter>
          <SearchInput
            placeholder="Buscar área..."
            search={searchArea}
            onChange={handleChangeSearchArea}
          />
          <Button style={{ width: 190, marginRight: 16 }} onClick={handleImportAreas} icon="plus">
            IMPORTAR ÁREAS
          </Button>
          <Button style={{ width: 160 }} onClick={handleInsertArea} icon="plus">
            NOVA ÁREA
          </Button>
        </DisplayFlexAlignCenter>
      </HeaderMain>
      <BoxHorizontalButtons
        items={[
          {
            textButton: 'Áreas Ativas',
            children: renderTable(areasFilter.filter(area => area.enabled)),
          },
          {
            textButton: 'Áreas Inativas',
            children: renderTable(areasFilter.filter(area => !area.enabled)),
          },
        ]}
      />
    </ContainerMain>
  );
};

export default Area;
