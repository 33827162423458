import Axios, { CancelToken } from 'axios';
import { UploadState } from '../components/input/inputPandaVideo/InputPandaVideo';
import { ConnectApiPost, urls } from '../functions/connection';

export const uploadImage = async (formData: FormData, isSetup: boolean | undefined) => {
  const url = isSetup ? urls.URL_UPLOAD_LOGO : urls.URL_UPLOAD_IMAGE;
  const respostaServico = await ConnectApiPost(url, formData);
  return respostaServico.data;
};

export const getVideoUploadPresignedURL = async (
  file: File,
  setStatus?: (x: UploadState) => void,
  config?: {
    onUploadProgress: (x: { loaded: number; total: number }) => void;
    cancelToken: CancelToken;
  },
) => {
  // Requests a URL to upload file to Panda Video
  setStatus?.('provisioning');
  const video = { name: file.name, size: file.size };
  const response = await ConnectApiPost(urls.VIDEO_UPLOAD_PRESIGNED_URL, { video });
  const { url: preSignedURL, videoId } = response.data;

  // Uploads file to Panda using the pre-signed URL
  setStatus?.('uploading');
  await Axios.patch(preSignedURL, file, {
    ...config,
    headers: {
      'Upload-Offset': 0,
      'Tus-Resumable': '1.0.0',
      'Content-Type': 'application/offset+octet-stream',
    },
  });

  // Returns the video ID
  setStatus?.('finishing');
  return videoId;
};
