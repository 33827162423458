import React, { Dispatch, SetStateAction, useState, useEffect, ChangeEvent } from 'react';

import Button from '../../../shared/components/buttons/Button';
import Input from '../../../shared/components/input/Input';
import Modal from '../../../shared/components/modal/Modal';
import { BodyInsertCategory } from '../../../shared/modals/question/bodies';
import { BoxButtons, Title, Paragraph } from './styles';
import { Category } from '../../../shared/modals/question/category';
import { useQuestion } from '../../../store/reducers/question';

type PInsertCategory = {
  openInsertCategory: boolean;
  setOpenInsertCategory: Dispatch<SetStateAction<boolean>>;
  editCategory?: Category;
};

const initialBody = {
  name: '',
};

const InsertCategory = ({
  openInsertCategory,
  setOpenInsertCategory,
  editCategory,
}: PInsertCategory) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [bodyInsertCategory, setBodyInsertCategory] = useState<BodyInsertCategory>(initialBody);
  const questionRedux = useQuestion();

  useEffect(() => {
    if (editCategory) {
      setBodyInsertCategory({ name: editCategory.name });
    } else {
      setBodyInsertCategory(initialBody);
    }
    setErrorMessage('');
  }, [editCategory, openInsertCategory]);

  const handleInsertCategory = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (editCategory) {
        await questionRedux.editCategory(bodyInsertCategory, editCategory.idCategory);
      } else {
        await questionRedux.insertCategory(bodyInsertCategory);
      }
      setOpenInsertCategory(false);
      setBodyInsertCategory(initialBody);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
    setLoading(false);
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setBodyInsertCategory({
      ...bodyInsertCategory,
      name: event.target.value,
    });
  };

  return (
    <Modal onClose={() => setOpenInsertCategory(false)} open={openInsertCategory}>
      <form onSubmit={handleInsertCategory}>
        <Title>{editCategory ? 'Editar' : 'Nova categoria'}</Title>
        <Paragraph>
          {editCategory
            ? 'Edite a categoria abaixo e depois salve suas alterações'
            : 'Preencha abaixo os dados da nova categoria:'}
        </Paragraph>
        <Input
          title="Categoria*"
          placeholder="Categoria*"
          value={bodyInsertCategory.name}
          onChange={handleChangeName}
          error={errorMessage !== ''}
          messageError={errorMessage}
        />
        <BoxButtons>
          <Button
            onClick={() => setOpenInsertCategory(false)}
            typeButton="primary-white"
            style={{ width: 120 }}
          >
            CANCELAR
          </Button>
          <Button
            disabled={bodyInsertCategory.name === '' || loading}
            loading={loading}
            type="submit"
            style={{ width: 120, marginLeft: 16 }}
          >
            {editCategory ? 'SALVAR' : 'CONFIRMAR'}
          </Button>
        </BoxButtons>
      </form>
    </Modal>
  );
};

InsertCategory.defaultProps = {
  editCategory: null,
};

export default InsertCategory;
