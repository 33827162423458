import {
  Question,
  ImportQuestionError,
  QuestionPagination,
} from '../../../shared/modals/question/question';
import { Category } from '../../../shared/modals/question/category';
import { QuestionTypes } from './questionTypes';

export const setCategories = (categories: Category[]) => ({
  type: QuestionTypes.SET_CATEGORY,
  payload: categories,
});

export const setQuestions = (questions: Question[]) => ({
  type: QuestionTypes.SET_QUESTIONS,
  payload: questions,
});

export const setQuestion = (question?: Question) => ({
  type: QuestionTypes.SET_QUESTION,
  payload: question,
});

export const setQuestionInfoAnswer = (question?: Question) => ({
  type: QuestionTypes.SET_QUESTION_INFO_ANSWER,
  payload: question,
});

export const setImportErrors = (errors: ImportQuestionError[]) => ({
  type: QuestionTypes.SET_IMPORT_ERRORS,
  payload: errors,
});

export const setTotalCount = (totalCount: number) => ({
  type: QuestionTypes.SET_TOTAL_COUNT,
  payload: totalCount,
});

export const setQuestionPagination = (questionPagination: QuestionPagination) => ({
  type: QuestionTypes.SET_QUESTION_PAGINATION,
  payload: questionPagination,
});
