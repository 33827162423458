import React, { useState, Dispatch, SetStateAction } from 'react';

import { useStage } from '../../../store/reducers/stage';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Stage } from '../../../shared/modals/stage/stage';
import { Paragraph } from '../../../shared/components/modal/modalConfirm/styles';

type PDeleteStage = {
  openDeleteStage: boolean;
  setOpenDeleteStage: Dispatch<SetStateAction<boolean>>;
  stage: Stage | undefined;
  idTrail?: string;
};

const DeleteStage = ({ openDeleteStage, setOpenDeleteStage, stage, idTrail }: PDeleteStage) => {
  const [loading, setLoading] = useState(false);
  const { deleteStage } = useStage();

  if (!stage) return null;

  const handleDeleteStage = async () => {
    setLoading(true);
    await deleteStage(stage.idPhase, idTrail);
    setLoading(false);
    setOpenDeleteStage(false);
  };

  const warningMsg = () => (
    <>
      <Paragraph style={{ marginBottom: '20px' }}>
        CUIDADO! Esta ação impacta as estatísticas dos relatórios, pois remove PERMANENTEMENTE:
      </Paragraph>
      <Paragraph>- A fase;</Paragraph>
      <Paragraph>- As partidas (associadas à fase);</Paragraph>
      <Paragraph>- As respostas (associadas às partidas da fase).</Paragraph>
    </>
  );

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title="Tem certeza que deseja excluir a fase?"
      message={warningMsg()}
      onClose={() => setOpenDeleteStage(false)}
      open={openDeleteStage}
      onClickPrimary={handleDeleteStage}
      onClickSecondary={() => setOpenDeleteStage(false)}
    />
  );
};

DeleteStage.defaultProps = {
  idTrail: undefined,
};

export default DeleteStage;
