import * as React from 'react';

const UnorderedListIcon = ({ active, disabled }: { active: boolean; disabled: boolean }) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <mask id="unorderedIconMask" maskUnits="userSpaceOnUse" x={0} y={0} width="100%" height="100%">
      <rect x="0" y="0" width="100%" height="100%" fill={disabled ? '#777' : '#fff'} />
    </mask>
    <g>
      <g fill={active ? 'blue' : '#5f6368'} fillRule="evenodd" transform="translate(2 2)">
        <rect mask="url(#unorderedIconMask)" width="9" height="2" x="5" y="1" />
        <rect mask="url(#unorderedIconMask)" width="9" height="2" x="5" y="6" />
        <rect mask="url(#unorderedIconMask)" width="9" height="2" x="5" y="11" />
        <circle mask="url(#unorderedIconMask)" cx="2" cy="2" r="1.5" />
        <circle mask="url(#unorderedIconMask)" cx="2" cy="7" r="1.5" />
        <circle mask="url(#unorderedIconMask)" cx="2" cy="12" r="1.5" />
      </g>
    </g>
  </svg>
);

export default UnorderedListIcon;
