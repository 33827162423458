import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CSSProperties } from 'styled-components';
import { FaFileDownload } from 'react-icons/fa';

import Cropper from 'react-easy-crop';
import { useDispatch } from 'react-redux';
import { Slider } from '@material-ui/core';
import IconTrash from '../../../images/icon/iconTrash';
import { uploadImage } from '../../../services/serviceFile';
import ButtonIcon from '../../buttons/buttonIcon/ButtonIcon';
import getCroppedImg from './getCroppedImg';

import {
  Container,
  InputFileField,
  BoxImage,
  Image,
  TextZoom,
  DownloadText,
  DownloadContainer,
} from './styles';
import { Title, SubTitle, ContainerInput, BoxInput, InfoLocalFile } from '../styles';
import IconAttach from '../../../images/icon/iconAttach';
import ModalConfirm from '../../modal/modalConfirm/ModalConfirm';
import PdfViewer from '../../pdf/PdfViewer';
import { setSnackbar } from '../../../../store/reducers/general/generalActions';
import Checkbox from '../checkbox/Checkbox';
import { BoxFlex } from '../../../../features/contestReport/styles';
import { MediaType } from '../../../modals/trail/bodies';
import { getMediaTypeFromFile, isDownloadable, isImage, isPdf } from '../../../functions/mediaType';

const INITIAL_MESSAGE_NOT_FILE = 'Nenhum arquivo selecionado.';

type CropValues = {
  x: number;
  y: number;
};

type CroppedAreaValues = {
  x: number;
  y: number;
  width: number;
  height: number;
};

// Requires `dimensions` if `isCrop` is true
type PInputFile =
  | {
      isCrop: true;
      dimensions: { width: number; height: number };
      urlImage: string;
      mediaType?: MediaType;
      setUrlImage: (x: string, y?: MediaType) => void;
      setAllowDownload?: (x: boolean) => void;
      allowDownload?: boolean;
      title?: string;
      subTitle?: string;
      style?: CSSProperties;
      showImage?: boolean;
      duplicated?: boolean;
      isSetup?: boolean;
      token?: string;
    }
  | {
      isCrop: false | undefined;
      dimensions?: { width: number; height: number };
      urlImage: string;
      mediaType?: MediaType;
      setUrlImage: (x: string, y?: MediaType) => void;
      setAllowDownload?: (x: boolean) => void;
      allowDownload?: boolean;
      title: string;
      subTitle?: string;
      style?: CSSProperties;
      showImage?: boolean;
      duplicated?: boolean;
      isSetup?: boolean;
      token?: string;
    };

const InputFile = ({
  title,
  dimensions,
  subTitle,
  style,
  urlImage,
  mediaType = 'image',
  setUrlImage,
  setAllowDownload,
  allowDownload,
  isCrop,
  showImage,
  duplicated,
  isSetup,
  token,
}: PInputFile) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingCrop, setLoadingCrop] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [valueInput, setValueInput] = useState('');
  const [urlImageCrop, setUrlImageCrop] = useState('');
  const [crop, setCrop] = useState<CropValues>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CroppedAreaValues>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setUrlImageCrop(urlImage);
  }, [urlImage]);

  const handleCropImage = useCallback(async () => {
    setLoadingCrop(true);
    const croppedImage = await getCroppedImg(urlImageCrop, croppedAreaPixels, dimensions);
    setUrlImage('');
    setUrlImageCrop('');
    const formData = new FormData();
    formData.append('image', croppedImage);
    if (!duplicated) {
      formData.append('origin', urlImageCrop);
    }
    if (isSetup && token) {
      formData.append('token', token);
    }
    setLoading(true);
    const returnUploadImage = await uploadImage(formData, isSetup);
    setLoading(false);
    setZoom(1);

    setUrlImageCrop(returnUploadImage.locationFile);
    setUrlImage(returnUploadImage.locationFile);
    setLoadingCrop(false);
    setOpenModal(false);
  }, [croppedAreaPixels]);

  const onCropComplete = (
    croppedArea: CroppedAreaValues,
    newCroppedAreaPixels: CroppedAreaValues,
  ) => {
    setCroppedAreaPixels(newCroppedAreaPixels);
  };

  const onCropChange = (newCrop: CropValues) => {
    setCrop(newCrop);
  };

  const onZoomChange = (newZoom: number) => {
    setZoom(newZoom);
  };

  const handleDeleteImage = () => {
    setValueInput('');
    setUrlImage('');
    setUrlImageCrop('');
  };

  const handleCancelCropImage = () => {
    handleDeleteImage();
    setOpenModal(false);
  };

  const buildPayload = (file: File) => {
    const formData = new FormData();
    formData.append('image', file);
    if (!duplicated) formData.append('origin', urlImage);
    if (isSetup && token) formData.append('token', token);

    return formData;
  };

  const submitData = async (file: File, fakePath: string) => {
    const payload = buildPayload(file);

    setUrlImage('');
    setLoading(true);
    setValueInput(fakePath);
    const returnUploadImage = await uploadImage(payload, isSetup);
    setLoading(false);

    return returnUploadImage.locationFile;
  };

  const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files, value: fakePath } = event.target;

    if (files?.length) {
      const file = files[0];
      const mediaType = getMediaTypeFromFile(file);

      if (isImage(mediaType) && file.size > 1000000) {
        setValueInput('');
        setUrlImage('');
        dispatch(setSnackbar('Imagem excede tamanho máximo de 1MB.'));
        return;
      }

      const fileUrl = await submitData(file, fakePath);

      if (isCrop) {
        setUrlImageCrop(fileUrl);
        setOpenModal(true);
      } else {
        setUrlImage(fileUrl, mediaType);
      }
    }
  };

  const renderModal = () => {
    if (!isCrop || !dimensions) {
      return null;
    }
    return (
      <>
        <BoxImage
          style={{
            height: 400,
          }}
        >
          <Cropper
            image={urlImageCrop === 'none' ? undefined : urlImageCrop}
            crop={crop}
            zoom={zoom}
            aspect={dimensions.width / dimensions.height}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
        </BoxImage>
        <BoxFlex style={{ marginTop: '10px' }}>
          <TextZoom>100%</TextZoom>
          <Slider
            value={zoom}
            min={1}
            max={4}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => (typeof zoom === 'number' ? onZoomChange(zoom) : null)}
            style={{
              padding: '20px 0px',
            }}
            valueLabelDisplay="auto"
            scale={e => Math.round(100 * e)}
          />
          <TextZoom>400%</TextZoom>
        </BoxFlex>
      </>
    );
  };

  return (
    <Container style={style}>
      <ModalConfirm
        open={openModal}
        onClose={handleCancelCropImage}
        title="Editar imagem"
        message={renderModal()}
        onClickPrimary={handleCropImage}
        onClickSecondary={handleCancelCropImage}
        loadingPrimary={loadingCrop}
        textPrimary="CONCLUIR"
        textSecondary="CANCELAR"
      />
      <Title>{title}</Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      <ContainerInput>
        <BoxInput>
          <InfoLocalFile>{valueInput || INITIAL_MESSAGE_NOT_FILE}</InfoLocalFile>
          <IconAttach />
          <InputFileField type="file" onChange={handleChangeFile} value={valueInput} />
        </BoxInput>
        <ButtonIcon onClick={handleDeleteImage}>
          <IconTrash />
        </ButtonIcon>
      </ContainerInput>
      {showImage && (
        <>
          <BoxImage style={{ position: style?.position }}>
            {urlImage && !isDownloadable(mediaType) && (
              <>
                {isPdf(mediaType) ? (
                  <PdfViewer height={150} urlImage={urlImage} />
                ) : (
                  <Image src={`${urlImage}?dummy=${Math.random() * 10000}`} alt="image" />
                )}
              </>
            )}

            {urlImage && isDownloadable(mediaType) && (
              <DownloadContainer>
                <FaFileDownload size={42} />
                <DownloadText>Arquivo para download</DownloadText>
              </DownloadContainer>
            )}
            {loading && <CircularProgress size={48} color="primary" />}
          </BoxImage>
          {setAllowDownload && showImage && urlImage && isPdf(mediaType) && (
            <Checkbox
              idCheckbox={urlImage}
              text="Permitir download"
              checked={allowDownload}
              onChange={e => setAllowDownload?.(e.target.checked)}
            />
          )}
        </>
      )}
    </Container>
  );
};

InputFile.defaultProps = {
  subTitle: '',
  style: {},
  isCrop: false,
  showImage: true,
  duplicated: false,
  isSetup: false,
  token: '',
};

export default InputFile;
