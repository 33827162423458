import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';

import Modal from '../Modal';
import { insertPercentagemInNumber } from '../../../functions/number';
import IconThumbDown from '../../../images/icon/iconThumbDown';
import { Question } from '../../../modals/question/question';
import { DisplayFlexColumnAlignCenter } from '../../styled/general';
import { colors } from '../../../functions/colors';
import Button from '../../buttons/Button';
import {
  TitleAlternatives,
  ContainerAlternatives,
  Alternatives,
  TextAlternatives,
  FooterAlternatives,
  Title,
  ContainerPercentage,
  ExtraText,
  Text,
  TextPercentage,
} from './styles';
import { useQuestion } from '../../../../store/reducers/question';

type PMoreInfoQuestionAnswer = {
  openMoreInfoQuestionAnswer: boolean;
  idQuestion?: string;
  idContest?: string;
  idStage?: string;
  onClose: () => void;
  title: string;
};

const calcPercentageWrongAnswer = (question: Question): number => {
  let percentage = 0;
  question.alternatives.forEach(({ trueQuestion, percentageAnswer }) => {
    if (!trueQuestion && percentageAnswer) percentage += Number(percentageAnswer);
  });

  return percentage;
};

const MoreInfoQuestionAnswer = ({
  openMoreInfoQuestionAnswer,
  onClose,
  idQuestion,
  idContest,
  idStage,
  title,
}: PMoreInfoQuestionAnswer) => {
  const { questionInfoAnswer, getInfoAnswerQuestion } = useQuestion();
  let amountQuestion;

  useEffect(() => {
    if (idQuestion) {
      getInfoAnswerQuestion(idQuestion, idContest, idStage);
    }
  }, [idQuestion]);

  if (questionInfoAnswer) {
    amountQuestion = questionInfoAnswer.alternatives
      .map(alterantive => alterantive.amountAnswer || 0)
      .reduce((a, b) => a + b, 0);
  }

  return (
    <Modal open={openMoreInfoQuestionAnswer} onClose={onClose}>
      <Title>{title}</Title>
      <DisplayFlexColumnAlignCenter style={{ justifyContent: 'center' }}>
        {questionInfoAnswer ? (
          <>
            <ContainerPercentage style={{ width: 200 }}>
              <IconThumbDown />
              <TextPercentage>
                {insertPercentagemInNumber(calcPercentageWrongAnswer(questionInfoAnswer))}
              </TextPercentage>
              <ExtraText>das pessoas erraram</ExtraText>
            </ContainerPercentage>
            <Text>{questionInfoAnswer.text}</Text>
            <ContainerAlternatives>
              <TitleAlternatives>
                <ExtraText>RESPOSTAS</ExtraText>
                <ExtraText>PORCENTAGEM - QUANTIDADE</ExtraText>
              </TitleAlternatives>
              {questionInfoAnswer.alternatives.map(alternative => {
                return (
                  <Alternatives>
                    <TextAlternatives trueQuestion={alternative.trueQuestion}>
                      {alternative.text}
                    </TextAlternatives>
                    <TextAlternatives
                      style={{ marginLeft: 16 }}
                      trueQuestion={alternative.trueQuestion}
                    >
                      {`${insertPercentagemInNumber(
                        alternative.percentageAnswer ? alternative.percentageAnswer : 0,
                      )} - ${alternative.amountAnswer} resposta(s)`}
                    </TextAlternatives>
                  </Alternatives>
                );
              })}
              <FooterAlternatives>
                <ExtraText>{`Total: 100% - ${amountQuestion} resposta(s)`}</ExtraText>
              </FooterAlternatives>
            </ContainerAlternatives>
            <Button style={{ width: 150 }} typeButton="primary-white" onClick={onClose}>
              FECHAR
            </Button>
          </>
        ) : (
          <ReactLoading type="bubbles" color={colors.primary} width={44} height={40} />
        )}
      </DisplayFlexColumnAlignCenter>
    </Modal>
  );
};

MoreInfoQuestionAnswer.defaultProps = {
  idQuestion: undefined,
  idContest: undefined,
  idStage: undefined,
};

export default MoreInfoQuestionAnswer;
