import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import * as journeysService from '../../../shared/services/journeys';

type PDeleteJourney = {
  open: boolean;
  handleCloseModal: () => void;
  refreshList: () => void;
  id: string | undefined;
};

const DeleteJourney = ({ id, open, handleCloseModal, refreshList }: PDeleteJourney) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  if (!id) return null;

  const handleDelete = async () => {
    setLoading(true);
    await journeysService.destroy(id, dispatch);
    refreshList();
    setLoading(false);
    handleCloseModal();
  };

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title="Excluir jornada"
      message="Tem certeza que deseja excluir a jornada?"
      onClose={handleCloseModal}
      open={open}
      onClickPrimary={handleDelete}
      onClickSecondary={handleCloseModal}
    />
  );
};

export default DeleteJourney;
