import {
  BodyUserLogin,
  BodyCreateUser,
  BodyForgotPassword,
  BodyChangePassword,
} from '../modals/user/bodies';
import {
  ConnectApiPost,
  ConnectApiGet,
  urls,
  ConnectApiPatch,
  ConnectApiPUT,
  ConnectApiDelete,
} from '../functions/connection';
import { Company, UserPagination } from '../modals/user/user';
import {
  generatePaginateQueryString,
  generateQueryString,
} from '../functions/connection/queryString';

export const login = async (body: BodyUserLogin) => {
  const url = generateQueryString(urls.URL_AUTH, { isDashboard: true });
  const respostaServico = await ConnectApiPost(url, body);
  return respostaServico.data;
};

export const getUserData = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_AUTH);
  return respostaServico.data;
};

export const getAllUsers = async (pagination?: UserPagination) => {
  const respostaServico = await ConnectApiGet(
    generatePaginateQueryString(urls.URL_USER, pagination),
  );
  return respostaServico.data;
};

export const getUser = async (idUser: string) => {
  const url = urls.URL_USER_ID.replace('{idUser}', `${idUser}`);
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};

export const insertUser = async (body: BodyCreateUser) => {
  const respostaServico = await ConnectApiPost(urls.URL_USER, body);
  return respostaServico.data;
};

export const importUserList = async (body: FormData) => {
  const respostaServico = await ConnectApiPost(urls.URL_USER_LIST, body);
  return respostaServico.data;
};

export const insertAdmin = async (body: BodyCreateUser) => {
  const respostaServico = await ConnectApiPost(urls.URL_USER_INSERT_ADMIN_COMPANY, body);
  return respostaServico.data;
};

export const insertAdminGlobal = async (body: BodyCreateUser, company: Company) => {
  const url = urls.URL_USER_INSERT_ADMIN_COMPANY_GLOBAL.replace(
    '{idCompany}',
    `${company.idCompany}`,
  );
  const respostaServico = await ConnectApiPost(url, body);
  return respostaServico.data;
};

export const editUser = async (body: BodyCreateUser, idUser: string) => {
  const url = urls.URL_USER_ID.replace('{idUser}', `${idUser}`);
  const respostaServico = await ConnectApiPUT(url, body);
  return respostaServico.data;
};

export const requestPasswordEmail = async (body: BodyForgotPassword) => {
  const respostaServico = await ConnectApiPost(urls.URL_USER_REQUEST_EMAIL, body);
  return respostaServico.data;
};

export const redefinePassword = async (body: BodyChangePassword) => {
  const respostaServico = await ConnectApiPost(urls.URL_USER_REDEFINE_PASSWORD, body);
  return respostaServico.data;
};

export const deleteUser = async (idUser: string) => {
  const url = urls.URL_USER_ID.replace('{idUser}', `${idUser}`);
  const respostaServico = await ConnectApiDelete(url);
  return respostaServico.data;
};

export const blockUser = async (idUser: string) => {
  const url = urls.URL_USER_BLOCK.replace('{idUser}', `${idUser}`);
  const respostaServico = await ConnectApiPatch(url, {});
  return respostaServico.data;
};

export const unlockUser = async (idUser: string) => {
  const url = urls.URL_USER_UNLOCK.replace('{idUser}', `${idUser}`);
  const respostaServico = await ConnectApiPatch(url, {});
  return respostaServico.data;
};

export const getAllLevels = async () => {
  const respostaServico = await ConnectApiGet(urls.URL_LEVEL);
  return respostaServico.data;
};

export const editPasswordUser = async (idUser: string, password: string) => {
  const url = urls.URL_USER_CHANGE_PASSWORD.replace('{idUser}', `${idUser}`);
  const respostaServico = await ConnectApiPatch(url, { password });
  return respostaServico.data;
};

export const getUserReport = async (idUser: string, startDate: string, endDate: string) => {
  const url = generateQueryString(urls.URL_USER_REPORT.replace('{idUser}', `${idUser}`), {
    startDate,
    endDate,
  });
  const respostaServico = await ConnectApiGet(url);
  return respostaServico.data;
};
