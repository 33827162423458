import React, { useState } from 'react';

import Table from '../../../shared/components/table/Table';
import { UserReport } from '../../../shared/modals/user/user';
import { insertPercentagemInNumber } from '../../../shared/functions/number';

type PStagesUserReport = {
  userReport: UserReport;
  isPrint: boolean;
};

const StagesUserReport = ({ userReport, isPrint }: PStagesUserReport) => {
  const [showTable, setShowTable] = useState(false);

  setTimeout(() => {
    setShowTable(true);
  }, 100);

  if (!showTable) {
    return null;
  }

  const renderRowsTable = () =>
    userReport.phases.map(stage => ({
      columns: [
        stage.name,
        stage.percentage ? insertPercentagemInNumber(stage.percentage, 2) : '',
        stage.engagement ? insertPercentagemInNumber(stage.engagement, 2) : '',
        stage.status,
      ],
    }));

  return (
    <Table
      nameReferenceReduxTable="table_stages_user_report"
      listRowsPerPage={isPrint ? [1000] : [10, 25, 50, 100]}
      titles={[
        {
          name: 'Trilha',
          openClick: true,
          type: 'string',
          widthTd: '40%',
        },
        {
          name: '% de acerto',
          openClick: true,
          type: 'percentage',
          widthTd: '20%',
        },
        {
          name: '% de conclusão (engajamento)',
          openClick: true,
          type: 'percentage',
          widthTd: '20%',
        },
        {
          name: 'Status',
          openClick: true,
          type: 'string',
          widthTd: '20%',
        },
      ]}
      rows={renderRowsTable()}
    />
  );
};

export default StagesUserReport;
