import styled from 'styled-components';
import { colors } from '../../../shared/functions/colors';
import { fonts } from '../../../shared/functions/fonts';

export const Container = styled.div``;

export const Description = styled.p`
  color: ${colors.black};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

export const DisplayFlex = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
`;
