import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import BoxVerticalImage from '../../shared/components/box/boxVerticalImage/BoxVerticalImage';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import { BoxWhite } from '../../shared/components/styled/box';
import {
  ContainerMain,
  DisplayFlexAlignCenter,
  HeaderMain,
  TextHeaderMain,
} from '../../shared/components/styled/general';
import { DefaultText, TitleSemiBold, TitleContest } from '../../shared/components/styled/text';
import { MenuUrl } from '../../shared/enums/menuUrl';
import { colors } from '../../shared/functions/colors';
import { useLibrary } from '../../store/reducers/library';
import QuestionsLibrary from './questionsLibrary/QuestionsLibrary';
import { Image, ContainerBoxLibraries } from './styles';

type ParamTypes = {
  idLibrary?: string;
};

const Library = () => {
  const { idLibrary } = useParams<ParamTypes>();
  const history = useHistory();
  const { getLibraries, libraries } = useLibrary();

  useEffect(() => {
    getLibraries();
  }, []);

  if (idLibrary) {
    return <QuestionsLibrary idLibrary={idLibrary} />;
  }

  return (
    <ContainerMain>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Biblioteca',
          },
        ]}
      />
      <HeaderMain>
        <TextHeaderMain>
          Preparamos alguns materiais para te auxiliar, confira abaixo.
        </TextHeaderMain>
      </HeaderMain>
      <BoxWhite>
        <DisplayFlexAlignCenter style={{ padding: '40px 80px' }}>
          <Image src="/img/portfolio_update.png" alt="portfolio" />
          <div>
            <TitleSemiBold color={colors.pinkRegular}>
              Conheça a nossa Biblioteca de Conteúdos!
            </TitleSemiBold>
            <DefaultText>
              <br />
              <br />
              Aqui você encontra alguns conteúdos pré-preparados para você. Temos torneios, trilhas,
              categorias, perguntas, etc, modelos prontos para uso.
              <br />
              <br />
              Você pode utilizar essa biblioteca e seus conteúdos para iniciar os jogos em sua
              empresa. Aproveite esse benefício e comece já!
            </DefaultText>
          </div>
        </DisplayFlexAlignCenter>
        <TitleContest>PERGUNTAS</TitleContest>
        <TextHeaderMain style={{ margin: 24 }}>
          Banco de perguntas separadas por temas e/ou categorias e/ou áreas.
        </TextHeaderMain>
        <ContainerBoxLibraries style={{ flexFlow: 'wrap' }}>
          {libraries.map(library => {
            return (
              <BoxVerticalImage
                style={{ margin: '0px 0px 24px 24px' }}
                srcImage={library.image ? library.image : '/img/invest.png'}
                titleBox={library.name}
                textBox={`Qtde perguntas: ${library.amountQuestions}`}
                onClick={() => history.push(`${MenuUrl.library}/${library.idLibrary}`)}
              />
            );
          })}
        </ContainerBoxLibraries>
      </BoxWhite>
    </ContainerMain>
  );
};

export default Library;
