import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import * as selfSignUpCampaignsService from '../../../shared/services/selfSignUpCampaigns';

type PDeleteSelfSignUpCampaign = {
  open: boolean;
  handleCloseModal: () => void;
  refreshList: () => void;
  id: string | undefined;
};

const DeleteSelfSignUpCampaign = ({
  id,
  open,
  handleCloseModal,
  refreshList,
}: PDeleteSelfSignUpCampaign) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  if (!id) return null;

  const handleDelete = async () => {
    setLoading(true);
    await selfSignUpCampaignsService.destroy(id, dispatch);
    refreshList();
    setLoading(false);
    handleCloseModal();
  };

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title="Excluir campanha de cadastro"
      message="Tem certeza que deseja excluir a campanha de cadastro?"
      onClose={handleCloseModal}
      open={open}
      onClickPrimary={handleDelete}
      onClickSecondary={handleCloseModal}
    />
  );
};

export default DeleteSelfSignUpCampaign;
