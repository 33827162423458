import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import * as journeysService from '../../../shared/services/journeys';

type PDuplicateJourney = {
  open: boolean;
  handleCloseModal: () => void;
  refreshList: () => void;
  id: string | undefined;
};

const DuplicateJourney = ({ id, open, handleCloseModal, refreshList }: PDuplicateJourney) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  if (!id) return null;

  const handleDuplicate = async () => {
    setLoading(true);
    await journeysService.duplicate(id, dispatch);
    refreshList();
    setLoading(false);
    handleCloseModal();
  };

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title="Duplicar jornada"
      message="Tem certeza que deseja duplicar a jornada?"
      onClose={handleCloseModal}
      open={open}
      onClickPrimary={handleDuplicate}
      onClickSecondary={handleCloseModal}
    />
  );
};

export default DuplicateJourney;
