/* eslint-disable no-underscore-dangle */
import React, { useRef } from 'react';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryContainer } from 'victory';
import { colors } from '../../../functions/colors';
import { insertPercentagemInNumber } from '../../../functions/number';
import { Container, Title } from './styles';

export type DataGraphicBarsHorizontal = {
  id: number;
  value: number;
  tickText: string;
};

type PGraphicBarsHorizontal = {
  data: DataGraphicBarsHorizontal[];
  graphicColor?: string;
  title?: string;
};

const GraphicBarsHorizontal = ({ data, graphicColor, title }: PGraphicBarsHorizontal) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const widthChart = containerRef.current ? containerRef.current.clientWidth : 300;
  const currentData = data.filter((x, index) => index < 10);

  return (
    <Container ref={containerRef}>
      {title && <Title color={graphicColor || colors.pinkDark}>{title}</Title>}
      <VictoryChart
        width={widthChart}
        domainPadding={50}
        domain={{ y: [0, 0.85] }}
        animate={{ duration: 500 }}
        containerComponent={<VictoryContainer responsive={false} style={{ width: '100%' }} />}
      >
        <VictoryAxis
          style={{
            tickLabels: { fill: colors.grey80, angle: -25, fontSize: 10 },
            axis: { stroke: colors.grey80, fontSize: 10 },
          }}
          tickValues={currentData.map((_, index) => index + 1)}
          tickFormat={currentData.map(item => item.tickText)}
        />
        <VictoryAxis
          style={{ tickLabels: { fill: colors.grey80 }, axis: { stroke: colors.black } }}
          dependentAxis
          tickFormat={(x: number) => `${insertPercentagemInNumber(x)}`}
        />
        <VictoryBar
          horizontal
          cornerRadius={{ topLeft: 4, topRight: 4 }}
          labels={({ datum }) => `${insertPercentagemInNumber(datum._y)}`}
          barRatio={0.7}
          style={{ data: { fill: graphicColor }, labels: { fill: graphicColor } }}
          data={currentData}
          x="id"
          y="value"
        />
      </VictoryChart>
    </Container>
  );
};

GraphicBarsHorizontal.defaultProps = {
  graphicColor: colors.purpleLight,
  title: undefined,
};

export default GraphicBarsHorizontal;
