import { Dispatch } from 'redux';

import * as contestActions from './contestActions';
import * as serviceContest from '../../../shared/services/serviceContest';
import { BodyCreateContest } from '../../../shared/modals/contest/bodies';
import {
  ERROR_MAINTENANCE,
  CONTEST_DELETE,
  CONTEST_DUPLICATE,
  CONTEST_EDIT,
  CONTEST_INSERT,
  CONTEST_PERMISSION,
} from '../../../shared/constants/messages';
import { doLogout } from '../../../shared/functions/connection/auth';
import { setSnackbar } from '../general/generalActions';
import { SUCCESS_SNACKBAR } from '../../../shared/components/snackbar/Snackbar';

export const getContests = (
  startDate?: string,
  endDate?: string,
  idAreas?: string[],
  idCategories?: string[],
  enabled?: boolean,
) => async (dispatch: Dispatch) => {
  try {
    const contests = await serviceContest.getContests(
      startDate,
      endDate,
      idAreas,
      idCategories,
      enabled,
    );
    dispatch(contestActions.setContests(contests));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const insertContest = (bodyCreateContest: BodyCreateContest) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceContest.insertContest(bodyCreateContest);
    await getContests()(dispatch);
    dispatch(setSnackbar(CONTEST_INSERT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const editContest = (bodyCreateContest: BodyCreateContest, idContest: string) => async (
  dispatch: Dispatch,
) => {
  try {
    await serviceContest.editContest(bodyCreateContest, idContest);
    await getContests()(dispatch);
    dispatch(setSnackbar(CONTEST_EDIT, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const getContest = (idContest: string) => async (dispatch: Dispatch) => {
  try {
    const contest = await serviceContest.getContest(idContest);
    dispatch(contestActions.setContest(contest));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const deleteContest = (idContest: string) => async (dispatch: Dispatch) => {
  try {
    await serviceContest.deleteContest(idContest);
    await getContests()(dispatch);
    dispatch(setSnackbar(CONTEST_DELETE, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const duplicateContest = (idContest: string) => async (dispatch: Dispatch) => {
  try {
    await serviceContest.duplicateContest(idContest);
    await getContests()(dispatch);
    dispatch(setSnackbar(CONTEST_DUPLICATE, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const changeContestEnable = (idContest: string) => async (dispatch: Dispatch) => {
  try {
    await serviceContest.changeContestEnable(idContest);
    await getContests()(dispatch);
    dispatch(setSnackbar(CONTEST_PERMISSION, SUCCESS_SNACKBAR));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const getContestReport = (idContest: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(contestActions.setContestReport());
    const contest = await serviceContest.getContestReport(idContest);
    dispatch(contestActions.setContestReport(contest));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const downloadOnePageCsv = (idContest: string) => async (dispatch: Dispatch) => {
  try {
    await serviceContest.downloadOnePageCsv(idContest);
  } catch (error) {
    dispatch(contestActions.setContestReport());
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
    }
  }
};

export const importContestList = (body: FormData) => async (dispatch: Dispatch) => {
  try {
    const contests = await serviceContest.importContestList(body);
    dispatch(contestActions.setContestErros(contests));
    await getContests()(dispatch);
    return contests;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
    throw new Error('');
  }
};
