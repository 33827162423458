import { ExtraUrl, MenuUrl } from '../../enums/menuUrl';
import { OnBoarding } from './BoxOnBoarding';

export const listOnBoarding: OnBoarding[] = [
  {
    id: 1,
    arrowDirection: 'left',
    title: 'Tutorial Guiado',
    text:
      'Bem-vindo(a) ao Tutorial Guiado da TuTo! \n\nNo menu Início, você vai encontrar os principais indicadores da sua empresa e de seus membros. Após os torneios e trilhas serem iniciados, esses indicadores irão aparecer na tela de início e você poderá acompanhar a evolução de cada um.',
    left: '220px',
    top: '116px',
    url: `${MenuUrl.question}`,
  },
  {
    id: 2,
    arrowDirection: 'left',
    title: 'Tutorial Guiado',
    text:
      'Aqui no menu de Membros você vai encontrar a lista de membros já cadastrados e poderá realizar ações de permissões, edição ou exclusão. \n\nVocê poderá também adicionar novos membros aqui, basta clicar no botão Novo Membro.',
    left: '220px',
    top: '168px',
    url: `${MenuUrl.user}`,
  },
  {
    id: 3,
    arrowDirection: 'left',
    title: 'Tutorial Guiado',
    text:
      'Aqui no menu de Perguntas você vai encontrar todas as perguntas já cadastradas e poderá realizar ações de permissões, edição ou exclusão. E poderá também adicionar novas perguntas aqui, basta clicar no botão Nova Pergunta. \n\nPara cada Pergunta será selecionada uma Categoria a qual ela pertence.',
    left: '220px',
    top: '338px',
    url: `${MenuUrl.question}`,
  },
  {
    id: 4,
    arrowDirection: 'left',
    title: 'Tutorial Guiado',
    text:
      'Aqui no sub-menu de Categorias você poderá realizar ações de edição ou exclusão de categorias. E poderá também adicionar novas categorias, clicando no botão Nova Categoria.',
    left: '220px',
    top: '398px',
    url: `${MenuUrl.category}`,
  },
  {
    id: 5,
    arrowDirection: 'left',
    title: 'Tutorial Guiado',
    text:
      'Aqui no menu de Torneios você vai encontrar todos os torneios já cadastrados, informações de data, número de participantes e poderá realizar ações de edição ou exclusão. \n\nTambém poderá adicionar novos torneios aqui, basta clicar no botão Novo Torneio.',
    left: '220px',
    top: '228px',
    url: `${MenuUrl.contest}`,
  },
  {
    id: 6,
    arrowDirection: 'left',
    title: 'Tutorial Guiado',
    text:
      'Aqui no menu de Trilhas você vai encontrar todas as trilhas já cadastradas, informações de posição (ranking), data, número de fases e poderá realizar ações de permissão, edição ou exclusão.\n\nTambém poderá adicionar novas trilhas aqui, basta clicar no botão Nova Trilha.',
    left: '220px',
    top: '398px',
    url: `${MenuUrl.trail}`,
  },
  {
    id: 7,
    arrowDirection: 'right',
    title: 'Tutorial Guiado',
    text:
      'Dentro de cada Trilha você vai encontrar as Fases que fazem parte dessa trilha. Para criar ou editar uma fase, basta clicar no ícone de edição da fase que deseja e assim, você será direcionado(a) para a tela de Fases.\n\nNa tela de Fases você vai encontrar informações de nível da fase, número de perguntas, e ações de edição e exclusão. Também poderá adicionar novas fases aqui, basta clicar no botão Nova Fase.',
    left: '-520px',
    top: '-10px',
    isPadding: true,
    url: `${MenuUrl.trail}`,
  },
  {
    id: 8,
    arrowDirection: 'right',
    title: 'Tutorial Guiado',
    text:
      'Aqui você encontra as informações do seu Pefil (nome e função na empresa) e também um botão de Sair, para quando desejar sair do sistema ou realizar o login com um novo usuário.',
    left: '-520px',
    top: '0px',
    isPadding: true,
    url: `${MenuUrl.trail}`,
  },
  {
    id: 9,
    arrowDirection: 'topLeft',
    title: 'Tutorial de ajuda',
    text: '1. Para começar clique no botão "NOVO MEMBRO" e preencha as informações necessárias.',
    left: '-48px',
    top: '104px',
    isPadding: true,
    url: `${MenuUrl.user}`,
    isClickNext: true,
  },
  {
    id: 10,
    arrowDirection: 'topLeft',
    title: 'Tutorial de ajuda',
    text: '2. Insira o nome completo do membro;',
    left: '-48px',
    top: '140px',
    isPadding: true,
    url: `${MenuUrl.user}/${ExtraUrl.new}`,
  },
  {
    id: 11,
    arrowDirection: 'topLeft',
    title: 'Tutorial de ajuda',
    text: '3. Agora digite um e-mail válido deste membro;',
    left: '-48px',
    top: '140px',
    isPadding: true,
    url: `${MenuUrl.user}/${ExtraUrl.new}`,
  },
  {
    id: 12,
    arrowDirection: 'bottomLeft',
    title: 'Tutorial de ajuda',
    text: '4. Agora selecione quais as áreas esse membro terá acesso/relação;',
    left: '0px',
    top: '-240px',
    isPadding: true,
    url: `${MenuUrl.user}/${ExtraUrl.new}`,
  },
  {
    id: 13,
    arrowDirection: 'bottomLeft',
    title: 'Tutorial de ajuda',
    text: '5. Selecione essa caixa apenas se este membro for um gerenciador da plataforma;',
    left: '0px',
    top: '-240px',
    isPadding: true,
    url: `${MenuUrl.user}/${ExtraUrl.new}`,
  },
  {
    id: 14,
    arrowDirection: 'bottomLeft',
    title: 'Tutorial de ajuda',
    text:
      '6. Agora com todas as informações preenchidas corretamente, clique em "SALVAR" para finalizar;',
    left: '-80px',
    top: '-240px',
    isPadding: true,
    url: `${MenuUrl.user}/${ExtraUrl.new}`,
  },
  {
    id: 15,
    arrowDirection: 'left',
    title: 'Tutorial Guiado',
    text:
      'Aqui você encontra perguntas criadas por nosso time de especialistas. Basta escolher as perguntas que deseja e importar para o banco de perguntas de sua empresa.',
    left: '220px',
    top: '456px',
    url: `${MenuUrl.library}`,
  },
];
