import { Reducer } from 'redux';

import { GeneralState, GeneralTypes } from './generalTypes';

const INITIAL_STATE: GeneralState = {
  currentOnBoarding: undefined,
  currentIndexOnBoarding: 0,
  loadingBlockScreen: false,
  tableInfo: [],
  snackbar: {
    type: '',
    show: false,
    message: '',
  },
  currentSelectedTab: 0,
  filterInput: '',
  filterSelect: [],
};

const reducer: Reducer<GeneralState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GeneralTypes.SET_CURRENT_ON_BOARDING:
      return {
        ...state,
        currentOnBoarding: action.payload,
      };
    case GeneralTypes.SET_TABLE_INFO:
      return {
        ...state,
        tableInfo: action.payload,
      };
    case GeneralTypes.SET_LOADING_BLOCK_SCREEN:
      return {
        ...state,
        loadingBlockScreen: action.payload,
      };
    case GeneralTypes.SET_CURRENT_INDEX_ON_BOARDING:
      return {
        ...state,
        currentIndexOnBoarding: action.payload,
      };
    case GeneralTypes.SET_CURRENT_SELECTED_TAB:
      return {
        ...state,
        currentSelectedTab: action.payload,
      };
    case GeneralTypes.SET_FILTER_INPUT:
      return {
        ...state,
        filterInput: action.payload,
      };
    case GeneralTypes.SET_FILTER_SELECT:
      return {
        ...state,
        filterSelect: action.payload,
      };
    case GeneralTypes.SET_SNACKBAR:
      return {
        ...state,
        snackbar: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
