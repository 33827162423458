import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, BoxInfo, BoxPadding, BoxFlex, BoxBottom } from '../../mission/create/styles';
import BreadCrumbs from '../../../shared/components/breadCrumbs/BreadCrumbs';
import Input from '../../../shared/components/input/Input';
import Button from '../../../shared/components/buttons/Button';
import {
  get as getChallenge,
  create as createChallenge,
  update as updateChallenge,
} from '../../../shared/services/challenges';
import { getAll as getMechanics } from '../../../shared/services/mechanics';
import { TitleContest } from '../../../shared/components/styled/text';
import Select, { POptions } from '../../../shared/components/select/Select';
import Checkbox from '../../../shared/components/input/checkbox/Checkbox';
import RichTextEditor from '../../../shared/components/richEditor/RichTextEditor';
import Presentation, {
  BODY_START_PRESENTATION,
} from '../../../shared/components/presentation/Presentation';
import { Presentation as PresentationObj } from '../../../shared/modals/trail/trail';

// TODO: prefix all interfaces with "I"
// See: https://github.com/typescript-eslint/typescript-eslint/blob/v1.13.0/packages/eslint-plugin/docs/rules/interface-name-prefix.md
export type ChallengeInterface = {
  missionId: string | undefined;
  title: string;
  manuallyApproved: boolean;
  mechanicId: number | undefined;
  prize: number | undefined;
  entryLimit: number | undefined;
  instructions: string;
  duration: number | undefined;
  questionAttributes: { text: string };
};

export type ChallengeState = {
  id: string | undefined;
  missionId: string;
  title: string;
  manuallyApproved: boolean;
  mechanicId: number | undefined;
  prize: number | undefined;
  entryLimit: number | undefined;
  instructions: string;
  questionId: string | undefined;
  question: string;
  alternatives: { id: string; trueQuestion: boolean; text: string }[];
  duration: number | undefined;
  presentations: PresentationObj[];
};

const CreateEditChallenge = () => {
  const dispatch = useDispatch();
  const { missionId, challengeId } = useParams() as { missionId: string; challengeId: string };
  const history = useHistory();
  const [challenge, setChallenge] = useState<ChallengeState>({
    id: '',
    missionId,
    title: '',
    manuallyApproved: false,
    mechanicId: undefined,
    prize: undefined,
    entryLimit: undefined,
    instructions: '',
    questionId: '',
    question: '',
    alternatives: [],
    presentations: [BODY_START_PRESENTATION],
    duration: undefined,
  });
  const [mechanicOptions, setMechanicOptions] = useState<
    (POptions & { autoApproved: boolean })[]
  >();
  const [isLoading, setIsLoading] = useState(false);

  const fetchMechanics = async () => {
    const mechanicOptions = await getMechanics();
    const formattedMechanicOptions = mechanicOptions.map(mechanic => ({
      value: mechanic.id.toString(),
      viewValue: mechanic.name,
      autoApproved: mechanic.autoApproved,
    }));
    const defaultOption = mechanicOptions.length ? mechanicOptions[0].id : undefined;

    setMechanicOptions(formattedMechanicOptions);
    setChallenge(prevChallenge => ({ ...prevChallenge, mechanicId: defaultOption }));
  };

  const fetchChallenge = async () => {
    if (challengeId) {
      const challenge = await getChallenge(challengeId, dispatch);

      if (challenge) {
        const {
          id,
          title,
          manuallyApproved,
          prize,
          entryLimit,
          instructions,
          mission,
          mechanics,
          question,
          duration,
          presentations,
        } = challenge;

        setChallenge({
          id,
          title,
          manuallyApproved,
          prize,
          entryLimit,
          instructions,
          duration,
          presentations: presentations.length ? presentations : [BODY_START_PRESENTATION],
          missionId: mission.id,
          mechanicId: mechanics.id,
          questionId: question.id,
          question: question.text,
          alternatives: question.alternatives,
        });
      }
    }
  };

  useEffect(() => {
    fetchMechanics().then(fetchChallenge);
  }, []);

  const isAutoApprovedMechanicSelected = (mechanicId: string) => {
    if (!mechanicOptions) return false;

    const option = mechanicOptions.find(o => o.value === mechanicId);
    return option ? option.autoApproved : false;
  };

  const goToMissionsList = () => {
    history.push('/missions');
  };

  const goToChallengesList = () => {
    history.push(`/missions/${missionId}/challenges`);
  };

  const shouldUncheckManuallyApproved = (fieldName: string, fieldValue: string) => {
    return fieldName === 'mechanicId' && isAutoApprovedMechanicSelected(fieldValue);
  };

  const handleChangeValue = (target: EventTarget) => {
    const { type, name, value, checked } = target as HTMLInputElement;
    const state = ((type === 'checkbox'
      ? { [name]: checked }
      : { [name]: value }) as unknown) as Pick<ChallengeState, keyof ChallengeState>;

    if (shouldUncheckManuallyApproved(name, value)) {
      state.manuallyApproved = false;
    }

    setChallenge(prevChallenge => ({ ...prevChallenge, ...state }));
  };

  const handleRichTextChange = (content: string, field: string) => {
    const state = ({ [field]: content } as unknown) as Pick<ChallengeState, keyof ChallengeState>;
    setChallenge(prevChallenge => ({ ...prevChallenge, ...state }));
  };

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    const { id, questionId, question, presentations } = challenge;

    const body = {
      ...challenge,
      questionAttributes: { id: questionId, text: question },
      presentationsAttributes: presentations,
    };

    try {
      if (id) {
        await updateChallenge(body, id, dispatch);
      } else {
        await createChallenge(body, dispatch);
      }
      setIsLoading(false);
      goToChallengesList();
    } catch (_) {
      setIsLoading(false);
    }
  };

  const isSubmissionDisabled = () => {
    const { title, mechanicId, entryLimit, instructions, question } = challenge;

    if (title && mechanicId && entryLimit && instructions && question) return false;

    return true;
  };

  if (!mechanicOptions) return null;

  return (
    <Container onSubmit={handleSubmit}>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Missões',
            action: goToMissionsList,
          },
          {
            name: 'Desafios',
            action: goToChallengesList,
          },
          {
            name: 'Novo desafio',
          },
        ]}
      />
      <BoxInfo>
        <TitleContest>CONFIGURAÇÕES</TitleContest>
        <BoxPadding>
          <BoxFlex style={{ width: '100%' }}>
            <BoxFlex style={{ width: '60%' }}>
              <BoxFlex style={{ width: '100%', flexWrap: 'nowrap' }}>
                <Select
                  title="Tipo do desafio* (mecânica)"
                  nameSelect="mechanicId"
                  listOptions={mechanicOptions as POptions[]}
                  onChange={event => {
                    handleChangeValue(event.target);
                  }}
                  valueSelected={`${challenge.mechanicId}`}
                  style={{ flexGrow: 1, flexShrink: 1 }}
                />
                <Input
                  title="Valor do desafio (opcional)"
                  name="prize"
                  placeholder="0"
                  onChange={event => {
                    handleChangeValue(event.target);
                  }}
                  value={challenge?.prize}
                  style={{ flexGrow: 1, flexShrink: 1 }}
                />
                <Input
                  title="Nº de tentativas*"
                  name="entryLimit"
                  placeholder="000"
                  onChange={event => {
                    handleChangeValue(event.target);
                  }}
                  value={challenge?.entryLimit}
                  style={{ flexGrow: 1, flexShrink: 1 }}
                />
                <Input
                  title="Carga Horária"
                  name="duration"
                  placeholder="0"
                  onChange={event => {
                    handleChangeValue(event.target);
                  }}
                  value={challenge?.duration}
                  style={{ flexGrow: 1, flexShrink: 1 }}
                />
              </BoxFlex>
              <div style={{ width: '100%' }}>
                <RichTextEditor
                  label="Descrição e/ou regra*"
                  value={challenge?.instructions || ''}
                  maxLength={1000}
                  style={{ width: '100%' }}
                  onChange={(content: string) => handleRichTextChange(content, 'instructions')}
                  key="richTextChallenge"
                />
              </div>
            </BoxFlex>
            <BoxFlex style={{ width: '37%', height: 'fit-content' }}>
              <BoxFlex style={{ width: '100%' }}>
                <Input
                  title="Título do desafio*"
                  name="title"
                  placeholder="Digite"
                  onChange={event => {
                    handleChangeValue(event.target);
                  }}
                  value={challenge?.title}
                  style={{ width: '100%' }}
                />
              </BoxFlex>
              <div style={{ width: '100%' }}>
                <RichTextEditor
                  label="Pergunta*"
                  value={challenge?.question || ''}
                  maxLength={1000}
                  style={{ width: '100%', marginRight: '0' }}
                  onChange={(content: string) => handleRichTextChange(content, 'question')}
                  key="richTextChallengeQuestion"
                />
              </div>
              <BoxFlex>
                <Checkbox
                  idCheckbox="manuallyApproved"
                  name="manuallyApproved"
                  checked={challenge?.manuallyApproved}
                  onChange={event => {
                    handleChangeValue(event.target);
                  }}
                  text="Marque se o desafio precisar de aprovação do gestor"
                  disabled={isAutoApprovedMechanicSelected(`${challenge.mechanicId}`)}
                />
              </BoxFlex>
            </BoxFlex>
          </BoxFlex>
        </BoxPadding>
        <Presentation
          presentations={challenge.presentations}
          setPresentations={(presentations: PresentationObj[]) =>
            setChallenge({
              ...challenge,
              presentations,
            })
          }
        />
        <BoxBottom>
          <Button onClick={goToChallengesList} style={{ width: 120 }} typeButton="primary-white">
            CANCELAR
          </Button>
          <Button
            loading={isLoading}
            disabled={isSubmissionDisabled()}
            type="submit"
            style={{ width: 120, marginLeft: 16 }}
          >
            CONCLUIR
          </Button>
        </BoxBottom>
      </BoxInfo>
    </Container>
  );
};

export default CreateEditChallenge;
