import React, { useState, ChangeEvent, useEffect } from 'react';

import { useHistory } from 'react-router';
import Button from '../../../shared/components/buttons/Button';
import { useUser } from '../../../store/reducers/user';
import { Container, BoxInsertUser, BoxBottom, ContainerInsertUser } from './styles';
import BreadCrumbs from '../../../shared/components/breadCrumbs/BreadCrumbs';
import InputFile from '../../../shared/components/input/inputFile/InputFile';
import FixedSnackBar, {
  PFixedSnackbar,
} from '../../../shared/components/snackbar/fixedSnackbar/FixedSnackbar';
import { MenuUrl } from '../../../shared/enums/menuUrl';

const ImportUserList = () => {
  const history = useHistory();
  const { importUserList, clearErrors, errors } = useUser();
  const [loading, setLoading] = useState(false);
  const [fixedSnackBar, setFixedSnackbar] = useState<PFixedSnackbar>({
    type: 'error',
    errors: [],
    title: 'Ops, ocorreu um problema!',
    subtitle:
      'Ao carregar sua planilha houve um erro e a(s) linha(s) descrita(s) abaixo não foram possíveis de importar. Por favor, corrija as linhas e tente importar novamente seu arquivo.',
    show: false,
  });
  const [formData] = useState<FormData>(new FormData());
  const [disabled, setDisabled] = useState(true);
  const [valueInput, setValueInput] = useState('');

  const handleImportUserList = async () => {
    setLoading(true);
    try {
      await importUserList(formData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setFixedSnackbar({
        type: 'error',
        title: 'Ops, ocorreu um problema!',
        subtitle:
          'Ao carregar sua planilha houve um erro e a(s) linha(s) descrita(s) abaixo não foram possíveis de importar. Por favor, corrija as linhas e tente importar novamente seu arquivo.',
        errors,
        show: true,
      });
    }
  };

  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    if (!errors.length && valueInput !== '') {
      history.push(MenuUrl.user);
    } else if (errors.length > 0) {
      setFixedSnackbar({
        type: 'error',
        title: 'Ops, ocorreu um problema!',
        subtitle:
          'Ao carregar sua planilha houve um erro e a(s) linha(s) descrita(s) abaixo não foram possíveis de importar. Por favor, corrija as linhas e tente importar novamente seu arquivo.',
        errors,
        show: true,
      });
    }
  }, [errors]);

  const handleDelete = () => {
    setValueInput('');
  };

  const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    setValueInput(event.target.value);
    const { files } = event.target;
    if (files?.length) {
      setLoading(true);
      formData.set('userList', files[0]);
      setDisabled(false);
      setLoading(false);
    }
  };

  const closeScreen = () => {
    clearErrors();
    history.push(MenuUrl.user);
  };

  return (
    <Container>
      <BreadCrumbs
        listMenus={[
          {
            name: 'Membros',
            action: () => history.go(-1),
          },
          {
            name: 'Importar lista',
          },
        ]}
      />
      <ContainerInsertUser>
        <BoxInsertUser>
          <InputFile
            title="Arquivo CSV*"
            subTitle="As áreas listadas no arquivo precisam ser criadas previamente"
            onChange={handleChangeFile}
            handleDelete={handleDelete}
            valueInput={valueInput}
            style={{ marginLeft: 32, width: '40%' }}
          />
          <BoxBottom>
            <Button onClick={closeScreen} style={{ width: 120 }} typeButton="primary-white">
              Cancelar
            </Button>
            <Button
              loading={loading}
              onClick={handleImportUserList}
              disabled={disabled}
              style={{ width: 120, marginLeft: 16 }}
            >
              SALVAR
            </Button>
            <Button style={{ width: 130, marginLeft: 16 }}>
              <a
                href="/modelo_usuarios.csv"
                download="modelo_usuarios.csv"
                style={{ color: 'white', textDecoration: 'none' }}
              >
                BAIXAR MODELO
              </a>
            </Button>
          </BoxBottom>
        </BoxInsertUser>
      </ContainerInsertUser>
      <FixedSnackBar
        type={fixedSnackBar.type}
        title={fixedSnackBar.title}
        subtitle={fixedSnackBar.subtitle}
        errors={fixedSnackBar.errors}
        show={fixedSnackBar.show}
        setSnackBar={setFixedSnackbar}
      />
    </Container>
  );
};

export default ImportUserList;
