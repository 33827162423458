import { useDispatch, useSelector } from 'react-redux';
import {
  BodyUserLogin,
  BodyCreateUser,
  BodyForgotPassword,
  BodyChangePassword,
} from '../../../shared/modals/user/bodies';
import { Company, UserPagination } from '../../../shared/modals/user/user';
import { RootStateGlobal } from '../../reducer';
import * as userOperations from './userOperations';
import * as userActions from './userActions';

export const useUser = () => {
  const dispatch = useDispatch();

  const {
    userData,
    users,
    user,
    levels,
    errors,
    userReport,
    totalCount,
    userPagination,
  } = useSelector((state: RootStateGlobal) => state.userReducer);

  const reqLogin = async (bodyUserLogin: BodyUserLogin) =>
    userOperations.reqLogin(bodyUserLogin)(dispatch);
  const getUserData = async () => userOperations.getUserData()(dispatch);
  const getUsersFiltered = async (userPagination?: UserPagination) =>
    userOperations.getUsersFiltered(userPagination)(dispatch);
  const getUser = async (idUser: string) => userOperations.getUser(idUser)(dispatch);
  const blockUser = async (idUser: string, userPagination: UserPagination) =>
    userOperations.blockUser(idUser, userPagination)(dispatch);
  const unlockUser = async (idUser: string, userPagination: UserPagination) =>
    userOperations.unlockUser(idUser, userPagination)(dispatch);
  const insertUser = async (body: BodyCreateUser, userPagination: UserPagination) =>
    userOperations.insertUser(body, userPagination)(dispatch);
  const importUserList = async (body: FormData) => userOperations.importUserList(body)(dispatch);
  const insertAdmin = async (body: BodyCreateUser) => userOperations.insertAdmin(body)(dispatch);
  const insertAdminGlobal = async (
    body: BodyCreateUser,
    company: Company,
    skipGetUsersFiltered = false,
  ) => userOperations.insertAdminGlobal(body, company, skipGetUsersFiltered)(dispatch);
  const editUser = async (body: BodyCreateUser, idUser: string, pagination: UserPagination) =>
    userOperations.editUser(body, idUser, pagination)(dispatch);
  const requestPasswordEmail = async (body: BodyForgotPassword) =>
    userOperations.requestPasswordEmail(body)(dispatch);
  const deleteUser = async (idUser: string, pagination: UserPagination) =>
    userOperations.deleteUser(idUser, pagination)(dispatch);
  const getAllLevels = async () => userOperations.getAllLevels()(dispatch);
  const editPasswordUser = async (idUser: string, password: string) =>
    userOperations.editPasswordUser(idUser, password)(dispatch);
  const redefinePassword = async (body: BodyChangePassword) =>
    userOperations.redefinePassword(body)(dispatch);
  const getUserReport = async (idUser: string, startDate: string, endDate: string) =>
    userOperations.getUserReport(idUser, startDate, endDate)(dispatch);
  const setUserPagination = (x: UserPagination) => dispatch(userActions.setUserPagination(x));

  const clearErrors = () => {
    dispatch(userActions.setImportErrors([]));
  };

  return {
    userData,
    users,
    user,
    levels,
    errors,
    userReport,
    totalCount,
    userPagination,
    reqLogin,
    getUserData,
    getUsersFiltered,
    getUser,
    blockUser,
    unlockUser,
    insertUser,
    importUserList,
    insertAdmin,
    insertAdminGlobal,
    editUser,
    deleteUser,
    getAllLevels,
    requestPasswordEmail,
    redefinePassword,
    clearErrors,
    editPasswordUser,
    getUserReport,
    setUserPagination,
  };
};
