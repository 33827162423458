import { Reward, RewardManager } from '../../../shared/modals/reward/reward';
import { RewardTypes } from './rewardTypes';

export const setRewards = (rewards: Reward[]) => ({
  type: RewardTypes.SET_REWARDS,
  payload: rewards,
});

export const setReward = (reward: Reward[]) => ({
  type: RewardTypes.SET_REWARD,
  payload: reward,
});

export const setRewardsManagers = (rewardsRequests: RewardManager[]) => ({
  type: RewardTypes.SET_REWARDS_REQUESTS,
  payload: rewardsRequests,
});
