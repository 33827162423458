import React, { useEffect, useState, Dispatch, SetStateAction, ChangeEvent } from 'react';
import Button from '../../../../shared/components/buttons/Button';
import Input from '../../../../shared/components/input/Input';
import Modal from '../../../../shared/components/modal/Modal';
import Select from '../../../../shared/components/select/Select';
import { BoxButtonsMain } from '../../../../shared/components/styled/box';
import { DisplayFlexJustifyContent } from '../../../../shared/components/styled/general';
import { TextDefault, TitleBold } from '../../../../shared/components/styled/text';
import { Category } from '../../../../shared/modals/question/category';
import { useLibrary } from '../../../../store/reducers/library';
import { useQuestion } from '../../../../store/reducers/question';

type PModalSelectCategory = {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  idQuestions: string[];
};

const ModalSelectCategory = ({ openModal, setOpenModal, idQuestions }: PModalSelectCategory) => {
  const { getCategories, categories } = useQuestion();
  const { importLibrary, importLibraryInsertCategory } = useLibrary();
  const [idCategory, setIdCategory] = useState<string>();
  const [nameCategory, setNameCategory] = useState<string>('');
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    getCategories();
  }, [openModal]);

  const handleOnChangeSelectCategory = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setIdCategory(event.target.value);
  };

  const handleImportLibrary = async () => {
    setLoading(true);
    const body = {
      idCategory: `${idCategory}`,
      idQuestions,
    };
    if (idCategory) {
      await importLibrary(body);
    } else if (nameCategory !== '') {
      await importLibraryInsertCategory(body, nameCategory);
    }

    setLoading(false);
    setOpenModal(false);
  };

  const convertCategoriesToPOptions = () => {
    const arrayReturn = [
      {
        value: '',
        viewValue: 'Selecione',
      },
    ];

    categories.forEach((category: Category) => {
      arrayReturn.push({
        value: `${category.idCategory}`,
        viewValue: category.name,
      });
    });

    return arrayReturn;
  };

  const handleChangeNameCategory = (event: ChangeEvent<HTMLInputElement>) => {
    setNameCategory(event.target.value);
  };

  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <TitleBold>Importar perguntas</TitleBold>
      <TextDefault style={{ margin: '16px 0px' }}>
        <strong>Deseja importar as perguntas em qual categoria?</strong>
        {` Você pode importar para uma categoria já existente ou criar uma nova categoria.`}
      </TextDefault>
      <DisplayFlexJustifyContent>
        {!!categories.length && (
          <>
            <Select
              title="Categoria"
              nameSelect="select_category"
              listOptions={convertCategoriesToPOptions()}
              onChange={handleOnChangeSelectCategory}
              style={{ width: 230 }}
            />
            <TextDefault style={{ marginTop: 16 }}>OU</TextDefault>
          </>
        )}

        <Input
          value={nameCategory}
          onChange={handleChangeNameCategory}
          title="Criar nova categoria"
          style={{ width: 230 }}
        />
      </DisplayFlexJustifyContent>
      <BoxButtonsMain>
        <Button
          onClick={() => setOpenModal(false)}
          typeButton="primary-white"
          style={{ width: 120 }}
        >
          CANCELAR
        </Button>
        <Button
          disabled={(!idCategory && nameCategory === '') || (!!idCategory && nameCategory !== '')}
          loading={loading}
          onClick={handleImportLibrary}
          style={{ width: 120, marginLeft: 16 }}
        >
          CONFIRMAR
        </Button>
      </BoxButtonsMain>
    </Modal>
  );
};

export default ModalSelectCategory;
