export enum MenuUrl {
  home = '/home',
  company = '/company',
  question = '/question',
  user = '/member',
  notification = '/notification',
  firstLogin = '/firstLogin',
  contest = '/tournament',
  trail = '/trail',
  stage = '/phase',
  category = '/category',
  report = '/report',
  setup = '/setup',
  contestReport = '/tournamentReport',
  userReport = '/memberReport',
  trailReport = '/trailReport',
  libraryAdmin = '/libraryAdmin',
  library = '/library',
  area = '/area',
  preferences = '/preferences',
  permission = '/permission',
  typeUser = '/typeMember',
  reward = '/award',
  rewardManage = '/AwardManage',
  missions = '/missions',
  challenges = '/missions/challenges',
  approvals = '/missions/approvals',
  selfSignUpCampaigns = '/selfSignUpCampaigns',
  importAreas = '/areas/import',
  journeys = '/journeys',
  rollouts = '/rollouts',
}

export enum ExtraUrl {
  new = 'new',
  import = 'import',
}
