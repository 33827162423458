import { Area } from '../../../shared/modals/area/area';
import { ImportUserError } from '../../../shared/modals/user/user';

import { AreaTypes } from './areaTypes';

export const setImportErrors = (errors: ImportUserError[]) => ({
  type: AreaTypes.SET_IMPORT_ERRORS,
  payload: errors,
});

export const setAreas = (areas: Area[]) => ({
  type: AreaTypes.SET_ALL_AREAS,
  payload: areas,
});
