import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';

import Button from '../../shared/components/buttons/Button';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import SearchInput from '../../shared/components/input/searchInput/SearchInput';
import EnrichedText from '../../shared/components/richEditor/EnrichedText';
import Table from '../../shared/components/table/Table';
import { filterArraySearch } from '../../shared/functions/utils';
import IconDuplicate from '../../shared/images/icon/iconDuplicate';
import IconEdit from '../../shared/images/icon/iconEdit';
import IconTrash from '../../shared/images/icon/iconTrash';
import { Question as IQuestion } from '../../shared/modals/question/question';
import { useQuestion } from '../../store/reducers/question';
import DeleteQuestion from './deleteQuestion/DeleteQuestion';
import DuplicateQuestion from './duplicateQuestion/DuplicateQuestion';
import Filter, { ClickFilterHandle } from './filter/Filter';
import ImportQuestionList from './importQuestionList/ImportQuestionList';
import ImportResearchList from './importResearchList/ImportResearchList';
import InsertQuestion from './insertQuestion/InsertQuestion';
import { Container, Header, TextHeader, BoxHeaderButtons } from './styles';
import { useGeneral } from '../../store/reducers/general';

type ParamTypes = {
  idLibrary?: string;
};

const Question = () => {
  const { idLibrary } = useParams<ParamTypes>();
  const { filterSelect } = useGeneral();
  const {
    questions,
    getQuestionsFiltered,
    getCategories,
    clearErrors,
    totalCount,
    questionPagination,
    setQuestionPagination,
  } = useQuestion();
  const [searchQuestion, setSearchQuestion] = useState('');
  const [questionFilter, setQuestionFilter] = useState<IQuestion[]>([]);
  const [openFilter, setOpenFilter] = useState(false);

  const [questionAction, setQuestionAction] = useState<IQuestion>();
  const [openDeleteQuestion, setOpenDeleteQuestion] = useState(false);
  const [openDuplicateQuestion, setOpenDuplicateQuestion] = useState(false);
  const [openInsertQuestion, setOpenInsertQuestion] = useState(false);
  const [showImportQuestionList, setShowImportQuestionList] = useState(false);
  const [showImportResearchList, setShowImportResearchList] = useState(false);
  const [idQuestionEdit, setIdQuestionEdit] = useState('');

  const childRef = useRef<ClickFilterHandle>();

  const filterQuestionsByCategory = () => {
    if (filterSelect.length > 0) {
      const selectedCategories = filterSelect
        .filter(accordion => accordion.selected)
        .map(accordion => accordion.id);

      const filteredQuestions = questions.filter(question =>
        selectedCategories.some(category => category === question.idCategory),
      );

      return filteredQuestions;
    }

    return questions;
  };

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!idLibrary) {
      getCategories();
    }

    clearErrors();
  }, []);

  useEffect(() => {
    getQuestionsFiltered(questionPagination);
  }, [questionPagination]);

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    if (questionPagination.constraints.search || searchQuestion.length >= 3) {
      debounceTimeout.current = setTimeout(() => {
        setQuestionPagination({
          ...questionPagination,
          page: 1,
          constraints: { ...questionPagination.constraints, search: searchQuestion },
        });
      }, 500);
    }
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchQuestion]);

  useEffect(() => {
    const filteredByCategory = filterQuestionsByCategory();
    setQuestionFilter(filterArraySearch(filteredByCategory, 'text', searchQuestion));
  }, [questions]);

  const handleChangeSearchQuestion = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuestion(event.target.value);
  };

  const handleOnClickDeleteQuestion = (question: IQuestion) => {
    setQuestionAction(question);
    setOpenDeleteQuestion(true);
  };

  const handleOnClickDuplicateQuestion = (question: IQuestion) => {
    setQuestionAction(question);
    setOpenDuplicateQuestion(true);
  };

  const handleOnClickEditQuestion = (question: IQuestion) => {
    setIdQuestionEdit(question.idQuestion);
    setOpenInsertQuestion(true);
  };

  const goToImportQuestionList = () => {
    setShowImportQuestionList(true);
  };

  const goToImportResearchList = () => {
    setShowImportResearchList(true);
  };

  const renderRowsTable = () =>
    questionFilter.map(question => ({
      columns: [
        question.category ? question.category.name : '',
        <EnrichedText value={question.text} />,
        <>
          <ButtonIcon
            style={{ marginRight: 16 }}
            onClick={() => handleOnClickEditQuestion(question)}
          >
            <IconEdit />
          </ButtonIcon>
          <ButtonIcon
            style={{ marginRight: 16 }}
            onClick={() => handleOnClickDuplicateQuestion(question)}
          >
            <IconDuplicate />
          </ButtonIcon>
          <ButtonIcon onClick={() => handleOnClickDeleteQuestion(question)}>
            <IconTrash />
          </ButtonIcon>
        </>,
      ],
    }));

  if (openInsertQuestion) {
    return (
      <InsertQuestion
        setOpenInsertQuestion={setOpenInsertQuestion}
        idQuestionEdit={idQuestionEdit}
        setIdQuestionEdit={setIdQuestionEdit}
        idLibrary={idLibrary}
      />
    );
  }

  if (showImportQuestionList) {
    return (
      <ImportQuestionList
        setShowImportQuestionList={setShowImportQuestionList}
        idLibrary={idLibrary}
      />
    );
  }

  if (showImportResearchList) {
    return (
      <ImportResearchList
        setShowImportResearchList={setShowImportResearchList}
        idLibrary={idLibrary}
      />
    );
  }
  return (
    <Container>
      <Filter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        ref={childRef}
        pagination={questionPagination}
        setPagination={setQuestionPagination}
      />
      <DeleteQuestion
        question={questionAction}
        openDeleteQuestion={openDeleteQuestion}
        setOpenDeleteQuestion={setOpenDeleteQuestion}
        idLibrary={idLibrary}
      />
      <DuplicateQuestion
        question={questionAction}
        openDuplicateQuestion={openDuplicateQuestion}
        setOpenDuplicateQuestion={setOpenDuplicateQuestion}
        idLibrary={idLibrary}
      />
      <BreadCrumbs
        listMenus={[
          {
            name: 'Perguntas',
          },
        ]}
      />
      <Header>
        <TextHeader>Confira abaixo as perguntas cadastradas e/ou insira uma nova.</TextHeader>
        <BoxHeaderButtons>
          <SearchInput
            placeholder="Buscar pergunta..."
            search={searchQuestion}
            onChange={handleChangeSearchQuestion}
          />
          <Button
            style={{ marginRight: 16, width: 180 }}
            onClick={() => setOpenInsertQuestion(true)}
            icon="plus"
          >
            NOVA PERGUNTA
          </Button>
          <Button
            style={{ width: 200, marginRight: 16 }}
            onClick={goToImportQuestionList}
            icon="plus"
          >
            IMPORTAR PERGUNTAS
          </Button>
          <Button style={{ width: 200 }} onClick={goToImportResearchList} icon="plus">
            IMPORTAR PESQUISAS
          </Button>
        </BoxHeaderButtons>
      </Header>
      <Table
        setPagination={setQuestionPagination}
        pagination={questionPagination}
        totalCount={totalCount}
        nameReferenceReduxTable="table_question"
        listRowsPerPage={[10, 25, 50, 100]}
        titles={[
          {
            openClick: true,
            name: idLibrary ? '' : 'Categorias',
            type: 'string',
            widthTd: idLibrary ? '0%' : '35%',
          },
          {
            openClick: true,
            name: 'Perguntas',
            type: 'string',
            widthTd: '50%',
          },
          {
            openClick: false,
            name: 'Ações',
            type: 'ReactNode',
            widthTd: '20%',
          },
        ]}
        rows={renderRowsTable()}
        withFilter
        setOpenFilter={setOpenFilter}
      />
    </Container>
  );
};

export default Question;
