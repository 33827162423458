import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../../functions/colors';

type PIconList = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconList = ({ width, height, color, style }: PIconList) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" style={style}>
    <mask
      id="iconList"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x={3}
      y={5}
      width={17}
      height={14}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 9H7V5H3V9ZM7 14H3V10H7V14ZM7 19H3V15H7V19ZM20 14H8V10H20V14ZM8 19H20V15H8V19ZM8 9V5H20V9H8Z"
        fill="white"
      />
    </mask>
    <g mask="url(#iconList)">
      <rect width="24" height="24" fill={color} />
    </g>
  </svg>
);

IconList.defaultProps = {
  width: 24,
  height: 24,
  color: colors.white,
  style: {},
};

export default IconList;
