import { differenceBy } from 'lodash';
import React from 'react';
import { colors } from '../../../functions/colors';
import Button from '../../buttons/Button';
import Select, { POptions } from '../Select';
import BoxSelected from './boxSelected/BoxSelected';

import { Container, FlexContainer, BoxItensSelected } from './styles';

const SELECT_ALL_OPTION = 'SELECT-ALL';
const REMOVE_ALL_OPTION = 'REMOVE-ALL';

const DEFAULT_OPTIONS = [
  {
    value: '',
    viewValue: 'Selecione',
    selected: true,
  },
  {
    value: SELECT_ALL_OPTION,
    viewValue: '🟢 SELECIONAR TUDO',
  },
  {
    value: REMOVE_ALL_OPTION,
    viewValue: '🔴 REMOVER TUDO',
  },
];

type PMultipleSelect = {
  title: string;
  listOptions: POptions[];
  itensSelected: POptions[];
  setItensSelected: (x: POptions[]) => void;
  textButton?: string;
  onClickButton?: () => void;
};

const MultipleSelect = ({
  title,
  listOptions,
  itensSelected,
  setItensSelected,
  textButton,
  onClickButton,
}: PMultipleSelect) => {
  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const optionSelected = listOptions.find(pOptions => pOptions.value === event.target.value);

    if (optionSelected) {
      itensSelected.push(optionSelected);
      setItensSelected([...itensSelected]);
    } else if (event.target.value === SELECT_ALL_OPTION) {
      setItensSelected(listOptions);
    } else if (event.target.value === REMOVE_ALL_OPTION) {
      setItensSelected([]);
    }
  };

  const renderShowSelect = () => {
    const notSelectedOptions = differenceBy(listOptions, itensSelected, 'value');

    return [...DEFAULT_OPTIONS, ...notSelectedOptions];
  };

  const handleRemoveItem = (option: POptions) => {
    const itensRemoved = itensSelected.filter(item => item.value !== option.value);
    setItensSelected([...itensRemoved]);
  };

  return (
    <Container>
      <FlexContainer>
        <div>
          <Select
            title={title}
            nameSelect="multipleSelect"
            listOptions={renderShowSelect()}
            onChange={handleSelect}
            value=""
          />
          {textButton && (
            <Button
              typeButton="primary-white"
              style={{ marginTop: 16, width: '100%', minWidth: 180 }}
              onClick={onClickButton}
              icon="plus"
              colorIcon={colors.purpleRegular}
            >
              {textButton}
            </Button>
          )}
        </div>
        <BoxItensSelected>
          {itensSelected.map(item => (
            <BoxSelected
              onClick={() => handleRemoveItem(item)}
              text={item.viewValue}
              key={item.value}
            />
          ))}
        </BoxItensSelected>
      </FlexContainer>
    </Container>
  );
};

MultipleSelect.defaultProps = {
  textButton: undefined,
  onClickButton: () => null,
};

export default MultipleSelect;
