import { Company } from '../../../shared/modals/user/user';

export enum CompanyTypes {
  SET_COMPANIES = '@company/SET_COMPANIES',
  SET_COMPANY = '@company/SET_COMPANY',
}

export type CompanyState = {
  readonly companies: Company[];
  readonly company?: Company;
};
