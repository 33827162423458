type EnvironmentType = 'production' | 'staging' | 'development';

export const environment = (): EnvironmentType => {
  const { NODE_ENV, REACT_APP_API_URL } = (process.env as unknown) as {
    NODE_ENV: EnvironmentType;
    REACT_APP_API_URL: string;
  };

  if (NODE_ENV === 'production') {
    return REACT_APP_API_URL === 'https://api.tutodigital.com.br' ? 'production' : 'staging';
  }

  return NODE_ENV;
};
