import React, {
  ChangeEvent,
  Dispatch,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import Button from '../../../shared/components/buttons/Button';
import ButtonIcon from '../../../shared/components/buttons/buttonIcon/ButtonIcon';
import CheckboxAccordion, {
  PListAccordion,
} from '../../../shared/components/input/checkboxAccordion/CheckboxAccordion';
import { colors } from '../../../shared/functions/colors';
import IconClose from '../../../shared/images/icon/iconClose';
import { useUser } from '../../../store/reducers/user';
import { useQuestion } from '../../../store/reducers/question';
import { useStage } from '../../../store/reducers/stage';
import { useContest } from '../../../store/reducers/contest';
import { useTrail } from '../../../store/reducers/trail';
import { Container, Shadow, Menu, BoxButtonsBottom, Header, Title, BoxAccordions } from './styles';
import { useReport } from '../../../store/reducers/report';
import { ReportFiltersType } from '../../../shared/enums/reportFiltersTypes';
import Input from '../../../shared/components/input/Input';
import { filterArraySearchInsertShow } from '../../../shared/functions/utils';
import { Stage } from '../../../shared/modals/stage/stage';
import { useArea } from '../../../store/reducers/area';

type PFilter = {
  openFilter: boolean;
  setOpenFilter: (x: boolean) => void;
  startDate: string;
  endDate: string;
  setLoadingFilter: Dispatch<boolean>;
  setLabelDataSet: Dispatch<string>;
};

export type ClickFilterHandle = {
  onClickFilter: () => void;
};

const Filter = forwardRef(
  (
    { openFilter, setOpenFilter, startDate, endDate, setLoadingFilter, setLabelDataSet }: PFilter,
    ref: React.Ref<ClickFilterHandle | undefined>,
  ) => {
    const { users, getUsersFiltered } = useUser();
    const { areas, getAllAreas } = useArea();
    const { categories, questions, getCategories, getQuestionsFiltered } = useQuestion();
    const { contests, getContests } = useContest();
    const { stages, getStages } = useStage();
    const { trails, getTrails } = useTrail();
    const { getReport } = useReport();
    const [textSearch, setTextSearch] = useState('');
    const [changeSearch, setChangeSearch] = useState<undefined | boolean>();
    const [loading, setLoading] = useState(false);
    const [clear, setClear] = useState(false);
    const [listAccordionStage, setListAccordionStage] = useState<PListAccordion[]>([]);
    const [listAccordionArea, setListAccordionArea] = useState<PListAccordion[]>([]);
    const [listAccordionCategory, setListAccordionCategory] = useState<PListAccordion[]>([]);
    const [listAccordionUser, setListAccordionUser] = useState<PListAccordion[]>([]);
    const [listAccordionQuestion, setListAccordionQuestion] = useState<PListAccordion[]>([]);
    const [listAccordionContest, setListAccordionContest] = useState<PListAccordion[]>([]);
    const [listAccordionTrail, setListAccordionTrail] = useState<PListAccordion[]>([]);

    const convertTrailsToAccordion = (): PListAccordion[] => {
      if (!trails || trails.length <= 0) {
        return [];
      }
      return filterArraySearchInsertShow(
        trails.map(trail => ({
          id: trail.idTrail,
          label: trail.name,
          selected: false,
        })),
        'label',
        textSearch,
      );
    };

    const convertContestsToAccordion = (): PListAccordion[] => {
      if (!contests || contests.length <= 0) {
        return [];
      }
      return filterArraySearchInsertShow(
        contests.map(contest => ({
          id: contest.idTourney,
          label: contest.name,
          selected: false,
        })),
        'label',
        textSearch,
      );
    };

    const convertQuestionsToAccordion = (): PListAccordion[] => {
      if (!questions || questions.length <= 0) {
        return [];
      }
      return filterArraySearchInsertShow(
        questions.map(question => ({
          id: question.idQuestion,
          label: question.text,
          selected: false,
        })),
        'label',
        textSearch,
      );
    };

    const convertUsersToAccordion = (): PListAccordion[] => {
      if (!users || users.length <= 0) {
        return [];
      }
      return filterArraySearchInsertShow(
        users.map(user => ({
          id: user.idUser,
          label: user.name,
          selected: false,
        })),
        'label',
        textSearch,
      );
    };

    const convertStagesToAccordion = (): PListAccordion[] => {
      if (!stages || stages.length <= 0) {
        return [];
      }
      return filterArraySearchInsertShow(
        stages.map((stage: Stage) => ({
          id: stage.idPhase,
          label: stage.name,
          selected: false,
        })),
        'label',
        textSearch,
      );
    };

    const convertAreasToAccordion = (): PListAccordion[] => {
      if (!areas || areas.length <= 0) {
        return [];
      }
      return filterArraySearchInsertShow(
        areas.map(area => ({
          id: area.idArea,
          label: area.name,
          selected: false,
        })),
        'label',
        textSearch,
      );
    };

    const convertCategoriesToAccordion = (): PListAccordion[] => {
      if (!categories || categories.length <= 0) {
        return [];
      }
      return filterArraySearchInsertShow(
        categories.map(category => ({
          id: category.idCategory,
          label: category.name,
          selected: false,
        })),
        'label',
        textSearch,
      );
    };

    useEffect(() => {
      if (listAccordionArea.filter(accordion => accordion.selected).length > 0) {
        setListAccordionTrail(convertTrailsToAccordion());
        setListAccordionContest(convertContestsToAccordion());
        setListAccordionQuestion(convertQuestionsToAccordion());
        setListAccordionUser(convertUsersToAccordion());
        setListAccordionStage(convertStagesToAccordion());
        setListAccordionCategory(convertCategoriesToAccordion());
      }
    }, [listAccordionArea]);

    useEffect(() => {
      if (listAccordionCategory.filter(accordion => accordion.selected).length > 0) {
        setListAccordionTrail(convertTrailsToAccordion());
        setListAccordionContest(convertContestsToAccordion());
        setListAccordionQuestion(convertQuestionsToAccordion());
        setListAccordionUser(convertUsersToAccordion());
        setListAccordionStage(convertStagesToAccordion());
        setListAccordionArea(convertAreasToAccordion());
      }
    }, [listAccordionCategory]);

    useEffect(() => {
      if (listAccordionUser.filter(accordion => accordion.selected).length > 0) {
        setListAccordionTrail(convertTrailsToAccordion());
        setListAccordionContest(convertContestsToAccordion());
        setListAccordionQuestion(convertQuestionsToAccordion());
        setListAccordionStage(convertStagesToAccordion());
        setListAccordionArea(convertAreasToAccordion());
        setListAccordionCategory(convertCategoriesToAccordion());
      }
    }, [listAccordionUser]);

    useEffect(() => {
      if (listAccordionQuestion.filter(accordion => accordion.selected).length > 0) {
        setListAccordionTrail(convertTrailsToAccordion());
        setListAccordionContest(convertContestsToAccordion());
        setListAccordionUser(convertUsersToAccordion());
        setListAccordionStage(convertStagesToAccordion());
        setListAccordionArea(convertAreasToAccordion());
        setListAccordionCategory(convertCategoriesToAccordion());
      }
    }, [listAccordionQuestion]);

    useEffect(() => {
      if (listAccordionContest.filter(accordion => accordion.selected).length > 0) {
        setListAccordionTrail(convertTrailsToAccordion());
        setListAccordionQuestion(convertQuestionsToAccordion());
        setListAccordionUser(convertUsersToAccordion());
        setListAccordionStage(convertStagesToAccordion());
        setListAccordionArea(convertAreasToAccordion());
        setListAccordionCategory(convertCategoriesToAccordion());
      }
    }, [listAccordionContest]);

    useEffect(() => {
      if (listAccordionTrail.filter(accordion => accordion.selected).length > 0) {
        setListAccordionContest(convertContestsToAccordion());
        setListAccordionQuestion(convertQuestionsToAccordion());
        setListAccordionUser(convertUsersToAccordion());
        setListAccordionStage(convertStagesToAccordion());
        setListAccordionArea(convertAreasToAccordion());
        setListAccordionCategory(convertCategoriesToAccordion());
      }
    }, [listAccordionTrail]);

    useEffect(() => {
      if (listAccordionStage.filter(accordion => accordion.selected).length > 0) {
        setListAccordionTrail(convertTrailsToAccordion());
        setListAccordionContest(convertContestsToAccordion());
        setListAccordionQuestion(convertQuestionsToAccordion());
        setListAccordionUser(convertUsersToAccordion());
        setListAccordionArea(convertAreasToAccordion());
        setListAccordionCategory(convertCategoriesToAccordion());
      }
    }, [listAccordionStage]);

    useEffect(() => {
      getStages();
      if (!areas || areas.length <= 0) {
        getAllAreas();
      }
      if (!categories || categories.length <= 0) {
        getCategories();
      }
      if (!users || users.length <= 0) {
        getUsersFiltered();
      }
      if (!questions || questions.length <= 0) {
        getQuestionsFiltered();
      }
      if (!contests || contests.length <= 0) {
        getContests();
      }
      if (!trails || trails.length <= 0) {
        getTrails();
      }
    }, []);

    useEffect(() => {
      setListAccordionTrail(convertTrailsToAccordion());
    }, [trails, clear]);

    useEffect(() => {
      setListAccordionContest(convertContestsToAccordion());
    }, [contests, clear]);

    useEffect(() => {
      setListAccordionQuestion(convertQuestionsToAccordion());
    }, [questions, clear]);

    useEffect(() => {
      setListAccordionUser(convertUsersToAccordion());
    }, [users, clear]);

    useEffect(() => {
      setListAccordionStage(convertStagesToAccordion());
    }, [stages, clear]);

    useEffect(() => {
      setListAccordionArea(convertAreasToAccordion());
    }, [areas, clear]);

    useEffect(() => {
      setListAccordionCategory(convertCategoriesToAccordion());
    }, [categories, clear]);

    const handleOnClickFilter = async () => {
      setLoading(true);
      const selectedAreas = listAccordionArea.filter(accordion => accordion.selected);
      const selectedCategories = listAccordionCategory.filter(accordion => accordion.selected);
      const selectedStages = listAccordionStage.filter(accordion => accordion.selected);
      const selectedUsers = listAccordionUser.filter(accordion => accordion.selected);
      const selectedQuestions = listAccordionQuestion.filter(accordion => accordion.selected);
      const selectedTrails = listAccordionTrail.filter(accordion => accordion.selected);
      const selectedContests = listAccordionContest.filter(accordion => accordion.selected);

      if (selectedAreas.length > 0) {
        await getReport(
          selectedAreas.length === listAccordionArea.length
            ? []
            : selectedAreas.map(accordion => accordion.id),
          startDate,
          endDate,
          ReportFiltersType.area,
        );
        setLabelDataSet('Áreas');
      } else if (selectedCategories.length > 0) {
        await getReport(
          selectedCategories.length === listAccordionCategory.length
            ? []
            : selectedCategories.map(accordion => accordion.id),
          startDate,
          endDate,
          ReportFiltersType.category,
        );
        setLabelDataSet('Categorias');
      } else if (selectedStages.length > 0) {
        await getReport(
          selectedStages.length === listAccordionStage.length
            ? []
            : selectedStages.map(accordion => accordion.id),
          startDate,
          endDate,
          ReportFiltersType.stage,
        );
      } else if (selectedUsers.length > 0) {
        await getReport(
          selectedUsers.length === listAccordionUser.length
            ? []
            : selectedUsers.map(accordion => accordion.id),
          startDate,
          endDate,
          ReportFiltersType.user,
        );
        setLabelDataSet('Membros');
      } else if (selectedQuestions.length > 0) {
        await getReport(
          selectedQuestions.length === listAccordionQuestion.length
            ? []
            : selectedQuestions.map(accordion => accordion.id),
          startDate,
          endDate,
          ReportFiltersType.question,
        );
        setLabelDataSet('Perguntas');
      } else if (selectedTrails.length > 0) {
        await getReport(
          selectedTrails.length === listAccordionTrail.length
            ? []
            : selectedTrails.map(accordion => accordion.id),
          startDate,
          endDate,
          ReportFiltersType.trail,
        );
        setLabelDataSet('Trilhas');
      } else if (selectedContests.length > 0) {
        await getReport(
          selectedContests.length === listAccordionContest.length
            ? []
            : selectedContests.map(accordion => accordion.id),
          startDate,
          endDate,
          ReportFiltersType.contest,
        );
        setLabelDataSet('Torneios');
      }

      setLoading(false);
      setOpenFilter(false);
    };

    const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
      const valueSearch = event.target.value;
      setTextSearch(valueSearch);
      setListAccordionUser([
        ...filterArraySearchInsertShow(listAccordionUser, 'label', valueSearch),
      ]);
      setListAccordionTrail([
        ...filterArraySearchInsertShow(listAccordionTrail, 'label', valueSearch),
      ]);
      setListAccordionContest([
        ...filterArraySearchInsertShow(listAccordionContest, 'label', valueSearch),
      ]);
      setListAccordionQuestion([
        ...filterArraySearchInsertShow(listAccordionQuestion, 'label', valueSearch),
      ]);
      setListAccordionStage([
        ...filterArraySearchInsertShow(listAccordionStage, 'label', valueSearch),
      ]);
      setListAccordionCategory([
        ...filterArraySearchInsertShow(listAccordionCategory, 'label', valueSearch),
      ]);
      setListAccordionArea([
        ...filterArraySearchInsertShow(listAccordionArea, 'label', valueSearch),
      ]);
      setChangeSearch(!changeSearch);
    };

    useImperativeHandle(ref, () => ({
      async onClickFilter() {
        setLoadingFilter(true);
        await handleOnClickFilter();
        setLoadingFilter(false);
      },
    }));

    if (!openFilter) {
      return null;
    }

    return (
      <Container>
        <Shadow />
        <Menu>
          <Header>
            <Title>Filtrar por</Title>
            <ButtonIcon onClick={() => setOpenFilter(false)}>
              <IconClose width={24} height={24} color={colors.grey100} />
            </ButtonIcon>
          </Header>
          <Input
            style={{ padding: 24 }}
            backgroundColor={colors.grey40}
            showBorder={false}
            icon="search"
            placeholder="Buscar"
            value={textSearch}
            onChange={handleChangeSearch}
          />
          <BoxAccordions>
            <CheckboxAccordion
              title="Por área"
              idCheckbox="por_area"
              listAccordion={listAccordionArea}
              setListAccordion={setListAccordionArea}
              changeSearch={changeSearch}
            />
            {/* <CheckboxAccordion
              title="Por categoria"
              idCheckbox="por_categoria"
              listAccordion={listAccordionCategory}
              setListAccordion={setListAccordionCategory}
              changeSearch={changeSearch}
            /> */}
            {/* <CheckboxAccordion
              title="Por fase"
              idCheckbox="por_fase"
              listAccordion={listAccordionStage}
              setListAccordion={setListAccordionStage}
              changeSearch={changeSearch}
            /> */}
            <CheckboxAccordion
              title="Por membro"
              idCheckbox="por_membro"
              listAccordion={listAccordionUser}
              setListAccordion={setListAccordionUser}
              changeSearch={changeSearch}
            />
            {/* <CheckboxAccordion
              title="Por pergunta"
              idCheckbox="por_pergunta"
              listAccordion={listAccordionQuestion}
              setListAccordion={setListAccordionQuestion}
              changeSearch={changeSearch}
            /> */}
            <CheckboxAccordion
              title="Por torneio"
              idCheckbox="por_torneio"
              listAccordion={listAccordionContest}
              setListAccordion={setListAccordionContest}
              changeSearch={changeSearch}
            />
            <CheckboxAccordion
              title="Por trilha"
              idCheckbox="por_trilha"
              listAccordion={listAccordionTrail}
              setListAccordion={setListAccordionTrail}
              changeSearch={changeSearch}
            />
          </BoxAccordions>
          <BoxButtonsBottom>
            <Button
              onClick={() => setClear(!clear)}
              typeButton="primary-white"
              style={{ width: 164 }}
            >
              LIMPAR
            </Button>
            <Button loading={loading} style={{ width: 164 }} onClick={handleOnClickFilter}>
              FILTRAR
            </Button>
          </BoxButtonsBottom>
        </Menu>
      </Container>
    );
  },
);

export default Filter;
