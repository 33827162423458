import styled from 'styled-components';
import { fonts } from '../../../shared/functions/fonts';
import { colors } from '../../../shared/functions/colors';

export const Container = styled.div``;

export const Title = styled.h1`
  color: ${colors.secondary};
  font-family: ${fonts.bold};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 16px;
`;

export const Text = styled.p`
  color: ${colors.black};
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  margin-bottom: 24px;
`;

export const BoxButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
`;
