import styled from 'styled-components';
import { fonts } from '../../../functions/fonts';
import { colors } from '../../../functions/colors';

type PContainer = {
  error?: boolean;
};

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Select = styled.select<PContainer>`
  width: 50%;
  border: none;
  color: ${props => (props.error ? colors.error : colors.black)};

  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

export const Title = styled.h2`
  width: 50%;
  text-align: right;
  color: ${colors.primary};
  font-family: ${fonts.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  margin-right: 16px;
`;
