import styled from 'styled-components';
import { colors } from '../../../functions/colors';
import { fonts } from '../../../functions/fonts';

type PContainerButton = {
  isSelected?: boolean;
};

export const ContainerButton = styled.button<PContainerButton>`
  width: 100%;
  height: 56px;
  cursor: pointer;
  background-color: ${colors.white};
  color: ${props => (props.isSelected ? colors.pinkRegular : colors.grey100)};
  border: none;
  border-bottom: ${props => (props.isSelected ? '3px' : '1px')} solid
    ${props => (props.isSelected ? colors.pinkRegular : colors.grey100)};

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: ${props => (props.isSelected ? fonts.semiBold : fonts.regular)};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
`;
