import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import BreadCrumbs from '../../shared/components/breadCrumbs/BreadCrumbs';
import ButtonIcon from '../../shared/components/buttons/buttonIcon/ButtonIcon';
import {
  ContainerMain,
  DisplayFlex,
  HeaderMain,
  TextHeaderMain,
} from '../../shared/components/styled/general';
import Table from '../../shared/components/table/Table';
import { Reward as RewardObj } from '../../shared/modals/reward/reward';
import { useRewards } from '../../store/reducers/reward';
import IconEdit from '../../shared/images/icon/iconEdit';
import IconTrash from '../../shared/images/icon/iconTrash';
import Button from '../../shared/components/buttons/Button';
import { filterArraySearch } from '../../shared/functions/utils';
import { colors } from '../../shared/functions/colors';
import InsertEditReward from './insertEditReward/InsertEditReward';
import { ExtraUrl, MenuUrl } from '../../shared/enums/menuUrl';
import DeleteReward from './deleteReward/DeleteReward';
import BoxHorizontalButtons from '../../shared/components/box/boxHorizontalButtons/BoxHorizontalButtons';
import IconCheck from '../../shared/images/icon/iconCheck';
import IconBlock from '../../shared/images/icon/iconBlock';
import EnableReward from './enableReward/EnableReward';
import SearchInput from '../../shared/components/input/searchInput/SearchInput';
import IconDuplicate from '../../shared/images/icon/iconDuplicate';
import DuplicateReward from './duplicateReward/DuplicateReward';

type ParamTypes = {
  idReward?: string;
};

const Reward = () => {
  const history = useHistory();
  const { idReward } = useParams<ParamTypes>();
  const { rewards, getRewards } = useRewards();
  const [searchReward, setSearchReward] = useState('');
  const [rewardsFilter, setRewardsFilter] = useState<RewardObj[]>([]);
  const [rewardDelete, setRewardDelete] = useState<RewardObj | undefined>();
  const [rewardAction, setRewardAction] = useState<RewardObj>();
  const [openEnableReward, setOpenEnableReward] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDuplicateReward, setOpenDuplicateReward] = useState(false);

  useEffect(() => {
    getRewards();
  }, []);

  useEffect(() => {
    if (rewards) {
      setRewardsFilter(filterArraySearch(rewards, 'name', searchReward));
    }
  }, [rewards]);

  const handleChangeSearchUser = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchReward(event.target.value);
    if (rewards?.length) {
      setRewardsFilter(filterArraySearch(rewards, 'name', event.target.value));
    }
  };

  const handleGoToInsertReward = () => {
    history.push(`${MenuUrl.reward}/${ExtraUrl.new}`);
  };

  const handleOpenDelete = (reward: RewardObj) => {
    setRewardDelete(reward);
    setOpenDelete(true);
  };

  const handleEditReward = (reward: RewardObj) => {
    history.push(`${MenuUrl.reward}/${reward.idAward}`);
  };

  const handleOnClickEnableReward = (reward: RewardObj) => {
    setRewardAction(reward);
    setOpenEnableReward(true);
  };

  const handleOnClickDuplicateReward = (reward: RewardObj) => {
    setRewardAction(reward);
    setOpenDuplicateReward(true);
  };

  const renderRowsTable = (rewardStatus: RewardObj[]) =>
    rewardStatus.map((reward: RewardObj) => ({
      columns: [
        reward.name,
        reward.value,
        reward.available,
        <>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleOnClickEnableReward(reward)}>
            {reward.enabled ? <IconCheck color={colors.green} /> : <IconBlock color={colors.red} />}
          </ButtonIcon>
        </>,
        <>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleEditReward(reward)}>
            <IconEdit />
          </ButtonIcon>
          <ButtonIcon
            style={{ marginRight: 16 }}
            onClick={() => handleOnClickDuplicateReward(reward)}
          >
            <IconDuplicate />
          </ButtonIcon>
          <ButtonIcon style={{ marginRight: 16 }} onClick={() => handleOpenDelete(reward)}>
            <IconTrash />
          </ButtonIcon>
        </>,
      ],
    }));

  if (idReward) {
    return <InsertEditReward idReward={idReward !== ExtraUrl.new ? idReward : undefined} />;
  }

  const renderTable = (rewardStatus: RewardObj[]) => {
    return (
      <Table
        nameReferenceReduxTable="table_reward"
        listRowsPerPage={[10, 25, 50, 100]}
        titles={[
          {
            name: 'Produtos',
            openClick: true,
            type: 'string',
            widthTd: '20%',
          },
          {
            name: 'Valor (em moedas)',
            openClick: true,
            type: 'number',
          },
          {
            name: 'Unidades disponíveis',
            openClick: true,
            type: 'number',
          },
          {
            name: 'Permissões',
            openClick: false,
            type: 'ReactNode',
            widthTd: '10%',
          },
          {
            name: 'Ações',
            openClick: false,
            type: 'ReactNode',
            widthTd: '15%',
          },
        ]}
        rows={renderRowsTable(rewardStatus)}
      />
    );
  };

  return (
    <ContainerMain>
      <EnableReward
        reward={rewardAction}
        openEnableReward={openEnableReward}
        setOpenEnableReward={setOpenEnableReward}
      />
      <DeleteReward
        reward={rewardDelete}
        openDeleteReward={openDelete}
        setOpenDeleteReward={setOpenDelete}
      />
      <DuplicateReward
        reward={rewardAction}
        openDuplicateReward={openDuplicateReward}
        setOpenDuplicateReward={setOpenDuplicateReward}
      />
      <BreadCrumbs
        listMenus={[
          {
            name: 'Lojas de prêmios',
          },
        ]}
      />
      <HeaderMain>
        <TextHeaderMain>Abaixo listagem de produtos já cadastrados.</TextHeaderMain>
        <DisplayFlex>
          <SearchInput
            placeholder="Buscar produto..."
            search={searchReward}
            onChange={handleChangeSearchUser}
          />
          <Button
            style={{ marginRight: 16, width: 180 }}
            onClick={handleGoToInsertReward}
            icon="plus"
          >
            NOVO PRODUTO
          </Button>
        </DisplayFlex>
      </HeaderMain>

      <BoxHorizontalButtons
        items={[
          {
            textButton: 'Prêmios Ativos',
            children: renderTable(rewardsFilter.filter(reward => reward.enabled)),
          },
          {
            textButton: 'Prêmios Bloqueados',
            children: renderTable(rewardsFilter.filter(reward => !reward.enabled)),
          },
        ]}
      />
    </ContainerMain>
  );
};

export default Reward;
