import * as React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../../functions/colors';

type PIconLibrary = {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
};

const IconLink = ({ width, height, color, style }: PIconLibrary) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" style={style}>
    <mask id="prefix_IconLink" maskUnits="userSpaceOnUse" width={width} height={height}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 15.001H7C5.35 15.001 4 13.651 4 12.001C4 10.351 5.35 9.00098 7 9.00098H11V7.00098H7C4.24 7.00098 2 9.24098 2 12.001C2 14.761 4.24 17.001 7 17.001H11V15.001ZM17 7.00098H13V9.00098H17C18.65 9.00098 20 10.351 20 12.001C20 13.651 18.65 15.001 17 15.001H13V17.001H17C19.76 17.001 22 14.761 22 12.001C22 9.24098 19.76 7.00098 17 7.00098ZM16 11.001H8V13.001H16V11.001Z"
        fill={color}
      />
    </mask>
    <g mask="url(#prefix_IconLink)">
      <rect width={width} height={height} fill="#929898" />
    </g>
  </svg>
);

IconLink.defaultProps = {
  width: 24,
  height: 24,
  color: colors.white,
  style: {},
};

export default IconLink;
