import { Stage } from '../../../shared/modals/stage/stage';

export enum StageTypes {
  SET_STAGES = '@stage/SET_STAGES',
  SET_STAGE = '@stage/SET_STAGE',
}

export type StageState = {
  readonly stages: Stage[];
  readonly stage?: Stage;
};
