import fileDownload from 'js-file-download';
import { IMission as ListMissionInterface } from '../../features/mission/Mission';
import { IMission } from '../../features/mission/create/CreateEditMission';
import {
  ConnectApiGet,
  ConnectApiPost,
  ConnectApiPUT,
  ConnectApiDelete,
  urls,
} from '../functions/connection';
import { doLogout } from '../functions/connection/auth';
import { ERROR_MAINTENANCE } from '../constants/messages';
import { setSnackbar, SnackBarActionInterface } from '../../store/reducers/general/generalActions';

export const getAll = async (dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    const response = await ConnectApiGet(urls.MISSIONS_URL);
    const missions: ListMissionInterface[] = response.data;
    return missions;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }

  return [];
};

export const get = async (id: string, dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    const response = await ConnectApiGet(`${urls.MISSIONS_URL}/${id}`);
    const mission: IMission = response.data;
    return mission;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }

  return null;
};

export const create = async (attrs: IMission, dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    await ConnectApiPost(urls.MISSIONS_URL, { mission: attrs });
    dispatch(setSnackbar('Missão inserida com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const update = async (
  attrs: IMission | { enabled: boolean },
  id: string,
  dispatch: (arg: SnackBarActionInterface) => void,
) => {
  try {
    await ConnectApiPUT(`${urls.MISSIONS_URL}/${id}`, { mission: attrs });
    dispatch(setSnackbar('Missão modificada com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const destroy = async (id: string, dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    await ConnectApiDelete(`${urls.MISSIONS_URL}/${id}`);
    dispatch(setSnackbar('Missão excluída com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const duplicate = async (id: string, dispatch: (arg: SnackBarActionInterface) => void) => {
  try {
    await ConnectApiPost(`${urls.MISSIONS_URL}/${id}/duplicate`, {});
    dispatch(setSnackbar('Missão duplicada com sucesso!', 'success'));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === ERROR_MAINTENANCE) {
        doLogout();
      }
      dispatch(setSnackbar(error.message));
      throw new Error(error.message);
    }
  }
};

export const downloadReport = async () => {
  const url = urls.MISSIONS_REPORT_URL;
  const response = await ConnectApiGet(url);

  fileDownload(response.data, 'Relatório Missões.csv');
  return response;
};
