import styled from 'styled-components';
import { colors } from '../../functions/colors';
import { fonts } from '../../functions/fonts';

export const BoxRadiusBorder = styled.div`
  border: 1px solid ${colors.grey80};
  border-radius: 4px;
  padding: 2px 0px;
`;

export const BoxWhite = styled.div`
  background-color: ${colors.white};
`;

export const BoxButtonsMain = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
`;

export const BoxAccordions = styled.div`
  overflow: auto;
  max-height: calc(100vh - 200px);
`;

export const BoxAreas = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const BoxNameArea = styled.div`
  border-radius: 12px;
  padding: 2px 8px;
  background-color: ${colors.grey40};

  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  margin: 0px 8px 8px 0px;

  color: ${colors.black};
`;
