import React, { useState, Dispatch, SetStateAction } from 'react';

import { useContest } from '../../../store/reducers/contest';
import ModalConfirm from '../../../shared/components/modal/modalConfirm/ModalConfirm';
import { Contest } from '../../../shared/modals/contest/contest';
import { Paragraph } from '../../../shared/components/modal/modalConfirm/styles';

type PDeleteContest = {
  openDeleteContest: boolean;
  setOpenDeleteContest: Dispatch<SetStateAction<boolean>>;
  contest: Contest | undefined;
};

const DeleteContest = ({ openDeleteContest, setOpenDeleteContest, contest }: PDeleteContest) => {
  const [loading, setLoading] = useState(false);
  const { deleteContest } = useContest();

  if (!contest) return null;

  const handleDeleteContest = async () => {
    setLoading(true);
    await deleteContest(contest.idTourney);
    setLoading(false);
    setOpenDeleteContest(false);
  };

  const warningMsg = () => (
    <>
      <Paragraph style={{ marginBottom: '20px' }}>
        CUIDADO! Esta ação impacta as estatísticas dos relatórios, pois remove PERMANENTEMENTE:
      </Paragraph>
      <Paragraph>- O torneio;</Paragraph>
      <Paragraph>- As partidas (associadas ao torneio);</Paragraph>
      <Paragraph>- As respostas (associadas às partidas do torneio);</Paragraph>
    </>
  );

  return (
    <ModalConfirm
      loadingPrimary={loading}
      title="Tem certeza que deseja excluir o torneio?"
      message={warningMsg()}
      onClose={() => setOpenDeleteContest(false)}
      open={openDeleteContest}
      onClickPrimary={handleDeleteContest}
      onClickSecondary={() => setOpenDeleteContest(false)}
    />
  );
};

export default DeleteContest;
